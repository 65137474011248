const SkeletonAnnualCampaignCard = (): JSX.Element => {
  return (
    <div className="skeleton-annual-campaign-card">
      <div className="skeleton-annual-campaign-card--media" />
      <div className="skeleton-annual-campaign-card--wrapper">
        <div>
          <div className="skeleton-annual-campaign-card--title" />
          <div className="skeleton-annual-campaign-card--subtitle" />
          <div className="skeleton-annual-campaign-card--description skeleton-annual-campaign-card--description__1" />
          <div className="skeleton-annual-campaign-card--description skeleton-annual-campaign-card--description__2" />
          <div className="skeleton-annual-campaign-card--description skeleton-annual-campaign-card--description__3" />
        </div>
        <div>
          <div className="skeleton-annual-campaign-card--head" />
          <div className="skeleton-annual-campaign-card--label">
            <div className="skeleton-annual-campaign-card--label--left" />
            <div className="skeleton-annual-campaign-card--label--right" />
          </div>
          <div className="skeleton-annual-campaign-card--label">
            <div className="skeleton-annual-campaign-card--label--left" />
            <div className="skeleton-annual-campaign-card--label--right" />
          </div>
          <div className="skeleton-annual-campaign-card--label skeleton-annual-campaign-card--label__last">
            <div className="skeleton-annual-campaign-card--label--left" />
            <div className="skeleton-annual-campaign-card--label--right" />
          </div>
        </div>
      </div>
      <div className="skeleton-annual-campaign-card--separator" />
      <div className="skeleton-annual-campaign-card--link" />
    </div>
  );
};

export default SkeletonAnnualCampaignCard;
