import React, { useEffect, useRef, useState } from 'react';
import { YuIcon } from '@isdin/yuma-react-web-pin';
import { Row } from 'antd';
import _ from 'lodash';

interface IDisclaimerProps {
  messages: React.ReactNode[];
  messagesDelay: number;
}

const countCharsBetweenHtmlTags = (
  commercialDisclaimers: React.ReactNode[]
): boolean => {
  const MAX_LENGTH = 50;
  let largerDisclaimerMessage = '';

  //We identify the larger disclaimer inside the array of disclaimers
  for (const str of commercialDisclaimers) {
    if (_.isString(str) && str.length > largerDisclaimerMessage.length) {
      largerDisclaimerMessage = str;
    }
  }

  //We identify the strings that are bettween html tags
  const matches = largerDisclaimerMessage.match(/>(.*?)</g);
  //We clean the chracters > and < from the strings
  const cleanString = matches?.map((match) => match.slice(1, -1));
  // We count the total characters to decide they height of the disclaimer
  const totalCharacters = cleanString?.reduce(
    (total, str) => total + str.length,
    0
  );

  return totalCharacters > MAX_LENGTH;
};

const CommercialDisclaimer = ({
  messages,
  messagesDelay,
}: IDisclaimerProps) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const intervalRef = useRef<number>();

  const handleClickPrevious = () => {
    setCurrentMessageIndex((prevIndex) =>
      prevIndex === 0 ? messages.length - 1 : prevIndex - 1
    );
    resetInterval();
  };

  const handleClickNext = () => {
    setCurrentMessageIndex((prevIndex) =>
      prevIndex === messages.length - 1 ? 0 : prevIndex + 1
    );
    resetInterval();
  };

  const resetInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = window.setInterval(() => {
      setCurrentMessageIndex((prevIndex) =>
        prevIndex === messages.length - 1 ? 0 : prevIndex + 1
      );
    }, messagesDelay);
  };

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setCurrentMessageIndex((prevIndex) =>
        prevIndex === messages.length - 1 ? 0 : prevIndex + 1
      );
    }, messagesDelay);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [messages]);

  return messages.length ? (
    <Row
      align="top"
      justify="center"
      className={`disclaimer disclaimer-top disclaimer-top-mobile ${
        messages.length > 1 ? 'disclaimer-top-mobile_multiple-message' : ''
      }
      ${
        countCharsBetweenHtmlTags(messages)
          ? 'disclaimer-top-mobile_long-message'
          : ''
      }`}
    >
      {messages.length > 1 && (
        <YuIcon
          name="ChevronSmallLeft"
          size="M"
          onClick={handleClickPrevious}
          className="chevron"
        />
      )}

      {_.isString(messages[currentMessageIndex]) ? (
        <div
          className="message"
          dangerouslySetInnerHTML={{
            __html: messages[currentMessageIndex] as string,
          }}
        />
      ) : (
        messages[currentMessageIndex]
      )}

      {messages.length > 1 && (
        <YuIcon
          name="ChevronSmallRight"
          size="M"
          onClick={handleClickNext}
          className="chevron"
        />
      )}
    </Row>
  ) : (
    <></>
  );
};

export default CommercialDisclaimer;
