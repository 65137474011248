// TODO Move interfaces to /src/shared/models
export interface UserReferralData {
  products: ReferralProduct[];
}

export interface ReferralProduct {
  sku: number;
  name: string;
  image: string;

  pointsEarned: number;
  scannedDoses: number;
  scannedProducts: number;

  isExpanded?: boolean;
}

export interface IProperty {
  value: number;
  name: string;
}

export enum ModalSectionEnum {
  QR = 'qr',
  POINTS = 'points',
  USERS = 'users',
  SEARCH = 'search',
}

export enum UserRoleUserStatus {
  REFERRAL_FIRST_MODAL_NOT_SHOWN = 0,
  REFERRAL_FIRST_MODAL_SHOWN = 1,
}
