import {
  PlatformUserStatusEnum,
  UserStatusEnum,
} from 'src/shared/enums/user.enum';
import {
  CenterRolesEnum,
  UserCenterRequestEnum,
} from 'src/shared/enums/usersCenter.enum';
import { IUser } from 'src/shared/models';
import { isPlatformPIN, isUSAPlatform } from 'utils/platformUtils';

const {
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  ACCOUNT_NOT_VALIDATED,
  DENIED,
  HCP_NOT_VALIDATED,
  MANAGER_NOT_VALIDATED,
  NO_CENTER,
  STAFF_NOT_VALIDATED,
  MIGRATE_PENDING,
} = UserStatusEnum;
const {
  DECLINED: REQUEST_DECLINED,
  PENDING: REQUEST_PENDING,
  ACCEPTED: REQUEST_ACCEPTED,
} = UserCenterRequestEnum;

export const getUserStatus = (user: IUser): UserStatusEnum => {
  const { usersCenter, idSalesforce, userStatus } = user;
  let center;
  let userRequest;
  let idRole;
  switch (true) {
    case userStatus === PlatformUserStatusEnum.MIGRATE_PENDING:
      return MIGRATE_PENDING;

    case isPlatformPIN:
      return idSalesforce ? ACCEPTED_EMPLOYEE_PIN : ACCOUNT_NOT_VALIDATED;

    case isUSAPlatform:
      center = usersCenter?.filter((request) => request.status);
      if (!center?.length) return NO_CENTER;

      userRequest = center[0].userRequest;
      idRole = center[0].idRole;

      switch (true) {
        //Hasta ahora, aunque un usuario no estuviera validado en PIN, le estabamos seteando el status a ACCEPTED (ver segundo case)
        //Ahora en el primer case, si el status esta pending siempre devolveremos NOT_VALIDATED para que en el front esté bloqueado el perfil y el checkout
        case userStatus === PlatformUserStatusEnum.PENDING ||
          userStatus === PlatformUserStatusEnum.PENDING_FIRST_LOGIN:
          return idRole === CenterRolesEnum.MANAGER
            ? MANAGER_NOT_VALIDATED
            : ACCOUNT_NOT_VALIDATED;

        case userRequest === REQUEST_ACCEPTED:
          return idRole === CenterRolesEnum.MANAGER
            ? ACCEPTED_MANAGER
            : ACCEPTED_EMPLOYEE;

        case userRequest === REQUEST_DECLINED:
          return DENIED;

        case userRequest === REQUEST_PENDING:
          if (idRole === CenterRolesEnum.MANAGER) return MANAGER_NOT_VALIDATED;
          return !user.idSpecialty?.isHCP
            ? STAFF_NOT_VALIDATED
            : HCP_NOT_VALIDATED;

        default:
          return DENIED;
      }
    default:
      return DENIED;
  }
};
