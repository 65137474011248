import { config } from 'config/config';
import { NextPage } from 'next';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { IRootReducers } from 'src/reducers';
import { YuSitemap } from '@isdin/yuma-react-web-pin';
import { IMenu } from '../../shared/models';
import { getFooter } from '../menu/Menu.utils';
import _ from 'lodash';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { PLATFORM_PREFIX } = publicRuntimeConfig;

type Props = ReturnType<typeof mapStateToProps>;

const BasicPreFooterMenu: NextPage<Props, unknown> = ({
  authenticated,
  publicMenu,
  privateMenu,
}) => {
  const [menu, setMenu] = useState<IMenu[]>(
    getFooter(authenticated, publicMenu, privateMenu, false)
  );

  useEffect(() => {
    setMenu(getFooter(authenticated, publicMenu, privateMenu, false));
  }, [authenticated, publicMenu, privateMenu]);

  const socialLinks = useMemo(() => {
    const response = [];
    if (config.APP.RRSS.FACEBOOK) {
      response.push({
        href: `https://www.facebook.com/${config.APP.RRSS.FACEBOOK}/`,
        icon: 'Facebook',
        name: 'Facebook',
        targetBlank: true,
      });
    }
    if (config.APP.RRSS.INSTAGRAM) {
      response.push({
        href: `https://www.instagram.com/${config.APP.RRSS.INSTAGRAM}/`,
        icon: 'Instagram',
        name: 'Instagram',
        targetBlank: true,
      });
    }
    return response;
  }, [config.APP.RRSS.FACEBOOK, config.APP.RRSS.INSTAGRAM]);

  return (
    <div className={`sitemap-container ${!menu.length ? 'no-items' : ''}`}>
      <YuSitemap
        sections={_.map(menu, (item) => {
          return {
            category: item.name,
            links: _.map(item.subMenu, (subItem) => {
              return {
                href: subItem.href,
                nextAs: subItem.href.startsWith('http')
                  ? subItem.href
                  : `${PLATFORM_PREFIX}${subItem.href}`,
                name: subItem.name,
                targetBlank: subItem.href.startsWith('http'),
              };
            }),
          };
        })}
        social={{
          extraLinks: [],
          socialLinks: socialLinks,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    authenticated:
      state.auth.accessToken !== null && state.auth.accessToken !== '',
    userStatus: state.auth.userStatus,
    publicMenu: state.app.publicMenu,
    privateMenu: state.app.userMenu,
    isUSAPlatform: state.platform.isUSAPlatform,
  };
};

export default connect(mapStateToProps, null)(BasicPreFooterMenu);
