export enum IconEnum {
  NONE = 0,
  LEARN = 1,
  EVENT = 2,
  REQUEST_ORDER = 3,
  DOCUMENT = 4,
  GIFT = 5,
  ADDRESS = 6,
  CAMPAIGN = 7,
}
