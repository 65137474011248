import { Avatar, Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { IChallengeSpeaker } from 'src/shared/models';
import { config } from 'config/config';

const ChallengeSpeakers = ({
  challengeSpeakers,
}: {
  challengeSpeakers: IChallengeSpeaker[];
}): JSX.Element => {
  const buildSpeakerCard = (
    speaker: IChallengeSpeaker,
    justify: 'start' | 'end' | 'center',
    index: number
  ): JSX.Element => {
    if (!speaker) {
      return <></>;
    }

    return (
      <div className={'speaker-card'} key={`challenge-speaker-${index}`}>
        <Col className={`speaker-container speaker-container__${justify}`}>
          <Col className="speaker-image">
            <Avatar
              src={
                speaker?.imageURL || `${config.APP.BASE_URL}/delegate_back.svg`
              }
            />
          </Col>
          <Col className="speaker-data">
            <div>
              <Row className="speaker-name">{speaker.name}</Row>
            </div>
            <div className="speaker-info">
              <Row className="speaker-job">{speaker.job}</Row>
            </div>
            <div className="speaker-info">
              <Row className="speaker-email">{speaker.email}</Row>
            </div>
            <div className="speaker-info">
              <Row className="speaker-phone">{speaker.phone}</Row>
            </div>
          </Col>
        </Col>
      </div>
    );
  };
  const rowJustify = challengeSpeakers?.length > 1 ? 'start' : 'center';

  const renderSpeakers = (): JSX.Element[] => {
    const speakers = challengeSpeakers
      .sort((a, b) => a.order - b.order)
      .map((challengeSpeaker, index) => {
        let justify: 'start' | 'end' | 'center' = 'center';

        if (challengeSpeakers.length > 1) {
          justify = index % 2 == 0 ? 'end' : 'start';
        }

        return buildSpeakerCard(challengeSpeaker, justify, index);
      });

    return speakers;
  };

  return challengeSpeakers.length == 0 ? null : (
    <>
      <Row justify="center" className="challengeDetail--speakers">
        <Col span={24}>
          <Row justify={rowJustify}>{renderSpeakers()}</Row>
        </Col>
      </Row>
    </>
  );
};

export default connect(null)(ChallengeSpeakers);
