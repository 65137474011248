import React, { FC, MouseEventHandler } from 'react';
import { Button, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

interface OwnProps {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  showRight: boolean;
}

export const ToggleButtons = (
  onRestore: MouseEventHandler<HTMLElement>,
  onAccept: MouseEventHandler<HTMLElement>,
  loading?: boolean,
  onAcceptDisabled?: boolean
) => {
  return (
    <Row
      className="toggle-contents-buttons"
      justify="space-between"
      align="middle"
    >
      <Col className="toggle-column toggle-column--left toggle-column--over">
        <Button
          className="btn-primary"
          data-testid="button-back"
          block
          onClick={onRestore}
          disabled={loading}
        >
          <Row justify="space-between" align="middle">
            <div className="icon icon--arrow-normal-left pages-link__icon" />
            <FormattedMessage id="button.back" />
          </Row>
        </Button>
      </Col>

      <Col className="toggle-column toggle-column--right">
        <Button
          className="btn-primary btn-primary--black"
          data-testid="button-save"
          block
          disabled={onAcceptDisabled}
          onClick={onAccept}
          loading={loading}
        >
          <FormattedMessage id="button.save" />
        </Button>
      </Col>
    </Row>
  );
};

const Toggle: FC<OwnProps> = ({
  leftComponent,
  rightComponent,
  showRight,
}): JSX.Element => {
  return showRight ? <>{rightComponent}</> : <>{leftComponent}</>;
};

export default Toggle;
