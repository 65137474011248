import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { auto } from '@popperjs/core';
import _ from 'lodash';
import { NextPage } from 'next';
import ConditionalRenderer from 'src/components/ConditionalRenderer';

const SkeletonMyBag: NextPage = (): JSX.Element => {
  const { upXS, upL, upXL, upS } = useSize();

  const skeletonRowDesktop = () => {
    return (
      <YuFlex flexDirection="row" style={{ width: upXS ? 'auto' : '100%' }}>
        <YuSkeleton
          marginLeft={'XS'}
          marginRight={'XS'}
          height={70}
          variant="text"
          width={'80px'}
        />
        <YuFlex marginRight={'M'} flexDirection="column">
          <YuSpacer size="XXS" />
          <YuSkeleton height={20} variant="text" width={'320px'} />
          <YuSpacer size="XXS" />
          <YuSkeleton height={20} variant="text" width={'100px'} />
          <YuSpacer size="XXS" />
        </YuFlex>
        <YuFlex style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <YuSkeleton
            marginLeft={'XXS'}
            marginRight={'S'}
            height={40}
            variant="text"
            width={'115px'}
          />
        </YuFlex>
        <YuFlex style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <YuSkeleton
            marginLeft={'XL'}
            marginRight={'XS'}
            height={22}
            variant="text"
            width={'100px'}
          />
        </YuFlex>
        <YuFlex style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <YuSkeleton
            marginLeft={'XS'}
            height={24}
            variant="text"
            width={'24px'}
          />
        </YuFlex>
      </YuFlex>
    );
  };

  const skeletonRowTablet = () => {
    return (
      <YuFlex flexDirection="row" style={{ width: '100%' }}>
        <YuFlex
          width={'15%'}
          justify={'center'}
          marginLeft={'XS'}
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
        >
          <YuSkeleton height={70} variant="text" width={'80px'} />
        </YuFlex>
        <YuFlex
          flexDirection="column"
          width={'25%'}
          marginLeft={'S'}
          justify={'center'}
        >
          <YuSpacer size="XXS" />
          <YuSkeleton height={20} variant="text" width={'80%'} />
          <YuSpacer size="XXS" />
          <YuSkeleton height={20} variant="text" width={'80%'} />
          <YuSpacer size="XXS" />
          <YuSkeleton height={20} variant="text" width={'60px'} />
          <YuSpacer size="XXS" />
        </YuFlex>
        <YuFlex
          width={'15%'}
          justify={'start'}
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
        >
          <YuSkeleton height={40} variant="text" width={'115px'} />
        </YuFlex>
        <YuFlex flexDirection="row" width={'45%'} justify={'center'}>
          <YuFlex style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <YuSkeleton
              marginRight={'XS'}
              height={22}
              variant="text"
              width={'100px'}
            />
          </YuFlex>
          <YuFlex style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <YuSkeleton
              marginLeft={'XS'}
              height={24}
              variant="text"
              width={'24px'}
            />
          </YuFlex>
        </YuFlex>
      </YuFlex>
    );
  };

  const skeletonRowMobile = () => {
    return (
      <YuFlex flexDirection="row" style={{ width: '100%' }}>
        <YuFlex width={'25%'} justify={'center'}>
          <YuSkeleton height={80} variant="text" width={'80px'} />
        </YuFlex>
        <YuFlex
          flexDirection="column"
          width={'55%'}
          marginLeft={'S'}
          justify={'center'}
        >
          <YuSkeleton height={22} variant="text" width={'100%'} />
          <YuSpacer size="XXS" />
          <YuSkeleton height={22} variant="text" width={'70%'} />
          <YuSpacer size="XS" />
          <YuSkeleton height={16} variant="text" width={'90px'} />
          <YuSpacer size="XS" />
          <YuSkeleton height={32} variant="text" width={'97px'} />
        </YuFlex>

        <YuFlex flexDirection="row" width={'20%'} justify={'center'}>
          <YuSkeleton
            marginLeft={'XS'}
            height={20}
            variant="text"
            width={'20px'}
          />
        </YuFlex>
      </YuFlex>
    );
  };

  const SkeletonMyBagDesktop = (): JSX.Element => {
    return (
      <>
        <YuSkeleton
          height={19}
          variant="text"
          width={upXS ? '70px' : '100%'}
          style={{ marginLeft: upXL ? '200px' : '80px', marginTop: '30px' }}
        />
        <YuSkeletonWrapper className="yuma-custom-skeleton container">
          <YuFlex flexDirection="column" style={{ margin: 'auto' }}>
            <YuSpacer size="L" />
            <YuFlex justify={'center'}>
              <YuSkeleton height={36} variant="text" width={'160px'} />
            </YuFlex>
            <YuFlex flexDirection="column">
              <YuSpacer size="M" />
              <YuFlex flexDirection="row">
                <YuSkeleton
                  marginLeft={'XXL'}
                  marginRight={'XXL'}
                  height={20}
                  variant="text"
                  width={'100px'}
                />
                <YuSkeleton
                  marginLeft={'XXXL'}
                  height={20}
                  variant="text"
                  width={'80px'}
                />
                <YuSkeleton
                  marginLeft={'XXXL'}
                  marginRight={'L'}
                  height={20}
                  variant="text"
                  width={'100px'}
                />
              </YuFlex>
              {_.times(3, () => {
                return (
                  <>
                    <YuSpacer size="S" />
                    <YuSkeleton height={1} variant="text" width={'100%'} />
                    <YuSpacer size="M" />
                    <YuFlex gap={upL ? 'L' : 'M'} wrap="wrap" justify="start">
                      {skeletonRowDesktop()}
                    </YuFlex>
                  </>
                );
              })}
            </YuFlex>
            <YuSpacer size="S" />
            <YuSkeleton height={1} variant="text" width={'100%'} />
            <YuSpacer size="L" />
            <YuFlex flexDirection="row" justify={'end'}>
              <YuSkeleton height={22} width={64} variant="text" />
              <YuSkeleton
                height={22}
                width={120}
                marginLeft={'XXL'}
                variant="text"
              />
            </YuFlex>
            <YuSpacer size="L" />
            <YuFlex flexDirection="row" justify={'end'}>
              <YuSkeleton height={50} width={300} variant="text" />
            </YuFlex>
            <YuSpacer size="L" />
          </YuFlex>
        </YuSkeletonWrapper>
      </>
    );
  };

  const SkeletonMyBagTablet = (): JSX.Element => {
    return (
      <>
        <YuSkeleton
          height={19}
          variant="text"
          width={upXS ? '70px' : '100%'}
          style={{ marginLeft: upXL ? '200px' : '80px', marginTop: '30px' }}
        />
        <YuSkeletonWrapper>
          <YuFlex flexDirection="column">
            <YuSpacer size="L" />
            <YuFlex justify={'center'}>
              <YuSkeleton height={36} variant="text" width={'160px'} />
            </YuFlex>
            <YuFlex flexDirection="column">
              <YuSpacer size="M" />

              <YuFlex flexDirection="row">
                <YuFlex width={'43%'} justify={'center'}>
                  <YuSkeleton
                    height={20}
                    variant="text"
                    marginLeft={'M'}
                    width={'100px'}
                  />
                </YuFlex>
                <YuFlex width={'27%'} justify={'start'}>
                  <YuSkeleton height={20} variant="text" width={'80px'} />
                </YuFlex>
                <YuFlex width={'30%'} justify={'start'}>
                  <YuSkeleton
                    marginRight={'L'}
                    height={20}
                    variant="text"
                    width={'100px'}
                  />
                </YuFlex>
              </YuFlex>
              {_.times(3, () => {
                return (
                  <>
                    <YuSpacer size="S" />
                    <YuSkeleton height={1} variant="text" width={'100%'} />
                    <YuSpacer size="M" />
                    <YuFlex gap={upL ? 'L' : 'M'} wrap="wrap" justify="start">
                      {skeletonRowTablet()}
                    </YuFlex>
                  </>
                );
              })}
            </YuFlex>
            <YuSpacer size="S" />
            <YuSkeleton height={1} variant="text" width={'100%'} />
            <YuSpacer size="L" />
            <YuFlex flexDirection="row">
              <YuSkeleton
                height={22}
                width={64}
                variant="text"
                marginLeft={'XS'}
                style={{ marginRight: auto }}
              />
              <YuSkeleton
                height={22}
                width={120}
                marginRight={'XS'}
                variant="text"
              />
            </YuFlex>
            <YuSpacer size="L" />
            <YuFlex flexDirection="row">
              <YuSkeleton
                height={50}
                width="100%"
                variant="text"
                marginRight={'XS'}
                marginLeft={'XS'}
              />
            </YuFlex>
            <YuSpacer size="L" />
          </YuFlex>
        </YuSkeletonWrapper>
      </>
    );
  };

  const SkeletonMyBagMobile = (): JSX.Element => {
    return (
      <>
        <YuSkeleton
          height={19}
          variant="text"
          width={'70px'}
          style={{
            marginLeft: '40px',
            marginTop: '30px',
          }}
        />
        <YuSkeletonWrapper>
          <YuFlex flexDirection="column">
            <YuSpacer size="L" />
            <YuFlex justify={'center'}>
              <YuSkeleton height={36} variant="text" width={'160px'} />
            </YuFlex>
            <YuFlex flexDirection="column">
              <YuSpacer size="XL" />
              {_.times(3, () => {
                return (
                  <>
                    <YuFlex gap={upL ? 'L' : 'M'} wrap="wrap" justify="start">
                      {skeletonRowMobile()}
                    </YuFlex>
                    <YuSpacer size="L" />
                    <YuSkeleton height={1} variant="text" width={'100%'} />
                    <YuSpacer size="L" />
                  </>
                );
              })}
            </YuFlex>
            <YuFlex flexDirection="row">
              <YuSkeleton
                height={22}
                width={64}
                variant="text"
                marginLeft={'XS'}
                style={{ marginRight: auto }}
              />
              <YuSkeleton
                height={22}
                width={120}
                marginRight={'XS'}
                variant="text"
              />
            </YuFlex>
            <YuSpacer size="L" />
            <YuFlex flexDirection="row">
              <YuSkeleton
                height={50}
                width="100%"
                variant="text"
                marginRight={'XS'}
                marginLeft={'XS'}
              />
            </YuFlex>
            <YuSpacer size="L" />
          </YuFlex>
        </YuSkeletonWrapper>
      </>
    );
  };
  return (
    <ConditionalRenderer
      condition={upS}
      trueComponent={<SkeletonMyBagDesktop />}
      falseComponent={
        <ConditionalRenderer
          condition={upXS}
          trueComponent={<SkeletonMyBagTablet />}
          falseComponent={<SkeletonMyBagMobile />}
        />
      }
    />
  );
};
export default SkeletonMyBag;
