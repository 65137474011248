const SkeletonTextCenter = (): JSX.Element => {
  return (
    <div className="text-center-skeleton">
      <div className="text-center-skeleton-title" />
      <div className="text-center-skeleton-description text-center-skeleton-description__1" />
      <div className="text-center-skeleton-description text-center-skeleton-description__2" />
    </div>
  );
};

export default SkeletonTextCenter;
