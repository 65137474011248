import { IUser } from '../shared/models';
import { useUserIsDermatologistSpecialty } from './useUserIsDermatologistSpecialty';
import { useShowUserProfileSpecialtyExtraFields } from './useShowUserProfileSpecialtyExtraFields';
import { PlatformUserStatusEnum } from '../shared/enums';

export const useShowCompleteProfile = (user: IUser): boolean => {
  if (
    ![
      PlatformUserStatusEnum.GRANT,
      PlatformUserStatusEnum.MIGRATE_GRANT,
      PlatformUserStatusEnum.GRANT_FIRST_LOGIN,
    ].includes(user?.userStatus)
  )
    return false;
  const showUserProfileSpecialtyExtraFields =
    useShowUserProfileSpecialtyExtraFields(user);
  // if showUserProfileSpecialtyExtraFields is true return false because all fields are filled
  if (showUserProfileSpecialtyExtraFields) return false;

  const isDermatologist = useUserIsDermatologistSpecialty(user);

  // Add more
  return isDermatologist; // || isMedic;
};
