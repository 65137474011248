import { Button, Col, Form, notification, Row } from 'antd';
import routePaths from 'config/routes';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { deleteDataCall, postDataCall, putDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import ChallengeCard from 'src/components/challenge/ChallengeCard';
import { ChallengeDetailsBox } from 'src/components/challenge/ChallengeDetailsBox';
import ChallengeResourceWrapper from 'src/components/challenge/ChallengeResourceWrapper';
import Link from 'src/components/Link';
import PearlForms from 'src/components/pearl/PearlForms';
import PearlList from 'src/components/pearl/PearlList';
import Toggle from 'src/components/Toggle';
import { ResourceTypeEnum } from 'src/shared/enums';
import {
  ChallengeCardButton,
  ChallengeDetail,
  IPearl,
  IRequestPearlData,
  IUserPearl,
} from 'src/shared/models';
import {
  customFormatMessage,
  getShortDataFormat,
  getTranslatedData,
} from '../../../utils';
import { DateTime } from 'luxon';

export const renderLinkedPearl = (
  challenge: ChallengeDetail,
  isLinkedChallenge?: boolean
): JSX.Element => {
  const { formatMessage } = useIntl();

  const config: ChallengeDetail = {
    ...challenge,
    challengeName: getTranslatedData(challenge, 'name'),
    challengeDescription:
      getTranslatedData(challenge, 'description') ||
      getTranslatedData(challenge, 'descriptionLong'),
    isLinkedChallenge,
  };

  const pearlResourceData: IPearl = challenge.challengeResources.find(
    (_resource) =>
      _resource.resource.idResourceType.idResourceType ===
      ResourceTypeEnum.PEARL
  ).config;

  const startDate = DateTime.fromJSDate(
    new Date(config.challengeDates.startDate)
  ).toFormat(getShortDataFormat());
  const endDate = DateTime.fromJSDate(
    new Date(config.challengeDates.endDate)
  ).toFormat(getShortDataFormat());

  const component = (
    <ChallengeDetailsBox
      allowRetry={false}
      completed={false}
      date={`${startDate}-${endDate}`}
      isPearl={true}
      description={config.challengeDescription.trim()}
      detailTitle={config.challengeName}
      disabled={false}
      image={config.cardImage}
      points={config.challengePoints || 0}
      title={config.challengeName}
      isCampaignComponent={false}
      sharePearl={true}
      showButton={!pearlResourceData.isUserPearlActive}
      challengeSlug={config.slug}
      idParentChallenge={config?.idParentChallenge}
    />
  );

  return (
    <Row
      justify="center"
      className="challengeDetail--quiz challengeDetail--campaign-quiz"
    >
      <ChallengeResourceWrapper
        children={component}
        title={null}
        styleClass="pearl__linked-challenge"
        description={null}
        icon={null}
        points={null}
        bottomLabel={
          pearlResourceData?.isUserPearlActive && {
            label: pearlResourceData?.pearlStatusLabel,
            style: pearlResourceData?.formattedPearlStatus,
          }
        }
      />
      {pearlResourceData?.isUserPearlActive && (
        <Row className="pearl__link-buttons" gutter={[32, 0]}>
          <Col span={12}>
            <Button className="btn-ghost btn-ghost--black">
              <Link
                href={{
                  pathname: routePaths.PAGES.PEARL_DETAIL,
                  query: {
                    id: config.slug,
                    idParentChallenge: config?.idParentChallenge,
                  },
                }}
              >
                {formatMessage({
                  id: 'page.challenge.pearl.completed-show-pearls',
                })}
              </Link>
            </Button>
          </Col>
          <Col span={12}>
            <Button className="btn-ghost btn-ghost--black">
              <Link
                href={{
                  pathname: routePaths.PAGES.PEARL_DETAIL,
                  query: {
                    id: config.slug,
                    viewMyPearl: true,
                    idParentChallenge: config?.idParentChallenge,
                  },
                }}
              >
                {formatMessage({
                  id: 'page.challenge.pearl.completed-show-my-pearl',
                })}
              </Link>
            </Button>
          </Col>
        </Row>
      )}
    </Row>
  );
};

export const renderChallengePearl = (
  challenge: ChallengeDetail
): JSX.Element => {
  const { formatMessage } = useIntl();

  let userStatus: { idResource: number; status: number };
  const challengeCardButton: ChallengeCardButton = {
    className: 'challengeCard__link challengeCard__link--active',
    path: routePaths.PAGES.PEARL_DETAIL,
    title: formatMessage({ id: 'pearl.see' }),
  };

  const config: ChallengeDetail = {
    ...challenge,
    challengeName: getTranslatedData(challenge, 'name'),
    challengeDescription:
      getTranslatedData(challenge, 'description') ||
      getTranslatedData(challenge, 'descriptionLong'),
    challengeCardButton,
    userStatus,
  };

  return <ChallengeCard {...config} />;
};

export const ChallengePearl = ({
  config,
  updateChallenge,
  user,
  accessToken,
}) => {
  const [form] = Form.useForm();
  const router = useRouter();
  const [usersPearls, setUsersPearls] = useState<IUserPearl[]>([]);
  const [loggedUserPearl, setLoggedUserPearl] = useState<IUserPearl>(null);
  const [createPearl, setCreatePearl] = useState<boolean>(false);
  const [pearlSelected, setPearlSelected] = useState<IUserPearl>(null);
  const [showOkModal, setShowOkModal] = useState<boolean>(false);
  const { query } = router;
  const { formatMessage } = useIntl();

  const pearlConditions = config?.conditions;

  const handleCreateOrUpdatePearl = async (data: IRequestPearlData) => {
    const userData = {
      pearlDetails: {
        ...data,
        userName: user.salesforce.userName,
        idUser: user.idUser,
      },
      idResource: config.idResource,
    };

    const requestAction = pearlSelected ? putDataCall : postDataCall;

    try {
      await requestAction({
        dataPath: apiPaths.CHALLENGES.USER_RESOURCE_PEARL,
        data: userData,
        callConfig: {},
      }).then((_response) => {
        // TODO: modal is not showing
        window?.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth',
        });
        setShowOkModal(true);
        updateChallenge(_response.data);
      });
    } catch (err) {
      notification.error({
        message: formatMessage({
          id: 'form.address.error',
        }),
      });
    } finally {
      setCreatePearl(false);
      setPearlSelected(null);
    }
  };

  const handleDeletePearl = async () => {
    try {
      await deleteDataCall({
        dataPath: apiPaths.CHALLENGES.USER_RESOURCE_PEARL,
        id: pearlSelected?.idUserResource,
        callConfig: {},
      }).then(() => {
        setLoggedUserPearl(null);
      });
    } catch (err) {
      notification.error({
        message: customFormatMessage('app.error.generic'),
      });
    } finally {
      setCreatePearl(false);
      setPearlSelected(null);
    }
  };

  useEffect(() => {
    setUsersPearls(config.otherUserPearls);
    setLoggedUserPearl(config.loggedUserPearl);
  }, [config]);

  useEffect(() => {
    if (query?.createPearl === 'true') {
      setCreatePearl(true);
    }
  }, [query]);

  useEffect(() => {
    if (query?.viewMyPearl === 'true') {
      setPearlSelected(loggedUserPearl);
    }
  }, [query, loggedUserPearl]);

  return (
    <div className="container">
      <Toggle
        key={`pearl-details-${config.challengeId}`}
        showRight={Boolean(createPearl || pearlSelected)}
        leftComponent={
          <PearlList
            loggedUserPearl={loggedUserPearl}
            setPearlSelected={setPearlSelected}
            usersPearls={usersPearls}
          />
        }
        rightComponent={
          <PearlForms
            form={form}
            setCreatePearl={setCreatePearl}
            onAccept={handleCreateOrUpdatePearl}
            pearl={config}
            accessToken={accessToken}
            pearlSelected={pearlSelected}
            setPearlSelected={setPearlSelected}
            isLoggedUserPearl={
              pearlSelected && pearlSelected?.idUser === user?.idUser
            }
            setShowOkModal={setShowOkModal}
            showOkModal={showOkModal}
            pearlConditions={pearlConditions}
            handleDeletePearl={handleDeletePearl}
          />
        }
      />
    </div>
  );
};
