import { FC, useEffect, useRef, useState } from 'react';
import CustomModal from '../CustomModal';
import { JourneyModalIterator } from './modal_journey_iterator';
import React from 'react';

interface OwnProps {
  isLoading: boolean;
  isOnCourse: boolean;
  journeyCollection: JourneyModalIterator;
}

const ModalsJourney: FC<OwnProps> = ({
  isLoading,
  isOnCourse,
  journeyCollection,
}): JSX.Element => {
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  const stepsController = useRef(0);

  journeyCollection?.setStepController(stepsController);

  useEffect(() => {
    document.body.classList.add('not-overflow');

    if (!shouldShowModal) {
      document.body.classList.remove('not-overflow');

      return () => {
        journeyCollection.resetSteps();
      };
    }

    return () => {
      journeyCollection.closeJourney();
      journeyCollection.resetSteps();
    };
  }, [shouldShowModal]);

  useEffect(() => {
    setShouldShowModal(isOnCourse);
  }, [isOnCourse]);

  return (
    <CustomModal
      {...{
        loading: isLoading,
        visible: shouldShowModal,
        content: journeyCollection.getCurrentStepContent(),
        onCancel: () => {
          setShouldShowModal(false);
        },
        destroyOnClose: true,
        centered: true,
        hideFooter: true,
        showIcon: false,
        customClass: journeyCollection.getCurrentStepCustomClass() ?? '',
      }}
    />
  );
};

export default ModalsJourney;
