import { useEffect, useState } from 'react';
import { getHeaderHeight } from '../helpers/getHeaderHeight';

export const useHeaderHeight = (): number => {
  const [height, setHeight] = useState<number>(0);

  const checkHeight = () => {
    const newHeight = getHeaderHeight();
    if (height !== newHeight) {
      setHeight(newHeight);
    }
  };

  useEffect(() => {
    const interval = setInterval(checkHeight, 1000 / 30);
    return () => {
      clearInterval(interval);
    };
  }, [height]);

  return height;
};
