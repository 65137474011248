import React from 'react';
import { InfoModal } from './InfoModal';

export const InfoModalContext = React.createContext(null);
InfoModalContext.displayName = 'InfoModalContext';

type InfoModalContextProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  buttonText: string;
};

export const InfoModalContextProvider = ({ children }) => {
  const [state, setState] = React.useState<InfoModalContextProps>();

  const showInfoModal = (params: InfoModalContextProps) => {
    setState(params);
  };

  const hideInfoModal = () => {
    setState(null);
  };

  return (
    <InfoModalContext.Provider
      value={{ ...state, showInfoModal, hideInfoModal }}
    >
      <InfoModal
        opened={!!state}
        onClose={hideInfoModal}
        title={state?.title}
        description={state?.description}
        buttonText={state?.buttonText}
      />
      {children}
    </InfoModalContext.Provider>
  );
};
