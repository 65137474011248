import {
  useSize,
  YuFlex,
  YuParagraph,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { FormattedMessage, useIntl } from 'react-intl';
import { BannerReferenceLimitedInfo } from '../../orderLimits/bannerReferenceLimitedInfo';
import React from 'react';
import { MyBagProductQtyButtons } from './MyBagProductQtyButtons';
import { MyBagProductTrash } from './MyBagProductTrash';

export const MyBagProductBagMode = ({
  product,
  maxProductsOrdered,
  handleRemoveProduct,
  name,
  orderRestrictions,
  annualMaxQuantityProduct,
  handleChangeQuantity,
  maxQuantity,
  canUpdateProduct,
  price,
  setProductLoading,
  qty,
}) => {
  const size = useSize();
  const { formatNumber } = useIntl();
  return (
    <>
      <YuFlex
        flexDirection="row"
        align="top"
        justify="center"
        className="my-bag__product no-border no-space"
      >
        <YuFlex style={{ maxWidth: 148, width: '25%' }}>
          <img
            style={{ objectFit: 'contain' }}
            className="product__image"
            src={product.image}
            loading="lazy"
          />
        </YuFlex>
        <YuFlex style={{ width: '75%' }} align="top" flexDirection="column">
          <YuFlex flexDirection="row">
            <YuFlex style={{ width: size.upL ? '70%' : '55%' }}>
              <YuParagraph size="L" fw="sbold">
                {name}
              </YuParagraph>
            </YuFlex>
            <YuFlex style={{ width: size.upL ? '30%' : '45%' }}>
              <div style={{ width: '100%' }}>
                <YuParagraph
                  size={{ XS: 'L', L: 'XL' }}
                  fw="bold"
                  style={{ textAlign: 'right' }}
                >
                  <FormattedMessage
                    id="menu.section.personal.{points}-point"
                    values={{ points: formatNumber(price * qty) }}
                  />
                </YuParagraph>
              </div>
            </YuFlex>
          </YuFlex>
          <YuSpacer size={{ initial: 'XS', upL: 'S' }} />
          <YuFlex align="top" flexDirection="row">
            <YuFlex style={{ width: '70%' }}>
              {canUpdateProduct ? (
                <MyBagProductQtyButtons
                  {...{
                    orderRestrictions,
                    annualMaxQuantityProduct,
                    maxQuantity,
                    handleChangeQuantity,
                    qty,
                  }}
                />
              ) : null}
            </YuFlex>
            <YuFlex style={{ width: '30%' }}>
              <YuFlex
                style={{ width: '100%' }}
                flexDirection="column"
                alignItems="end"
              >
                {handleRemoveProduct ? (
                  <MyBagProductTrash
                    {...{
                      handleRemoveProduct,
                      product,
                      setProductLoading,
                    }}
                  />
                ) : null}
              </YuFlex>
            </YuFlex>
          </YuFlex>
        </YuFlex>
      </YuFlex>
      {maxQuantity <= qty && !maxProductsOrdered ? (
        <YuFlex flexDirection="column" style={{ width: '100%' }}>
          <YuSpacer size="XS" />
          <BannerReferenceLimitedInfo remaining={maxQuantity} />
        </YuFlex>
      ) : null}
    </>
  );
};
