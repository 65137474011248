export const routePaths = {
  BASE: '',
  INDEX: '/',
  ERROR: '/error',
  FOOTER: {
    CONTACT_US: '/contact-us',
  },
  PAGES: {
    ANNUAL_CAMPAIGN: '/campaign/annual',
    CAMPAIGN: '/campaign',
    CAMPAIGN_DETAIL: '/campaign/[id]',
    CERTIFIED_COURSES: '/certified-course',
    CONTACT_REP: '/contact-rep',
    LOGIN: '/login',
    MY_ACCOUNT_EDIT: '/accountEdit',
    MY_ORDERS: '/orders',
    RECOVERY_CHANGE: '/recovery',
    RECOVERY_PASSWORD: '/recovery-password',
    SUBSCRIBE_COMMERCIALS: '/subscribe-commercials',
    ASSIGN_NEW_PASSWORD: '/account/assign-new-password',
    SIGNUP: '/register',
    TALK: '/talk',
    TALKS: '/talks',
    TALK_DETAIL: '/talk/[id]',
    CONGRESS: '/congress',
    CONGRESS_DETAIL: '/congress/[id]',
    TRAININGS: '/trainings',
    TRAINING: '/training',
    TRAINING_DETAIL: '/training/[id]',
    PEARL: '/pearl',
    PEARL_DETAIL: '/pearl/[id]',
    REDEEM_POINTS: {
      CHECKOUT: '/redeem-points/checkout',
      DETAILS: '/redeem-points/[id]',
      MAIN: '/redeem-points',
      MY_BAG: '/redeem-points/my-bag',
    },
    REQUEST_ORDER: '/request-order',
    MARKETING_MATERIALS: '/marketing-materials',
    SCIENTIFIC_MATERIALS: '/scientific-materials',
    LOGOUT: '/logout',
    SURVEY: '/survey',
    SURVEY_DYNAMIC: '/survey/[id]',
    MY_ACCOUNT: {
      ACCOUNT_INDEX: '/account',
      MY_PROFILE: '/account/my-profile',
      PROFILE_EXTRA_FIELDS: '/account/profile/complete',
      MY_POINTS: '/account/my-points',
      HISTORY: '/account/points-historical',
      DASHBOARD_QR: '/account/dashboard-qr',
      ORDERS: '/account/orders',
      ORDER_DETAILS: '/account/orders/[id]',
      MY_ADDRESSES: '/account/addresses',
    },
    MY_CENTER: {
      CENTER_INDEX: '/center',
      CENTER_PROFILE: '/center/profile',
      CENTER_MEMBERS: '/center/members',
    },
    RESET_PASSWORD: '/reset-password',
  },
  IMPERSONATE: '/impersonate',
  STATIC: '/static',
  FAQS: '/static?page=/faqs',
};

// TODO: Remove all related cause now are static pages
export const deprecatedRoutePaths = {
  FOOTER: {
    TERMS: '/terms-conditions',
    PRIVACY_POLICY: '/privacy-policy',
    STATIC_PAGE_REFERRALS_FAQS: '/faqs-love-isdin-pro-samples',
  },
  PAGES: {
    TALKS: '/talks',
    TRAININGS: '/trainings',
  },
};

export const publicPaths = [
  routePaths.INDEX,
  routePaths.IMPERSONATE,
  routePaths.PAGES.LOGIN,
  routePaths.PAGES.SIGNUP,
  routePaths.PAGES.RECOVERY_PASSWORD,
  routePaths.PAGES.ASSIGN_NEW_PASSWORD,
  routePaths.FOOTER.CONTACT_US,
  routePaths.PAGES.ASSIGN_NEW_PASSWORD,
  routePaths.STATIC,
  routePaths.ERROR,
  routePaths.PAGES.LOGOUT,
  routePaths.PAGES.RESET_PASSWORD,
];

export const checkRoute = (routeName: string, routes: object): boolean => {
  let included = false;

  for (const routeKey in routes) {
    const route = routes[routeKey];

    if (typeof route === 'string') {
      included = route === routeName;
    } else {
      included = checkRoute(routeName, route);
    }

    if (included) break;
  }

  return included;
};

export const isRouteDefined = (route: string, withoutParams?: string) => {
  const hasParams = route.includes('?');

  const allowParams = routeAllowParams(withoutParams ?? route, [
    routePaths.BASE,
    routePaths.INDEX,
    routePaths.PAGES.ANNUAL_CAMPAIGN,
    routePaths.PAGES.CAMPAIGN_DETAIL,
    routePaths.PAGES.CAMPAIGN,
    routePaths.PAGES.TRAINING,
    routePaths.PAGES.SCIENTIFIC_MATERIALS,
    routePaths.PAGES.REDEEM_POINTS.MAIN,
    routePaths.PAGES.PEARL,
    routePaths.PAGES.TALKS,
    routePaths.PAGES.LOGIN,
    routePaths.PAGES.SIGNUP,
    routePaths.PAGES.RECOVERY_PASSWORD,
    routePaths.PAGES.SUBSCRIBE_COMMERCIALS,
    routePaths.PAGES.ASSIGN_NEW_PASSWORD,
    routePaths.PAGES.TALK_DETAIL,
    routePaths.PAGES.TRAINING_DETAIL,
    routePaths.PAGES.SURVEY,
    routePaths.PAGES.SURVEY_DYNAMIC,
    routePaths.PAGES.PEARL_DETAIL,
    routePaths.PAGES.MY_ACCOUNT.ORDER_DETAILS,
    routePaths.STATIC,
    routePaths.PAGES.LOGOUT,
    routePaths.PAGES.RESET_PASSWORD,
  ]);

  const allowRoute =
    isSurveyDetail(route) ||
    checkRoute(withoutParams ?? route, routePaths) ||
    checkRoute(withoutParams ?? route, deprecatedRoutePaths.PAGES);

  return hasParams ? allowParams : allowRoute;
};

const routeAllowParams = (route: string, routePaths: string[]) => {
  let included = false;

  routePaths.forEach((path) => {
    included = included || route === path;
  });

  return included;
};

// !LEGACY -- Should be removed
export const isChallengeDetail = (route = '') => {
  return (
    route.includes(routePaths.PAGES.TALK_DETAIL.replace('[id]', '')) ||
    route.includes(routePaths.PAGES.TRAINING_DETAIL.replace('[id]', '')) ||
    route.includes(routePaths.PAGES.CAMPAIGN_DETAIL.replace('[id]', '')) ||
    route.includes(routePaths.PAGES.REDEEM_POINTS.DETAILS.replace('[id]', ''))
  );
};

// !LEGACY -- Should be removed
export const isSurveyDetail = (route: string) => {
  return route.includes(routePaths.PAGES.SURVEY_DYNAMIC.replace('[id]', ''));
};

export default routePaths;
