import React from 'react';
import { NextPage } from 'next';
import Link from 'src/components/Link';
import { Avatar, Col, Menu, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { profileConfigMenu } from 'config/_profile';
import { IRootReducers } from '../reducers';
import { connect } from 'react-redux';
import { IMenu } from 'src/shared/models';

interface OwnProps {
  updatedProfileImage?: string;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

// !DEPRECATED. Should be removed
const AccountMenu: NextPage<Props, unknown> = ({
  user,
  userPoints,
  updatedProfileImage,
}): JSX.Element => {
  const accountPoints =
    userPoints && typeof userPoints === 'number' ? userPoints : '-';

  const renderMenu = profileConfigMenu.map((item: IMenu): JSX.Element => {
    const { subMenu } = item;
    return (
      <Menu
        key={item.idMenu}
        mode="inline"
        className="accountMenu"
        selectedKeys={['account']}
        defaultOpenKeys={['0']}
      >
        <Menu.SubMenu key={item.idMenu} title={item.name}>
          {subMenu.map((subItem: IMenu) => (
            <Menu.Item
              key={subItem.idMenu}
              className={subItem.disabled ? 'disabled' : ''}
            >
              <Link href={subItem.route}>
                <a className={subItem.disabled ? 'disabled' : ''}>
                  {subItem.name}
                </a>
              </Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    );
  });

  return (
    <>
      <Row justify="center">
        <Col span={24}>
          <Row justify="center" className="block--small">
            {updatedProfileImage || user?.imageProfile ? (
              <Avatar
                src={updatedProfileImage || user.imageProfile}
                size={160}
              />
            ) : (
              <Avatar
                size={160}
                style={{ color: '#EEE', backgroundColor: '#FFF' }}
                icon={<UserOutlined />}
              />
            )}
          </Row>
          <Col>
            <Row justify="center" style={{ textAlign: 'center' }}>
              <strong className="accountMenu__points">{accountPoints}</strong>
            </Row>
            <Row justify="center" style={{ textAlign: 'center' }}>
              <span className="extra-small-body"> TOTAL MIS PUNTOS</span>
            </Row>
          </Col>
        </Col>
      </Row>
      {renderMenu}
    </>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    userPoints: state.auth.userPoints,
  };
};
export default connect(mapStateToProps, {})(AccountMenu);
