import SkeletonSubHeaderTitle from '../SubHeaderTitle.skeleton';
import SkeletonCampaignDetailsCard from '../CampaignDetailsCard.skeleton';
import SkeletonTextCenter from '../TextCenter.skeleton';
import SkeletonStepAction from '../StepAction.skeleton';

const SkeletonCampaignDetails = (): JSX.Element => {
  return (
    <>
      <SkeletonSubHeaderTitle />
      <div className="campaign-details-skeleton">
        <div className="campaign-details-skeleton-title" />
        <SkeletonCampaignDetailsCard />
        <div className="campaign-details-skeleton-wrapper-text-center">
          <SkeletonTextCenter />
        </div>
        <div className="campaign-details-skeleton-wrapper-step-action">
          <div className="campaign-details-skeleton-wrapper-step-action--lines">
            <div className="campaign-details-skeleton-wrapper-step-action--lines--line" />
            <div className="campaign-details-skeleton-wrapper-step-action--lines--line" />
            <div className="campaign-details-skeleton-wrapper-step-action--lines--line" />
          </div>
          <SkeletonStepAction />
          <SkeletonStepAction thirdLineInDesktop />
          <SkeletonStepAction />
          <SkeletonStepAction thirdLineInDesktop />
        </div>
      </div>
    </>
  );
};

export default SkeletonCampaignDetails;
