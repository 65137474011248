// FIXME: BORRAR APP_FETCH_CATEGORIES Y APP_FETCH_PATHOLOGIES
export enum appTypes {
  APP_ADD_BAG_PRODUCT = '[APP] Add MyBag product',
  APP_ADD_OR_REMOVE_DISCOUNT_COUPON = '[APP] Add or remove Checkout discount coupon',
  APP_FETCH_BRANDS = '[APP] Fetch brands',
  APP_FETCH_CAMPAINGS = '[APP] fetch campaigns',
  APP_FETCH_CART = '[APP] Fetch cart',
  APP_FETCH_CATEGORIES = '[APP] Fetch categories',
  APP_FETCH_CERTIFIED_COURSES = '[APP] featch certified trainings',
  APP_FETCH_CHAT_CUSTOMER_CARE_CONFIGURATION = '[APP] fetch chat customer care configuration',
  APP_FETCH_DISCLAIMERS = '[APP] Fetch disclaimers',
  APP_FETCH_COMMERCIAL_SUBSCRIPTION_RECRUITMENT = '[APP] Fetch commercial subscription recruitment data.',
  APP_FETCH_PUBLIC_DISCLAIMERS = '[APP] Fetch public disclaimers',
  APP_FETCH_MAINTENANCE_MODE = '[APP] Fetch maintenance mode',
  APP_FETCH_REFERRAL_PRODUCTS_LIST = '[APP] Fetch referral products list',
  APP_FETCH_EMBRACE_PRACTICES = '[APP] Fetch Embrace Practices - Registered',
  APP_FETCH_HOME_PAGE = '[APP] Fetch home page',
  // FIXME:
  APP_FETCH_FILTERS = '[APP] Fetch filters',
  APP_FETCH_LABEL_REDEEM_PRODUCTS = '[APP] fetch label redeem products',
  APP_FETCH_LIWW_PRACTICES = '[APP] Fetch LIWW Practices - Unregistered',
  APP_FETCH_PATHOLOGIES = '[APP] Fetch pathologies',
  APP_FETCH_PEARLS = '[APP] fetch pearls',
  APP_FETCH_PUBLIC_MENU = '[APP] Fetch public menu',
  APP_FETCH_REDEEM_POINTS_PRODUCTS = '[APP] Fetch Redeem Points Products',
  APP_FETCH_SHOPPING_PRODUCTS = '[APP] Fetch shopping cart products',
  APP_FETCH_SPECIALTIES = '[APP] Fetch specialties',
  APP_FETCH_STATES = '[APP] Fetch states',
  APP_FETCH_TALKS = '[APP] fetch talks',
  APP_FETCH_TRAININGS = '[APP] fetch trainings',
  APP_FETCH_USA_PRACTICES = '[APP] Fetch USA Practices',
  APP_FETCH_USER_HOME = '[APP] Fetch user home page',
  APP_FETCH_USER_MENU = '[APP] Fetch user menu',
  APP_REDEEM_COUPON = '[APP] Redeem coupon',
  APP_REMOVE_BAG_PRODUCT = '[APP] Delete MyBag product',
  APP_SET_BAG_PRODUCTS = '[APP] Set MyBag products',
  APP_SET_CHALLENGE = '[APP] Set Challenge',
  APP_SET_COUNTRY = '[APP] set country',
  APP_SET_PROVINCES_COUNTRIES = '[APP] set provinces countries',
  APP_SET_SALESFORCE_CENTER = '[APP] Set Salesforce center',
  APP_SET_STATIC_PAGE = '[APP] Set Static page',
  APP_SHOW_BAG_MENU = '[APP] Show MyBag menu',
  APP_UPDATE_BAG_PRODUCT = '[APP] Update MyBag product',
  APP_UPDATE_CHALLENGES = '[APP] Update challenges',
  APP_INSERT_CHALLENGES = '[APP] Insert challenges',
  APP_INSERT_ANNUAL_CAMPAIGN = '[APP] Insert annual campaign',
  APP_UPDATE_SORTBY = '[APP] fetch sort by name or price',
  APP_RESET = '[APP] User info has been reset',
  APP_UPDATE_LOGGING_IN = '[APP] Update is logging in',
  APP_FETCH_PRODUCTS_LIMIT = '[APP] Fetch products limits',
  APP_FETCH_ORDERED_PRODUCT_QTY_SKU = '[APP] Fetch ordered quantity product',
}
