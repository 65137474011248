import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { fetchChatCustomerCareConfigurations } from 'src/actions/appActions';
import { injectEmbeddedService } from 'src/components/chatCustomerCare/ChatCustomerCare.utils';
import { IRootReducers } from 'src/reducers';
import getConfig from 'next/config';
import { DateTime } from 'luxon';

const { CUSTOMER_CARE_CHAT_URL } = getConfig().publicRuntimeConfig;

interface OwnProps {
  customClass?: string;
}

type IChatCustomerCare = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const ChatCustomerCare: FC<IChatCustomerCare> = ({
  user,
  chatCustomerCare,
  fetchChatCustomerCareConfigurations,
}): JSX.Element => {
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const handleChatVisibility = (): void => {
    if (!chatCustomerCare) return;

    const { schedule_start, schedule_end, active } = chatCustomerCare;

    const beginningTime = DateTime.fromFormat(
      schedule_start,
      'h:mma'
    ).toJSDate();
    const endTime = DateTime.fromFormat(schedule_end, 'h:mma').toJSDate();
    const now = new Date();
    const isChatVisible =
      now > beginningTime && now < endTime && active && !!user;

    changeChatVisibility(isChatVisible);
  };

  const changeChatVisibility = (visibility: boolean) => {
    changeElementVisibility('embeddedServiceHelpButton', visibility);
    changeElementVisibility('embeddedServiceSidebar', visibility);
  };

  const changeElementVisibility = (
    elementClass: string,
    visibility: boolean
  ) => {
    const element = document.getElementsByClassName(elementClass)[0];

    element?.setAttribute(
      'style',
      `visibility: ${visibility ? 'visible' : 'hidden'}`
    );
  };

  useEffect(() => {
    if (user) {
      if (!isFetched) {
        fetchChatCustomerCareConfigurations();
        setIsFetched(true);
      }
      changeChatVisibility(true);
    }
    if (!user) changeChatVisibility(false);
  }, [user]);

  useEffect(() => {
    if (!chatCustomerCare || !chatCustomerCare.active) return;

    const injected = injectEmbeddedService({
      parameters: chatCustomerCare,
      user,
      targetURL: null,
    });

    if (!injected) {
      const scriptChat = document.createElement('script');
      scriptChat.setAttribute('id', 'embeddedServiceScript');
      scriptChat.setAttribute('src', CUSTOMER_CARE_CHAT_URL);
      scriptChat.onload = function () {
        injected;
      };
      const findScript = document.getElementById('embeddedServiceScript');
      if (findScript) {
        findScript.remove();
      }
      document.body.appendChild(scriptChat);
    }

    const intervalId = setInterval(() => {
      handleChatVisibility();
    }, 1000);

    // make sure to clear the timer on component teardown
    return () => clearInterval(intervalId);
  }, [chatCustomerCare]);

  return (
    <div className="chatCustomerCare">
      <script
        type="text/javascript"
        src={CUSTOMER_CARE_CHAT_URL}
        className="optanon-category-C0001"
      />
    </div>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    accessToken: state.auth.accessToken,
    chatCustomerCare: state.app.chatCustomerCare,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ fetchChatCustomerCareConfigurations }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatCustomerCare);
