export const yuFeedback = (
  message: string,
  status: 'info' | 'success' | 'error'
) => {
  return message
    ? {
        message: message,
        status,
      }
    : null;
};
