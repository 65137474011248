import { Button, Checkbox, Col, Collapse, Row } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRootReducers } from 'src/reducers';
import { UserStatusEnum } from 'src/shared/enums/user.enum';
import { IFilter, IFilterItem } from 'src/shared/models';

const {
  ACCOUNT_NOT_VALIDATED,
  MANAGER_NOT_VALIDATED,
  NO_CENTER,
  HCP_NOT_VALIDATED,
  STAFF_NOT_VALIDATED,
} = UserStatusEnum;

interface OwnProps {
  filters: IFilter[];
  filtersCategoryOrder?: string[];
  onDone: (distributorFilters: IFilter[]) => void;
  isRedeemPoints?: boolean;
  customIdDivFilters?: string;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const DistributorFilter: FC<Props> = ({
  filters,
  filtersCategoryOrder,
  impersonate,
  onDone,
  userStatus,
  isRedeemPoints,
  customIdDivFilters,
}): JSX.Element => {
  if (filters?.length === 0) return <React.Fragment />;

  const [showModal, setShowModal] = useState<boolean>(false);

  const [selectedItemsCount, setSelectedItemsCount] = useState<number>(0);
  const [distributorFilters, setDistributorFilters] = useState<IFilter[]>([]);
  const [activeFilters, setActiveFilters] = useState<number>(0);

  const { formatMessage } = useIntl();

  const handleClick = (item: IFilterItem, filterId: string) => {
    const isChecked = item.isChecked;
    const tempSelectedItemsCount = selectedItemsCount + (isChecked ? -1 : 1);
    setSelectedItemsCount(tempSelectedItemsCount);
    const newFilter = [...distributorFilters];
    newFilter
      .find(({ id }) => id === filterId)
      .items.find(({ value }) => value === item.value).isChecked = !isChecked;
    setDistributorFilters(newFilter);
  };

  const clearFilters = () => {
    const tempDistributorFilters = distributorFilters;
    tempDistributorFilters?.forEach((filter) => {
      filter.items.forEach((item) => {
        if (item.isChecked) {
          item.isChecked = false;
        }
      });
    });
    setSelectedItemsCount(0);
    setDistributorFilters(tempDistributorFilters);
    deactivateShadow();
  };

  const activeShadow = () => {
    document.body.classList.add('not-overflow');
  };

  const deactivateShadow = () => {
    onDone(distributorFilters);
    setShowModal(false);
    document.body.classList.remove('not-overflow');
  };

  // modules

  const renderButton: JSX.Element = (
    <Row
      className="filters-button"
      align="middle"
      justify="start"
      onClick={() => {
        setShowModal(true);
        activeShadow();
      }}
    >
      {activeFilters ? (
        <div className={`icon icon--settings-sliders-selected`} />
      ) : (
        <div className={`icon icon--settings-sliders`} />
      )}

      <span>
        <FormattedMessage id="page.challenge.all-filters" />
      </span>
    </Row>
  );

  const renderFilterSection = (filter: IFilter, filterGroupIndex: number) => {
    return (
      <CollapsePanel
        forceRender
        className="distributorFilter__panel"
        header={
          isRedeemPoints
            ? formatMessage({ id: filter.label })
            : formatMessage({ id: `distributor-filter-${filter.label}` })
        }
        key={filterGroupIndex}
      >
        {filter.items?.map((item: IFilterItem, filterIndex: number) => {
          return (
            <Col xs={24} key={`${filter.id}-${filterIndex}`}>
              <Row justify="start" className="filterElement__checkbox">
                <Checkbox
                  checked={item.isChecked}
                  key={`${filter.id}-checkbox-${filterIndex}`}
                  onChange={() => handleClick(item, filter.id)}
                >
                  {item.label}
                </Checkbox>
              </Row>
            </Col>
          );
        })}
      </CollapsePanel>
    );
  };

  const renderFiltersModal = (
    <>
      <Row className="filter-header">
        <div className="filter-header__title filter-header__title__dekstop">
          <FormattedMessage id="page.challenge.all-filters" />
        </div>

        <div className="filter-header__title filter-header__title__mobile">
          <FormattedMessage id="page.challenge.all-filters-mobile" />
        </div>
        <i
          className="icon icon--close-xl"
          onClick={() => deactivateShadow()}
        ></i>
        <div className="filter-scroll-content">
          <Collapse
            accordion
            className="distributorFilter__collapse"
            expandIconPosition="right"
            ghost
          >
            {distributorFilters?.map(renderFilterSection)}
          </Collapse>
        </div>
      </Row>
      <Row className="filter-buttons">
        <Button
          className="btn-text filter-button filter-buttons--button-clear"
          onClick={clearFilters}
        >
          <FormattedMessage id="button.clear-filters" />
        </Button>
        <Button
          className="btn-primary btn-primary--black filter-buttons--button-done"
          onClick={deactivateShadow}
        >
          <FormattedMessage id="button.apply-filters" />
        </Button>
      </Row>
    </>
  );

  const modalContainerClassName =
    impersonate ||
    [
      ACCOUNT_NOT_VALIDATED,
      MANAGER_NOT_VALIDATED,
      NO_CENTER,
      HCP_NOT_VALIDATED,
      STAFF_NOT_VALIDATED,
    ].includes(userStatus)
      ? 'custom-filter-shadow__container-low filter-modal-content'
      : 'custom-filter-shadow__container filter-modal-content';

  const sortFiltersCategories = (
    _filters: IFilter[] = [],
    filterCategoryOrder: string[] = []
  ): IFilter[] => {
    const filters = _filters ? [..._filters] : [];

    filters.sort((a, b) => {
      const indexA = filterCategoryOrder.indexOf(a.label);
      const indexB = filterCategoryOrder.indexOf(b.label);

      if (indexA === -1) {
        return 1;
      }

      if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    });

    return filters;
  };

  useEffect(() => {
    const sortedFilters = sortFiltersCategories(filters, filtersCategoryOrder);
    setDistributorFilters(sortedFilters);
    setActiveFilters(
      sortedFilters
        .flatMap((filter) => filter.items)
        .filter((item) => item.isChecked).length
    );
  }, [filters]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('not-overflow');
    };
  }, []);

  return (
    <>
      <div
        className="custom-filter"
        id={customIdDivFilters ?? 'distributor-filter'}
        key={customIdDivFilters ?? 'distributor-filter'}
      >
        {renderButton}
      </div>

      {showModal && (
        <div id="custom-filter-shadow" className={`custom-filter-shadow `}>
          <div className={modalContainerClassName}>{renderFiltersModal}</div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    impersonate: state.auth.impersonate,
    userStatus: state.auth.userStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DistributorFilter);
