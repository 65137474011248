import { IMenu } from 'src/shared/models';
import routePaths from './routes';

export const profileConfigMenu: IMenu[] = [
  {
    idMenu: 0,
    name: 'Mi cuenta',
    visible: true,
    disabled: false,
    route: '',
    status: true,
    absolute: false,
    subMenu: [
      {
        idMenu: 3,
        name: 'Mi perfil',
        subMenu: [],
        visible: true,
        disabled: false,
        route: routePaths.PAGES.MY_ACCOUNT.MY_PROFILE,
        status: true,
        absolute: false,
      },
    ],
  },
  {
    idMenu: 1,
    name: 'Mi farmacia',
    visible: true,
    disabled: true,
    route: '',
    status: true,
    absolute: false,
    subMenu: [
      {
        idMenu: 4,
        name: 'Datos de la farmacia',
        subMenu: [],
        visible: true,
        disabled: true,
        route: '/customer/pharmacy_info',
        status: true,
        absolute: false,
      },
      {
        idMenu: 5,
        name: 'Asignación de puntos',
        subMenu: [],
        visible: true,
        disabled: true,
        route: '/customer/pharmacy_points',
        status: true,
        absolute: false,
      },
      {
        idMenu: 6,
        name: 'Histórico de puntos',
        subMenu: [],
        visible: true,
        disabled: true,
        route: '/customer/pharmacy_points_history',
        status: true,
        absolute: false,
      },
      {
        idMenu: 7,
        name: 'Mis pedidos de farmacia',
        subMenu: [],
        visible: true,
        disabled: true,
        route: '/customer/order_history',
        status: true,
        absolute: false,
      },
      {
        idMenu: 8,
        name: 'Mis facturas',
        subMenu: [],
        visible: true,
        disabled: true,
        route: '/customer/pharmacy_invoices',
        status: true,
        absolute: false,
      },
      {
        idMenu: 9,
        name: 'Modelo 347',
        subMenu: [],
        visible: true,
        disabled: true,
        route: '/customer/pharmacy_invoices_anual',
        status: true,
        absolute: false,
      },
    ],
  },
  {
    idMenu: 2,
    name: 'Mis puntos',
    visible: true,
    disabled: true,
    route: '',
    status: true,
    absolute: false,

    subMenu: [
      {
        idMenu: 10,
        name: 'Desglose de puntos',
        subMenu: [],
        visible: true,
        disabled: true,
        route: '/customer/aw_store_credit',
        status: true,
        absolute: false,
      },
      {
        idMenu: 11,
        name: 'Mis pedidos',
        subMenu: [],
        visible: true,
        disabled: true,
        route: '/customer/orders',
        status: true,
        absolute: false,
      },
    ],
  },
];
