import { config, config as configApp } from 'config/config';
import {
  PIN_AR,
  PIN_BR,
  PIN_CL,
  PIN_CO,
  PIN_DE,
  PIN_ES,
  PIN_GT,
  PIN_IT,
  PIN_MX,
  PIN_PE,
  PIN_PT,
} from 'config/provinces';
import { useIntl } from 'react-intl';
import {
  ChannelType,
  PIN_PlatformID_Enum,
  PlateTypeEnum,
  SalesforceMedicalCenter,
} from 'src/shared/enums';
import { ISalesforceCenter } from 'src/shared/models';
import { IRegion } from 'src/shared/models/staticPages.model';
import { getPlatformDefaultMedicalCenterType } from '../../../utils';

export enum RegisterStepsEnum {
  ACCOUNT_DATA = 0,
  PERSONAL_DATA = 1,
  PRACTICE_DATA = 2,
  PRACTICE_DATA_USA = 3,
  ADDRESS_DATA = 4,
}

export interface IPracticeForm {
  city: string;
  contactRepSale: boolean;
  directionNumber: string;
  directionStreet: string;
  name: string;
  state: string;
  telephone: string;
  zipCode: string;
}

export interface IPracticeData {
  canContinue: boolean;
  contactRepSale: boolean;
  idCenter?: string;
  idSalesforce?: string;
  isCenterRegistered: boolean;
  isPracticeRegistered: boolean;
  practice: IPracticeForm;
  practiceName: string;
  practiceType: 'Farmacia' | 'Centro_Medico';
  saleforceCenter: ISalesforceCenter;
  userIsOwnerManager: boolean;
  zipCode: string;
}

export type IPracticeDataUSA = Omit<
  IPracticeData,
  'isPracticeRegistered' | 'isCenterRegistered' | 'practice'
>;

export interface ISignInData {
  embracePractices: ISalesforceCenter[];
  LIWWPractices: ISalesforceCenter[];
  USAPractices: ISalesforceCenter[];
}

export interface IPersonalData {
  canContinue: boolean;
  clientType: 'Staff' | 'HCP';
  idMagentoSpecialty: number;
  idSalesforceSpecialty: number;
  idSpecialty: number;
  name: string;
  plateType?: PlateTypeEnum;
  npi?: string;
  provincialPlate?: string;
  surname: string;
  telephone: string;
  userType: 'Employee' | 'Manager';
  channel: ChannelType;
}

export interface IPersonalDataRegister {
  userDocumentID?: string;
  name: string;
  surname: string;
}

// TODO: Use interface "IAddressSalesForce"
export interface IAddress {
  addressBilling: boolean;
  addressCity: string;
  addressCP: string;
  addressDelivery: boolean;
  addressID?: string;
  addressName: string;
  addressNumber: string;
  addressState: string;
  addressStreet: string;
  addressType: 'Consumidor';
  medicalCenterType: SalesforceMedicalCenter;
  userID?: string;
}

export interface IAddress_PIN extends IAddress {
  addressCountry: string;
  addressStateName?: string;
  country: string;
  PlatformID: string;
  primary: boolean;
  region: IRegion;
  canContinue: boolean;
  userMedicalCenter?: string;
  userHowFindUs?: string;
  delegateVisitYou?: string;
}

export interface IProfessionalPINRegister {
  specialty: number;
  plateType?: PlateTypeEnum;
  npi?: string;
  plateNumber?: string;
  isResident: boolean;
  collegiateNumber?: string;
  graduationYear: number;
  workCenter?: string;
  street: string;
  streetDetails: string;
  zipCode: string;
  town: string;
  state: string;
  country: string;
  prefix?: string;
  telephone: string;
  medicalCenterSalesForceId?: string;
  medicalCenter?: string;
  medicalCenterName?: string;
  odontologyName?: string;
  odontologyCollegiateNumber?: number;
}

export const initialProfessionalDataPINRegister: IProfessionalPINRegister = {
  specialty: null,
  plateType: null,
  isResident: false,
  collegiateNumber: '',
  npi: '',
  graduationYear: null,
  street: '',
  streetDetails: '',
  zipCode: null,
  town: '',
  state: '',
  country: '',
  prefix: '',
  telephone: '',
  medicalCenterSalesForceId: '',
  medicalCenter: '',
  medicalCenterName: '',
  odontologyName: null,
  odontologyCollegiateNumber: null,
};

export interface IPersonalData_PIN extends Omit<IPersonalData, 'clientType'> {
  clientType: 'Consumidor';
  userRegion?: string;
  userLocation?: string;
  userDocumentID?: string;
  userMainCountryCode?: string;
}

export interface IAccountData {
  canContinue: boolean;
  email: string;
  emailRepeat: string;
  isValidPassword: boolean;
  newsletter: boolean;
  password: string;
  terms_conditions: boolean;
}

export interface IUser_PIN {
  behavior: string;

  // Magento Values
  name: string;
  lastName: string;

  addressState: string;
  channel: ChannelType;
  clientType: 'Consumidor';
  email: string;
  idMagentoSpecialty: number;
  idSalesforceSpecialty: number;
  idSpecialty: number;

  medicalCenterType: SalesforceMedicalCenter;
  newsletterSubscription: boolean;
  plateType?: PlateTypeEnum;
  npi?: string;
  provincialPlate?: string;
  password: string;
  PlatformID: PIN_PlatformID_Enum;
  userFirstname: string;
  userHowFindUs: string;
  userName: string;
  userPhone: string;
  userSpecialty: number;
  userSurname: string;
  userDocumentID: string;
  userCountry: string;
  userMedicalCenter?: string;
  centerDirectionCP?: string;
  delegateVisitYou?: string;
}

export interface IUserPINRegister {
  behavior: string;

  // Magento Values
  name: string;
  lastName: string;

  addressState: string;
  channel: ChannelType;
  clientType: 'Consumidor';
  email: string;
  idSalesforceSpecialty: number;
  isResident: boolean;
  collegiateNumber: string;
  graduationYear: number;

  medicalCenterType: SalesforceMedicalCenter;
  newsletterSubscription: boolean;
  plateType?: PlateTypeEnum;
  npi?: string;
  provincialPlate?: string;
  password: string;
  PlatformID: PIN_PlatformID_Enum;
  userFirstname: string;
  userHowFindUs: string;
  userName: string;
  userPrefix: string;
  userPhone: string;
  userSpecialty: number;
  userSurname: string;
  userDocumentID: string;
  userCountry: string;
  userMedicalCenter?: string;
  medicalCenterSalesForceId?: string;
  medicalCenterUUID?: string;
  medicalCenterName?: string;
  centerDirectionCP?: string;
  delegateVisitYou?: boolean;
  odontologyName?: string;
  odontologyCollegiateNumber?: number;
}

export interface IAddressPINRegister {
  workCenter?: string;
  street: string;
  streetDetails: string;
  zipCode: string;
  town: string;
  state: IRegion;
  country: string;
  prefix: string;
  telephone: string;
}

export interface IRegisterPIN {
  customer: IUserPINRegister;
  address: IAddressPINRegister;
}

export const getProvincesListByPlatform = (): IRegion[] => {
  let provincesList: IRegion[];

  const { PLATFORM } = configApp.APP;

  switch (PLATFORM) {
    case PIN_PlatformID_Enum.PIN_ARGENTINA:
      provincesList = PIN_AR;
      break;
    case PIN_PlatformID_Enum.PIN_SPAIN:
      provincesList = PIN_ES;
      break;
    case PIN_PlatformID_Enum.PIN_PERU:
      provincesList = PIN_PE;
      break;
    case PIN_PlatformID_Enum.PIN_GUATEMALA:
      provincesList = PIN_GT;
      break;
    case PIN_PlatformID_Enum.PIN_COLOMBIA:
      provincesList = PIN_CO;
      break;
    case PIN_PlatformID_Enum.PIN_CHILE:
      provincesList = PIN_CL;
      break;
    case PIN_PlatformID_Enum.PIN_MEXICO:
      provincesList = PIN_MX;
      break;
    case PIN_PlatformID_Enum.PIN_PORTUGAL:
      provincesList = PIN_PT;
      break;
    case PIN_PlatformID_Enum.PIN_BRAZIL:
      provincesList = PIN_BR;
      break;
    case PIN_PlatformID_Enum.PIN_ITALY:
      provincesList = PIN_IT;
      break;
    case PIN_PlatformID_Enum.PIN_ALEMANIA:
      provincesList = PIN_DE;
      break;
  }

  return provincesList;
};

export const buildCenterData = (
  data: IPracticeData,
  isUserManager: boolean
) => {
  if (data.isPracticeRegistered || data.isCenterRegistered) {
    const { saleforceID, centerID, centerName } = data.saleforceCenter;

    return isUserManager
      ? {
          idSalesforce: saleforceID,
          centerName,
          centerType: config.REGISTER.PRACTICE_TYPE,
        }
      : { uuidCenter: centerID };
  }

  return {
    // Center does not exist
    centerDirectionCity: data.practice.city,
    centerDirectionCountry: config.REGISTER.DIRECTION_COUNTRY,
    centerDirectionCP: data.practice.zipCode,
    centerDirectionNumber: data.practice.directionNumber,
    centerDirectionState: data.practice.state,
    centerDirectionStreet: data.practice.directionStreet,
    centerName: data.practice.name,
    centerPhone: data.practice.telephone,
    centerType: config.REGISTER.PRACTICE_TYPE,
    CIF: '',
    PlatformID: config.REGISTER.PLATFORM_ID,
  };
};

export const buildCenterDataUSA = (
  data: IPracticeDataUSA,
  isUserManager: boolean
) => {
  const { saleforceID, centerID, centerName } = data.saleforceCenter;

  return isUserManager
    ? {
        idSalesforce: saleforceID,
        centerName,
        centerType: config.REGISTER.PRACTICE_TYPE,
      }
    : { uuidCenter: centerID };
};

export const buildUserData = (
  practiceData: IPracticeData,
  personalData: IPersonalData,
  accountData: IAccountData
) => {
  return {
    PlatformID: config.REGISTER.PLATFORM_ID,
    behavior: config.REGISTER.BEHAVIOR,
    channel: ChannelType.NATIONAL,

    name: personalData.name,
    lastName: personalData.surname,
    surName: personalData.surname,
    userType: personalData.userType,
    newsletterSubscription: String(Number(accountData.newsletter)),
    idSpecialty: personalData.idSpecialty,
    idMagentoSpecialty: personalData.idMagentoSpecialty,
    idSalesforceSpecialty: personalData.idSalesforceSpecialty,
    clientType: personalData.clientType,
    plateType: personalData.plateType,
    npi: personalData.npi,
    provincialPlate: personalData.provincialPlate,
    telephone: personalData.telephone,
    centerDirectionCP:
      practiceData.practice?.zipCode ||
      practiceData.saleforceCenter?.centerDirectionCP,

    email: accountData.email,
    password: accountData.password,
    repVisit: practiceData.contactRepSale,
  };
};

export const buildUserDataPIN = (
  addressData: IAddress_PIN,
  personalData: IPersonalData_PIN,
  accountData: IAccountData
): IUser_PIN => {
  return {
    addressState: addressData.region.id,
    channel: addressData.region.channel,
    behavior: config.REGISTER.BEHAVIOR,
    clientType: 'Consumidor',
    medicalCenterType: getPlatformDefaultMedicalCenterType(),
    PlatformID: config.REGISTER.PLATFORM_ID,

    newsletterSubscription: accountData.newsletter,

    idSpecialty: personalData.idSpecialty,
    idMagentoSpecialty: personalData.idMagentoSpecialty,
    idSalesforceSpecialty: personalData.idSalesforceSpecialty,

    password: accountData.password,
    email: accountData.email,

    // Magento Data
    name: personalData.name,
    lastName: personalData.surname,
    plateType: personalData.plateType,
    npi: personalData.npi,
    provincialPlate: personalData.provincialPlate,

    userFirstname: personalData.name,
    userName: `${personalData.name} ${personalData.surname}`,
    userDocumentID: personalData.userDocumentID?.toUpperCase(),
    userPhone: personalData.telephone,
    userSpecialty: personalData.idSalesforceSpecialty,
    userSurname: personalData.surname,

    userCountry: addressData.addressCountry || config.REGISTER.COUNTRY_CODE,
    centerDirectionCP: addressData.addressCP,

    userMedicalCenter: addressData.userMedicalCenter,
    userHowFindUs: addressData.userHowFindUs || null,
    delegateVisitYou: addressData.delegateVisitYou || null,
  };
};

export const buildUserDataPINRegister = (
  professionalData: IProfessionalPINRegister,
  personalData: IPersonalDataRegister,
  accountData: IAccountData
): IUserPINRegister => {
  const provincesList = getProvincesListByPlatform();
  const region = provincesList?.find(
    (region) => region.id === professionalData.state
  );

  return {
    addressState: region?.id,
    channel: region?.channel,
    behavior: config.REGISTER.BEHAVIOR,
    clientType: 'Consumidor',
    medicalCenterType: getPlatformDefaultMedicalCenterType(),
    medicalCenterSalesForceId: professionalData.medicalCenterSalesForceId,
    medicalCenterUUID: professionalData?.medicalCenter,
    medicalCenterName: professionalData?.medicalCenterName,
    PlatformID: config.REGISTER.PLATFORM_ID,

    newsletterSubscription: accountData.newsletter,
    idSalesforceSpecialty: professionalData.specialty,
    isResident: professionalData.isResident,
    collegiateNumber: professionalData.collegiateNumber ?? null,
    graduationYear: professionalData.graduationYear,

    password: accountData.password,
    email: accountData.email,

    // Magento Data
    name: personalData.name,
    lastName: personalData.surname,
    plateType: professionalData.plateType ?? PlateTypeEnum.NPI,
    npi: professionalData.collegiateNumber ?? professionalData.npi,
    provincialPlate: professionalData.plateNumber ?? null,

    userFirstname: personalData.name,
    userName: `${personalData.name} ${personalData.surname}`,
    userDocumentID: personalData.userDocumentID?.toUpperCase(),
    userPrefix: professionalData.prefix,
    userPhone: professionalData.telephone,
    userSpecialty: professionalData.specialty,
    userSurname: personalData.surname,

    userCountry: professionalData.country || config.REGISTER.COUNTRY_CODE,
    centerDirectionCP: professionalData.zipCode,
    odontologyName: professionalData.odontologyName ?? null,
    odontologyCollegiateNumber:
      professionalData.odontologyCollegiateNumber ?? null,

    userMedicalCenter: professionalData.workCenter,
    userHowFindUs: '',
    delegateVisitYou: null,
  };
};

export const buildAddressDataPINRegister = (
  professionalData: IProfessionalPINRegister
): IAddressPINRegister => {
  const provincesList = getProvincesListByPlatform();

  return {
    workCenter: professionalData.workCenter,
    street: professionalData.street,
    streetDetails: professionalData.streetDetails,
    zipCode: professionalData.zipCode,
    town: professionalData.town,
    state: provincesList?.find(
      (province) => province.id === professionalData.state
    ),
    country: professionalData.country,
    prefix: professionalData.prefix,
    telephone: professionalData.telephone,
  };
};

export const initialPracticeData: IPracticeData = {
  canContinue: false,
  contactRepSale: null,
  isCenterRegistered: null,
  isPracticeRegistered: null,
  practice: null,
  practiceName: '',
  practiceType: 'Farmacia',
  saleforceCenter: null,
  userIsOwnerManager: null,
  zipCode: null,
};

export const initialPracticeDataUSA: IPracticeDataUSA = {
  canContinue: false,
  contactRepSale: null,
  practiceName: '',
  practiceType: 'Farmacia',
  saleforceCenter: null,
  zipCode: null,
  userIsOwnerManager: null,
};

export const initialPersonalData: IPersonalData = {
  canContinue: false,
  clientType: null,
  idMagentoSpecialty: -1,
  idSalesforceSpecialty: -1,
  idSpecialty: -1,
  name: '',
  npi: '',
  surname: '',
  telephone: null,
  userType: null,
  channel: ChannelType.NATIONAL,
};

export const initialPersonalDataRegister: IPersonalDataRegister = {
  name: '',
  surname: '',
};

export const initialAccountData: IAccountData = {
  canContinue: false,
  email: '',
  emailRepeat: '',
  isValidPassword: false,
  newsletter: false,
  password: '',
  terms_conditions: false,
};

export const initialAddressDataUSA = {
  addressName: '',
  addressNumber: '',
  zipcode: '',
  city: '',
  state: '',
  phoneNumber: '',
  country: config.APP.COUNTRY,
};

export const initialAddressDataPIN = (): IAddress_PIN => {
  const { formatMessage } = useIntl();

  return {
    addressBilling: false,
    addressCity: '',
    addressCountry: config.REGISTER.COUNTRY_CODE,
    addressCP: '',
    addressDelivery: false,
    addressName: formatMessage({ id: 'form.field.address-default-name' }),
    addressNumber: null,
    addressState: null,
    addressStateName: '',
    addressStreet: '',
    addressType: 'Consumidor',
    country: config.REGISTER.COUNTRY,
    medicalCenterType: getPlatformDefaultMedicalCenterType(),
    PlatformID: config.REGISTER.PLATFORM_ID,
    primary: true,
    region: {
      code: '-1',
      id: '',
      name: '',
      channel: ChannelType.NATIONAL,
    },
    canContinue: false,
  };
};
