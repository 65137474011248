import { config } from 'config/config';
import { Countries, CountryInfoMap } from './platformSelector.enum';

const { LANGUAGE_CODE } = config.APP;
export const COOKIE_NAME = 'country';

export const getCountryCodeByLanguageCode = (languageCode: string): string => {
  for (const country in CountryInfoMap) {
    const countryInfo = CountryInfoMap[country as Countries];
    if (
      countryInfo.specialCountry &&
      languageCode.includes(countryInfo.specialCountry)
    ) {
      const countryCode = countryInfo.specialCountry.split('country=')[1];
      return countryCode;
    }
  }

  return LANGUAGE_CODE.split('-')[1];
};

export const isSpecialCountryParamDefined = (url: string): string | false => {
  let countryCode = '';
  for (const country in CountryInfoMap) {
    const countryInfo = CountryInfoMap[country as Countries];
    if (
      countryInfo.specialCountry &&
      url.includes(countryInfo.specialCountry)
    ) {
      countryCode = countryInfo.specialCountry.split('country=')[1];
      return countryCode;
    }
  }

  return false;
};
