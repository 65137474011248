export enum ResourceTypeEnum {
  TEXT = 1,
  VIDEO = 2,
  IMAGE = 3,
  QUIZ = 4,
  QUESTION = 5,
  ANSWER = 6,
  EVENT = 7,
  REMINDER = 8,
  SPEAKER = 9,
  INSCRIPTION = 10,
  ATTEND = 11,
  PARTICIPATION = 12,
  FAQ = 13,
  FAQ_1_CONTENT = 14,
  FAQ_2_CONTENT = 15,
  FAQ_3_CONTENT = 16,
  FAQ_4_CONTENT = 17,
  FAQ_5_CONTENT = 18,
  GALLERY = 19,
  GALLERY_IMAGE = 20,
  GALLERY_VIDEO = 21,
  CONTACT_MAIL = 22,
  MATERIAL = 23,
  SURVEY = 24,
  ACTIVATION_BUTTON = 25,
  SHOPPING_CART = 26,
  SHOPPING_CART_GROUP = 27,
  SHOPPING_CART_PRODUCT = 28,
  RESOURCES_GUIDE = 29,
  LINKED_CHALLENGES = 30,
  CHALLENGE_MATERIALS = 31,
  CHALLENGE_VISIBILITY = 37,
  SELL_OUT = 39,
  SELL_OUT_POINTS_RANGE = 40,
  SELL_IN = 43,
  SELL_IN_PRODUCTS = 44,
  SELL_IN_POINTS_RANGE = 45,
  PEARL = 50,
  GENERIC_BUTTON = 56,
  WYSIWYG_CHALLENGE = 57,
  ARN_SURVEY = 63,
  ARN_SURVEY_TOKEN = 64,
  LEGAL_CHECK = 76,
  CERTIFIED_COURSE = 77,
  EXTERNAL_VALIDATOR = 78,
  EXPERTS_GROUP = 79,
  EXTERNAL_REDEEM_POINTS = 84,
  EXTERNAL_REDEEM_POINTS_POINTS = 85,
  CLAUSE = 86,
}

export enum ChallengedTabsEnum {
  ALL = 0,
  PENDING = 1,
  COMPLETED = 2,
  FAVORITES = 3,
  REGISTERED = 4,
  ATTENDED = 5,
}

export enum IResourceBehaviorsEnum {
  DISABLED = 1,
  HIDDEN = 2,
}

export enum S3BucketPathEnum {
  DEFAULT = 0, // Use this for media files (images, docs, etc) not related to specific resources
  RESOURCE_CHALLENGE_VISIBILITY = 1,
  SELL_OUT_REPORTS = 2,
  PEARLS_IMAGES = 3,
}

export enum QuizQuestionTypeEnum {
  UNIQUE = '0',
  MULTIRESPONSE = '1',
  ALL_VALID = '2',
}
