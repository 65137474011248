import { Button, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { IRootReducers } from 'src/reducers';
import {
  IChallengeExternalRedeemPointsPoints,
  IChallengeResourceRender,
  IExternalRedeemPoints,
} from 'src/shared/models';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from '../ChallengeResourceWrapper';
import ExternalRedeemPointsModal from './ExternalRedeemPointsModal';
import PointsToRedeemCard from './PointsToRedeemCard';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { updateUserExpirationPoints } from 'src/actions/authActions';
import { CardsPositionEnum } from './ExternalRedeemPoints.enum';

interface OwnProps extends IChallengeResourceRender {
  config: IExternalRedeemPoints;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const ChallengeExternalRedeemPoints: FC<Props> = ({
  config,
  totalPoints,
  updateChallenge,
  updateUserPoints,
  updateUserExpirationPoints,
}): JSX.Element => {
  const { type, buttonLabel, pointsToRedeemList, isCompleted } = config;

  const { formatNumber } = useIntl();
  const [shouldStartModalJourney, setShouldStartModalJourney] = useState(false);
  const [allCardsDisabled, setAllCardsDisabled] = useState(false);
  const [pointsCardsList, setPointsCardsList] =
    useState<IChallengeExternalRedeemPointsPoints[]>(pointsToRedeemList);
  const [selectedCard, setSelectedCard] =
    useState<IChallengeExternalRedeemPointsPoints>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const checkIfEnoughPoints = () => {
    const notEnoughPoints = pointsCardsList.every(
      (card) => card.points > totalPoints
    );

    setIsButtonDisabled(notEnoughPoints);
  };

  useEffect(() => {
    if (allCardsDisabled) {
      pointsCardsList.map((card) => {
        card.isDisabled = true;
        card.isSelected = false;
      });
      setIsButtonDisabled(true);
    }
  }, [allCardsDisabled]);

  useEffect(() => {
    checkIfEnoughPoints();

    const updatedCards = pointsToRedeemList.map((card, index) => {
      const _isDisabled = pointsCardsList[index].isDisabled;

      if (_isDisabled) setSelectedCard({ ...card, isDisabled: _isDisabled });

      return {
        ...card,
        isDisabled: _isDisabled,
      };
    });
    setPointsCardsList(updatedCards);

    if (!allCardsDisabled) initialCardSelect();
  }, []);

  const isCheckeableCard = (
    cardIndex: number,
    firstCardWithEnoughPoints: IChallengeExternalRedeemPointsPoints
  ): void => {
    const enoughPoints = pointsCardsList[cardIndex].points <= totalPoints;
    const index = pointsCardsList.findIndex(
      (card) => firstCardWithEnoughPoints.id === card.id
    );

    if (enoughPoints) checkSelectedCard(cardIndex);
    else checkSelectedCard(index);
  };

  const initialCardSelect = () => {
    if (allCardsDisabled) return;
    const notEnoughPoints = pointsCardsList.some(
      (element) => totalPoints > element.points
    );

    if (!notEnoughPoints) return;

    let firstCardWithEnoughPoints: IChallengeExternalRedeemPointsPoints = null;

    firstCardWithEnoughPoints = pointsCardsList.find(
      (card) => card.points < totalPoints
    );

    if (pointsCardsList.length === 3) {
      isCheckeableCard(
        CardsPositionEnum.SECOND_POSITION,
        firstCardWithEnoughPoints
      );
    } else {
      isCheckeableCard(
        CardsPositionEnum.FIRST_POSITION,
        firstCardWithEnoughPoints
      );
    }
  };

  const checkSelectedCard = (cardIndex: number): void => {
    const updatedOptions = pointsCardsList.map(
      (pointsToRedeemCard, index: number) => {
        const _isSelected = index === cardIndex;

        if (_isSelected) setSelectedCard({ ...pointsToRedeemCard });

        return {
          ...pointsToRedeemCard,
          isSelected: _isSelected,
        };
      }
    );

    setPointsCardsList(updatedOptions);
  };

  const component = (
    <>
      <ChallengeResourceWrapper_Blocked />
      <div className="actual-points">
        <img className="icon icon--points actual-points--icon" />
        <span className="you-have">
          <FormattedMessage id="component.external-redeem-points-you-have.label" />
        </span>
        &nbsp;
        <FormattedMessage
          id="component.external-redeem-points-current-points.label"
          values={{
            points: formatNumber(totalPoints || 0),
          }}
        />
      </div>
      <Row className="cards-list-container">
        {pointsCardsList.map((pointsToRedeemCard, index: number) => {
          return (
            <PointsToRedeemCard
              externalRedeemType={type}
              key={index}
              cardData={pointsToRedeemCard}
              onClick={() => checkSelectedCard(index)}
            />
          );
        })}
      </Row>

      <Button
        className={`btn-primary btn-primary--black button-external-redeem-points`}
        block
        onClick={() => setShouldStartModalJourney(true)}
        disabled={isButtonDisabled}
      >
        {buttonLabel}
      </Button>

      <ExternalRedeemPointsModal
        setAllCardsDisabled={setAllCardsDisabled}
        shouldStartModalJourney={shouldStartModalJourney}
        selectedCard={selectedCard}
        config={config}
        updateChallenge={updateChallenge}
        updateUserPoints={updateUserPoints}
        updateUserExpirationPoints={updateUserExpirationPoints}
      />
    </>
  );

  useEffect(() => {
    if (shouldStartModalJourney) setShouldStartModalJourney(false);
  }, [shouldStartModalJourney]);

  return (
    <ChallengeResourceWrapper
      bottomLabel={
        isCompleted && {
          label:
            'page.challenge.congratulations-external-redeem-points-completed',
          style: 'completed',
        }
      }
      children={component}
      description={config.description}
      styleClass="challengeDetail--external-redeem-points"
      title={config.title}
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    challengeResources: state.app.challenge?.challengeResources,
    totalPoints: state.auth.userPoints,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateUserExpirationPoints }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeExternalRedeemPoints);
