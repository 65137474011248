import { PIN_DE } from 'config/provinces/pin-de';
import getConfig from 'next/config';
import { PIN_PlatformID_Enum } from 'src/shared/enums';
import {
  PIN_AR,
  PIN_BR,
  PIN_CL,
  PIN_CO,
  PIN_ES,
  PIN_GT,
  PIN_IT,
  PIN_MX,
  PIN_PE,
  PIN_PT,
  PIN_US,
} from './provinces';
import { PIN_ES_PREFIXES } from './prefixes/pin-es';
import { PIN_BR_PREFIXES } from './prefixes/pin-br';
import { PIN_IT_PREFIXES } from './prefixes/pin-it';
import { PIN_PT_PREFIXES } from './prefixes/pin-pt';
import { PIN_US_PREFIXES } from './prefixes/pin-us';
import { PIN_AR_PREFIXES } from './prefixes/pin-ar';
import { PIN_CL_PREFIXES } from './prefixes/pin-cl';
import { PIN_PE_PREFIXES } from './prefixes/pin-pe';
import { PIN_CO_PREFIXES } from './prefixes/pin-co';
import { PIN_MX_PREFIXES } from './prefixes/pin-mx';
import { PIN_DE_PREFIXES } from './prefixes/pin-de';
import { PIN_GT_PREFIXES } from './prefixes/pin-gt';

const {
  API_BASE_URL,
  APP_ENV,
  ASSET_PREFIX,
  CURRENCY_FORMAT,
  COUNTRY,
  COUNTRY_CODE,
  DEFAULT_TIMEZONE_DESCRIPTION,
  EMAIL_CUSTOMER_CARE,
  LANGUAGE_CODE,
  MAGENTO_BASE_URL,
  PHONE,
  PLATFORM_ID,
  PLATFORM_PREFIX,
  PLATFORM,
  RRSS_FACEBOOK,
  RRSS_INSTAGRAM,
  SCHEDULE,
  SHIPPING_METHOD_DAYS,
  SHIPPING_METHOD_HOURS,
  TIMEZONE_NAME,
  TYPEFORM_URL,
  SHOW_POINTS_LIMIT,
  ZIPCODE_LENGTH,
  ID_TYPEFORM_NPS,
} = getConfig().publicRuntimeConfig;
const isPlatformPIN = Object.values(PIN_PlatformID_Enum).includes(PLATFORM);

export const config = {
  API: {
    BASE_URL: API_BASE_URL,
  },
  APP: {
    ASSET_PREFIX,
    DEFAULT_IMAGE: `${ASSET_PREFIX}/event_image.png`,
    BASE_URL: PLATFORM_PREFIX,
    CURRENCY_FORMAT,
    ID_TYPEFORM_NPS,
    COUNTRY,
    DEFAULT_TIMEZONE_DESCRIPTION,
    EMAIL_CUSTOMER_CARE,
    ENV: APP_ENV,
    LANGUAGE_CODE,
    MARKETING_MATERIALS: {
      DEFAULT_IMAGE_BG: `${ASSET_PREFIX}/material_default_bg.png`,
      DEFAULT_VIDEO_BG: `${ASSET_PREFIX}/material_default_video.png`,
      DEFAULT_FILE_BG: `${ASSET_PREFIX}/material_default_pdf_bg.png`,
      ID_VADEMECUM: 4, //TODO: Use "name" property.
      MAX_ROW_CARDS: 4,
    },
    NAME: 'Isdin',
    PHONE: PHONE,
    PLATFORM_PREFIX,
    PLATFORM: PLATFORM,
    PREFIXES: {
      'pin-es': PIN_ES_PREFIXES,
      'pin-br': PIN_BR_PREFIXES,
      'pin-it': PIN_IT_PREFIXES,
      'pin-pt': PIN_PT_PREFIXES,
      'pin-us': PIN_US_PREFIXES,
      'pin-ar': PIN_AR_PREFIXES,
      'pin-cl': PIN_CL_PREFIXES,
      'pin-pe': PIN_PE_PREFIXES,
      'pin-co': PIN_CO_PREFIXES,
      'pin-mx': PIN_MX_PREFIXES,
      'pin-de': PIN_DE_PREFIXES,
      'pin-gt': PIN_GT_PREFIXES,
    },
    PROVINCES: {
      'pin-br': PIN_BR,
      'pin-es': PIN_ES,
      'pin-it': PIN_IT,
      'pin-pt': PIN_PT,
      'pin-us': PIN_US,
      'pin-ar': PIN_AR,
      'pin-cl': PIN_CL,
      'pin-pe': PIN_PE,
      'pin-co': PIN_CO,
      'pin-mx': PIN_MX,
      'pin-de': PIN_DE,
      'pin-gt': PIN_GT,
    },
    RRSS: {
      FACEBOOK: RRSS_FACEBOOK,
      INSTAGRAM: RRSS_INSTAGRAM,
    },
    SCHEDULE: SCHEDULE,
    SHIPPING_METHOD_DAYS,
    SHIPPING_METHOD_HOURS,
    TIMEZONE_NAME,
    TYPEFORM_URL: TYPEFORM_URL || 'https://isdin.typeform.com/to',
    SHOW_POINTS_LIMIT,
    YOUTUBE_EMBEDDED_URL: 'https://www.youtube.com/embed/',
    VIMEO_EMBEDDED_URL: 'https://player.vimeo.com/video/',
    ARN_SURVEY_BASE_URL: 'https://arnsurveys.no-ip.org',
  },
  BREAKPOINTS: {
    DESKTOP_BG: 1200,
    DESKTOP: 1025,
    TABLET: 768,
    MOBILE: 480,
  },
  REGISTER: {
    ID_LANGUAGE: 1,
    ID_COUNTRY: 1,
    BEHAVIOR: 'Mr',
    COUNTRY,
    COUNTRY_CODE,
    DIRECTION_COUNTRY: 'US',
    PLATFORM_ID: PLATFORM_ID || 'liww_usa',
    PRACTICE_TYPE: 'Centro_Medico',
    ZIPCODE_LENGTH: ZIPCODE_LENGTH || (isPlatformPIN ? 10 : 5),
  },
  COOKIES: {
    ACCESS_TOKEN: 'loveisdinww_auth',
    BASE_COOKIE_DOMAIN: APP_ENV === 'local' ? 'localhost' : '.isdin.com',
    REFRESH_TOKEN: 'loveisdinww_ref_t',
    UUID_USER: 'loveisdinww_uuid',
  },
  MAGENTO: {
    BASE_URL: MAGENTO_BASE_URL,
    CHECKOUT: '/checkout/cart/',
    REPEAT_ORDER: '/isdin_sales/order_history/reorder/order_id',
    SHOPPING_CART: '/magento/customer/cart',
  },
};
