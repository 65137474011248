import {
  useSize,
  YuButton,
  YuFlex,
  YuIllustration,
  YuModal,
  YuModalContent,
  YuModalFooter,
  YuParagraph,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { IRedeemPointsProduct } from '../../../shared/models';
import { OrderItem } from './OrderItem';
import { IRootReducers } from '../../../reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import {
  removeBagProduct,
  updateBagProduct,
} from '../../../actions/appActions';
import { connect } from 'react-redux';
import { addToast } from '../../../actions/toastActions';

interface OwnProps {
  products?: IRedeemPointsProduct[];
  isOpen?: boolean;
  onClose?: () => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const _ProductsOutStockModal: FC<Props> = ({
  products,
  isOpen,
  onClose,
  addToast,
  cart,
  removeBagProduct,
  updateBagProduct,
}): JSX.Element => {
  const sizes = useSize();
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const typeStr = products?.length > 1 ? 'plural' : 'single';

  const updateCartBeforeClose = async () => {
    setLoading(true);
    for (let i = 0, l = products.length; i < l; i++) {
      const product = products[i];
      const stockDiff = product.qty - product.stock;
      if (stockDiff === product.qty) {
        try {
          await removeBagProduct(product, cart);
        } catch (err) {
          addToast({
            message: formatMessage({ id: 'my-bag.remove.error' }),
            status: 'error',
          });
        }
      } else {
        await updateBagProduct({ ...product, qty: product.stock }, cart);
      }
    }
    onClose();
    setLoading(false);
  };

  return (
    <YuModal
      isOpen={isOpen}
      closable={true}
      size="M"
      onClose={updateCartBeforeClose}
    >
      <YuModalContent className="dni-form-content">
        <YuFlex flexDirection="column" alignItems="center">
          <YuIllustration size={sizes.upL ? 'S' : 'XS'} name="OutOfStock" />
          <YuSpacer size="XXS" />
          <YuTitle center size="XS">
            {formatMessage({
              id: 'products-out-stock.modal.title.' + typeStr,
            })}
          </YuTitle>
          <YuSpacer size="S" />
          <YuParagraph center size="L">
            {formatMessage({
              id: 'products-out-stock.modal.description.' + typeStr,
            })}
          </YuParagraph>
          {products?.map((product) => {
            return (
              <>
                <YuSpacer size="S" />
                <OrderItem item={product} mode="out-stock" />
              </>
            );
          })}
        </YuFlex>
      </YuModalContent>
      <YuModalFooter buttonsPosition="center">
        <YuButton
          prependLoading={loading}
          onClick={updateCartBeforeClose}
          fullWidth={!sizes.upM}
          size="M"
        >
          {formatMessage({
            id: 'products-out-stock.modal.button',
          })}
        </YuButton>
      </YuModalFooter>
    </YuModal>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    cart: state.app.redeemPoints.cart,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      addToast,
      removeBagProduct,
      updateBagProduct,
    },
    dispatch
  );

export const ProductsOutStockModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ProductsOutStockModal);
