export enum authTypes {
  AUTH_IMPERSONATE = '[AUTH] Impersonating user',
  AUTH_LOGOUT = '[AUTH] User logged out',
  AUTH_SET_INITIAL_USER = '[AUTH] Set user initial info',
  AUTH_SET_TOKENS = '[AUTH] Refresh tokens',
  AUTH_UPDATE_USER_DATA = '[AUTH] Update user information',
  AUTH_UPDATE_USER_POINTS = '[AUTH] Update user points',
  AUTH_UPDATE_NEWSLETTER_SUBSCRIPTION = '[AUTH] Update user Newsletter subscription',
  AUTH_USER_LOGGED = '[AUTH] User logged in',
  AUTH_USER_DECLINED = '[AUTH] User declined',
  AUTH_RESET_USER_STATUS = '[AUTH] Reset User Status',
}
