type Status = 'info' | 'success' | 'warning' | 'error' | 'infoDark';

export enum ToastStatusEnum {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO_DARK = 'infoDark',
}

export interface IToast {
  id?: string;
  status: Status;
  message: React.ReactNode;
  oneTime?: boolean;
}
