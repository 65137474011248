import {
  YuButton,
  YuFlex,
  YuIcon,
  YuModal,
  YuParagraph,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import React, { FC } from 'react';

type Props = {
  opened: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  description?: React.ReactNode;
  buttonText: string;
};

const InfoModalFunc: FC<Props> = ({
  opened,
  onClose = _.noop,
  title,
  description,
  buttonText,
}): JSX.Element => {
  return (
    <YuModal
      size="M"
      behaviour={{
        close: { onClickOutArea: false },
      }}
      isOpen={opened}
      onClose={onClose}
    >
      <YuFlex flexDirection="column" alignItems="center">
        <YuIcon name="Exclamation" size="XL" />
        <YuSpacer size="XS" />
        {title ? (
          <>
            <YuTitle center size="XS">
              {title}
            </YuTitle>
            <YuSpacer size="XS" />
          </>
        ) : null}
        {description ? (
          <>
            <YuParagraph center size="L">
              {description}
            </YuParagraph>
            <YuSpacer size="XS" />
          </>
        ) : null}
        <YuSpacer size="XS" />
        <YuButton size="M" onClick={onClose}>
          {buttonText}
        </YuButton>
      </YuFlex>
    </YuModal>
  );
};

export const InfoModal = React.memo(InfoModalFunc);
