interface Props {
  thirdLineInDesktop?: boolean;
}

const SkeletonStepAction = (props: Props): JSX.Element => {
  const { thirdLineInDesktop } = props;

  return (
    <div className="step-action-skeleton">
      <div className="step-action-skeleton--image" />
      <div className="step-action-skeleton--text">
        <div className="step-action-skeleton--text-title" />
        <div className="step-action-skeleton--text-subtitle" />
        {thirdLineInDesktop && (
          <div className="step-action-skeleton--text-third" />
        )}
      </div>
    </div>
  );
};

export default SkeletonStepAction;
