import { config } from 'config/config';
import { serialize } from 'cookie';
import cookie from 'js-cookie';
import { ReduxPageContext } from 'src/store';
import type { IncomingMessage } from 'http';

const { ACCESS_TOKEN, BASE_COOKIE_DOMAIN, REFRESH_TOKEN } = config.COOKIES;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookieFromClient = (key: string, value: any, expires = 5) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires,
      path: '/',
      domain: BASE_COOKIE_DOMAIN,
    });
  }
};

export const setCookiesFromServer = (
  ctx: ReduxPageContext,
  accessToken: string,
  refreshToken: string
) => {
  const accessExpires = new Date();
  accessExpires.setDate(accessExpires.getDate() + 1);

  const refreshExpires = new Date();
  refreshExpires.setDate(refreshExpires.getDate() + 5);

  ctx.res.setHeader('Set-Cookie', [
    serialize(ACCESS_TOKEN, accessToken, {
      path: '/',
      domain: BASE_COOKIE_DOMAIN,
      expires: accessExpires,
    }),
    serialize(REFRESH_TOKEN, refreshToken, {
      path: '/',
      domain: BASE_COOKIE_DOMAIN,
      expires: refreshExpires,
      // maxAge: refreshTokenExpiresAt,
      // httpOnly: true,
      // sameSite: true
    }),
  ]);
};

export const getCookie = (key: string, req?: IncomingMessage) => {
  return process.browser
    ? getCookiesFromBrowser(key)
    : getCookiesFromServer(key, req);
};

const getCookiesFromBrowser = (key: string) => {
  return cookie.get(key);
};

const getCookiesFromServer = (key: string, req: IncomingMessage) => {
  if (!req?.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .toString()
    .split(';')
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const removeCookieFromClient = (key: string, expires = 5) => {
  if (process.browser) {
    cookie.remove(key, {
      expires,
      domain: BASE_COOKIE_DOMAIN,
    });
  }
};

export const removeCookiesFromServer = (ctx: ReduxPageContext) => {
  ctx.res.setHeader('Set-Cookie', [
    serialize(ACCESS_TOKEN, '', {
      path: '/',
      domain: BASE_COOKIE_DOMAIN,
      maxAge: -1,
    }),
    serialize(REFRESH_TOKEN, '', {
      path: '/',
      domain: BASE_COOKIE_DOMAIN,
      maxAge: -1,
    }),
  ]);
};
