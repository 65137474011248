import { FC } from 'react';
import { useIntl } from 'react-intl';
import { IChallengeExternalRedeemPointsPoints } from 'src/shared/models';
import { ExternalRedeemPointsTypeEnum } from './ExternalRedeemPoints.enum';
import { config } from 'config/config';

interface IProps {
  externalRedeemType: string;
  key: number;
  cardData: IChallengeExternalRedeemPointsPoints;
  onClick: () => void;
}

const PointsToRedeemCard: FC<IProps> = ({
  externalRedeemType,
  key,
  cardData,
  onClick,
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const { pointsMessage, points, isSelected, isDisabled } = cardData;
  const { ASSET_PREFIX } = config.APP;

  const cardClass = () => {
    return `card${isSelected ? ' selected' : ''} ${
      isDisabled ? ' card-disabled' : ''
    }`;
  };

  const isDisabledCard = (): (() => void) => {
    if (isDisabled) return;
    return onClick;
  };

  const donationCard = (): JSX.Element => {
    return (
      <div
        key={`donation-card--${key}`}
        className="card-wrapper card-wrapper_donation"
      >
        <div className={cardClass()} onClick={isDisabledCard()}>
          <div className="points-donation">
            {points.toLocaleString()}
            {formatMessage({
              id: 'component.external-redeem-points.card-points',
            })}
          </div>
        </div>
        <div className="icon-check">
          {isSelected && (
            <img
              loading="lazy"
              className="icon icon--green-check"
              src={`${ASSET_PREFIX}/green-check.svg`}
            />
          )}
        </div>
      </div>
    );
  };

  const isdCard = (): JSX.Element => {
    return (
      <div
        key={`donation-isd-card--${key}`}
        className="card-wrapper card-wrapper_isd"
      >
        <div className={cardClass()} onClick={isDisabledCard()}>
          <div className="discount">{pointsMessage}</div>
          <div className="points-isd">
            {points.toLocaleString()}
            {formatMessage({
              id: 'component.external-redeem-points.card-points',
            })}
          </div>
        </div>
        <div className="icon-check">
          {isSelected && (
            <img
              loading="lazy"
              className="icon icon--green-check"
              src={`${ASSET_PREFIX}/green-check.svg`}
            />
          )}
        </div>
      </div>
    );
  };

  switch (externalRedeemType) {
    case ExternalRedeemPointsTypeEnum.EXTERNAL_REDEEM_DONATION:
      return donationCard();
    case ExternalRedeemPointsTypeEnum.EXTERNAL_REDEEM_ISD:
      return isdCard();
    default:
      return (
        <div
          style={{
            color: 'red',
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        >
          {`External Redeem Points Type not implemented: ${externalRedeemType}`}
        </div>
      );
  }
};

export default PointsToRedeemCard;
