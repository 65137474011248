import { NextPage } from 'next';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRootReducers } from '../../../reducers';
import { redeemPointStatus } from '../../../../utils';
import BasicHeaderBag from './BasicHeaderBag';
import { useSize, YuPopperBase } from '@isdin/yuma-react-web-pin';
import BasicHeaderBagIconBadge from './BasicHeaderBagIconBadge';
import { BasicHeaderBagContext } from './BasicHeaderBagContext';
import routePaths from '../../../../config/routes';
import { useRouter } from 'next/router';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const BasicHeaderBagIcon: NextPage<Props, unknown> = ({
  userStatus,
}): JSX.Element => {
  const { upM } = useSize();
  const { visible, setBasicHeaderBagVisible } = useContext(
    BasicHeaderBagContext
  );

  const router = useRouter();

  if (
    upM &&
    !router.pathname.includes(routePaths.PAGES.REDEEM_POINTS.CHECKOUT) &&
    !router.pathname.includes(routePaths.PAGES.REDEEM_POINTS.MY_BAG)
  ) {
    return (
      <YuPopperBase
        behaviour={{
          close: {
            onClickOutArea: true,
            onMouseOutArea: true,
          },
        }}
        placement="bottom-end"
        disabled={!redeemPointStatus.includes(userStatus) || !upM}
        visible={visible}
        onChangeVisibility={setBasicHeaderBagVisible}
        content={<BasicHeaderBag />}
      >
        <BasicHeaderBagIconBadge />
      </YuPopperBase>
    );
  }
  return <BasicHeaderBagIconBadge />;
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    userStatus: state.auth.userStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BasicHeaderBagIcon);
