import { config } from 'config/config';
import getConfig from 'next/config';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import {
  LEGACY_PlatformID_Enum,
  MarketingCloudUserStatus,
  PIN_PlatformID_Enum,
} from 'src/shared/enums';
import { format } from 'url';

const { PLATFORM } = config.APP;

export const isUSAPlatform = PLATFORM === LEGACY_PlatformID_Enum.PIN_USA;
export const isDEPlatform = PLATFORM === PIN_PlatformID_Enum.PIN_ALEMANIA;
export const isESPlatform = PLATFORM === PIN_PlatformID_Enum.PIN_SPAIN;
export const isPTPlatform = PLATFORM === PIN_PlatformID_Enum.PIN_PORTUGAL;
export const isBRPlatform = PLATFORM === PIN_PlatformID_Enum.PIN_BRAZIL;
export const isPEPlatform = PLATFORM === PIN_PlatformID_Enum.PIN_PERU;
export const isCLPlatform = PLATFORM === PIN_PlatformID_Enum.PIN_CHILE;
export const isPlatformPIN =
  Object.values(PIN_PlatformID_Enum).includes(PLATFORM);

export const isDNIRequired = isBRPlatform || isPEPlatform;

export const getCurrencyFormat = () => {
  return config.APP.CURRENCY_FORMAT;
};

export const getFullDateFormat = (): 'MM/dd/yyyy' | 'dd/MM/yyyy' => {
  if (isUSAPlatform) return 'MM/dd/yyyy';
  return 'dd/MM/yyyy';
};

export const getShortDataFormat = (): 'MM/dd' | 'dd/MM' => {
  if (isUSAPlatform) return 'MM/dd';
  return 'dd/MM';
};

export const hideUserAddress = (): boolean => {
  return isUSAPlatform;
};

export const scrollDown = (value: string) => {
  document
    .getElementById(value)
    ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const handleShoppingCartDirection = (): boolean => {
  return !isUSAPlatform;
};

export const setDetailUrl = (
  router: NextRouter,
  basePath: string,
  detailPath: string
) => {
  const { asPath, query, replace } = router;
  const { publicRuntimeConfig } = getConfig();
  const { PLATFORM_PREFIX } = publicRuntimeConfig;

  const constructDetailUrl = (
    basePath: string,
    query: ParsedUrlQuery
  ): string => {
    const { id, ...restParams } = query;
    delete restParams.mainCampaignSlug;
    const basePage = `${basePath}/${id}`;

    const paramsArr = Object.keys(restParams);

    if (paramsArr.length === 0) {
      return basePage;
    }

    const stringifiedParams = paramsArr.reduce((acc, cur) => {
      const operator = acc ? '&' : '?';
      const currentParam = `${[cur]}=${restParams[cur]}`;
      return acc + operator + currentParam;
    }, '');

    return `${basePage}${stringifiedParams}`;
  };

  if (asPath.includes(detailPath)) {
    const page = constructDetailUrl(basePath, query);
    const newPath = `${PLATFORM_PREFIX || ''}${format(page)}`;
    const replacedPath = asPath.replace(PLATFORM_PREFIX, '');

    replace(replacedPath, newPath, { shallow: true });
  }
};

export const commercialModalVisibility = (
  isUserSubscribed: MarketingCloudUserStatus,
  commercialSubscriptionRecruitmentDate: string,
  userUnsubscriptionDate: string,
  accountCreateDate: Date,
  commercialSubscriptionRecruitmentDays: number,
  modalCommercialClosedDate: number,
  tokenCommercialSubscritionStatus: string
): boolean => {
  let shouldShowModal = false;

  const validToken =
    isDEPlatform && tokenCommercialSubscritionStatus === 'valid';

  const currentTimeMs = new Date().getTime();
  const recruitmentTimeMs = new Date(
    Number(commercialSubscriptionRecruitmentDate)
  ).getTime();
  const dateUserUnsubscriptionTimeMs = userUnsubscriptionDate
    ? new Date(userUnsubscriptionDate).getTime()
    : null;
  const dateModalCommercialClosedTimeMs = modalCommercialClosedDate
    ? new Date(Number(modalCommercialClosedDate)).getTime()
    : null;

  const accountCreateTime = new Date(accountCreateDate).getTime();

  if (
    isUserSubscribed ||
    commercialSubscriptionRecruitmentDays === 0 ||
    commercialSubscriptionRecruitmentDate === '0' ||
    validToken
  ) {
    shouldShowModal = false;
  } else {
    if (currentTimeMs > recruitmentTimeMs) {
      if (dateModalCommercialClosedTimeMs || dateUserUnsubscriptionTimeMs) {
        shouldShowModal = shouldRecruitUserAgain(
          currentTimeMs,
          recruitmentTimeMs,
          commercialSubscriptionRecruitmentDays * (1000 * 60 * 60 * 24), // Transform days to miliseconds
          Math.max(
            dateModalCommercialClosedTimeMs || 0,
            dateUserUnsubscriptionTimeMs || 0
          )
        );
      } else {
        shouldShowModal =
          accountCreateTime < recruitmentTimeMs ||
          shouldRecruitUserAgain(
            currentTimeMs,
            recruitmentTimeMs,
            commercialSubscriptionRecruitmentDays * (1000 * 60 * 60 * 24), // Transform days to miliseconds
            accountCreateTime
          );
      }
    }
  }

  return shouldShowModal;
};

export const getFullBaseUrl = (url: string): string => {
  const { BASE_URL } = config.APP;
  return BASE_URL + url;
};

const shouldRecruitUserAgain = (
  currentTimeMs: number,
  recruitmentTimeMs: number,
  daysShowAgainMs: number,
  lastRejectCommercialActionMade: number
): boolean => {
  const actionTimeFromRecruitment =
    lastRejectCommercialActionMade - recruitmentTimeMs;
  const showAgainIteration = Math.floor(
    actionTimeFromRecruitment / daysShowAgainMs
  );
  const expectedNextAction =
    (showAgainIteration + 1) * daysShowAgainMs + recruitmentTimeMs;
  return currentTimeMs >= expectedNextAction;
};
