import React, { Component } from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal';
import { FormattedMessage } from 'react-intl';
import { config } from 'config/config';

export const loadingSpinner = (
  <LoadingOutlined
    style={{
      fontSize: 50,
      color: 'red',
      marginTop: '100px',
      marginBottom: '100px',
    }}
    spin
  />
);

export const loadingSpinner__small = (
  <LoadingOutlined
    style={{
      color: 'red',
    }}
    spin
  />
);

interface OwnProps {
  customClass?: string;
  content: string | React.ReactNode | Element;
  error?: React.ReactNode;
  icon?: string;
  loading: boolean;
  hideFooter: boolean;
  showIcon?: boolean;
  title?: string | React.ReactNode;
  onCancel?: () => void;
}

type Props = ModalProps & OwnProps;

const preventDefault = (e) => {
  e.preventDefault();
};

export default class CustomModal extends Component<Props, unknown> {
  public static defaultProps: Partial<Props> = {
    showIcon: true,
    icon: `${config.APP.ASSET_PREFIX}/check_icon.svg`,
    content: '',
  };

  componentWillUnmount() {
    document.body.removeEventListener('touchmove', preventDefault);
    document.body.classList.remove('not-overflow');
  }

  render() {
    const {
      customClass,
      content,
      error,
      hideFooter,
      icon,
      loading,
      showIcon,
      title,
    } = this.props;

    let _title = title;
    if (!loading && title)
      _title = (
        <Row justify="center" className="title">
          {title}
        </Row>
      );

    let _footer = this.props.footer;
    if (hideFooter) _footer = null;

    const modalProps = {
      ...this.props,
      title: _title,
      footer: _footer,
      className: `custom-modal ${customClass}`,
    };

    if (typeof document !== 'undefined')
      if (modalProps.visible) {
        document.body.addEventListener('touchmove', preventDefault, {
          passive: false,
        });
        document.body.classList.add('not-overflow');
      } else {
        document.body.removeEventListener('touchmove', preventDefault);
        document.body.classList.remove('not-overflow');
      }

    return (
      <Modal {...modalProps}>
        {modalProps.visible && (
          <Row justify="center">
            {loading ? (
              <Spin indicator={loadingSpinner} />
            ) : error ? (
              <Row justify="center" className="regular-body">
                <Col>
                  <div className="custom-modal__error-icon-container">
                    <i
                      className={`icon icon--not-valid-circle icon__error custom-modal__error-icon`}
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    {error || <FormattedMessage id="register.submit.error" />}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row justify="center">
                {showIcon ? (
                  <img loading="lazy" src={icon} width="106" height="106" />
                ) : (
                  <></>
                )}
                {typeof content === 'string' ? (
                  <Col style={{ margin: '20px' }}>
                    <p
                      className="small-body"
                      style={{ fontWeight: 700, textAlign: 'center' }}
                    >
                      {content}
                    </p>
                  </Col>
                ) : (
                  content
                )}
              </Row>
            )}
          </Row>
        )}
      </Modal>
    );
  }
}
