export enum ChallengeTypeEnum {
  FORMATION = 1,
  TALK = 2,
  CAMPAIGN = 3,
  ANNUAL_CAMPAIGN = 4,
  EXPERIENCE = 5,
  PEARL = 6,
  CONGRESS = 9,
  FORMATION_CON = 10,
  CERTIFIED_COURSE = 11,
  EXPERIENCE_PRODUCT = 12,
  LEARNING = 13,
  SURVEY = 14,
}

export const CHALLENGE_TYPE_KEYS = Object.freeze({
  [ChallengeTypeEnum.ANNUAL_CAMPAIGN]: 'annualCampaign',
  [ChallengeTypeEnum.CAMPAIGN]: 'campaigns',
  [ChallengeTypeEnum.CERTIFIED_COURSE]: 'certifiedCourses',
  [ChallengeTypeEnum.CONGRESS]: 'congresses',
  [ChallengeTypeEnum.FORMATION]: 'trainings',
  [ChallengeTypeEnum.PEARL]: 'pearls',
  [ChallengeTypeEnum.TALK]: 'events',
});

export type CHALLENGE_TYPE_VALUES =
  typeof CHALLENGE_TYPE_KEYS[keyof typeof CHALLENGE_TYPE_KEYS];
