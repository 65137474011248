import { DateTime } from 'luxon';
import React from 'react';
import {
  buildChallengeEvents,
  buildChallengeGallery,
  buildChallengeImage,
  buildChallengeMaterials,
  buildChallengeQuiz,
  buildChallengeUserUploadMedia,
  buildChallengeVideo,
  builderGenericButton,
  builderWysWygChallenge,
  buildExpertsGroup,
  buildExternalRedeemPoints,
  buildHeadboard,
  buildLinkedChallenges,
  buildPearl,
  buildResourceGuide,
  buildSellIn,
  buildSellOut,
} from 'src/components/challenge/builder';
import ChallengeActivationButton from 'src/components/challenge/ChallengeActivationButton';
import ChallengeGenericButton from 'src/components/challenge/ChallengeGenericButton';
import ChallengeImage from 'src/components/challenge/ChallengeImage';
import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import ChallengeLinkedChallenges from 'src/components/challenge/ChallengeLinkedChallenges';
import ChallengeMaterials from 'src/components/challenge/ChallengeMaterials';
// TODO: Add an index.tsx
import { builderCertifiedCourse } from 'src/components/challenge/builder/BuilderCertifiedCourse';
import ChallengeArnSurvey from 'src/components/challenge/ChallengeArnSurvey';
import ChallengeCertifiedCourse from 'src/components/challenge/ChallengeCertifiedCourse';
import ChallengeQuiz from 'src/components/challenge/ChallengeQuiz';
import ChallengeResourceGuide from 'src/components/challenge/ChallengeResourceGuide';
import ChallengeSellIn from 'src/components/challenge/ChallengeSellIn';
import ChallengeSellOut from 'src/components/challenge/ChallengeSellOut';
import ChallengeSurvey from 'src/components/challenge/ChallengeSurvey';
import ChallengeUserUploadMedia from 'src/components/challenge/ChallengeUserUploadMedia';
import ChallengeVideo from 'src/components/challenge/ChallengeVideo';
import ChallengeExternalRedeemPoints from 'src/components/challenge/externalRedeemPoints/ChallengeExternalRedeemPoints';
import ChallengeWyswyg from 'src/components/challenge/ChallengeWYSWYG';
import { ChallengePearl } from 'src/components/pearl/PearlRenders';
import ShoppingCart from 'src/components/shoppingCart/ShoppingCart';
import {
  CampaignModuleStatusEnum,
  ChallengeTypeEnum,
  ResourceTypeEnum,
  UserResourceEnum,
} from 'src/shared/enums';
import {
  ChallengeDetail,
  IChallenge,
  IChallengeCoverVideo,
  IChallengeDates,
  IChallengeQuiz,
  IChallengeResource,
  IChallengeResourceConfig,
  IChallengeSpeaker,
  IExternalRedeemPoints,
  IGallery,
  isVideoValidFormat,
  IUser,
  TypeChallengeModality,
  TypeChallengeStatusLabel,
  TypeChallengeUserLabel,
} from 'src/shared/models';
import {
  checkCampaignActive,
  checkUserStatus,
  constructResources,
  getShortDataFormat,
  setResourcesDependentCondictions,
} from '../../../../utils';
import { getTimeZone } from 'utils/timeUtils';
import ChallengeExpertsGroup from '../ChallengeExpertsGroup';
import { builderSurvey } from './BuilderSurvey';
import { buildActivationButton } from './BuildActivationButton';
import ChallengeClause from '../ChallengeClause';
import { buildClause } from './BuilderClause';

const {
  ACTIVATION_BUTTON,
  CHALLENGE_MATERIALS,
  CLAUSE,
  EVENT,
  FAQ,
  GALLERY,
  INSCRIPTION,
  LINKED_CHALLENGES,
  PARTICIPATION,
  QUIZ,
  RESOURCES_GUIDE,
  GENERIC_BUTTON,
  WYSIWYG_CHALLENGE,
  SHOPPING_CART,
  SURVEY,
  ARN_SURVEY,
  TEXT,
  VIDEO,
  CHALLENGE_VISIBILITY,
  SELL_OUT,
  SELL_IN,
  PEARL,
  IMAGE,
  EXPERTS_GROUP,
  CERTIFIED_COURSE: CERTIFIED_COURSE_RESOURCE,
  EXTERNAL_REDEEM_POINTS,
} = ResourceTypeEnum;

const { INACTIVE, DONE, WAITING_LIST } = UserResourceEnum;
const passiveResources = [
  ResourceTypeEnum.IMAGE,
  ResourceTypeEnum.WYSIWYG_CHALLENGE,
  ResourceTypeEnum.TEXT,
  ResourceTypeEnum.FAQ,
  ResourceTypeEnum.FAQ_1_CONTENT,
  ResourceTypeEnum.FAQ_2_CONTENT,
  ResourceTypeEnum.FAQ_3_CONTENT,
  ResourceTypeEnum.FAQ_4_CONTENT,
  ResourceTypeEnum.FAQ_5_CONTENT,
];
const { FORMATION, CAMPAIGN, TALK, CONGRESS, CERTIFIED_COURSE } =
  ChallengeTypeEnum;

const getMultiEvents = (resources: IResourceList[]) => {
  return resources.filter(
    (ele) => ele.idResourceType.idResourceType === ResourceTypeEnum.EVENT
  );
};

export const builderChallenge = (
  challenge: IChallenge,
  user: IUser,
  accessToken?: string
): ChallengeDetail => {
  const {
    brand: challengeBrand,
    categories,
    coverImage,
    coverImageMobile,
    coverVideo,
    description,
    descriptionLong,
    endDate,
    endImage,
    endImageMobile,
    endVisDate,
    featured,
    i18nList,
    idChallenge,
    idChallengeType,
    likesList,
    name,
    resourceList,
    showPoints,
    startDate,
    startVisDate,
    showHeader,
    duration,
    showDuration,
    slug,
  } = challenge;

  let userStatus: { idResource: number; status: number };
  let challengeQuiz: IChallengeQuiz;
  let challengeText = [];

  const challengeType: ChallengeTypeEnum = idChallengeType?.idChallengeType;
  const isChallengeCampaign = challengeType === CAMPAIGN;
  let isChallengeInProgress = false;

  let challengeModality: TypeChallengeModality;
  let challengeStatusLabel: TypeChallengeStatusLabel = null;
  let challengeUserLabel: TypeChallengeUserLabel = null;

  let challengePoints = 0;
  let challengeFaqs: IResourceList;
  let challengeGallery: IGallery;
  // TODO: rename as "IChallengeModule"
  const challengeResources: IChallengeResource[] = [];
  let challengeUserUploadMedia: IChallengeResource;
  const sortedResources = constructResources(resourceList);
  const events = getMultiEvents(sortedResources);

  //TODO: Construir eventos con builderChallenge
  // TODO: Remove headboardConfiguration and use `challangeQuiz`
  const headboardConfiguration = buildHeadboard(challenge);
  const challengeEvents = buildChallengeEvents(
    sortedResources,
    challenge,
    events,
    challengeResources
  );
  const mainEvent = challengeEvents.find((event) => event.isMain);
  const challengeDates: IChallengeDates = {
    startDate: DateTime.fromJSDate(new Date(startDate))
      .setZone(getTimeZone())
      .toString(),
    endDate: DateTime.fromJSDate(new Date(endDate))
      .setZone(getTimeZone())
      .toString(),
    startVisDate: DateTime.fromJSDate(new Date(startVisDate))
      .setZone(getTimeZone())
      .toString(),
    endVisDate: DateTime.fromJSDate(new Date(endVisDate))
      .setZone(getTimeZone())
      .toString(),
  };

  const CHALLENGE_SPEAKERS: IChallengeSpeaker[] = [];
  challengeEvents
    .filter((challengeEvent) => {
      if (!mainEvent) return true;

      return challengeEvent.idResource == mainEvent.idResource;
    })
    .forEach(({ eventSpeakers }) => {
      eventSpeakers?.forEach((speaker) => CHALLENGE_SPEAKERS.push(speaker));
    });

  const shortDateFormat = getShortDataFormat();

  const dateValues = {
    startDate: DateTime.fromJSDate(new Date(startDate))
      .setZone(getTimeZone())
      .toFormat(shortDateFormat),
    endDate: DateTime.fromJSDate(new Date(endDate))
      .setZone(getTimeZone())
      .toFormat(shortDateFormat),
  };

  sortedResources.forEach((_resource: IResourceList) => {
    const resourceMainConfig: IChallengeResourceConfig = {
      title: _resource.name,
      idResource: _resource.idResource,
      uuidResource: _resource.uuidResource,
      isCampaignActivator: _resource.activate,
      isCompleted: checkUserStatus(_resource, [
        CampaignModuleStatusEnum.PROCESSED,
        CampaignModuleStatusEnum.GENERIC_DONE,
        CampaignModuleStatusEnum.SELL_IN_REGISTERED,
        CampaignModuleStatusEnum.SHOPPING_CART_ERROR,
        CampaignModuleStatusEnum.SHOPPING_CART_IN_PROGRESS,
      ]),
      isPartialDone: checkUserStatus(_resource, [
        CampaignModuleStatusEnum.PARTIAL_DONE,
        CampaignModuleStatusEnum.GENERIC_DONE,
      ]),
      isActive: false,
      isBlocked: false,
      isVisible: true,
      points: _resource.score?.points || 0,
    };

    let pearlConfig;
    let expertsGroupConfig;
    let arnSurveyConfig: IChallengeResourceConfig;
    let externalRedeemPointsConfig: IExternalRedeemPoints;
    let maxEventPoints = 0;
    switch (_resource.idResourceType.idResourceType) {
      case ACTIVATION_BUTTON:
        challengePoints += resourceMainConfig.points;
        challengeResources.push({
          key: `challenge--activation-button-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: ACTIVATION_BUTTON,
          config: {
            ...resourceMainConfig,
            ...buildActivationButton(_resource, user),
          },
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => (
            <ChallengeActivationButton
              {...{
                config: {
                  ...resourceMainConfig,
                  ...buildActivationButton(_resource, user),
                },
                idResource: _resource.idResource,
                shouldRenderChallengeHeader: showHeader,
                updateChallenge,
                updateUserPoints,
                id: `challenge--activation-button-${_resource.idResource}`,
              }}
            />
          ),
        });
        break;

      case CHALLENGE_MATERIALS:
        challengePoints += {
          ...resourceMainConfig,
          ...buildChallengeMaterials(_resource),
        }.points;

        challengeResources.push({
          key: `challenge--material-${_resource.idResource}`,
          idResource: _resource.idResource,
          config: {
            ...resourceMainConfig,
            ...buildChallengeMaterials(_resource),
          },
          type: CHALLENGE_MATERIALS,
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeMaterials
                {...{
                  config: {
                    ...resourceMainConfig,
                    ...buildChallengeMaterials(_resource),
                  },
                  updateUserPoints,
                  updateChallenge,
                }}
              />
            );
          },
        });
        break;

      case CERTIFIED_COURSE_RESOURCE:
        challengePoints += {
          ...resourceMainConfig,
          ...builderCertifiedCourse(_resource),
          idChallenge,
        }.points;
        isChallengeInProgress =
          isChallengeInProgress ||
          {
            ...resourceMainConfig,
            ...builderCertifiedCourse(_resource),
            idChallenge,
          }.isPartialDone;

        challengeResources.push({
          key: `challenge--certified-course-${_resource.idResource}`,
          idResource: _resource.idResource,
          config: {
            ...resourceMainConfig,
            ...builderCertifiedCourse(_resource),
            idChallenge,
          },
          type: CERTIFIED_COURSE_RESOURCE,
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeCertifiedCourse
                {...{
                  idResource: _resource.idResource,
                  config: {
                    ...resourceMainConfig,
                    ...builderCertifiedCourse(_resource),
                    idChallenge,
                  },
                  updateChallenge,
                  updateUserPoints,
                }}
              />
            );
          },
        });
        break;

      case CLAUSE:
        challengePoints += resourceMainConfig.points;
        challengeResources.push({
          key: `challenge--clause-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: CLAUSE,
          config: {
            ...resourceMainConfig,
            ...buildClause(_resource),
          },
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => (
            <ChallengeClause
              {...{
                config: {
                  ...resourceMainConfig,
                  ...buildClause(_resource),
                },
                idResource: _resource.idResource,
                updateChallenge,
                updateUserPoints,
                id: `challenge--clause-${_resource.idResource}`,
              }}
            />
          ),
        });
        break;

      case EVENT:
        challengeEvents.forEach((event) => {
          if (!mainEvent && challengePoints < event.points) {
            maxEventPoints = event.points;
          }

          challengeResources.push({
            key: `challenge--event-${event.idResource}`,
            idResource: event.idResource,
            config: event,
            type: EVENT,
            resource: _resource,
            render: () => <React.Fragment />,
          });
        });
        challengePoints += maxEventPoints;
        // TODO: Eliminar porque los eventos ya se construyen con ``buildChallengeEvents``
        if (_resource.resourceDetailList?.length) {
          _resource.resourceList?.forEach((eventResource) => {
            switch (eventResource.idResourceType.idResourceType) {
              case INSCRIPTION:
                if (eventResource.usersM2MList?.length) {
                  const { usersM2MList } = eventResource;
                  const userInscriptions = usersM2MList.filter(
                    (_m2m) => _m2m.status && _m2m.statusResource !== INACTIVE
                  );
                  if (userInscriptions.length)
                    userStatus = {
                      idResource: eventResource.idResource,
                      status: userInscriptions[0].statusResource,
                    };
                }
                break;
            }
          });
        }
        break;

      case FAQ:
        challengeFaqs = _resource;
        break;

      case GALLERY:
        challengeGallery = buildChallengeGallery(_resource, idChallenge);
        break;

      case LINKED_CHALLENGES:
        challengePoints += {
          ...resourceMainConfig,
          ...buildLinkedChallenges(_resource, user),
          mainCampaignSlug: challenge.slug,
          idParentChallenge: challenge.slug,
        }.points;

        challengeResources.push({
          key: `challenge--linked-challenge-${_resource.idResource}`,
          idResource: _resource.idResource,
          config: {
            ...resourceMainConfig,
            ...buildLinkedChallenges(_resource, user),
            mainCampaignSlug: challenge.slug,
            idParentChallenge: challenge.slug,
          },
          type: LINKED_CHALLENGES,
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeLinkedChallenges
                {...{
                  config: {
                    ...resourceMainConfig,
                    ...buildLinkedChallenges(_resource, user),
                    mainCampaignSlug: challenge.slug,
                    idParentChallenge: challenge.slug,
                  },
                  updateUserPoints,
                  updateChallenge,
                }}
              />
            );
          },
        });
        break;

      case QUIZ:
        challengeQuiz = {
          ...resourceMainConfig,
          ...buildChallengeQuiz(
            _resource,
            idChallenge,
            challengeType,
            mainEvent,
            challengeDates
          ),
        };
        challengePoints += challengeQuiz.points;

        challengeResources.push({
          key: `challenge--quiz-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: QUIZ,
          config: challengeQuiz,
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeQuiz
                {...{
                  isTraining: challengeType === FORMATION,
                  config: challengeQuiz,
                  resource: _resource,
                  updateUserPoints,
                  updateChallenge,
                }}
              />
            );
          },
        });

        _resource.resourceList.forEach((r) => {
          if (r.idResourceType.idResourceType === PARTICIPATION) {
            const isEvent = [TALK, CONGRESS].includes(challengeType);
            if (!isEvent)
              userStatus = { idResource: r.idResource, status: null };
            if (r.usersM2MList?.length) {
              const { usersM2MList } = r;
              const userParticipation = usersM2MList.filter(
                (_m2m) => _m2m.status && _m2m.statusResource !== INACTIVE
              );
              if (userParticipation.length && !isEvent)
                userStatus = {
                  ...userStatus,
                  status: userParticipation[0].statusResource,
                };
            }
          }
        });

        break;

      case RESOURCES_GUIDE:
        challengeResources.push({
          idResource: _resource.idResource,
          key: `challenge--resource-guide-${_resource.idResource}`,
          type: RESOURCES_GUIDE,
          config: {
            ...resourceMainConfig,
            ...buildResourceGuide(_resource),
          },
          resource: _resource,
          render: () => (
            <ChallengeResourceGuide
              {...{
                resourceGuideConfig: {
                  ...resourceMainConfig,
                  ...buildResourceGuide(_resource),
                },
              }}
            />
          ),
        });
        break;

      case GENERIC_BUTTON:
        challengeResources.push({
          idResource: _resource.idResource,
          key: `challenge--generic-button-${_resource.idResource}`,
          type: GENERIC_BUTTON,
          config: {
            ...resourceMainConfig,
            ...builderGenericButton(_resource),
          },
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => (
            <ChallengeGenericButton
              {...{
                idResource: _resource.idResource,
                config: {
                  ...resourceMainConfig,
                  ...builderGenericButton(_resource),
                },
                updateChallenge,
                updateUserPoints,
              }}
            />
          ),
        });
        break;

      case WYSIWYG_CHALLENGE:
        challengeResources.push({
          idResource: _resource.idResource,
          key: `challenge--wyswyg-challenge-${_resource.idResource}`,
          type: WYSIWYG_CHALLENGE,
          config: {
            ...resourceMainConfig,
            ...builderWysWygChallenge(_resource),
          },
          resource: _resource,
          render: () => (
            <ChallengeWyswyg
              config={{
                ...resourceMainConfig,
                ...builderWysWygChallenge(_resource),
              }}
            />
          ),
        });
        break;

      case SHOPPING_CART:
        challengePoints += resourceMainConfig.points;

        challengeResources.push({
          key: `challenge--shopping-cart-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: SHOPPING_CART,
          resource: _resource,
          config: {
            ...resourceMainConfig,
            icon: 'campaign-material-request',
            isCompleted: checkUserStatus(_resource, [
              CampaignModuleStatusEnum.PROCESSED,
              CampaignModuleStatusEnum.SHOPPING_CART_ERROR,
              CampaignModuleStatusEnum.SHOPPING_CART_IN_PROGRESS,
            ]),
            status: _resource.usersM2MList[0]?.statusResource,
          },
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ShoppingCart
                {...{
                  resource: _resource,
                  updateUserPoints,
                  updateChallenge,
                  config: {
                    ...resourceMainConfig,
                    icon: 'campaign-material-request',
                    isCompleted: checkUserStatus(_resource, [
                      CampaignModuleStatusEnum.PROCESSED,
                      CampaignModuleStatusEnum.SHOPPING_CART_ERROR,
                      CampaignModuleStatusEnum.SHOPPING_CART_IN_PROGRESS,
                    ]),
                    status: _resource.usersM2MList[0]?.statusResource,
                  },
                }}
              />
            );
          },
        });
        break;

      case SURVEY:
        challengePoints += {
          ...resourceMainConfig,
          ...builderSurvey(_resource),
        }.points;

        challengeResources.push({
          key: `challenge--survey-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: SURVEY,
          resource: _resource,
          config: {
            ...resourceMainConfig,
            ...builderSurvey(_resource),
          },
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeSurvey
                key={`challenge--survey-${_resource.idResource}`}
                {...{
                  idResource: _resource.idResource,
                  hideTopPoints:
                    challenge.idChallengeType.idChallengeType ===
                    ChallengeTypeEnum.SURVEY,
                  config: {
                    ...resourceMainConfig,
                    ...builderSurvey(_resource),
                  },
                  updateChallenge,
                  updateUserPoints,
                }}
              />
            );
          },
        });
        break;

      case TEXT:
        challengeText = [
          ...challengeText,
          _resource.resourceDetailList[0].value,
        ];
        break;

      case IMAGE:
        challengeResources.push({
          key: `challenge--image-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: IMAGE,
          config: {
            ...resourceMainConfig,
            ...buildChallengeImage(_resource, idChallenge),
          },
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeImage
                {...{
                  config: {
                    ...resourceMainConfig,
                    ...buildChallengeImage(_resource, idChallenge),
                  },
                  updateUserPoints,
                  updateChallenge,
                }}
              />
            );
          },
        });
        break;

      case CHALLENGE_VISIBILITY:
        challengePoints += {
          ...resourceMainConfig,
          ...buildChallengeUserUploadMedia(_resource, idChallenge),
        }.points;

        challengeResources.push({
          key: `challenge--user-upload-file-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: CHALLENGE_VISIBILITY,
          config: {
            ...resourceMainConfig,
            ...buildChallengeUserUploadMedia(_resource, idChallenge),
          },
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => (
            <ChallengeUserUploadMedia
              {...{
                config: {
                  ...resourceMainConfig,
                  ...buildChallengeUserUploadMedia(_resource, idChallenge),
                },
                updateChallenge,
                updateUserPoints,
              }}
            />
          ),
        });
        break;

      case VIDEO:
        challengePoints += {
          ...resourceMainConfig,
          ...buildChallengeVideo(_resource),
        }.points;

        challengeResources.push({
          key: `challenge--video-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: VIDEO,
          config: {
            ...resourceMainConfig,
            ...buildChallengeVideo(_resource),
          },
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeVideo
                {...{
                  config: {
                    ...resourceMainConfig,
                    ...buildChallengeVideo(_resource),
                  },
                  updateUserPoints,
                  updateChallenge,
                }}
              />
            );
          },
        });
        break;

      case SELL_OUT:
        challengePoints += {
          ...resourceMainConfig,
          ...buildSellOut(_resource, user),
        }.points;

        challengeResources.push({
          key: `challenge--sell-out-${_resource.idResource}`,
          idResource: _resource.idResource,
          config: {
            ...resourceMainConfig,
            ...buildSellOut(_resource, user),
          },
          type: SELL_OUT,
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeSellOut
                {...{
                  config: {
                    ...resourceMainConfig,
                    ...buildSellOut(_resource, user),
                  },
                  updateUserPoints,
                  updateChallenge,
                  resource: _resource,
                  user,
                  idChallenge: challenge.idChallenge,
                }}
              />
            );
          },
        });
        break;

      case SELL_IN:
        challengePoints += {
          ...resourceMainConfig,
          ...buildSellIn(_resource, resourceMainConfig),
        }.points;

        challengeResources.push({
          key: `challenge--sell-in-${_resource.idResource}`,
          idResource: _resource.idResource,
          config: {
            ...resourceMainConfig,
            ...buildSellIn(_resource, resourceMainConfig),
          },
          type: SELL_IN,
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeSellIn
                {...{
                  config: {
                    ...resourceMainConfig,
                    ...buildSellIn(_resource, resourceMainConfig),
                  },
                  updateUserPoints,
                  updateChallenge,
                  resource: _resource,
                  user,
                  idChallenge: challenge.idChallenge,
                }}
              />
            );
          },
        });
        break;

      case PEARL:
        pearlConfig = {
          ...resourceMainConfig,
          ...buildPearl(
            { ..._resource, endDate: challengeDates.endDate },
            user
          ),
        };

        if (pearlConfig?.formattedPearlStatus)
          challengeUserLabel = `pearl-${pearlConfig?.formattedPearlStatus}`;

        challengePoints += resourceMainConfig.points;

        challengeResources.push({
          key: `challenge--pearl-${_resource.idResource}`,
          idResource: _resource.idResource,
          config: pearlConfig,
          type: PEARL,
          resource: _resource,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengePearl
                {...{
                  config: pearlConfig,
                  updateUserPoints,
                  updateChallenge,
                  resource: _resource,
                  user,
                  idChallenge: challenge.idChallenge,
                  accessToken,
                }}
              />
            );
          },
        });
        break;

      case ARN_SURVEY:
        arnSurveyConfig = {
          ...resourceMainConfig,
          isCompleted: checkUserStatus(_resource.resourceList[0], [
            UserResourceEnum.DONE,
          ]),
          points: _resource?.score?.points || 0,
          icon: 'survey',
        };
        challengePoints += arnSurveyConfig.points;

        challengeResources.push({
          key: `challenge--arn-survey-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: ARN_SURVEY,
          resource: _resource,
          // TODO: Add "config" to align dependecies
          config: arnSurveyConfig,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeArnSurvey
                key={`challenge--arn-survey-${_resource.idResource}`}
                {...{
                  ...arnSurveyConfig,
                  isCampaignComponent:
                    challenge.idChallengeType?.idChallengeType === CAMPAIGN,
                  isChallengeComponent: true,
                  resource: _resource,
                  updateChallenge,
                  updateUserPoints,
                }}
              />
            );
          },
        });
        break;

      case EXPERTS_GROUP:
        expertsGroupConfig = {
          ...resourceMainConfig,
          ...buildExpertsGroup(_resource, sortedResources),
        };

        challengeResources.push({
          key: `challenge--experts-group-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: EXPERTS_GROUP,
          resource: _resource,
          config: expertsGroupConfig,
          render: () => {
            return <ChallengeExpertsGroup config={expertsGroupConfig} />;
          },
        });
        break;

      case EXTERNAL_REDEEM_POINTS:
        externalRedeemPointsConfig = {
          order: _resource.order,
          ...resourceMainConfig,
          ...buildExternalRedeemPoints({
            resource: _resource,
            availableUserPoints: Number(
              user.expirationPoints.details.customerBalance ?? 0
            ),
          }),
        };
        challengeResources.push({
          key: `challenge--external-redeem-points-${_resource.idResource}`,
          idResource: _resource.idResource,
          type: EXTERNAL_REDEEM_POINTS,
          resource: _resource,
          config: externalRedeemPointsConfig,
          render: ({ updateChallenge, updateUserPoints }) => {
            return (
              <ChallengeExternalRedeemPoints
                {...{
                  config: externalRedeemPointsConfig,
                  updateUserPoints,
                  updateChallenge,
                }}
              />
            );
          },
        });
        break;
    }
  });

  const isChallengeExpired =
    DateTime.now().setZone(getTimeZone()) >
    DateTime.fromJSDate(new Date(endDate)).setZone(getTimeZone());
  challengePoints += mainEvent?.points || 0;

  switch (true) {
    case challengeEvents.length > 1:
      challengeModality = 'multi-event';
      break;

    case mainEvent?.status?.isOnline:
      challengeModality = 'online';
      break;

    case mainEvent?.status?.isOnsite:
      challengeModality = 'onSite';
      break;

    case challengeType === FORMATION:
      challengeModality = 'training';
      break;
  }

  switch (true) {
    case challengeType === CERTIFIED_COURSE:
      challengeStatusLabel = 'certificate';
      break;

    case mainEvent?.status?.isCanceled:
      challengeStatusLabel = 'canceled';
      break;

    case isChallengeExpired:
      challengeStatusLabel = 'expired';
      break;

    // case mainEvent?.status?.isFull:
    //   challengeStatusLabel = 'sold-out';
    //   break;

    // TODO: Funcionalidad nueva. API no preparada
    // case mainEvent?.status?.isInProgress:
    //   challengeStatusLabel = 'on-live';
    //   break;

    // case isLastPlaces:
    //   challengeStatusLabel = 'last-places';
    //   break;

    case DateTime.now()
      .setZone(getTimeZone())
      .diff(DateTime.fromJSDate(new Date(startVisDate)), 'days').days <= 3:
      challengeStatusLabel = 'new';
      break;
  }
  const isCampaignCompleted = challengeResources.every(
    ({
      config: { isCompleted },
      resource: {
        idResourceType: { idResourceType },
      },
    }) => isCompleted || passiveResources.includes(idResourceType)
  );

  const today = DateTime.now().setZone(getTimeZone());

  const isChallengeInactive =
    today <=
    DateTime.fromJSDate(new Date(challengeDates.startDate)).setZone(
      getTimeZone()
    );

  const isSpecialChallenge = [CAMPAIGN, CERTIFIED_COURSE].includes(
    challengeType
  );

  isChallengeInProgress =
    isChallengeInProgress || headboardConfiguration.isQuizInprogress;

  switch (true) {
    case mainEvent?.attend?.isPerformed:
      break;

    case mainEvent?.inscription?.isPerformed:
      challengeUserLabel = 'registered';
      break;

    case userStatus?.status == WAITING_LIST:
      challengeUserLabel = 'waiting-list';
      break;

    case isChallengeInProgress:
      challengeUserLabel = 'in-progress';
      break;

    case headboardConfiguration.isQuizDone &&
      challengeType !== ChallengeTypeEnum.CAMPAIGN:
      challengeUserLabel = 'quiz-done';
      break;

    case headboardConfiguration.isQuizDone &&
      challengeQuiz.dateDone >
        DateTime.fromJSDate(new Date(endDate)).setZone(getTimeZone()) &&
      challengeType !== ChallengeTypeEnum.CAMPAIGN:
      challengeUserLabel = 'training-done';
      break;

    case isCampaignCompleted &&
      isSpecialChallenge &&
      challengeType !== ChallengeTypeEnum.CERTIFIED_COURSE:
      challengeUserLabel = 'campaign-completed';
      break;

    case isChallengeInactive && isChallengeCampaign:
      challengeUserLabel = 'campaign-inactive';
      break;

    case isCampaignCompleted &&
      isSpecialChallenge &&
      challengeType === ChallengeTypeEnum.CERTIFIED_COURSE:
      challengeUserLabel = 'certified-completed';
      break;
  }

  let challengeLikes = { totalLikes: 0, liked: false };

  if (likesList?.length) {
    // TODO: Check why some campaigns has likesList = undefined
    const userHasLiked = likesList.filter(
      (_like) => _like.idUser.idUser === user.idUser
    );

    challengeLikes = {
      ...challengeLikes,
      totalLikes: likesList.length,
      liked: !!userHasLiked.length,
    };
  }

  const isChallengeCompleted =
    isCampaignCompleted ||
    userStatus?.status === DONE ||
    mainEvent?.attend?.isPerformed;

  const cardImage = isChallengeCompleted && endImage ? endImage : coverImage;
  const cardImageMobile =
    (isChallengeCompleted && endImageMobile
      ? endImageMobile
      : coverImageMobile) ?? cardImage;
  const challengeCategories: number[] =
    categories?.map((category) => category.idCategory) || [];

  const format = coverVideo?.trim().split('.');
  const isFromFile = format && isVideoValidFormat(format[format.length - 1]);
  const isCampaignActive =
    checkCampaignActive(challengeResources) && isChallengeCampaign;

  const hasCompletedResource = challengeResources.some(
    (resource) => resource.config.isCompleted
  );
  setResourcesDependentCondictions(challengeResources, isCampaignActive);

  const challengeVideo: IChallengeCoverVideo = {
    url: coverVideo?.trim(),
    isFromFile: Boolean(coverVideo?.trim()) && isFromFile,
    videoIsVertical: challenge.coverVideoIsVertical,
  };

  return {
    challengeId: idChallenge,
    challengeText,
    challengeVideo,
    challengeEvents,
    challengeFaqs,
    challengeGallery,
    challengePoints: challengePoints,
    challengeBrand,
    challengeCategories,
    challengeType,
    challengeModality,
    challengeDates,
    challengeStatusLabel,
    challengeSpeakers: CHALLENGE_SPEAKERS,
    challengeUserLabel,
    challengeCardButton: null,
    challengeListPath: null,
    challengeResources,
    challengeUserUploadMedia,
    showDuration,
    duration,
    showPoints,
    cardImage,
    cardImageMobile,
    challengeLikes,
    userStatus,
    headboardConfiguration,
    isCampaignActive,
    hasCompletedResource,
    isChallengeCompleted,
    isFeatured: featured,
    isChallengeExpired: isChallengeExpired,
    dateValues,
    slug,
    //Old Data
    i18nList,
    name,
    description,
    descriptionLong,
    showHeader,
  };
};
