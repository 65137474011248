import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_IT: IRegion[] = [
  {
    id: 'a0C5800000FiELbEAN',
    name: 'Agrigento',
    code: 'AG',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEM0EAN',
    name: 'Alessandria',
    code: 'AL',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMNEA3',
    name: 'Ancona',
    code: 'AN',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMcEAN',
    name: 'Aosta',
    code: 'AO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiENYEA3',
    name: 'Arezzo',
    code: 'AR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiENpEAN',
    name: 'Ascoli Piceno',
    code: 'AP',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiENrEAN',
    name: 'Asti',
    code: 'AT',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEO8EAN',
    name: 'Avellino',
    code: 'AV',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOYEA3',
    name: 'Bari',
    code: 'BA',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOaEAN',
    name: 'Barletta',
    code: 'BT',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOsEAN',
    name: 'Belluno',
    code: 'BL',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOtEAN',
    name: 'Benevento',
    code: 'BN',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOyEAN',
    name: 'Bergamo',
    code: 'BG',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEP4EAN',
    name: 'Biella',
    code: 'BI',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPHEA3',
    name: 'Bolonia',
    code: 'BO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPJEA3',
    name: 'Bolzano',
    code: 'BZ',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPaEAN',
    name: 'Brescia',
    code: 'BS',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPcEAN',
    name: 'Brindisi',
    code: 'BR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPxEAN',
    name: 'Cagliari',
    code: 'CA',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQ5EAN',
    name: 'Caltanisetta',
    code: 'CL',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQ9EAN',
    name: 'Campobasso',
    code: 'CB',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQWEA3',
    name: 'Caserta',
    code: 'CE',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQaEAN',
    name: 'Catania',
    code: 'CT',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQbEAN',
    name: 'Catanzaro',
    code: 'CZ',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiER9EAN',
    name: 'Chieti',
    code: 'CH',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEnVEAV',
    name: 'Ciudad Vaticano',
    code: 'VA',
    country: 'Vaticano',
    countryCode: 'VA',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiERbEAN',
    name: 'Como',
    code: 'CO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERsEAN',
    name: 'Cosenza',
    code: 'CS',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERyEAN',
    name: 'Cremona',
    code: 'CR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiES2EAN',
    name: 'Crotone',
    code: 'KR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiES7EAN',
    name: 'Cuneo',
    code: 'CN',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETLEA3',
    name: 'Enna',
    code: 'EN',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETnEAN',
    name: 'Fermo',
    code: 'FM',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEToEAN',
    name: 'Ferrara',
    code: 'FE',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETvEAN',
    name: 'Firenze',
    code: 'FI',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEU0EAN',
    name: 'Foggia',
    code: 'FG',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEU2EAN',
    name: 'Forli-Cesana',
    code: 'FC',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEU7EAN',
    name: 'Frosinone',
    code: 'FR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUPEA3',
    name: 'Genova',
    code: 'GE',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUfEAN',
    name: 'Gorizia',
    code: 'GO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUrEAN',
    name: 'Grosseto',
    code: 'GR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWSEA3',
    name: 'Imperia',
    code: 'IM',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWgEAN',
    name: 'Isernia',
    code: 'IS',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYTEA3',
    name: "L'Aquila",
    code: 'AQ',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYhEAN',
    name: 'La Spezia',
    code: 'SP',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZ0EAN',
    name: 'Latina',
    code: 'LT',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZ3EAN',
    name: 'Lecce',
    code: 'LE',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZ4EAN',
    name: 'Lecco',
    code: 'LC',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZSEA3',
    name: 'Livorno',
    code: 'LI',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZZEA3',
    name: 'Lodi',
    code: 'LO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZvEAN',
    name: 'Lucca',
    code: 'LU',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEa4EAF',
    name: 'Macerata',
    code: 'MC',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEaQEAV',
    name: 'Mantova',
    code: 'MN',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEacEAF',
    name: 'Massa Carrara',
    code: 'MS',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEaeEAF',
    name: 'Matera',
    code: 'MT',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEawEAF',
    name: 'Messina',
    code: 'ME',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEb9EAF',
    name: 'Milano',
    code: 'MI',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEbMEAV',
    name: 'Modena',
    code: 'MO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEbZEAV',
    name: 'Monza-Brianza',
    code: 'MB',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEc1EAF',
    name: 'Napoli',
    code: 'NA',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEcoEAF',
    name: 'Novara',
    code: 'NO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEcyEAF',
    name: 'Nuoro',
    code: 'NU',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdBEAV',
    name: 'Olbia-Tempio',
    code: 'OT',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdOEAV',
    name: 'Oristano',
    code: 'OR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdcEAF',
    name: 'Padua',
    code: 'PD',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdfEAF',
    name: 'Palermo',
    code: 'PA',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdpEAF',
    name: 'Parma',
    code: 'PR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEduEAF',
    name: 'Pavia',
    code: 'PV',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEe7EAF',
    name: 'Perugia',
    code: 'PG',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEe8EAF',
    name: 'Pesaro-Urbino',
    code: 'PU',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEe9EAF',
    name: 'Pescara',
    code: 'PE',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeMEAV',
    name: 'Piacenza',
    code: 'PC',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeXEAV',
    name: 'Pisa',
    code: 'PI',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeYEAV',
    name: 'Pistoia',
    code: 'PT',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeiEAF',
    name: 'Pordenone',
    code: 'PN',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeoEAF',
    name: 'Potenza',
    code: 'PZ',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeuEAF',
    name: 'Prato',
    code: 'PO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfMEAV',
    name: 'Ragusa',
    code: 'RG',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfPEAV',
    name: 'Ravenna',
    code: 'RA',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfUEAV',
    name: 'Reggio Calabria',
    code: 'RC',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfVEAV',
    name: 'Reggio Emilia',
    code: 'RE',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfxEAF',
    name: 'Rieti',
    code: 'RI',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfyEAF',
    name: 'Rimini',
    code: 'RN',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgGEAV',
    name: 'Roma',
    code: 'RM',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgMEAV',
    name: 'Rovigo',
    code: 'RO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgcEAF',
    name: 'Salerno',
    code: 'SA',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEnUEAV',
    name: 'San Marino',
    code: 'SM',
    country: 'San Marino',
    countryCode: 'SM',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEhEEAV',
    name: 'Sassari',
    code: 'SS',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhGEAV',
    name: 'Savona',
    code: 'SV',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhnEAF',
    name: 'Siena',
    code: 'SI',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhtEAF',
    name: 'Siracusa',
    code: 'SR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEiFEAV',
    name: 'Sondrio',
    code: 'SO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEj7EAF',
    name: 'Taranto',
    code: 'TA',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjNEAV',
    name: 'Teramo',
    code: 'TE',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjOEAV',
    name: 'Terni',
    code: 'TR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjwEAF',
    name: 'Trapani',
    code: 'TP',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEk0EAF',
    name: 'Trento',
    code: 'TN',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEk1EAF',
    name: 'Treviso',
    code: 'TV',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEk2EAF',
    name: 'Trieste',
    code: 'TS',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkEEAV',
    name: 'Torino',
    code: 'TO',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkIEAV',
    name: 'Udine',
    code: 'UD',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkfEAF',
    name: 'Varese',
    code: 'VA',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkvEAF',
    name: 'Venezia',
    code: 'VE',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkyEAF',
    name: 'Verbania',
    code: 'VB',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkzEAF',
    name: 'Vercelli',
    code: 'VC',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEl1EAF',
    name: 'Verona',
    code: 'VR',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEl7EAF',
    name: 'Vibo Valentia',
    code: 'VV',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEl9EAF',
    name: 'Vicenza',
    code: 'VI',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiElOEAV',
    name: 'Viterbo',
    code: 'VT',
    country: 'Italia',
    countryCode: 'IT',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'it-IT');
});
