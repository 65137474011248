import { YuParagraph, YuSpacer, YuTitle } from '@isdin/yuma-react-web-pin';
import { FormattedMessage, useIntl } from 'react-intl';
import { BannerReferenceLimitedInfo } from '../../orderLimits/bannerReferenceLimitedInfo';
import React from 'react';
import { MyBagProductQtyButtons } from './MyBagProductQtyButtons';
import { MyBagProductTrash } from './MyBagProductTrash';
import { Col, Row } from 'antd';

export const MyBagProductOneRowMode = ({
  isCompact,
  product,
  maxProductsOrdered,
  handleRemoveProduct,
  name,
  orderRestrictions,
  annualMaxQuantityProduct,
  handleChangeQuantity,
  maxQuantity,
  canUpdateProduct,
  price,
  setProductLoading,
  qty,
}) => {
  const { formatNumber } = useIntl();
  return (
    <Row
      align="middle"
      className={`my-bag__product ${
        isCompact ? 'no-display' : 'not_show_on_tablet'
      }`}
    >
      <Col sm={5} lg={3}>
        <img className="product__image" src={product.image} loading="lazy" />
      </Col>
      <Col sm={6} lg={10}>
        <YuTitle size="XS">{name}</YuTitle>
      </Col>
      {canUpdateProduct && (
        <Col sm={5} lg={5}>
          <MyBagProductQtyButtons
            {...{
              orderRestrictions,
              annualMaxQuantityProduct,
              maxQuantity,
              handleChangeQuantity,
              qty,
            }}
          />
        </Col>
      )}
      <Col sm={6} lg={5}>
        <YuParagraph size="L" fw="bold" style={{ textAlign: 'right' }}>
          <FormattedMessage
            id="menu.section.personal.{points}-point"
            values={{ points: formatNumber(price * qty) }}
          />
        </YuParagraph>
      </Col>
      {handleRemoveProduct && (
        <Col sm={2} lg={1}>
          <MyBagProductTrash
            {...{
              handleRemoveProduct,
              product,
              setProductLoading,
            }}
          />
        </Col>
      )}
      {maxQuantity <= qty && !maxProductsOrdered ? (
        <Col sm={24} lg={24}>
          <YuSpacer size="XS" />
          <BannerReferenceLimitedInfo remaining={maxQuantity} />
        </Col>
      ) : null}
    </Row>
  );
};
