import { toastTypes } from '../types/toastTypes';
import { ToastActionTypes } from '../actions/toastActions';
import { IToast } from '../shared/models/toast.model';

export interface ToastsState {
  oneTimeToastIds: string[];
  toasts: Array<IToast>;
}

const initialState: ToastsState = {
  toasts: [],
  oneTimeToastIds: [],
};

export const toastReducer = (
  state = initialState,
  action: ToastActionTypes
): ToastsState => {
  switch (action.type) {
    case toastTypes.SET_TOASTS:
      return { ...action.payload };
    default:
      return state;
  }
};
