import { LoadingOutlined } from '@ant-design/icons';
import { Row, Spin } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { deleteDataCall, deleteDataCallNoID, postDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import { UploadFiles } from 'src/components/challenge/UploadFiles';
import { IRootReducers } from 'src/reducers';
import {
  S3BucketPathEnum,
  UserResourceUploadMediaStatusEnum,
} from 'src/shared/enums';
import {
  IChallengeResourceRender,
  IUserUploadMedia,
  MediaTypeEnum,
} from 'src/shared/models';

export const getResourceStatusMessage = (
  statusResource: number,
  isSellOut?: boolean
) => {
  let message_id = 'page.challenge.user-upload-media.your-entry-is';
  let class_id = 'challengeResourceWrapper__label';
  switch (statusResource) {
    case UserResourceUploadMediaStatusEnum.DENY:
      message_id = `${message_id}-deny`;
      class_id = `${class_id}--error`;
      break;
    case UserResourceUploadMediaStatusEnum.PENDING:
      if (isSellOut) {
        message_id = 'sell-out.report-uploaded';
        class_id = `${class_id}--completed`;
      } else {
        message_id = `${message_id}-pending`;
        class_id = `${class_id}--pending`;
      }
      break;
    case UserResourceUploadMediaStatusEnum.VALIDATE:
      message_id = `${message_id}-valid`;
      class_id = `${class_id}--completed`;
      break;
    default:
      message_id = `${message_id}-error`;
      class_id = `${class_id}--error`;
      break;
  }
  return (
    <Row justify="center" align="middle">
      <div className={`challengeResourceWrapper__label ${class_id}`}>
        <FormattedMessage id={message_id} />
      </div>
    </Row>
  );
};

interface OwnProps extends IChallengeResourceRender {
  config: IUserUploadMedia;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const ChallengeUserUploadMedia: FC<Props> = ({
  accessToken,
  config,
  updateChallenge,
}) => {
  if (!config) return <React.Fragment />;
  const { formatNumber, formatMessage } = useIntl();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  // Get state from userResource)
  const {
    description,
    icon,
    idResource,
    title,
    mediaData: { fileName, isUploaded, size, type, url, statusResource },
    points,
    endDate,
  } = config as IUserUploadMedia;

  const handleUpdateResource = async (
    value: string,
    fileName: string,
    size: string
  ) => {
    setShowSpinner(true);
    const textMachine =
      type === MediaTypeEnum.URL ? fileName : `${fileName}|${size}`;
    const response = await postDataCall({
      dataPath: `${apiPaths.CHALLENGES.USER_RESOURCE_UPLOAD_MEDIA}/${idResource}`,
      data: {
        status: true,
        value,
        textMachine,
      },
      callConfig: {},
    });

    setShowSpinner(false);
    updateChallenge(response.data);
  };

  const handleDeleteFile = async () => {
    setShowSpinner(true);
    const responseDelete = await deleteDataCall({
      dataPath: `${apiPaths.CHALLENGES.USER_RESOURCE_UPLOAD_MEDIA}`,
      id: idResource,
      callConfig: {},
    });

    if ([MediaTypeEnum.IMAGE, MediaTypeEnum.VIDEO].includes(type))
      await deleteDataCallNoID({
        dataPath: apiPaths.CHALLENGES.CHALLENGE_VISIBILITY_FILES,
        callConfig: {
          data: {
            path: [url],
          },
        },
      });

    setShowSpinner(false);
    updateChallenge(responseDelete.data);
  };

  const handleBeforeUpload = () => {
    setShowSpinner(true);
  };

  const handleAfterError = () => {
    setShowSpinner(false);
  };

  const isDisabled = endDate < new Date();

  const labelText = isDisabled
    ? 'page.challenge.challenge-has-expired-upload'
    : null;

  const loadingSpinner = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: 'red',
        marginTop: '100px',
        marginBottom: '100px',
      }}
      spin
    />
  );

  const getResourceStatusMessage = () => {
    let message_id = 'page.challenge.user-upload-media.your-entry-is';
    let class_id = 'challengeResourceWrapper__label';
    switch (statusResource) {
      case UserResourceUploadMediaStatusEnum.DENY:
        message_id = `${message_id}-deny`;
        class_id = `${class_id}--error`;
        break;
      case UserResourceUploadMediaStatusEnum.PENDING:
        message_id = `${message_id}-pending`;
        class_id = `${class_id}--pending`;
        break;
      case UserResourceUploadMediaStatusEnum.VALIDATE:
        message_id = `${message_id}-valid`;
        class_id = `${class_id}--completed`;
        break;
      default:
        message_id = `${message_id}-error`;
        class_id = `${class_id}--error`;
        break;
    }
    return (
      <Row justify="center" align="middle">
        <div className={`challengeResourceWrapper__label ${class_id}`}>
          <FormattedMessage id={message_id} />
        </div>
      </Row>
    );
  };
  const getContentDetails = () => {
    const _components = [];
    switch (true) {
      case showSpinner:
        _components.push(<Spin indicator={loadingSpinner} />);
        break;
      case isUploaded:
        _components.push(getResourceStatusMessage());
      // eslint-disable-next-line no-fallthrough
      case !showSpinner:
        _components.push(
          <Row className="container-file-data" justify="center">
            {isUploaded ? (
              <Row justify="center" className="file-data__container">
                <a className="file-name" href={url} target="_blank">
                  {fileName}
                </a>
                <span className="file-size">{size}</span>
                {statusResource !==
                  UserResourceUploadMediaStatusEnum.VALIDATE && (
                  <a
                    className="icon icon--delete file-icon"
                    onClick={handleDeleteFile}
                  />
                )}
              </Row>
            ) : (
              <UploadFiles
                labelText={labelText}
                btnDisabled={isDisabled}
                userUploadconfig={config}
                accessToken={accessToken}
                updateResource={handleUpdateResource}
                beforeUpload={handleBeforeUpload}
                afterError={handleAfterError}
                s3Bucket={S3BucketPathEnum.RESOURCE_CHALLENGE_VISIBILITY}
              />
            )}
          </Row>
        );
    }

    return _components;
  };

  const component = (
    <Row className="challenge-upload-media" justify="center">
      {/* TODO: TO FIX in v2 */}
      {/* <Row
        className='challengeResourceWrapper__description'
        justify='center'
        align='middle'>
        <FormattedMessage
          id={
            'page.challenge.user-upload-media.accept-legal-bases-{fileFype}-{static-page}'
          }
          values={{
            link_staticPage: (chunks: string) => {
              return (
                <Link href={`${routePaths.STATIC}?page=${linkStaticPage}`}>
                  <span className='termsPage__externLink'>{chunks}</span>
                </Link>
              );
            },
            fileFype: MediaTypeEnum[type].toString().toLowerCase(),
          }}
        />
      </Row> */}
      <ChallengeResourceWrapper_Blocked />
      {getContentDetails()}
    </Row>
  );

  return (
    <ChallengeResourceWrapper
      styleClass="challengeDetail--UserUploadMedia"
      description={description}
      isDescriptionDangerouslySetInnerHTML={true}
      icon={icon}
      points={formatMessage(
        { id: 'page.challenge.plus-sign-{points}-points' },
        { points: formatNumber(points) }
      )}
      title={title}
      children={component}
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps)(ChallengeUserUploadMedia);
