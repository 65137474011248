import { DateTime } from 'luxon';
import {
  IResourceDetailList,
  IResourceList,
  IUsersM2MList,
} from 'src/components/challenge/ChallengeInterfaces';
import { intervalContainsNow } from '../../../helpers/luxon.helper';
import {
  ChallengeTypeEnum,
  QuizQuestionTypeEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
  UserResourceEnum,
} from 'src/shared/enums';
import {
  IChallengeDates,
  IChallengeEvent,
  IChallengeQuiz,
  IQuizAnswer,
  IQuizQuestion,
  isVideoValidFormat,
} from 'src/shared/models';

const { PARTICIPATION, QUESTION, ANSWER } = ResourceTypeEnum;

const {
  RETRY,
  CONTINUE,
  SHOW_STATISTICS,
  PRE_PARTICIPATION_TEXT,
  CORRECT,
  QUIZ_CONTENT,
  QUIZ_VIDEO_URL,
  QUESTION_IMAGE_URL,
  QUESTION_JUSTIFICATION,
  QUIZ_IMAGE,
  QUESTION_TYPE,
  QUESTION_SHOW_STATISTICS,
  QUESTION_DESCRIPTION,
  QUESTION_VIDEO_IS_VERTICAL,
  VIDEO_IS_VERTICAL,
} = ResourceTypeDetailEnum;

export const buildChallengeQuiz = (
  quiz: IResourceList,
  idChallenge: number,
  challengeType: ChallengeTypeEnum,
  mainEvent: IChallengeEvent,
  challengeDates: IChallengeDates
): IChallengeQuiz => {
  let challengeQuiz: IChallengeQuiz;
  const questions: IQuizQuestion[] = [];
  let accPoints = 0;
  let idResource: number = quiz.idResource;
  let userStatus: UserResourceEnum = UserResourceEnum.INACTIVE;
  let dateDone: DateTime;
  const disabled = false;

  const hasImage = false;
  let completationTitle = 'QuizBuilder-completation-title-not-defined';

  switch (challengeType) {
    case ChallengeTypeEnum.TALK:
    case ChallengeTypeEnum.CONGRESS:
    case ChallengeTypeEnum.CAMPAIGN:
      completationTitle = 'page.challenge.congratulations-quiz-completed';
      break;
    case ChallengeTypeEnum.FORMATION:
      completationTitle = 'page.challenge.congratulations-training-completed';
      break;
  }

  if (quiz.resourceDetailList?.length) {
    quiz.resourceDetailList
      .filter((_detail) => _detail.status)
      .forEach((_detail) => {
        switch (_detail.idResourceTypeD.idResourceTypeD) {
          case RETRY:
            challengeQuiz = {
              ...challengeQuiz,
              allowRetry: _detail.value === 'true' ? true : false,
            };
            break;
          case CONTINUE:
            challengeQuiz = {
              ...challengeQuiz,
              allowContinue: _detail.value === 'true' ? true : false,
            };
            break;
          case SHOW_STATISTICS:
            challengeQuiz = {
              ...challengeQuiz,
              showStatistics: _detail.value === 'true' ? true : false,
            };
            break;
          case PRE_PARTICIPATION_TEXT:
            challengeQuiz = {
              ...challengeQuiz,
              preInscriptionText: _detail.value,
            };
            break;
          case QUIZ_CONTENT:
            challengeQuiz = {
              ...challengeQuiz,
              description: _detail.value,
            };
            break;
          case QUIZ_IMAGE:
            challengeQuiz = {
              ...challengeQuiz,
              urlImage: _detail.value,
            };
            break;
        }
      });
  }

  const questionList = quiz.resourceList.filter(
    (resource) => resource.idResourceType.idResourceType === QUESTION
  );

  quiz.resourceList
    ?.filter((_detail) => _detail.status)
    .forEach((resource: IResourceList) => {
      let usersM2MList: IUsersM2MList;
      let currentQuestionIndex: number;
      let isLastQuestion: boolean;
      let data: { points: number; question: IQuizQuestion };
      switch (resource.idResourceType.idResourceType) {
        case PARTICIPATION:
          accPoints += !resource.score ? 0 : resource.score.points;
          idResource = resource.idResource;
          usersM2MList = resource.usersM2MList
            .filter((item) => item.status)
            .pop();

          if (usersM2MList) {
            userStatus = usersM2MList.statusResource as UserResourceEnum;
            dateDone = DateTime.fromJSDate(new Date(usersM2MList.createDate));
          }

          break;
        case QUESTION:
          currentQuestionIndex = questionList.findIndex(
            (questionResource) =>
              resource.idResource === questionResource.idResource
          );
          isLastQuestion = currentQuestionIndex === questionList.length - 1;
          data = buildQuizQuestion(resource, isLastQuestion);
          questions.push(data.question);
          accPoints += data.points;
          break;
      }
    });

  challengeQuiz = {
    ...challengeQuiz,
    completationTitle,
    disabled,
    idChallenge,
    isCompleted: Boolean(dateDone),
    isQuizDone: Boolean(dateDone),
    shouldRender: getShouldRenderQuiz(challengeType, mainEvent, {
      isQuizDone: Boolean(dateDone),
      challengeQuiz,
      challengeDates,
    }),
    showPresentation:
      challengeType !== ChallengeTypeEnum.FORMATION ||
      (challengeType === ChallengeTypeEnum.FORMATION &&
        !challengeQuiz.allowRetry &&
        Boolean(dateDone)),
    title: quiz.name,
    icon: 'trainning',
  };

  return {
    ...challengeQuiz,
    dateDone,
    hasImage,
    idResource,
    points: Math.max(accPoints, 0),
    questions,
    totalResponses: 0,
    userStatus,
  };
};

const getShouldRenderQuiz = (
  challengeType: ChallengeTypeEnum,
  mainEvent: IChallengeEvent,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
): boolean => {
  let shouldRenderQuiz = false;
  const {
    isQuizDone,
    challengeQuiz: { allowRetry },
    challengeDates: { startDate, endDate },
  } = data;
  const isChallengeInLife = intervalContainsNow(startDate, endDate);

  const challengeExpired =
    DateTime.now() > DateTime.fromJSDate(new Date(endDate));
  const isAvailableToDo = !isQuizDone && isChallengeInLife;
  const isAvailableToRepeat = allowRetry && challengeExpired;
  const isRepeatable = isQuizDone && allowRetry && isChallengeInLife;
  const isRenderingPresentation =
    isQuizDone && !allowRetry && isChallengeInLife;
  switch (challengeType) {
    case ChallengeTypeEnum.TALK:
    case ChallengeTypeEnum.CONGRESS:
      if (mainEvent) {
        const { attend, status } = mainEvent;
        shouldRenderQuiz = attend.isPerformed && status.isExpired;
      }
      break;
    case ChallengeTypeEnum.FORMATION:
      switch (true) {
        case isAvailableToDo:
        case isRepeatable:
        case isAvailableToRepeat:
        case isRenderingPresentation:
          shouldRenderQuiz = true;
          break;
      }
      break;
  }

  return shouldRenderQuiz;
};

const buildQuizQuestion = (
  questionData: IResourceList,
  isLastQuestion: boolean
): { points: number; question: IQuizQuestion } => {
  const { name, idResource, indResource, i18nList } = questionData;
  const answers: IQuizAnswer[] = [];
  let urlVideo: string;
  const urlImage: string[] = [];
  let score = 0;
  let textJustification: string;
  let hasCorrectAnswer = false;
  let questionType: string;
  let questionShowStatistics = false;
  let description: string;
  let videoIsVertical = false;

  questionData.resourceDetailList
    .filter((_detail) => _detail.status)
    .forEach((resource: IResourceDetailList) => {
      switch (resource.idResourceTypeD.idResourceTypeD) {
        case QUESTION_VIDEO_IS_VERTICAL:
        case VIDEO_IS_VERTICAL:
          videoIsVertical = resource.value === 'true';
          break;
        case QUIZ_VIDEO_URL:
          urlVideo = resource.value;
          break;
        case QUESTION_IMAGE_URL:
          if (!resource.value?.trim()) break;
          urlImage.push(resource.value);
          break;
        case QUESTION_JUSTIFICATION:
          textJustification = resource.value;
          break;
        case QUESTION_TYPE:
          questionType = resource.value;
          break;
        case QUESTION_SHOW_STATISTICS:
          questionShowStatistics = resource.value === 'true';
          break;
        case QUESTION_DESCRIPTION:
          description = resource.value;
          break;
      }
    });

  questionData.resourceList
    .filter((_detail) => _detail.status)
    .forEach((answerData: IResourceList) => {
      if (ANSWER == answerData.idResourceType.idResourceType) {
        const { points, answer } = buildQuizAnswer(answerData);
        hasCorrectAnswer = hasCorrectAnswer || answer.isCorrect;
        answers.push(answer);

        if (!questionType) score = Math.max(score, points);

        if (
          QuizQuestionTypeEnum.UNIQUE.includes(questionType) ||
          QuizQuestionTypeEnum.ALL_VALID.includes(questionType)
        )
          score = Math.max(score, points);

        if (
          QuizQuestionTypeEnum.MULTIRESPONSE.includes(questionType) &&
          points > 0
        )
          score += points;

        if (!hasCorrectAnswer) score = 0;
      }
    });

  const format = urlVideo ? urlVideo.split('.') : '';
  const isFromFile = isVideoValidFormat(format[format?.length - 1]);

  return {
    points: score,
    question: {
      idResource,
      indResource,

      answers,
      i18nList,
      score,
      title: name,
      description,

      videoIsVertical,
      hasAnswers: answers.length > 0,
      hasCorrectAnswer,
      hasImage: Boolean(urlImage?.length),
      hasJustification: Boolean(textJustification),
      hasVideo: Boolean(urlVideo),
      isLastQuestion,
      isVideoFromFile: Boolean(urlVideo) && isFromFile,

      textJustification,
      urlImage,
      urlVideo,
      questionType,
      questionShowStatistics,
    },
  };
};

const buildQuizAnswer = (
  answerData: IResourceList
): { points: number; answer: IQuizAnswer } => {
  let isCorrect = false;

  const { indResource, idResource, name, i18nList, resourceDetailList, score } =
    answerData;

  resourceDetailList
    .filter((_detail) => _detail.status)
    .forEach((detail: IResourceDetailList) => {
      if (CORRECT == detail.idResourceTypeD.idResourceTypeD) {
        isCorrect = detail.value === 'true';
      }
    });

  const points = !score ? 0 : score.points;

  return {
    points,
    answer: {
      indResource,
      idResource,
      name,
      i18nList,
      isCorrect,
      userResponses: 0,
      score: points,
    },
  };
};
