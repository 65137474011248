import { YuFlex, YuIcon, YuPopperBase } from '@isdin/yuma-react-web-pin';
import { useIntl } from 'react-intl';
import React from 'react';
import { ProductQuantityButtons } from '../../../../utils';

export const MyBagProductQtyButtons = ({
  orderRestrictions,
  annualMaxQuantityProduct,
  maxQuantity,
  handleChangeQuantity,
  qty,
}) => {
  const { formatMessage } = useIntl();
  return (
    <YuFlex gap="XS" align="center">
      <ProductQuantityButtons
        {...{
          qty,
          handleChangeQuantity,
          maxQuantity,
          minValue: 1,
        }}
      />
      {annualMaxQuantityProduct <= qty && (
        <YuPopperBase
          colorVariant="dark"
          behaviour={{ close: { onMouseOutArea: true } }}
          content={
            <div style={{ maxWidth: 190 }}>
              {formatMessage(
                { id: 'page.my-bag.annual-reference-limit' },
                {
                  limit:
                    orderRestrictions?.salesRestrictions?.maxSaleRestrictions
                      ?.annual?.maxSpecificProductByPeriod,
                  units: formatMessage({
                    id:
                      Number(
                        orderRestrictions?.salesRestrictions
                          ?.maxSaleRestrictions?.annual
                          ?.maxSpecificProductByPeriod
                      ) > 1
                        ? 'page.redeem-points.errors.units'
                        : 'page.redeem-points.errors.unit',
                  }),
                }
              )}
            </div>
          }
        >
          <YuIcon name="Info" size="M" />
        </YuPopperBase>
      )}
    </YuFlex>
  );
};
