export enum UserStatusEnum {
  DENIED = 0, //userCenter.requestStatus = false
  HCP_NOT_VALIDATED = 1, //userRequest => pending (idRole = Employee)
  MANAGER_NOT_VALIDATED = 2, //userRequest => pending (idRole = Manager)
  NO_CENTER = 3, //!userCenter || userCenter.status = false
  ACCEPTED_EMPLOYEE = 4,
  ACCEPTED_MANAGER = 5,
  ACCOUNT_NOT_VALIDATED = 6,
  STAFF_NOT_VALIDATED = 7,
  ACCEPTED_EMPLOYEE_PIN = 8,
  MIGRATE_PENDING = 9,
}

export enum PlatformUserStatusEnum {
  EXTERNAL_SERVICES = 0,
  DENIED = 1,
  PENDING = 2,
  GRANT = 3,
  MIGRATE_GRANT = 4,
  MIGRATE_MAGENTO_ID_ERROR = 5,
  MIGRATE_SALESFORCE_ID_ERROR = 6,
  MIGRATE_PENDING = 7,
  PENDING_FIRST_LOGIN = 10,
  GRANT_FIRST_LOGIN = 11,
}

export enum UserAccountStatusEnum {
  DENIED = 1,
  PENDING = 2,
  GRANT = 3,
}

export enum UserTypeEnum {
  HCP = 'Medico',
  STAFF = 'Staff',
}

export enum MarketingCloudUserStatus {
  UNSUBSCRIBED = 0,
  SUBSCRIBED = 1,
}

export enum RoleTypeEnum {
  FRIEND = 1,
  LOVER = 2,
  DERMO = 3,
  PREGNANCY = 4,
  REFERRAL = 5,
}

export enum ClauseTypeEnum {
  CLAUSE_REFERRAL = 'TC_QR_2023',
}

export enum PlateTypeEnum {
  NPI = 'NPI',
  PROVINCIAL = 'PROVINCIAL',
}

export enum UserCenterRoleEnum {
  EMPLOYEE = 'Employee',
  MANAGER = 'Manager',
}
