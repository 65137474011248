import { useSize, YuFlex, YuIcon } from '@isdin/yuma-react-web-pin';
import { notification, Row } from 'antd';
import routePaths, {
  deprecatedRoutePaths,
  isChallengeDetail,
} from 'config/routes';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { deleteDataCall, postDataCallById } from 'services/api';
import apiPaths from 'services/apiPaths';
import { UpdateUserPoints } from 'src/actions/authActions';

interface IChallengeLike {
  challengeLikes: { totalLikes: number; liked: boolean };
  challengeId: number;
  likedClassName: string;
  dislikedClassName: string;
  updateChallenges?: (liked: boolean, totalLikes: number) => void;
  updateUserPoints: (
    userPoints: number
  ) => (dispatch: Dispatch<UpdateUserPoints>) => void;
}

export const ChallengeLike = ({
  challengeLikes,
  challengeId,
  updateChallenges,
  updateUserPoints,
}: IChallengeLike) => {
  const { downXS } = useSize();
  const [liked, setLiked] = useState(challengeLikes.liked);
  const [totalLikes, setTotalLikes] = useState(challengeLikes.totalLikes);
  const [loading, setLoading] = useState(false);
  const [forceFullHeart, setForceFullHeart] = useState(false);
  const { pathname } = useRouter();

  const handleLikes = async () => {
    let response = null;
    let _totalLikes = totalLikes;
    try {
      setLoading(true);
      setLiked(!liked);
      if (!liked) {
        response = await postDataCallById({
          dataPath: apiPaths.CHALLENGES.LIKES,
          id: challengeId,
        });
        setLoading(false);
        if (response.status === 200) {
          const newScore = response.data.score;
          _totalLikes = _totalLikes + 1;
          if (newScore !== undefined && newScore !== null)
            updateUserPoints(newScore);
        }
      } else {
        response = await deleteDataCall({
          dataPath: apiPaths.CHALLENGES.LIKES,
          id: challengeId,
          callConfig: {},
        });
        setLoading(false);

        if (response.status === 200) {
          const newScore = response.data.score;
          _totalLikes = _totalLikes - 1;
          if (newScore !== undefined && newScore !== null)
            updateUserPoints(newScore);
        }
      }

      if (
        updateChallenges &&
        (isChallengeDetail(pathname) ||
          [
            routePaths.PAGES.TALK,
            routePaths.PAGES.TRAINING,
            routePaths.PAGES.CAMPAIGN,
            routePaths.PAGES.CERTIFIED_COURSES,
            deprecatedRoutePaths.PAGES.TALKS,
            deprecatedRoutePaths.PAGES.TRAININGS,
          ].includes(pathname))
      )
        updateChallenges(!liked, _totalLikes);
      setTotalLikes(_totalLikes);
    } catch (err) {
      console.error('~ err', err);

      setLiked(liked);
      setLoading(false);

      console.group(err);
      notification.error({
        message: 'Could not perform like',
      });
    }
  };

  let className = 'yu-heart';
  if (loading) className += ' animate disabled';

  const handleEnter = () => {
    if (!liked) setForceFullHeart(true);
  };
  const handleLeave = () => {
    if (!liked) setForceFullHeart(false);
  };

  return (
    <>
      <YuFlex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <YuIcon
          className={className}
          name={forceFullHeart || liked ? 'HeartFull' : 'Heart'}
          size={downXS ? 'M' : 'L'}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          onClick={handleLikes}
        />
      </YuFlex>
      <Row justify="center" align="middle" className="show_only_mobile">
        <div className="challengeCard__likes__number">{totalLikes}</div>
      </Row>
    </>
  );
};
