import { combineReducers } from 'redux';
import { authReducer, AuthState } from './authReducer';
import { appReducer, AppState } from './appReducer';
import { platformReducer, PlatformState } from './platformReducer';
import { toastReducer, ToastsState } from './toastsReducer';

export default combineReducers({
  toast: toastReducer,
  auth: authReducer,
  app: appReducer,
  platform: platformReducer,
});

export type IRootReducers = {
  toast: ToastsState;
  auth: AuthState;
  app: AppState;
  platform: PlatformState;
};
