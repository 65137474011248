const SkeletonSubHeaderTitle = (): JSX.Element => {
  return (
    <div className="skeleton-subheader-title">
      <div className="skeleton-subheader-title-top" />
      <div>
        <div className="skeleton-subheader-title-left" />
        <div className="skeleton-subheader-title-right" />
      </div>
    </div>
  );
};

export default SkeletonSubHeaderTitle;
