import { notification } from 'antd';
import { ChallengeTypeEnum } from 'src/shared/enums';
import { IFilter, IFilterItem } from 'src/shared/models';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';

export const fetchFilters = async (challengeType: ChallengeTypeEnum) => {
  try {
    const responseFilters = await api.getApiCombo(
      `${apiPaths.COMBO_IDS.FILTERS}&param=${challengeType}`
    );

    if (responseFilters?.status === 200 && responseFilters?.data) {
      const filtersCombo: IFilterItem[] = responseFilters?.data.map(
        (filter) => {
          return {
            label: filter.description,
            value: filter.value,
            isChecked: false,
            type: filter.type,
          };
        }
      );

      const responseFilterType = await api.getApiCombo(
        apiPaths.COMBO_IDS.FILTER_TYPE
      );

      if (responseFilterType?.status === 200 && responseFilterType?.data) {
        const filterTypeCombo: IFilterItem[] = responseFilterType?.data.map(
          (filter) => {
            return {
              label: filter.description,
              value: filter.value,
            };
          }
        );

        const filterList: IFilter[] = filterTypeCombo.map((filterType) => {
          const items = filtersCombo
            .filter((filter) => filter.type === filterType.value)
            .map((option) => option);

          return {
            id: filterType.value,
            label: filterType.label,
            items: [...items],
          };
        });
        return filterList;
      }
    }
  } catch (err) {
    console.error(err);
    if (err.response?.data?.message) {
      notification.error({
        message: err.response?.data?.message,
        duration: 3,
      });
    }
  }
};
