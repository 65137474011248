import React from 'react';

export const ScrollWithGradient = ({
  children,
  height,
  showGradient,
}): JSX.Element => {
  return (
    <div style={{ height, position: 'relative' }}>
      {showGradient ? <div className="scroll-white-gradient"></div> : null}
      <div style={{ height, overflow: 'auto' }}>{children}</div>
    </div>
  );
};
