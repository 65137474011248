import {
  IResourceDetailList,
  IResourceList,
} from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IChallengeWyswyg } from 'src/shared/models';

abstract class IWyswygFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(wyswygChallenge: IChallengeWyswyg): IChallengeWyswyg;
}

class DescriptionFiller extends IWyswygFiller {
  fill(wyswygChallenge: IChallengeWyswyg): IChallengeWyswyg {
    return {
      ...wyswygChallenge,
      description: this.detail.value,
    };
  }
}

class ContentFiller extends IWyswygFiller {
  fill(wyswygChallenge: IChallengeWyswyg): IChallengeWyswyg {
    return {
      ...wyswygChallenge,
      content: this.detail.value,
    };
  }
}

class ChallengeWyswygFactory {
  private readonly wyswygFillers: Map<ResourceTypeDetailEnum, IWyswygFiller>;

  constructor(detail: IResourceDetailList) {
    this.wyswygFillers = new Map();

    this.wyswygFillers.set(
      ResourceTypeDetailEnum.WYSWYG_DESCRIPTION,
      new DescriptionFiller(detail),
    );
    this.wyswygFillers.set(
      ResourceTypeDetailEnum.WYSWYG_CONTENT,
      new ContentFiller(detail),
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    wyswygChallenge: IChallengeWyswyg,
  ): IChallengeWyswyg {
    const wyswygFiller = this.wyswygFillers.get(idResourceTypeD);

    if (wyswygFiller) {
      return wyswygFiller.fill(wyswygChallenge);
    }

    return wyswygChallenge;
  }
}

class ChallengeWyswygChallengeBuilder {
  public build(resourceList: IResourceList): IChallengeWyswyg {
    let wyswygChallenge: IChallengeWyswyg;

    resourceList.resourceDetailList
      .filter((wyswyg) => wyswyg.status)
      .forEach((detail) => {
        const Factory = new ChallengeWyswygFactory(detail);

        wyswygChallenge = Factory.fill(detail, wyswygChallenge);
      });

    return {
      ...wyswygChallenge,
      title: resourceList.name,
    };
  }
}

export const builderWysWygChallenge = (
  resourceList: IResourceList,
): IChallengeWyswyg => {
  const builder = new ChallengeWyswygChallengeBuilder();

  return builder.build(resourceList);
};
