import { IMaintenanceMode } from '../shared/models/maintenanceMode.model';
import _ from 'lodash';
import getConfig from 'next/config';

const { PLATFORM_PREFIX } = getConfig().publicRuntimeConfig;

// Example: if /talk come in url path we consider it to be /talks url
const virtualUrls = {
  '/talk': '/talks',
  '/congress': '/talks',
  '/training': '/trainings',
};

/**
 * Example:
 * This is added virtual urls to maintenanceModes array only if /talk not added yet and /talks are already
 * added with includeChilds = true
 *
 * @param maintenanceModes All maintenance modes to check match
 * @returns IMaintenanceMode[]
 */
function getMaintenanceModesWithVirtualUrls(
  maintenanceModes: IMaintenanceMode[]
) {
  const _maintenanceModes = _.cloneDeep(maintenanceModes);
  const fromUrls = _.map(_maintenanceModes, 'fromUrl');
  _.forIn(virtualUrls, (dest, from) => {
    const maintenanceModeIndex = fromUrls.indexOf(dest);
    if (
      !fromUrls.includes(from) &&
      maintenanceModeIndex >= 0 &&
      _maintenanceModes[maintenanceModeIndex].includeChilds
    ) {
      _maintenanceModes.push({
        ..._maintenanceModes[maintenanceModeIndex],
        fromUrl: from,
      });
    }
  });
  return _maintenanceModes;
}

/**
 * Check if the current url path match with some maintenance mode
 *
 * @param maintenanceModes All maintenance modes to check match
 * @param currentPath Current url path
 * @returns IMaintenanceMode
 */
export function getMaintenanceModeIfMatch(
  maintenanceModes: IMaintenanceMode[],
  currentPath: string
): IMaintenanceMode | null {
  const _maintenanceModes =
    getMaintenanceModesWithVirtualUrls(maintenanceModes);
  let result = null;
  // Remove parameters from path if have
  const path = currentPath.replace(PLATFORM_PREFIX, '').split('?')[0];
  _.forEach(_maintenanceModes, (maintenanceMode) => {
    // Check is match
    if (path.startsWith(maintenanceMode.fromUrl)) {
      // If match, check if is equal if not need to include childs be true for return
      if (maintenanceMode.fromUrl === path || maintenanceMode.includeChilds) {
        result = maintenanceMode;
        return false;
      }
    }
  });
  return result;
}
