import {
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';

const SkeletonMyAccount: NextPage = (): JSX.Element => {
  return (
    <YuSkeletonWrapper
      style={{ margin: 'auto' }}
      paddingLeft="XS"
      paddingRight="XS"
      paddingTop="M"
      paddingBottom="L"
      minWidth="300px"
      maxWidth="400px"
    >
      <YuFlex gap="L" flexDirection="column">
        <YuSkeletonWrapper
          maxWidth="1400px"
          width="100%"
          minWidth="280px"
          style={{
            borderRadius: '4px',
            boxShadow: '0px 0px 1px 0px #1313134D, 0px 4px 8px 0px #13131333',
          }}
          backgroundColor={'bg.grey'}
          padding="XS"
        >
          <YuFlex flexDirection="row">
            <YuFlex gap="XXS" flexDirection="column">
              <YuSkeleton height={16} width="71px" variant="text" />
              <YuSkeleton height={24} width="154px" variant="text" />
            </YuFlex>
            <YuSpacer size="M" />
            <YuSkeleton height={56} width="150px" variant="text" />
          </YuFlex>
        </YuSkeletonWrapper>
        <YuFlex gap="S" flexDirection="column">
          <YuFlex flexDirection="row" gap="XS">
            <YuSkeleton height={24} width="24px" variant="text" />
            <YuSkeleton height={24} width="91px" variant="text" />
          </YuFlex>
          <YuFlex flexDirection="row" gap="XS">
            <YuSkeleton height={24} width="24px" variant="text" />
            <YuSkeleton height={24} width="127px" variant="text" />
          </YuFlex>
          <YuFlex flexDirection="row" gap="XS">
            <YuSkeleton height={24} width="24px" variant="text" />
            <YuSkeleton height={24} width="168px" variant="text" />
          </YuFlex>
          <YuFlex flexDirection="row" gap="XS">
            <YuSkeleton height={24} width="24px" variant="text" />
            <YuSkeleton height={24} width="127px" variant="text" />
          </YuFlex>
        </YuFlex>
      </YuFlex>
    </YuSkeletonWrapper>
  );
};

export default SkeletonMyAccount;
