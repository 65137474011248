import { AppActionTypes, IProductsLimit } from 'src/actions/appActions';
import { IChatCustomerCare } from 'src/components/chatCustomerCare/ChatCustomerCare.utils';
import {
  ChallengeDetail,
  ICategory,
  IChallenges,
  IContentPage,
  IFilter,
  IFilterItem,
  IMenu,
  IRedeemPoints,
  IRegion,
  ISalesforceCenter,
  ISignInData,
  ISpecialty,
  IState,
  IStaticPageConfig,
} from 'src/shared/models';
import {
  ICommercialDisclaimerDTO,
  IDisclaimerDTO,
} from 'src/shared/models/disclaimer.model';
import { appTypes } from 'src/types/appTypes';
import { UserReferralData } from '../shared/enums';
import { IMaintenanceMode } from '../shared/models/maintenanceMode.model';

export interface AppState {
  brandList: IFilterItem[] | null;
  // FIXME: BORRAR pathology
  pathologyList: IFilterItem[] | null;
  // FIXME:
  filterList: IFilter[];
  // FIXME: BORRAR categories
  categoryList: ICategory[] | null;
  challenge: ChallengeDetail;
  challenges: IChallenges;
  productsLimit: IProductsLimit | null;
  publicMenu: IMenu[] | null; //TODO: REMOVE
  salesforceCenter: ISalesforceCenter | null;
  redeemPoints: IRedeemPoints | null;
  shoppingProducts: [] | null;
  signInData: ISignInData | null;
  specialtyList: ISpecialty[] | null;
  stateList: IState | null;
  staticPageConfig: IStaticPageConfig;
  userHome: IContentPage | null;
  userMenu: IMenu[] | null; //TODO: REMOVE
  provinces: IRegion[];
  country: string | null;
  chatCustomerCare?: IChatCustomerCare;
  commercialSubscriptionRecruitment: { date: string; days: string };
  referralProductsList: UserReferralData;
  homePage: IContentPage;
  isLoggingIn: boolean;
  maintenanceModes: IMaintenanceMode[];
  disclaimers: {
    private: IDisclaimerDTO[];
    public: IDisclaimerDTO[];
    commercial: ICommercialDisclaimerDTO[];
  };
}

const challengeList = {
  total: 0,
  challenges: [],
  filters: [],
  filtersLoaded: false,
};

const initialState: AppState = {
  brandList: null,
  productsLimit: null,
  // FIXME: BORRAR pathology
  pathologyList: null,
  // FIXME:
  filterList: [],
  // FIXME: BORRAR category
  categoryList: null,
  challenge: null,
  maintenanceModes: null,
  challenges: {
    annualCampaign: null,
    campaigns: challengeList,
    certifiedCourses: challengeList,
    congresses: challengeList,
    events: challengeList,
    pearls: challengeList,
    trainings: challengeList,
  },
  commercialSubscriptionRecruitment: { date: '0', days: '0' },
  publicMenu: null,
  salesforceCenter: null,
  redeemPoints: {
    products: [],
    showSiderMenu: false,
    cart: {
      items: [],
    },
    totalProductElements: 0,
    totalProductPages: 0,
    labels: [],
    sortBy: 'name',
  },
  shoppingProducts: null,
  signInData: null,
  specialtyList: null,
  stateList: null,
  userHome: null,
  userMenu: null,
  staticPageConfig: null,
  provinces: null,
  country: null,
  chatCustomerCare: null,
  referralProductsList: null,
  homePage: null,
  isLoggingIn: false,
  disclaimers: {
    private: [],
    public: [],
    commercial: [],
  },
};

export const appReducer = (
  state = initialState,
  action: AppActionTypes
): AppState => {
  switch (action.type) {
    case appTypes.APP_FETCH_BRANDS:
    case appTypes.APP_FETCH_PATHOLOGIES:
    case appTypes.APP_FETCH_CATEGORIES:
    case appTypes.APP_FETCH_CHAT_CUSTOMER_CARE_CONFIGURATION:
    case appTypes.APP_FETCH_PUBLIC_MENU:
    case appTypes.APP_FETCH_PRODUCTS_LIMIT:
    case appTypes.APP_FETCH_COMMERCIAL_SUBSCRIPTION_RECRUITMENT:
    case appTypes.APP_FETCH_SPECIALTIES:
    case appTypes.APP_FETCH_SHOPPING_PRODUCTS:
    case appTypes.APP_FETCH_STATES:
    case appTypes.APP_FETCH_USER_HOME:
    case appTypes.APP_FETCH_USER_MENU:
    case appTypes.APP_SET_CHALLENGE:
    case appTypes.APP_SET_SALESFORCE_CENTER:
    case appTypes.APP_SET_STATIC_PAGE:
    case appTypes.APP_SET_PROVINCES_COUNTRIES:
    case appTypes.APP_SET_COUNTRY:
    case appTypes.APP_FETCH_REFERRAL_PRODUCTS_LIST:
    case appTypes.APP_FETCH_HOME_PAGE:
    case appTypes.APP_UPDATE_LOGGING_IN:
      return {
        ...state,
        ...action.payload,
      };
    case appTypes.APP_FETCH_MAINTENANCE_MODE:
      return {
        ...state,
        maintenanceModes: action.payload.maintenanceMode,
      };
    case appTypes.APP_FETCH_PUBLIC_DISCLAIMERS:
    case appTypes.APP_FETCH_DISCLAIMERS:
      return {
        ...state,
        disclaimers: {
          ...state.disclaimers,
          ...action.payload,
        },
      };
    case appTypes.APP_FETCH_EMBRACE_PRACTICES:
    case appTypes.APP_FETCH_LIWW_PRACTICES:
    case appTypes.APP_FETCH_USA_PRACTICES:
      return {
        ...state,
        signInData: { ...state.signInData, ...action.payload },
      };
    case appTypes.APP_FETCH_FILTERS:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          [action.payload.challengeType]: {
            ...state.challenges[action.payload.challengeType],
            filters: action.payload.filterList,
            filtersLoaded: true,
          },
        },
      };
    case appTypes.APP_INSERT_ANNUAL_CAMPAIGN:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          annualCampaign: action.payload.challenge,
        },
      };
    case appTypes.APP_INSERT_CHALLENGES:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          [action.payload.challengeKey]: {
            ...action.payload.challengeList,
          },
        },
      };
    case appTypes.APP_UPDATE_CHALLENGES:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          [action.payload.challengeType]: {
            ...state.challenges[action.payload.challengeType],
            challenges: action.payload.challengeList.challenges,
            total: action.payload.challengeList.total,
          },
        },
      };

    case appTypes.APP_FETCH_CAMPAINGS:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          campaigns: {
            ...state.challenges.campaigns,
            total: action.payload.total,
            challenges: action.payload.campaigns,
            // filters: action.payload.filters,
            // filtersLoaded: state.challenges.campaigns.filterLoaded,
          },
        },
      };
    case appTypes.APP_FETCH_TALKS:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          events: {
            ...state.challenges.events,
            total: action.payload.total,
            challenges: action.payload.talks,
            // filters: action.payload.filters,
            // filtersLoaded: state.challenges.events.filterLoaded,
          },
        },
      };
    case appTypes.APP_FETCH_TRAININGS:
      return {
        ...state,
        challenges: {
          ...state.challenges,
          trainings: {
            ...state.challenges.trainings,
            total: action.payload.total,
            challenges: action.payload.trainings,
            // filters: action.payload.filters,
            // filtersLoaded: state.challenges.trainings.filterLoaded,
          },
        },
      };
    case appTypes.APP_FETCH_REDEEM_POINTS_PRODUCTS:
    case appTypes.APP_SHOW_BAG_MENU:
    case appTypes.APP_FETCH_CART:
    case appTypes.APP_ADD_BAG_PRODUCT:
    case appTypes.APP_UPDATE_BAG_PRODUCT:
    case appTypes.APP_REMOVE_BAG_PRODUCT:
    case appTypes.APP_FETCH_LABEL_REDEEM_PRODUCTS:
    case appTypes.APP_UPDATE_SORTBY:
      return {
        ...state,
        redeemPoints: { ...state.redeemPoints, ...action.payload },
      };
    case appTypes.APP_ADD_OR_REMOVE_DISCOUNT_COUPON:
      return {
        ...state,
        redeemPoints: {
          ...state.redeemPoints,
          cart: { ...state.redeemPoints.cart, ...action.payload },
        },
      };
    case appTypes.APP_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
