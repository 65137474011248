export enum ScientificMaterialTypeEnum {
  PHOTOPROTECTION_PHOTOTYPES = 1,
  PHOTOPROTECTION_INNOVATION = 2,
  PHOTOPROTECTION_OUTDOOR_ACTIVITIES = 3,
  DERMATITIS_INNOVATION = 4,
  ORAL_ANTIOXIDANTS = 5,
  NAIL_FRAGILITY = 6,
  PHOTOAGING = 7,
  HYDRATION = 8,
  PATHOLOGY = 9,
  HAIR_AND_NAILS = 10,
  PHOTOPROTECTION = 11,
  OTHER = 12,
}

export enum ScientificMaterialArticleTypeEnum {
  REVIEW = 1,
  CLINICAL_DATA = 2,
  PRE_CLINICAL_DATA = 3,
  POSTERS = 4,
  OTHER = 5,
}

export enum ScientificMaterialFilterTypes {
  TYPE = 'scientificMaterialType',
  ARTICLE_TYPE = 'scientificMaterialArticleType',
  CATEGORIES = 'categoriesFilter',
}

export interface ScientificGroupedMaterials {
  cardType: ScientificMaterialTypeEnum;
  order?: number;
  description?: string;
  scientificMaterials: ScientificMaterial[];
}

export interface ScientificMaterial {
  idMaterial: number;
  name: string;
  description: string;
  order: number;
  videoUrl?: string;
  imageUrl: string;
  podcastUrl: string;
  fullPaperUrl?: string;
  summaryUrl: string;
  type: ScientificMaterialTypeEnum;
  articleType: ScientificMaterialArticleTypeEnum;
  categories: ScientificMaterialCategory[];
}

export interface ScientificMaterialCategory {
  idScientificMaterialCategory: number;
  idCategory: number;
}
