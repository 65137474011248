import { Button, Row } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { postDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { updateSalesforceData } from 'src/actions/authActions';
import {
  IChallenge,
  IChallengeClause,
  IChallengeResourceRender,
  ISalesforceUser,
} from 'src/shared/models';
import ChallengeLegalCheck from './ChallengeLegalCheck';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from './ChallengeResourceWrapper';
import { CheckOutlined } from '@ant-design/icons';
interface OwnProps extends IChallengeResourceRender {
  config: IChallengeClause;
  idResource: number;
  id: string;
}

type ChallengeClauseProps = ReturnType<typeof mapDispatchToProps> & OwnProps;

const ChallengeClause: FC<ChallengeClauseProps> = ({
  config,
  idResource,
  updateUserPoints,
  updateChallenge,
  updateSalesforceData,
  id,
}): JSX.Element => {
  if (!config) return <React.Fragment />;
  const { formatMessage, formatNumber } = useIntl();
  const {
    isCompleted,
    title,
    clauseLegalCheck,
    onCompleteClauseMessage,
    points,
    buttonText,
  } = config;
  const acceptedCheck = clauseLegalCheck ? clauseLegalCheck.wasAccepted : false;
  const [signError, setSignError] = useState<boolean>(false);
  const [legalCheckData, setLegalCheckData] = useState<{
    isAccepted: boolean;
    idResource: number;
  }>({
    idResource: 0,
    isAccepted: acceptedCheck,
  });

  interface ICompleteClauseResponse {
    score: number;
    challenge: IChallenge;
    salesforceUserData: ISalesforceUser;
  }

  const signClause = async () => {
    try {
      const response: {
        data: ICompleteClauseResponse;
      } = await postDataCall({
        dataPath: apiPaths.CHALLENGES.COMPLETE_RESOURCE_CLAUSE,
        data: {
          idResource,
        },
        callConfig: {},
      });

      const { score, challenge, salesforceUserData } = response.data;

      if (score && typeof score === 'number') updateUserPoints(score);
      updateChallenge(challenge);
      updateSalesforceData(salesforceUserData);
    } catch (error) {
      setSignError(true);
      return error;
    }
  };

  const component = (
    <Row justify="center">
      <ChallengeResourceWrapper_Blocked />
      {clauseLegalCheck && (
        <Row justify="start">
          <ChallengeLegalCheck
            {...{
              isCompleted: isCompleted,
              config: clauseLegalCheck,
              updateLegalData: setLegalCheckData,
            }}
          />
        </Row>
      )}
      {isCompleted ? (
        <Button
          type="primary"
          icon={<CheckOutlined />}
          className={`btn-ghost--green`}
        >
          {onCompleteClauseMessage ||
            formatMessage({ id: 'page.campaign.clause.success' })}
        </Button>
      ) : (
        <Button
          disabled={clauseLegalCheck && !legalCheckData.isAccepted}
          onClick={() => {
            signClause();
          }}
          type="primary"
          id={id}
          className={`btn-primary btn-primary--black`}
        >
          {buttonText}
        </Button>
      )}
      {signError && (
        <Row justify="center">
          <Row
            className={`activate-campaign-confirm-label  activate-campaign-confirm-label-error
            `}
            justify="center"
            align="middle"
          >
            <FormattedMessage id="page.campaign.clause.error" />
          </Row>
        </Row>
      )}
    </Row>
  );

  return (
    <ChallengeResourceWrapper
      children={component}
      icon={config.icon}
      points={
        points !== 0 &&
        formatMessage(
          { id: 'page.challenge.plus-sign-{points}-points' },
          { points: formatNumber(points) }
        )
      }
      title={title}
      styleClass="challengeDetail--Video"
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateSalesforceData }, dispatch);

export default connect(null, mapDispatchToProps)(ChallengeClause);
