import { authTypes } from 'src/types/authTypes';
import { AuthActionTypes } from 'src/actions/authActions';
import { IUser } from 'src/shared/models';
import { UserStatusEnum } from 'src/shared/enums/user.enum';

export interface AuthState {
  accessToken: string | null;
  impersonate: boolean;
  impersonateTargetURL?: string;
  isPreview?: boolean;
  refreshToken: string | null;
  user: IUser | null;
  userPoints: number | null;
  userStatus: UserStatusEnum | null;
  userCommercialSubscriptionTokenStatus: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  impersonate: false,
  refreshToken: null,
  user: null,
  userPoints: null,
  userStatus: null,
  userCommercialSubscriptionTokenStatus: null,
};

export const authReducer = (
  state = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case authTypes.AUTH_IMPERSONATE:
    case authTypes.AUTH_RESET_USER_STATUS:
    case authTypes.AUTH_SET_INITIAL_USER:
    case authTypes.AUTH_SET_TOKENS:
    case authTypes.AUTH_UPDATE_USER_DATA:
    case authTypes.AUTH_USER_DECLINED:
    case authTypes.AUTH_USER_LOGGED:
    case authTypes.AUTH_UPDATE_USER_POINTS:
      return {
        ...state,
        ...action.payload,
      };
    case authTypes.AUTH_LOGOUT:
      return {
        ...initialState,
      };
    case authTypes.AUTH_UPDATE_NEWSLETTER_SUBSCRIPTION:
      state.user.salesforce.newsletterSubscription =
        action.payload.newsletterSubscription;

      return { ...state };
    default:
      return state;
  }
};
