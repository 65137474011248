import {
  IResource,
  IResourceDetailList,
} from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IChallengeExternalValidator } from 'src/shared/models';

abstract class IExternalValidatorFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(
    externalValidator: IChallengeExternalValidator
  ): IChallengeExternalValidator;
}

class DefaultFiller extends IExternalValidatorFiller {
  fill(
    externalValidator: IChallengeExternalValidator
  ): IChallengeExternalValidator {
    return {
      ...externalValidator,
    };
  }
}

class ChallengeExternalValidatorFactory {
  private readonly externalValidatorFillers: Map<
    ResourceTypeDetailEnum,
    IExternalValidatorFiller
  >;

  constructor(detail: IResourceDetailList) {
    this.externalValidatorFillers = new Map();

    this.externalValidatorFillers.set(
      ResourceTypeDetailEnum.LEGAL_CHECK_URL,
      new DefaultFiller(detail)
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    externalValidator: IChallengeExternalValidator
  ): IChallengeExternalValidator {
    const externalValidatorFiller =
      this.externalValidatorFillers.get(idResourceTypeD);

    if (externalValidatorFiller) {
      return externalValidatorFiller.fill(externalValidator);
    }

    return externalValidator;
  }
}

class ChallengeExternalValidatorBuilder {
  public build(resource: IResource): IChallengeExternalValidator {
    let externalValidator: IChallengeExternalValidator;

    resource.resourceDetailList
      .filter((detail) => detail.status)
      .forEach((detail) => {
        const Factory = new ChallengeExternalValidatorFactory(detail);

        externalValidator = Factory.fill(detail, externalValidator);
      });

    return {
      ...externalValidator,
      uuidResource: resource.uuidResource,
    };
  }
}

export const buildExternalValidator = (
  resource: IResource
): IChallengeExternalValidator => {
  const builder = new ChallengeExternalValidatorBuilder();

  return builder.build(resource);
};
