import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { formatPearlStatus } from 'src/components/pearl/PearlCard';
import { PearlStatusEnum, ResourceTypeDetailEnum } from 'src/shared/enums';
import { IPearl, IUser, IUserPearl } from 'src/shared/models';
import { getShortDataFormat } from '../../../../utils';
import { DateTime } from 'luxon';

const { PEARL_CONDITIONS } = ResourceTypeDetailEnum;

export const buildPearl = (resource: IResourceList, user: IUser): IPearl => {
  const {
    resourceDetailList,
    idResource,
    name: title,
    usersM2MList,
    endDate,
  } = resource;

  let pearl: IPearl;
  const userPearls: IUserPearl[] = [];
  const points = 0;

  resourceDetailList
    .filter(({ status }) => status)
    .forEach((_item) => {
      switch (_item.idResourceTypeD.idResourceTypeD) {
        case PEARL_CONDITIONS:
          pearl = {
            ...pearl,
            conditions: _item.value,
          };
          break;
      }
    });

  usersM2MList
    ?.filter(({ status }) => status)
    .forEach((_userResource) => {
      const userPearlData = JSON.parse(_userResource?.value);

      const userPearl: IUserPearl = {
        idUserResource: _userResource.idUserResource,
        title: userPearlData?.title,
        description: userPearlData?.description,
        image: userPearlData?.image,
        idUser: userPearlData?.idUser,
        userName: userPearlData?.userName,
        date: DateTime.fromJSDate(new Date(_userResource.createDate)).toFormat(
          getShortDataFormat()
        ),
        statusResource: _userResource.statusResource,
      };

      userPearls.push(userPearl);
    });

  pearl.otherUserPearls = userPearls?.filter(
    (_userPearls: IUserPearl) => _userPearls.idUser !== user.idUser
  );
  pearl.loggedUserPearl = userPearls?.find(
    (_userPearls: IUserPearl) => _userPearls.idUser === user.idUser
  );

  const formattedPearlStatus =
    formatPearlStatus(pearl.loggedUserPearl?.statusResource) || null;

  const isUserPearlActive =
    (pearl.loggedUserPearl &&
      pearl.loggedUserPearl?.statusResource !== PearlStatusEnum.NOT_SENT) ||
    false;

  const pearlEndDate = new Date(endDate);

  const isPearlActive = (): boolean => {
    return pearlEndDate.getTime() > Date.now();
  };

  return {
    ...pearl,
    icon: 'medal',
    idResource,
    title,
    points,
    formattedPearlStatus,
    isUserPearlActive,
    pearlStatusLabel: `pearl.campaign-label.${formattedPearlStatus}`,
    isPearlActive: isPearlActive(),
  };
};
