import { YuButton } from '@isdin/yuma-react-web-pin';
import React from 'react';

export const MyBagProductTrash = ({
  handleRemoveProduct,
  product,
  setProductLoading,
}) => {
  return (
    <YuButton
      size="XS"
      variant="ghost"
      centeredIcon="Trash"
      onClick={() => handleRemoveProduct(product, setProductLoading)}
    />
  );
};
