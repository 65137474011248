import { NextPage } from 'next';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRootReducers } from '../../../reducers';
import BasicHeaderBagEmpty from './BasicHeaderBagEmpty';
import BasicHeaderBagItems from './BasicHeaderBagItems';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const BasicHeaderBag: NextPage<Props, unknown> = ({
  cartItems,
}): JSX.Element => {
  if (cartItems.length) {
    return <BasicHeaderBagItems />;
  }
  return <BasicHeaderBagEmpty />;
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    cartItems: state.app.redeemPoints.cart.items,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BasicHeaderBag);
