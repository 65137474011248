import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';

const SkeletonRedeemPoints: NextPage = (): JSX.Element => {
  const { upS, upXS } = useSize();

  const headerPdpDesktop = () => {
    return (
      <YuFlex
        flexDirection="column"
        style={{ margin: 'auto' }}
        width={'100%'}
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
        maxWidth={'1370px'}
      >
        <YuFlex flexDirection="row" gap={'L'}>
          <YuFlex flexDirection="column" style={{ margin: 'auto 40px auto 0' }}>
            <YuSkeleton height={84} variant="text" width={'84px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={84} variant="text" width={'84px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={84} variant="text" width={'84px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={84} variant="text" width={'84px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={84} variant="text" width={'84px'} />
          </YuFlex>
          <YuSkeleton height={550} variant="text" width={'550px'} />
          <YuFlex
            flexDirection="column"
            style={{ width: '45%', paddingLeft: '20px' }}
          >
            <YuSpacer size="L" />
            <YuSkeleton height={33} variant="text" width={'400px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={33} variant="text" width={'300px'} />
            <YuSpacer size="M" />
            <YuSkeleton height={24} variant="text" width={'100%'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={24} variant="text" width={'80%'} />
            <YuSpacer size="XXL" />
            <YuSkeleton height={40} variant="text" width={'200px'} />
            <YuSpacer size="M" />
            <YuFlex flexDirection="row" gap={'L'} justify="space-between">
              <YuSkeleton height={40} variant="text" width={'115px'} />
              <YuSkeleton height={40} variant="text" width={'240px'} />
            </YuFlex>
          </YuFlex>
        </YuFlex>
      </YuFlex>
    );
  };

  const headerPdpTablet = () => {
    return (
      <YuFlex
        flexDirection="column"
        style={{ margin: 'auto' }}
        width={'100%'}
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
        maxWidth={'1370px'}
      >
        <YuSpacer size="M" />
        <YuFlex justify="center">
          <YuSkeleton height={40} variant="text" width={'80%'} />
        </YuFlex>
        <YuSpacer size="L" />
        <YuFlex justify="start">
          <YuSkeleton height={24} variant="text" width={'80%'} />
        </YuFlex>
        <YuSpacer size="XL" />
        <YuFlex justify="center">
          <YuSkeleton height={300} variant="text" width={'300px'} />
        </YuFlex>
        <YuSpacer size="XL" />
        <YuSkeleton height={22} variant="text" width={'90px'} />
        <YuSpacer size="S" />
        <YuFlex flexDirection="row" gap={'L'} justify="space-between">
          <YuSkeleton height={40} variant="text" width={'115px'} />
          <YuSkeleton height={48} variant="text" width={'240px'} />
        </YuFlex>
        <YuSpacer size="XS" />
      </YuFlex>
    );
  };

  const headerPdpMobile = () => {
    return (
      <YuFlex
        flexDirection="column"
        style={{ margin: 'auto' }}
        width={'100%'}
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
        maxWidth={'1370px'}
      >
        <YuSpacer size="M" />
        <YuSkeleton height={40} variant="text" width={'100%'} />
        <YuSpacer size="L" />
        <YuFlex justify="center">
          <YuSkeleton height={24} variant="text" width={'90%'} />
        </YuFlex>
        <YuSpacer size="XL" />
        <YuFlex justify="center">
          <YuSkeleton height={300} variant="text" width={'300px'} />
        </YuFlex>
        <YuSpacer size="XL" />
        <YuFlex flexDirection="row" gap={'L'} justify="space-between">
          <YuSkeleton height={40} variant="text" width={'115px'} />
          <YuSkeleton height={40} variant="text" width={'110px'} />
        </YuFlex>
        <YuSpacer size="XL" />
        <YuFlex justify="center">
          <YuSkeleton height={48} variant="text" width={'98%'} />
        </YuFlex>
        <YuSpacer size="XS" />
      </YuFlex>
    );
  };

  const footerPdpMobile = () => {
    return (
      <YuFlex flexDirection="column" style={{ margin: 'auto' }} width={'100%'}>
        <YuSkeleton height={1} variant="text" width={'100%'} />
        <YuSpacer size="XS" />
        <YuFlex
          flexDirection="row"
          justify="space-between"
          style={{ padding: '0 32px' }}
        >
          <YuSkeleton height={25} variant="text" width={'120px'} />
          <YuSkeleton height={20} variant="text" width={'20px'} />
        </YuFlex>
        <YuSpacer size="XS" />
        <YuSkeleton height={1} variant="text" width={'100%'} />
        <YuSpacer size="XS" />
        <YuFlex
          flexDirection="row"
          justify="space-between"
          style={{ padding: '0 32px' }}
        >
          <YuSkeleton height={25} variant="text" width={'120px'} />
          <YuSkeleton height={20} variant="text" width={'20px'} />
        </YuFlex>
        <YuSpacer size="XS" />
        <YuSkeleton height={1} variant="text" width={'100%'} />
        <YuSpacer size="XS" />
        <YuFlex
          flexDirection="row"
          justify="space-between"
          style={{ padding: '0 32px' }}
        >
          <YuSkeleton height={25} variant="text" width={'120px'} />
          <YuSkeleton height={20} variant="text" width={'20px'} />
        </YuFlex>
        <YuSpacer size="XS" />
      </YuFlex>
    );
  };

  const footerPdpDesktop = () => {
    return (
      <YuFlex
        flexDirection="column"
        style={{ margin: 'auto' }}
        width={'100%'}
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
        maxWidth={'1370px'}
      >
        <YuSpacer size="XL" />
        <YuFlex
          flexDirection="row"
          gap={'L'}
          justify="space-between"
          style={{ margin: 'auto 15%' }}
        >
          <YuSkeleton height={22} variant="text" width={'91px'} />
          <YuSkeleton height={22} variant="text" width={'91px'} />
          <YuSkeleton height={22} variant="text" width={'91px'} />
        </YuFlex>
        <YuSpacer size="XXS" />
        <YuSkeleton height={1} variant="text" width={'100%'} />
        <YuFlex flexDirection="column">
          <YuSpacer size="XL" />
          <YuFlex justify="center" style={{ margin: 'auto' }}>
            <YuSkeleton height={31} variant="text" width={'200px'} />
          </YuFlex>
          <YuSpacer size="XS" />
          <YuFlex justify="center">
            <YuSkeleton height={22} variant="text" width={'50%'} />
          </YuFlex>
          <YuSpacer size="XXS" />
          <YuFlex justify="center">
            <YuSkeleton height={22} variant="text" width={'30%'} />
          </YuFlex>
          <YuSpacer size="XL" />
        </YuFlex>
        <YuFlex flexDirection="column" style={{ backgroundColor: '#f7f7f6' }}>
          <YuSpacer size="XL" />
          <YuFlex justify="center" style={{ margin: 'auto' }}>
            <YuSkeleton height={31} variant="text" width={'200px'} />
          </YuFlex>
          <YuSpacer size="XS" />
          <YuFlex justify="center">
            <YuSkeleton height={22} variant="text" width={'50%'} />
          </YuFlex>
          <YuSpacer size="XXS" />
          <YuFlex justify="center">
            <YuSkeleton height={22} variant="text" width={'30%'} />
          </YuFlex>
          <YuSpacer size="XL" />
        </YuFlex>
        <YuFlex flexDirection="column">
          <YuSpacer size="XL" />
          <YuFlex justify="center" style={{ margin: 'auto' }}>
            <YuSkeleton height={31} variant="text" width={'200px'} />
          </YuFlex>
          <YuSpacer size="XS" />
          <YuFlex justify="center">
            <YuSkeleton height={22} variant="text" width={'50%'} />
          </YuFlex>
          <YuSpacer size="XXS" />
          <YuFlex justify="center">
            <YuSkeleton height={22} variant="text" width={'30%'} />
          </YuFlex>
          <YuSpacer size="XL" />
        </YuFlex>
      </YuFlex>
    );
  };
  return (
    <>
      <YuSkeletonWrapper className="yuma-custom-skeleton">
        <YuSpacer size="L" />
        {upS
          ? headerPdpDesktop()
          : upXS
          ? headerPdpTablet()
          : headerPdpMobile()}
        {upS ? footerPdpDesktop() : footerPdpMobile()}
      </YuSkeletonWrapper>
    </>
  );
};

export default SkeletonRedeemPoints;
