import { config } from 'config/config';

export const isValidZipCode = (value: string) => {
  return value && value.length === config.REGISTER.ZIPCODE_LENGTH;
};

export const formatRUT = (value) => {
  let cleanedValue = value.replace(/[^\dA-Za-z]/g, '');

  cleanedValue = cleanedValue.slice(0, 9);

  const body = cleanedValue.slice(0, 8);
  const verifier = cleanedValue.slice(8);

  let formattedBody = body;
  if (body.length > 2) formattedBody = body.slice(0, 2) + '.' + body.slice(2);
  if (body.length > 5)
    formattedBody = formattedBody.slice(0, 6) + '.' + formattedBody.slice(6);

  return verifier ? `${formattedBody}-${verifier}` : formattedBody;
};
