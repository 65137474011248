import { DateTime } from 'luxon';
import { useYuInputDateCountryDate } from './useYuInputDateCountryDate';

export const useYuInputDateHelperFunctions = () => {
  const countryDate = useYuInputDateCountryDate();
  const dateToYuInputDateString = (date: Date | string): string => {
    if (!date) return date?.toString();
    const ldate = DateTime.fromJSDate(new Date(date));
    const str =
      countryDate === 'US'
        ? ldate.toFormat('MM/dd/yyyy')
        : ldate.toLocaleString(DateTime.DATE_SHORT);
    const split = str.split('/');
    return `${split[0].padStart(2, '0')}/${split[1].padStart(2, '0')}/${
      split[2]
    }`;
  };

  const yuInputDateStringToDate = (str: string): string => {
    const split = str.split('/');

    if (countryDate === 'EU') {
      return Number(split[2]) + '-' + Number(split[1]) + '-' + Number(split[0]);
    } else {
      return Number(split[2]) + '-' + Number(split[0]) + '-' + Number(split[1]);
    }
  };

  return {
    dateToYuInputDateString,
    yuInputDateStringToDate,
  };
};
