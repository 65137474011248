import {
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
} from '@isdin/yuma-react-web-pin';
import React from 'react';

export const UserPointsCardSkeleton = () => {
  return (
    <YuSkeletonWrapper
      style={{
        boxShadow: '0px 0px 1px 0px #1313134D, 0px 4px 8px 0px #13131333',
      }}
      maxWidth="1400px"
      width="96%"
      borderRadius="4px"
      backgroundColor="bg.grey"
      padding="XS"
    >
      <YuFlex flexDirection="row" gap="L">
        <YuFlex gap="XXS" flexDirection="column">
          <YuSkeleton height="16px" width="71px" />
          <YuSkeleton height="24px" width="154px" />
        </YuFlex>
        <YuSkeleton height="56px" width="56px" />
      </YuFlex>
    </YuSkeletonWrapper>
  );
};
