export enum MaterialsEnum {
  // FILES = 1,
  PRODUCT_IMAGES = 2,
  VIDEOS_YOUTUBE = 3,
  VIDEOS = 4,
  // LEARN_AND_EARN = 5,
  PRODUCT_INFORMATION = 6,
  OFFICE_BRANDING = 7,
  PATIENT_OUTREACH = 8,
  VIDEOS_VIMEO = 9,
}
