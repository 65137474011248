import NextLink, { LinkProps } from 'next/link';
import getConfig from 'next/config';
import { format } from 'url';

const { publicRuntimeConfig } = getConfig();

const Link: React.FunctionComponent<LinkProps> = ({ children, ...props }) => (
  <NextLink
    {...props}
    as={`${publicRuntimeConfig.PLATFORM_PREFIX || ''}${format(props.href)}`}>
    {children}
  </NextLink>
);

export default Link;
