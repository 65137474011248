import { IUser } from '../shared/models';
import {
  PIN_Specialty_Enum,
  PIN_Specialty_Salesforce_Enum,
} from '../shared/enums';

export const userIsDermatologistSpecialty = (user: IUser): boolean => {
  if (user?.idSpecialty) {
    return (
      user.idSpecialty.idSalesforce ===
        PIN_Specialty_Salesforce_Enum.DERMATOLOGO ||
      user.idSpecialty.idSpecialty === PIN_Specialty_Enum.DERMATOLOGO
    );
  }
  return false;
};
