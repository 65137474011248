import {IRedeemPointsProduct} from '../shared/models';
import _ from 'lodash';

/**
 * Return bag products out stock
 *
 * @param products Product with stock var
 * @param bagProducts Bag products with quantity to buy
 * @returns productsOutStock
 */
export function returnBagProductsIfOutStock(
  products: IRedeemPointsProduct[],
  bagProducts: IRedeemPointsProduct[]
): IRedeemPointsProduct[] {
  const productsOutStock = [];
  const productsBySku = _.keyBy(products, 'sku');
  _.forEach(bagProducts, (bagProduct) => {
    const product = productsBySku[bagProduct.sku];
    if (!product) throw new Error('Product not found');
    if (bagProduct.qty > product.stock) {
      productsOutStock.push({
        ...product,
        ...bagProduct,
      });
    }
  });
  return productsOutStock;
}
