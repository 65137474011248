import { builderChallenge } from 'src/components/challenge/builder';
import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { ChallengeTypeEnum, ResourceTypeDetailEnum } from 'src/shared/enums';
import { ChallengeDetail, ILinkedChallenges, IUser } from 'src/shared/models';

const {
  LINKED_CHALLENGES_DESCRIPTION,
  LINKED_CHALLENGE_TYPE,
  LINKED_CHALLENGE_ID,
} = ResourceTypeDetailEnum;

export const buildLinkedChallenges = (
  resource: IResourceList,
  user: IUser
): ILinkedChallenges => {
  const { idResource, name, i18nList, activate } = resource;

  let linkedChallenges: ILinkedChallenges = {} as ILinkedChallenges;
  const challenges: ChallengeDetail[] = [];
  let points = 0;
  let isCompleted = true;
  let isPartialDone = false;
  let challengeBuilded: ChallengeDetail;

  resource.resourceDetailList
    .sort((a, b) => a.order - b.order)
    .filter(({ status }) => status)
    .forEach((resourceDetailList) => {
      const {
        idResourceTypeD: { idResourceTypeD },
        value,
        challenge,
      } = resourceDetailList;

      switch (idResourceTypeD) {
        case LINKED_CHALLENGES_DESCRIPTION:
          linkedChallenges = {
            ...linkedChallenges,
            description: value,
          };
          break;
        case LINKED_CHALLENGE_TYPE:
          linkedChallenges = {
            ...linkedChallenges,
            type: parseInt(value),
          };
          break;
        case LINKED_CHALLENGE_ID:
          if (!challenge) break;

          challengeBuilded = builderChallenge(challenge, user);
          isCompleted =
            isCompleted && Boolean(challengeBuilded.isChallengeCompleted);
          isPartialDone = isPartialDone || false;
          challenges.push(challengeBuilded);
          points += challengeBuilded.challengePoints;

          break;
      }
    });

  const getIconByType = (type: ChallengeTypeEnum): string => {
    switch (type) {
      case ChallengeTypeEnum.CAMPAIGN:
        return null;
      case ChallengeTypeEnum.FORMATION:
        return 'challenge';
      case ChallengeTypeEnum.CONGRESS:
      case ChallengeTypeEnum.TALK:
        return 'map-pin icon__reduce_75';
      case ChallengeTypeEnum.PEARL:
        return null;
      default:
        return 'medal';
    }
  };

  return {
    // TODO: Do something similar to the BuilderResourceGuide
    ...linkedChallenges,
    challenges,
    i18nList,
    idResource,
    isCampaignActivator: activate,
    isCompleted,
    isPartialDone,
    points,
    title: name,
    icon: getIconByType(linkedChallenges.type),
  };
};
