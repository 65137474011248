import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';

const SkeletonRedeemPoints: NextPage = (): JSX.Element => {
  const { upXS, upL, upM, upS } = useSize();
  const CARDS_NUMBER_DESKTOP = 3;
  const CARD_NUMBER_TABLET = 2;
  const CARD_NUMBER_MOBILE = 1;

  const CARD_FILE_NUMBERS_DESKTOP = 3;
  const CARD_FILE_NUMBERS_TABLET = 4;
  const CARD_FILE_NUMBER_MOBILE = 9;

  const CARD_FILE_NUMBERS = upM
    ? CARD_FILE_NUMBERS_DESKTOP
    : upS
    ? CARD_FILE_NUMBERS_TABLET
    : CARD_FILE_NUMBER_MOBILE;

  const CARD_NUMBER = upS
    ? CARDS_NUMBER_DESKTOP
    : upXS
    ? CARD_NUMBER_TABLET
    : CARD_NUMBER_MOBILE;
  const skeletonCard = () => {
    return (
      <YuFlex
        flexDirection="column"
        style={{ width: upXS ? 'auto' : '100%', margin: 'auto' }}
      >
        <YuSkeleton
          variant="rectangular"
          width={{ initial: 200, upXS: 300 }}
          height={{ initial: 200, upXS: 420 }}
        />
        <YuFlex flexDirection="column" align={'center'}>
          <YuSpacer size={upM ? 'M' : 'L'} />
          <YuSkeleton variant="text" height={24} width="100%" />
          <YuSpacer size="S" />
          <YuSkeleton variant="text" height={22} width="80%" />
          <YuSpacer size="XS" />
          <YuSkeleton variant="text" height={25} width="120px" />
          <YuSpacer size="S" />
          <YuSkeleton variant="text" height={28} width="130px" />
          <YuSpacer size={upM ? 'M' : 'L'} />
        </YuFlex>
      </YuFlex>
    );
  };

  const headerGridDesktop = () => {
    return (
      <YuFlex
        flexDirection="column"
        style={{ margin: 'auto' }}
        width={'100%'}
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
        maxWidth={'1370px'}
      >
        <YuSpacer size="L" />
        <YuSkeleton
          height={40}
          variant="text"
          width={upXS ? '292px' : '100%'}
        />
        <YuSpacer size="M" />
        <YuFlex justify="end">
          <YuSkeleton height={31} variant="text" width={'230px'} />
        </YuFlex>
        <YuSpacer size="M" />
        <YuFlex style={{ paddingLeft: '8px' }}>
          <YuSkeleton height={40} variant="text" width={'150px'} />
        </YuFlex>
        <YuSpacer size="S" />
        <YuFlex justify="end" style={{ paddingRight: '50px' }}>
          <YuSkeleton height={24} variant="text" width={'220px'} />
        </YuFlex>
        <YuSpacer size="L" />
      </YuFlex>
    );
  };

  const headerGridMobile = () => {
    return (
      <YuFlex
        flexDirection="column"
        style={{ margin: 'auto' }}
        width={'100%'}
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
        maxWidth={'1370px'}
      >
        <YuSpacer size="XL" />
        <YuSkeleton height={40} variant="text" width={'80%'} />
        <YuSpacer size="XL" />
        <YuFlex justify="end">
          <YuSkeleton height={31} variant="text" width={'100%'} />
        </YuFlex>
        <YuSpacer size="M" />
        <YuFlex justify="end">
          <YuSkeleton height={40} variant="text" width={'40px'} />
        </YuFlex>
        <YuSpacer size="L" />
        <YuFlex justify="end" style={{ paddingRight: '50px' }}>
          <YuSkeleton height={24} variant="text" width={'220px'} />
        </YuFlex>
        <YuSpacer size="L" />
      </YuFlex>
    );
  };

  return (
    <>
      <YuSkeletonWrapper className="yuma-custom-skeleton">
        <YuFlex flexDirection="column">
          {upXS ? headerGridDesktop() : headerGridMobile()}
          {_.times(CARD_FILE_NUMBERS, () => {
            return (
              <YuFlex justify="center">
                <YuFlex
                  flexDirection="column"
                  pl={{ onlyXS: 'S', upXS: 'S' }}
                  pr={{ onlyXS: 'S', upXS: 'S' }}
                >
                  <YuFlex gap={upL ? 'M' : 'S'} wrap="wrap" justify="start">
                    {_.times(CARD_NUMBER, () => skeletonCard())}
                  </YuFlex>
                </YuFlex>
              </YuFlex>
            );
          })}
          <YuSpacer size="M" />
          <YuFlex justify={'center'}>
            <YuSkeleton height={36} variant="text" width={'133px'} />
          </YuFlex>
          <YuSpacer size="XL" />
        </YuFlex>
      </YuSkeletonWrapper>
    </>
  );
};

export default SkeletonRedeemPoints;
