import { isESPlatform } from '../../utils';
import { ISalesforceAddress } from '../shared/models';

export const addressExceedLimitForCurrentPlatform = (
  address: ISalesforceAddress
) => {
  return (
    isESPlatform &&
    (address?.addressStreet?.length > 30 || address?.addressNumber?.length > 10)
  );
};
