import {
  useSize,
  YuCol,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';

const SkeletonTalks: NextPage = (): JSX.Element => {
  const { upXS, upM } = useSize();
  const CARDS_NUMBER = 6;
  const CARDS_NUMBER_MOBILE = 4;

  const skeletonCard = () => {
    return (
      <YuFlex flexDirection="column" className="card">
        <YuSkeleton
          className="top-rounded-borders"
          variant="rectangular"
          width={{ initial: 327, upS: 390 }}
          height={{ initial: 260, upS: 260 }}
        />
        <YuSpacer size={upM ? 'M' : 'L'} />
        <YuCol className="description">
          <YuSkeleton
            variant="text"
            height={24}
            width={{ initial: 295, upS: 358 }}
          />
          <YuSpacer size={upM ? 'M' : 'L'} />
          <YuSkeleton variant="text" width={167} />
          <YuSpacer size="S" />
          <YuSkeleton variant="text" width={167} />
          <YuSpacer size="L" />
          <YuSkeleton variant="rectangular" width={123} height={32} />
        </YuCol>
        <YuSpacer size={upM ? 'M' : 'L'} />
      </YuFlex>
    );
  };

  return (
    <>
      <YuSkeletonWrapper className="yuma-custom-skeleton container">
        <YuFlex flexDirection="column">
          <YuFlex
            flexDirection="column"
            ml={{ onlyXS: 'S', upXS: 'S' }}
            mr={{ onlyXS: 'S', upXS: 'S' }}
          >
            <YuSpacer size="L" />
            <YuSkeleton
              height={40}
              variant="text"
              width={upXS ? '292px' : '100%'}
            />
            <YuSpacer size="L" />
          </YuFlex>

          <YuFlex flexDirection="column" pl={{ upXS: 'M' }} pr={{ upXS: 'M' }}>
            <YuFlex className="skeleton-challenge-carousel--cards">
              {_.times(upXS ? CARDS_NUMBER : CARDS_NUMBER_MOBILE, () =>
                skeletonCard()
              )}
            </YuFlex>
          </YuFlex>

          <YuSpacer size="L" />

          <YuFlex justify="center">
            <YuSkeleton variant="text" width={118} height={32} />
          </YuFlex>

          <YuSpacer size="XL" />
        </YuFlex>
      </YuSkeletonWrapper>
    </>
  );
};

export default SkeletonTalks;
