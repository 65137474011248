import { NextPageContext } from 'next';
import { createWrapper, HYDRATE, MakeStore } from 'next-redux-wrapper';
import { AnyAction, applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { config } from '../config/config';
import combineReducers, { IRootReducers } from './reducers';

export interface ReduxPageContext extends NextPageContext {
  store: Store;
}

const bindMiddleware = (middleware) => {
  if (config.APP.ENV === 'local') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const reducer = (state: IRootReducers, action: AnyAction) => {
  const staticPageConfig = state?.app.staticPageConfig;
  const showHeader = staticPageConfig?.showHeader;
  const showFooter = staticPageConfig?.showFooter;

  if (
    action.type === HYDRATE &&
    ((showHeader && showFooter) || staticPageConfig === null)
  ) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    // if (state.auth) nextState.auth = state.auth; // preserve auth value on client side navigation
    return nextState;
  } else {
    return combineReducers(state, action);
  }
};

let store: Store;

const makeStore: MakeStore<Store<IRootReducers>> = () => {
  store = createStore(reducer, bindMiddleware([thunkMiddleware]));
  return store;
};

export { store };

export const wrapper = createWrapper<Store<IRootReducers>>(makeStore, {});
