import {
  useSize,
  YuCol,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';
import ConditionalRenderer from 'src/components/ConditionalRenderer';

const SkeletonTraining: NextPage = (): JSX.Element => {
  const { upXS } = useSize();
  const CARDS_NUMBER = 6;
  const FILTERS = 4;

  const skeletonCard = () => {
    return (
      <YuFlex flexDirection="column" className="card">
        <YuSkeleton
          className="top-rounded-borders"
          variant="rectangular"
          width={{ initial: 327, upS: 390 }}
          height={{ initial: 260, upXS: 260 }}
        />
        <YuSpacer size="M" />
        <YuCol className="description">
          <YuSkeleton
            variant="text"
            height={24}
            width={{ initial: 295, upS: 358 }}
          />
          <YuSpacer size="M" />
          <YuSkeleton variant="text" width={167} />
          <YuSpacer size="L" />
          <YuSkeleton variant="rectangular" width={123} height={32} />
        </YuCol>
        <YuSpacer size="L" />
      </YuFlex>
    );
  };

  return (
    <>
      <YuSkeletonWrapper className="yuma-custom-skeleton container">
        <YuFlex flexDirection="column">
          <YuFlex
            flexDirection="column"
            ml={{ onlyXS: 'S', upXS: 'S' }}
            mr={{ onlyXS: 'S', upXS: 'S' }}
          >
            <YuSpacer size={{ initial: 'M', upXS: 'L' }} />
            <YuSkeleton
              height={40}
              variant="text"
              width={upXS ? '292px' : '100%'}
            />
            <YuSpacer size="L" />
          </YuFlex>

          <YuFlex
            flexDirection="column"
            mr={{ onlyXS: null, upXS: 'S' }}
            ml={{ onlyXS: 'S', upXS: 'S' }}
          >
            <YuFlex style={{ overflowX: 'hidden' }} gap="L">
              <YuFlex gap={{ upXS: 'S', onlyXS: 'XXS' }}>
                {_.times(FILTERS, () => (
                  <YuSkeleton height={24} variant="text" width="100px" />
                ))}
              </YuFlex>

              <YuSkeleton height={24} variant="text" width="140px" />
            </YuFlex>
          </YuFlex>

          <ConditionalRenderer
            condition={!upXS}
            trueComponent={
              <>
                <YuSpacer size="S" />
                <YuFlex
                  flexDirection="column"
                  mr={{ onlyXS: 'S' }}
                  ml={{ onlyXS: 'S' }}
                >
                  <YuFlex gap="M" justify="space-between">
                    <YuSkeleton height={24} variant="text" width={100} />
                    <YuSkeleton height={24} variant="text" width={24} />
                  </YuFlex>
                </YuFlex>
              </>
            }
          />
          <YuSpacer size="XL" />

          <YuFlex flexDirection="column" pl={{ upXS: 'M' }} pr={{ upXS: 'M' }}>
            <YuFlex className="skeleton-challenge-carousel--cards">
              {_.times(CARDS_NUMBER, () => skeletonCard())}
            </YuFlex>
          </YuFlex>

          <YuSpacer size={upXS ? 'XL' : 'L'} />

          <YuFlex justify="center">
            <YuSkeleton variant="text" width={118} height={32} />
          </YuFlex>

          <YuSpacer size="XL" />
        </YuFlex>
      </YuSkeletonWrapper>
    </>
  );
};

export default SkeletonTraining;
