import { DateTime, Interval } from 'luxon';
import { getTimeZone } from '../../utils/timeUtils';

/**
 * Checks if actual time is in the provided datetime interval.
 *
 * @param intervalStartDate - start date to compare
 * @param intervalEndDate - end date to compare
 * @param checkTimeZone - it checks if interval must check with local time or with some timezone
 * @returns boolean;
 */
export function intervalContainsNow(
  intervalStartDate: string | DateTime,
  intervalEndDate: string | DateTime,
  checkTimeZone = false
): boolean {
  const timeZone = checkTimeZone ? getTimeZone() : 'local';
  const beginningTime =
    typeof intervalStartDate === 'string'
      ? DateTime.fromJSDate(new Date(intervalStartDate)).setZone(timeZone)
      : intervalStartDate;
  const endTime =
    typeof intervalEndDate === 'string'
      ? DateTime.fromJSDate(new Date(intervalEndDate)).setZone(timeZone)
      : intervalEndDate;
  return Interval.fromDateTimes(beginningTime, endTime).contains(
    DateTime.now()
  );
}

/**
 * Parses a given date string or returns the current date and time.
 *
 * @param dateToParse - The date string to parse. If undefined or null, the current date is used.
 * @param setTimeZone - Whether to set the time zone of the returned date.
 * @returns A DateTime object representing either the parsed date or the current date, with optional time zone set.
 */
export function getDateOrNow(
  dateToParse: string | undefined | null,
  setTimeZone: boolean
) {
  let dateTime: DateTime;
  if (typeof dateToParse === 'string' && dateToParse.length > 0) {
    dateTime = DateTime.fromJSDate(new Date(dateToParse));
  } else {
    dateTime = DateTime.now();
  }
  return setTimeZone ? dateTime.setZone(getTimeZone()) : dateTime;
}
