import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_CO: IRegion[] = [
  {
    id: 'a0C5800000FiEMHEA3',
    code: '91',
    name: 'Amazonas',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMXEA3',
    code: '05',
    name: 'Antioquia',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMhEAN',
    code: '81',
    name: 'Arauca',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiENvEAN',
    code: '08',
    name: 'Atlántico',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPEEA3',
    code: '11',
    name: 'Bogotá',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPFEA3',
    code: '13',
    name: 'Bolívar',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPOEA3',
    code: '15',
    name: 'Boyaca',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQ2EAN',
    code: '17',
    name: 'Caldas',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQLEA3',
    code: '18',
    name: 'Caqueta',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQVEA3',
    code: '85',
    name: 'Casanare',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQcEAN',
    code: '19',
    name: 'Cauca',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQlEAN',
    code: '20',
    name: 'Cesar',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERCEA3',
    code: '27',
    name: 'Choco',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERjEAN',
    code: '23',
    name: 'Córdoba',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiES6EAN',
    code: '25',
    name: 'Cundinamarca',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUxEAN',
    code: '94',
    name: 'Guainía',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEV5EAN',
    code: '95',
    name: 'Guaviare',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEVyEAN',
    code: '41',
    name: 'Huila',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYXEA3',
    code: '44',
    name: 'La Guajira',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEa9EAF',
    code: '47',
    name: 'Magdalena',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEayEAF',
    code: '50',
    name: 'Meta',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEc4EAF',
    code: '52',
    name: 'Nariño',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEccEAF',
    code: '54',
    name: 'Norte Santander',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEf7EAF',
    code: '86',
    name: 'Putumayo',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfFEAV',
    code: '63',
    name: 'Quindío',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEg8EAF',
    code: '66',
    name: 'Risaralda',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEh2EAF',
    code: '68',
    name: 'Santander',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEglEAF',
    code: '88',
    name: 'San Andrés',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEieEAF',
    code: '70',
    name: 'Sucre',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjqEAF',
    code: '73',
    name: 'Tolima',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkZEAV',
    code: '76',
    name: 'Valle',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkrEAF',
    code: '97',
    name: 'Vaupes',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiElAEAV',
    code: '99',
    name: 'Vichada',
    country: 'Colombia',
    countryCode: 'CO',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'es-ES');
});
