import { Col, Row } from 'antd';
import routePaths from 'config/routes';
import { useRouter } from 'next/router';
import React from 'react';
import { useIntl } from 'react-intl';
import Link from 'src/components/Link';
import PearlCard from 'src/components/pearl/PearlCard';
import { IUserPearl } from 'src/shared/models';

const PearlList = ({ loggedUserPearl, setPearlSelected, usersPearls }) => {
  const { formatMessage } = useIntl();
  const { query } = useRouter();
  return (
    <div className="pearl__list">
      {loggedUserPearl && (
        <p className="title">{formatMessage({ id: 'pearl.my-pearl' })}</p>
      )}
      <Row gutter={[32, 58]}>
        {[loggedUserPearl]?.map((_loggedUserPearl: IUserPearl) => (
          <PearlCard
            key={`pearl-card-${_loggedUserPearl?.idUser}`}
            setPearlSelected={setPearlSelected}
            userPearl={_loggedUserPearl}
            isLoggedUserPearl={true}
          />
        ))}
      </Row>
      <p className="title">{formatMessage({ id: 'pearl.rest-of-pearls' })}</p>
      {usersPearls.length >= 1 ? (
        <Row gutter={[32, 58]}>
          {usersPearls?.map((otherUsersPearl: IUserPearl) => (
            <PearlCard
              key={`pearl-card-${otherUsersPearl?.idUser}`}
              setPearlSelected={setPearlSelected}
              userPearl={otherUsersPearl}
              isLoggedUserPearl={false}
            />
          ))}
        </Row>
      ) : (
        <Row justify="center" className="pearl__list--no-pearls">
          <Col span={24}>
            <p className="title title--no-pearls">
              {formatMessage({ id: 'pearl.list.no-pearls' })}
            </p>
          </Col>
          {query?.idParentChallenge ? (
            <Col span={24}>
              <p className="pearl__links">
                <Link
                  href={`${routePaths.PAGES.CAMPAIGN_DETAIL}?id=${query?.idParentChallenge}`}
                >
                  {formatMessage({ id: 'page.campaign.static-return' })}
                </Link>
                <span className="icon icon--arrow-dropdown-right"></span>
              </p>
            </Col>
          ) : null}
        </Row>
      )}
    </div>
  );
};

export default PearlList;
