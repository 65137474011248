import { JourneyModalIterator } from '../../modal_journey/modal_journey_iterator';

export const ExternalRedeemPointsTypeEnum = Object.freeze({
  EXTERNAL_REDEEM_DONATION: '1',
  EXTERNAL_REDEEM_ISD: '2',
});

export declare type ExternalRedeemPointsType =
  typeof ExternalRedeemPointsTypeEnum[keyof typeof ExternalRedeemPointsTypeEnum];

export declare type ExternalRedeemPointsTypeHandler = {
  [key in ExternalRedeemPointsType]: (iterator: JourneyModalIterator) => void;
};

export enum CardsPositionEnum {
  FIRST_POSITION = 0,
  SECOND_POSITION = 1,
}
