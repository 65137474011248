import { NextPage } from 'next';
import React, { useState } from 'react';

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Form, Input, notification, Row } from 'antd';
import { config } from 'config/config';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { putDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import {
  updateSalesforceData,
  updateUserBirthday,
} from 'src/actions/authActions';
import { IRootReducers } from 'src/reducers';
import { PIN_PlatformID_Enum } from 'src/shared/enums';
import { IEditAccountData } from 'src/shared/models/userProfile.model';
import { ToggleButtons } from '../Toggle';
import { useShowUserProfileSpecialtyExtraFields } from '../../hooks/useShowUserProfileSpecialtyExtraFields';
import { YuInputDate } from '@isdin/yuma-react-web-pin';

import { useYuInputDateCountryDate } from '../../hooks/useYuInputDateCountryDate';
import _ from 'lodash';
import { getPhoneMinLengthRegex } from '../../validations/getPhoneMinLengthRegex';
import { yuFeedback } from '../../helpers/yuFeedback';
import { customFormatMessage, isUSAPlatform } from '../../../utils';
import { useYuInputDateHelperFunctions } from 'src/hooks/useYuInputDateHelperFunction';

const { PLATFORM } = config.APP;
const SHOW_USER_DOCUMENT_ID_FIELD = ![
  PIN_PlatformID_Enum.PIN_ALEMANIA,
].includes(PLATFORM);

export const getValidationMessage = (id: string) => {
  const { formatMessage } = useIntl();

  switch (id) {
    case 'form.field.telephone':
      return `${formatMessage({ id: `${id}-is-required` })}`;

    case 'form.field.password':
      return `${formatMessage({
        id: 'page.password-change.validation.message',
      })}`;
    default:
      return `${formatMessage(
        { id: 'form.field.required' },
        { name: formatMessage({ id }) }
      )}`;
  }
};

export interface EditFormProps {
  closeForm: () => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  EditFormProps;

const EditPersonalDataForm: NextPage<Props> = (props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const {
    closeForm,
    updateUserBirthday,
    updateSalesforceData,
    userFirstname,
    userMail,
    userSurname,
    userPhone,
    userMedicalCenter,
    birthday,
    currentPosition,
    specialtyAreas,
    user,
  } = props;

  const { dateToYuInputDateString, yuInputDateStringToDate } =
    useYuInputDateHelperFunctions();
  const countryDate = useYuInputDateCountryDate();

  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const showUserProfileSpecialtyExtraFields =
    useShowUserProfileSpecialtyExtraFields(user);

  const onRestore = () => {
    form.resetFields();
    closeForm();
  };

  const onAccept = async () => {
    form
      .validateFields()
      .then(async (values: IEditAccountData) => {
        await handleUpdate(values);
      })
      .catch((errorInfo) => {
        console.group(errorInfo);
        notification.warning({
          message: formatMessage({ id: 'form.message.validation-error' }),
        });
      });
  };

  const handleUpdate = async (data: IEditAccountData) => {
    data = {
      ...data,
      userMail: userMail,
      userMedicalCenter: userMedicalCenter,
      currentPosition,
      birthday: data.birthday
        ? yuInputDateStringToDate(data.birthday.toString())
        : null,
      specialtyAreas: specialtyAreas
        ? _.map(specialtyAreas, 'idSpecialtyArea')
        : [],
    };
    try {
      setLoading(true);
      await putDataCall({
        dataPath: apiPaths.PROFILE.UPDATE_USER_INFO,
        data: data,
        callConfig: {},
      }).then(() => {
        const userData__BASE = {
          userName: data.name + ' ' + data.surName,
          userFirstname: data.name,
          userSurname: data.surName,
          userMail: data.userMail,
          userPhone: data.userPhone,
          userMedicalCenter: data.userMedicalCenter,
          birthdate: data.birthday,
        };
        const userData__PIN = {
          ...userData__BASE,
          userDocumentID: data.userDocumentID,
        };

        if (data.birthday) updateUserBirthday(data.birthday.toString());
        updateSalesforceData(isUSAPlatform ? userData__BASE : userData__PIN);
        form.resetFields();
        closeForm();
        notification.success({
          message: formatMessage({
            id: 'page.account.update-date.success',
          }),
        });
      });
    } catch (err) {
      const message = formatMessage({
        id: 'form.message.error-update-user-profile',
      });
      const description =
        err?.response?.data?.code === '0059'
          ? err.response.data.message
          : undefined;
      notification.error({
        message,
        description,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      name="edit-account"
      layout="vertical"
      className="editAccountForm"
      scrollToFirstError
      validateMessages={{
        whitespace: formatMessage({ id: 'form.field.empty' }),
      }}
    >
      <Form.Item
        label={formatMessage({ id: 'form.field.name' })}
        name="name"
        initialValue={userFirstname}
        rules={[
          {
            required: true,
            type: 'string',
            message: getValidationMessage('form.field.name'),
          },
        ]}
        extra={
          <Row align="middle" className="input-phone-information">
            <span id="label-information">
              <InfoCircleOutlined />
              {customFormatMessage('form.field.name-info', {
                email_customer_care: config.APP.EMAIL_CUSTOMER_CARE,
              })}
            </span>
          </Row>
        }
      >
        <Input
          id="standard-required--user"
          maxLength={40}
          required
          placeholder={formatMessage({ id: 'form.field.name' })}
          disabled={true}
        />
      </Form.Item>

      <Form.Item
        label={formatMessage({ id: 'form.field.last-name' })}
        initialValue={userSurname}
        name="surName"
        rules={[
          {
            required: true,
            type: 'string',
            message: getValidationMessage('form.field.last-name'),
          },
        ]}
        extra={
          <Row align="middle" className="input-phone-information">
            <span id="label-information">
              <InfoCircleOutlined />
              {customFormatMessage('form.field.lastname-info', {
                email_customer_care: config.APP.EMAIL_CUSTOMER_CARE,
              })}
            </span>
          </Row>
        }
      >
        <Input
          id="standard-required--last-name"
          maxLength={40}
          required
          placeholder={formatMessage({ id: 'form.field.last-name' })}
          disabled={true}
        />
      </Form.Item>

      <Form.Item
        label={formatMessage({ id: 'form.field.telephone' })}
        name="userPhone"
        initialValue={userPhone}
        rules={[
          {
            required: true,
            message: getValidationMessage('form.field.telephone'),
          },
          {
            pattern: getPhoneMinLengthRegex(user.salesforce.userCountry),
            message: getValidationMessage('form.field.telephone'),
          },
        ]}
        extra={
          <Row align="middle" className="input-phone-information">
            <InfoCircleOutlined />
            <span id="label-information">
              {formatMessage({ id: 'form.field.telephone-info' })}
            </span>
          </Row>
        }
      >
        <Input
          onKeyDown={(e) => {
            if (e.key === 'Backspace') return;
            if (!/^\d*$/.test(e.key)) {
              return e.preventDefault();
            }
            return ['e', ',', '.'].includes(e.key) && e.preventDefault();
          }}
          required
          type="string"
          id="standard-required--telephone"
          maxLength={15}
          placeholder={formatMessage({ id: 'form.field.telephone' })}
        />
      </Form.Item>

      {!isUSAPlatform && SHOW_USER_DOCUMENT_ID_FIELD && (
        <Form.Item
          label={formatMessage({ id: 'form.field.user-document-id' })}
          initialValue={props?.userDocumentID}
          name="userDocumentID"
          rules={[
            {
              required: true,
              type: 'string',
              message: getValidationMessage('form.field.user-document-id'),
            },
          ]}
          extra={
            <Row align="middle" className="input-phone-information">
              <span id="label-information">
                <InfoCircleOutlined />
                {customFormatMessage('form.field.edit-user-document-id-info', {
                  email_customer_care: config.APP.EMAIL_CUSTOMER_CARE,
                })}
              </span>
            </Row>
          }
        >
          <Input
            id="standard-required--userDocumentID"
            maxLength={19}
            required
            disabled={true}
            placeholder={formatMessage({ id: 'form.field.user-document-id' })}
          />
        </Form.Item>
      )}

      {showUserProfileSpecialtyExtraFields ? (
        <Form.Item
          label={formatMessage({ id: 'form.field.birthday' })}
          name="birthday"
          initialValue={dateToYuInputDateString(birthday)}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'page.account.profile.extra-fields.birthday-required',
              }),
            },
            {
              pattern: /^\d{2}\/\d{2}\/\d{4}$/,
              message: formatMessage({
                id: 'page.account.profile.extra-fields.birthday-bad-format',
              }),
            },
          ]}
        >
          <YuInputDate
            name="birthday"
            defaultValue={dateToYuInputDateString(birthday)}
            countryDate={countryDate}
            feedback={yuFeedback(
              form.getFieldsError(['birthday'])[0].errors[0],
              'error'
            )}
            onChange={(e, value) => {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                birthday: value,
              });
            }}
          />
        </Form.Item>
      ) : null}

      <Row className="text-label input-password-message">
        {formatMessage({ id: 'form.message.password-needed' })}
      </Row>

      <Form.Item
        name="currentPassword"
        rules={[
          {
            required: true,
            type: 'string',
            message: getValidationMessage('form.field.password'),
          },
        ]}
      >
        <Input.Password
          required
          id="standard-required--password"
          placeholder={formatMessage({ id: 'form.field.password' })}
          type="password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      {ToggleButtons(onRestore, onAccept, loading)}
    </Form>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    userFirstname: state.auth.user?.userMainData?.name,
    userSurname: state.auth.user?.userMainData?.lastname,
    userMail: state.auth.user?.salesforce?.userMail,
    userPhone: state.auth.user?.salesforce?.userPhone,
    userDocumentID: state.auth.user?.salesforce?.userDocumentID,
    userMedicalCenter: state.auth.user?.salesforce?.userMedicalCenter,
    birthday: state.auth.user?.salesforce?.birthdate,
    currentPosition: state.auth.user?.currentPosition,
    specialtyAreas: state.auth.user?.specialtyAreas,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateSalesforceData, updateUserBirthday }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPersonalDataForm);
