import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_DE: IRegion[] = [
  {
    id: 'a0C5800000FiEOJEA3',
    name: 'Baden-Württemberg',
    code: '08',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOgEAN',
    name: 'Bayern',
    code: '09',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEP0EAN',
    name: 'Berlin',
    code: '11',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPVEA3',
    name: 'Brandenburg',
    code: '12',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPZEA3',
    name: 'Bremen',
    code: '04',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEVMEA3',
    name: 'Hamburg',
    code: '02',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEViEAN',
    name: 'Hessen',
    code: '06',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEamEAF',
    name: 'Mecklenburg-Vorpommern',
    code: '13',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOREA3',
    name: 'Niedersachsen',
    code: '03',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },

  {
    id: 'a0C5800000FiEfXEAV',
    name: 'Nordrhein-Westfalen',
    code: '07',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfWEAV',
    name: 'Rheinland-Pfalz',
    code: '05',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETGEA3',
    name: 'Saarland',
    code: '10',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgXEAV',
    name: 'Sachsen',
    code: '14',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgYEAV',
    name: 'Sachsen-Anhalt',
    code: '15',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEi0EAF',
    name: 'Schleswig-Holstein',
    code: '01',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkFEAV',
    name: 'Thüringen',
    code: '16',
    country: 'Deutschland',
    countryCode: 'DE',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPlEAN',
    name: 'Burgenland',
    code: 'B',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEQPEA3',
    name: 'Kärnten',
    code: 'K',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEOOEA3',
    name: 'Niederösterreich',
    code: 'NO',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEMAEA3',
    name: 'Oberösterreich',
    code: 'OO',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEggEAF',
    name: 'Salzburgo',
    code: 'S',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiETZEA3',
    name: 'Steiermark',
    code: 'ST',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEjiEAF',
    name: 'Tirol',
    code: 'T',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiElSEAV',
    name: 'Vorarlberg',
    code: 'V',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiElCEAV',
    name: 'Wien',
    code: 'W',
    country: 'Österreich',
    countryCode: 'AT',
    channel: ChannelType.INTERNATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'de-DE');
});
