import { Col, Row } from 'antd';
import { config } from 'config/config';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { getCookie, setCookieFromClient } from 'utils/cookieUtils';
import {
  Countries,
  CountryInfo,
  CountryInfoMap,
} from './platformSelector.enum';
import {
  COOKIE_NAME,
  getCountryCodeByLanguageCode,
  isSpecialCountryParamDefined,
} from './platformSelector.utils';
import getConfig from 'next/config';
import { YuIcon } from '@isdin/yuma-react-web-pin';

const { publicRuntimeConfig } = getConfig();
const { PLATFORM_PREFIX } = publicRuntimeConfig;

const PlatformSelector: FC<{
  right?: boolean;
}> = ({ right }): JSX.Element => {
  const router = useRouter();
  const { LANGUAGE_CODE } = config.APP;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<{
    long: string;
    short: string;
  }>();
  const dropdownRef = useRef(null);
  const selectorValue = useRef(null);

  const arrowPosition = () => (showDropdown ? 'up' : 'down');

  const getPlatformData = useMemo(
    () =>
      (
        platformPrefix: string
      ): {
        long: string;
        short: string;
      } => {
        let platformValues: { long: string; short: string };

        const platform = Object.entries(CountryInfoMap).find(([, entry]) =>
          entry.platform.includes(platformPrefix)
        );

        const specialCountry = Object.entries(CountryInfoMap).find(
          ([, entry]) => entry.specialCountry?.includes(platformPrefix)
        );

        if (platform) {
          const countryCode = platform[1]?.platform.toUpperCase().split('-');
          platformValues = {
            long: platform[0]?.toUpperCase(),
            short: countryCode[1],
          };
        }

        if (specialCountry) {
          const countryCode = specialCountry[1]?.specialCountry
            .toUpperCase()
            .split('=');
          platformValues = {
            long: specialCountry[0]?.toUpperCase(),
            short: countryCode[1],
          };
        }

        return platformValues;
      },
    []
  );

  const handleSelectorClick = (countryData: CountryInfo) => {
    setCookieFromClient(COOKIE_NAME, countryData.countryCode, 400);
    const countryBase = `?${COOKIE_NAME}=${countryData.countryCode}`;

    router.push(
      `/${countryData.platform}${countryData.specialCountry ?? countryBase}`
    );
  };

  useEffect(() => {
    const countryParamInUrl = isSpecialCountryParamDefined(router.asPath);

    if (countryParamInUrl) {
      setCookieFromClient(COOKIE_NAME, countryParamInUrl, 400);
      setSelectedValue(getPlatformData(countryParamInUrl));
    } else {
      setSelectedValue(getPlatformData(LANGUAGE_CODE));
      const actualCountryCode = getCountryCodeByLanguageCode(LANGUAGE_CODE);
      setCookieFromClient(COOKIE_NAME, actualCountryCode, 400);
    }
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    document.body.classList.toggle('not-overflow', showDropdown);
  }, [showDropdown]);

  useEffect(() => {
    const cookie = getCookie(COOKIE_NAME);
    const { asPath, replace, query, pathname, route } = router;

    if (pathname === '/static' || pathname === '/logout') return;

    if (!cookie) return;

    if (query[COOKIE_NAME]) return;

    if (asPath.includes(`${COOKIE_NAME}=${cookie}`)) return;

    replace(
      {
        ...router,
      },
      {
        pathname: `${PLATFORM_PREFIX || ''}${route}`,
        query: {
          ...query,
          [COOKIE_NAME]: query[COOKIE_NAME] ?? cookie,
        },
      }
    );
  }, [router.asPath]);

  return (
    <div
      className="platform-selector"
      onClick={() => setShowDropdown(!showDropdown)}
      ref={dropdownRef}
    >
      <Row className="selector-header">
        <YuIcon name="Globe" size="M"></YuIcon>
        <Col className="platform-selector-short-value" ref={selectorValue}>
          {selectedValue?.short}
        </Col>
        <Col className="platform-selector-long-value" ref={selectorValue}>
          {selectedValue?.long}
        </Col>
        <Col
          className={`icon icon--arrow-dropdown-${arrowPosition()}-big icon__size-16 dropdown-arrow`}
        ></Col>
      </Row>
      <div style={{ height: '0px', position: 'relative' }}>
        <Col
          className={`dropdown-list ${right ? 'dropdown-right' : ''} ${
            showDropdown ? '' : 'hidden'
          }`}
        >
          <Col className="options-wrapper">
            {Object.keys(Countries)
              .sort((a, b) => Countries[a].localeCompare(Countries[b]))
              .map((country) => {
                const countryName = Countries[country];
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const countryData: CountryInfo = CountryInfoMap[countryName]!;

                return (
                  <Row
                    key={countryData?.countryCode}
                    onClick={() => handleSelectorClick(countryData)}
                    className={`list-option`}
                  >
                    {countryName}
                  </Row>
                );
              })}
          </Col>
        </Col>
      </div>
    </div>
  );
};

export default PlatformSelector;
