import {
  IResourceDetailList,
  IResourceList,
} from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IChallengeResourceGuide } from 'src/shared/models';

abstract class IResourceGuideFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(
    resourceGuide: IChallengeResourceGuide,
  ): IChallengeResourceGuide;
}

class DescriptionFiller extends IResourceGuideFiller {
  fill(resourceGuide: IChallengeResourceGuide): IChallengeResourceGuide {
    return {
      ...resourceGuide,
      description: this.detail.value,
    };
  }
}

class ShowScorerFiller extends IResourceGuideFiller {
  fill(resourceGuide: IChallengeResourceGuide): IChallengeResourceGuide {
    return {
      ...resourceGuide,
      showPoints: this.detail.value === 'true',
    };
  }
}

class ChallengeResourceGuideFactory {
  private readonly resourceGuideFillers: Map<
    ResourceTypeDetailEnum,
    IResourceGuideFiller
  >;

  constructor(detail: IResourceDetailList) {
    this.resourceGuideFillers = new Map();

    this.resourceGuideFillers.set(
      ResourceTypeDetailEnum.RESOURCE_GUIDE_DESCRIPTION,
      new DescriptionFiller(detail),
    );
    this.resourceGuideFillers.set(
      ResourceTypeDetailEnum.RESOURCE_GUIDE_SCORE,
      new ShowScorerFiller(detail),
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    resourceGuide: IChallengeResourceGuide,
  ): IChallengeResourceGuide {
    const resourceGuideFiller = this.resourceGuideFillers.get(idResourceTypeD);

    if (resourceGuideFiller) {
      return resourceGuideFiller.fill(resourceGuide);
    }

    return resourceGuide;
  }
}

class ChallengeResourceGuideBuilder {
  public build(resourceList: IResourceList): IChallengeResourceGuide {
    let resourceGuide: IChallengeResourceGuide;

    resourceList.resourceDetailList
      .filter((resourceGuide) => resourceGuide.status)
      .forEach((detail) => {
        const Factory = new ChallengeResourceGuideFactory(detail);

        resourceGuide = Factory.fill(detail, resourceGuide);
      });

    return resourceGuide;
  }
}

export const buildResourceGuide = (
  resourceList: IResourceList,
): IChallengeResourceGuide => {
  const builder = new ChallengeResourceGuideBuilder();

  return builder.build(resourceList);
};
