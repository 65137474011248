import getConfig from 'next/config';
import { IUser } from 'src/shared/models';
const {
  ORDER_ID,
  CUSTOMER_CARE_CHAT_V2_EWS_CONFIG_DEV_NAME,
  CUSTOMER_CARE_CHAT_V2_SITE_URL,
  CUSTOMER_CARE_CHAT_V2_SCRT_2_URL,
} = getConfig().publicRuntimeConfig;

export interface IChatCustomerCare {
  active: boolean;
  name: string;
  deploymentID: string;
  buttonID: string;
  eswLiveAgentDevName: string;
  schedule_start: string;
  schedule_end: string;
  type: string;
}

export const injectEmbeddedService = (params: {
  targetURL: string;
  parameters?: IChatCustomerCare;
  user: IUser;
}) => {
  const { user } = params;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const embeddedservice_bootstrap = window?.embeddedservice_bootstrap;

  if (
    !embeddedservice_bootstrap ||
    embeddedservice_bootstrap?.hasSessionDataLoaded
  )
    return false;

  embeddedservice_bootstrap.settings.displayHelpButton = true; //Or false
  embeddedservice_bootstrap.settings.language = 'es'; //For example, enter 'en' or 'en-US'

  // Images
  embeddedservice_bootstrap.settings.prechatBackgroundImgURL =
    'https://love.isdin.com/assets/bundles/app/img/salesforce-Chat/PreFormBanner.jpg';
  embeddedservice_bootstrap.settings.avatarImgURL =
    'https://love.isdin.com/assets/bundles/app/img/salesforce-Chat/ChatAvatar.png';
  embeddedservice_bootstrap.settings.smallCompanyLogoImgURL =
    'https://www-staging.cdn.love.isdin.com/20220324090046/isdin_logo.png';

  embeddedservice_bootstrap.settings.defaultMinimizedText = '¿Necesitas ayuda?'; //(Defaults to Chat with an Expert)
  embeddedservice_bootstrap.settings.disabledMinimizedText = 'Chat offline'; //(Defaults to Agent Offline)
  embeddedservice_bootstrap.settings.loadingText = 'Cargando'; //(Defaults to Loading)

  //embeddedservice_bootstrap.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

  embeddedservice_bootstrap.settings.prepopulatedPrechatFields = {
    FirstName: user?.salesforce?.userFirstname,
    LastName: user?.salesforce?.userSurname,
    Email: user?.salesforce?.userMail,
  };

  embeddedservice_bootstrap.settings.extraPrechatFormDetails = [
    { label: 'Origin', value: 'Chat', displayToAgent: true },
    { label: 'Reported By', value: 'Cliente', displayToAgent: true },
    { label: 'Priority', value: '3', displayToAgent: true },
    {
      label: 'Org. Ventas',
      value: 'Org. Ventas ISDIN ES',
      displayToAgent: true,
    },
    { label: 'Canal_Venta', value: 'Online', displayToAgent: true },
    { label: 'Tienda', value: 'M\u00E9dico', displayToAgent: true },
  ];

  embeddedservice_bootstrap.settings.extraPrechatInfo = [
    {
      entityFieldMaps: [
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Origin',
          isExactMatch: true,
          label: 'Origin',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Caso_Reportado_por__c',
          isExactMatch: true,
          label: 'Reported By',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Priority',
          isExactMatch: true,
          label: 'Priority',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Org_Ventas__c',
          isExactMatch: true,
          label: 'Org. Ventas',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Canal_Venta__c',
          isExactMatch: true,
          label: 'Canal_Venta',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Tienda__c',
          isExactMatch: true,
          label: 'Tienda',
        },
      ],
      entityName: 'Case',
    },
    {
      entityFieldMaps: [
        {
          doCreate: true,
          doFind: false,
          fieldName: 'LastName',
          isExactMatch: false,
          label: 'Last Name',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'FirstName',
          isExactMatch: false,
          label: 'First Name',
        },
        {
          doCreate: true,
          doFind: true,
          fieldName: 'Email',
          isExactMatch: true,
          label: 'Email',
        },
      ],
      entityName: 'Contact',
      saveToTranscript: 'Contact',
      showOnCreate: true,
    },
  ];

  embeddedservice_bootstrap.settings.enabledFeatures = ['LiveAgent'];
  embeddedservice_bootstrap.settings.entryFeature = 'LiveAgent';

  embeddedservice_bootstrap.init(
    ORDER_ID, //orgId
    CUSTOMER_CARE_CHAT_V2_EWS_CONFIG_DEV_NAME, //ewsConfigDevName
    CUSTOMER_CARE_CHAT_V2_SITE_URL, //siteURL
    {
      scrt2URL: CUSTOMER_CARE_CHAT_V2_SCRT_2_URL,
    } // snippetConfig
  );

  return embeddedservice_bootstrap;
};
