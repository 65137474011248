import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_GT: IRegion[] = [
  {
    id: 'a0C4I00000j1iCPUAY',
    name: 'Alta Verapaz',
    code: '1',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCQUAY',
    name: 'Baja Verapaz',
    code: '2',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCRUAY',
    name: 'Chimaltenango',
    code: '3',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCSUAY',
    name: 'Chiquimula',
    code: '4',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCTUAY',
    name: 'El Progreso',
    code: '5',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCUUAY',
    name: 'Escuintla',
    code: '6',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCVUAY',
    name: 'Guatemala',
    code: '7',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCWUAY',
    name: 'Huehuetenango',
    code: '8',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCXUAY',
    name: 'Izabal',
    code: '9',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCYUAY',
    name: 'Jalapa',
    code: '10',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCZUAY',
    name: 'Jutiapa',
    code: '11',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCaUAI',
    name: 'Petén',
    code: '12',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCbUAI',
    name: 'Quetzaltenango',
    code: '13',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCcUAI',
    name: 'Quiché',
    code: '14',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCdUAI',
    name: 'Retalhuleu',
    code: '15',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCeUAI',
    name: 'Sacatepéquez',
    code: '16',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCfUAI',
    name: 'San Marcos',
    code: '17',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCgUAI',
    name: 'Santa Rosa',
    code: '18',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iChUAI',
    name: 'Sololá',
    code: '19',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCiUAI',
    name: 'Suchitepéquez',
    code: '20',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCjUAI',
    name: 'Totonicapán',
    code: '22',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000j1iCkUAI',
    name: 'Zacapa',
    code: '22',
    country: 'Guatemala',
    countryCode: 'GT',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'es-ES');
});
