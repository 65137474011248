import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { fetchProductsLimit, updateBagProduct } from 'src/actions/appActions';
import { loadingSpinner } from 'src/components';
import { IRootReducers } from 'src/reducers';
import { IBagProduct } from 'src/shared/models';
import { MyBagProductCompact } from './myBagProduct/MyBagProductCompact';
import { MyBagProductBagMode } from './myBagProduct/MyBagProductBagMode';
import { MyBagProductOneRowMode } from './myBagProduct/MyBagProductOneRowMode';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IBagProduct;

const MyBagProduct: FC<Props> = ({
  design,
  canUpdateProduct,
  cart,
  maxSaleRestrictions,
  handleRemoveProduct,
  isCheckoutProduct = false,
  isCompact = false,
  product,
  updateBagProduct,
  fetchProductsLimit,
  orderRestrictions,
}) => {
  const { maxSpecificProductByPeriod } = maxSaleRestrictions.annual;
  const { qty, name, price } = product;
  const [productLoading, setProductLoading] = useState(false);

  const isMyBagDesign = design === 'my-bag';

  const [maxQuantity, setMaxQuantity] = useState<number>(
    Number(maxSpecificProductByPeriod) ?? 999
  );

  const annualMaxQuantityProduct = Number(maxSpecificProductByPeriod) ?? 999;
  const maxProductsOrdered = annualMaxQuantityProduct === qty;

  useEffect(() => {
    fetchProductsLimit();
  }, []);

  const handleChangeQuantity = async (qty: number) => {
    if (!productLoading) {
      setProductLoading(true);
      const _product = { ...product, qty };
      const updeteBagProductResponse = await updateBagProduct(_product, cart);

      if (!updeteBagProductResponse) {
        setMaxQuantity(qty - 1);
      }
      if (qty <= 0) {
        return await handleRemoveProduct(product, setProductLoading);
      }

      cart.items.map((bagProduct) => {
        if (bagProduct.sku === product.sku) {
          return _product;
        }

        return bagProduct;
      });

      setProductLoading(false);
    }
  };

  const oneRowMode = (
    <MyBagProductOneRowMode
      {...{
        isCompact,
        product,
        maxProductsOrdered,
        handleRemoveProduct,
        name,
        orderRestrictions,
        annualMaxQuantityProduct,
        handleChangeQuantity,
        maxQuantity,
        canUpdateProduct,
        price,
        setProductLoading,
        qty,
      }}
    />
  );

  const myBagMode = (
    <MyBagProductBagMode
      {...{
        product,
        maxProductsOrdered,
        handleRemoveProduct,
        name,
        orderRestrictions,
        annualMaxQuantityProduct,
        handleChangeQuantity,
        maxQuantity,
        canUpdateProduct,
        price,
        setProductLoading,
        qty,
      }}
    />
  );

  const compactMode = (
    <MyBagProductCompact
      {...{
        isCompact,
        product,
        maxProductsOrdered,
        handleRemoveProduct,
        setProductLoading,
        annualMaxQuantityProduct,
        name,
        maxQuantity,
        isCheckoutProduct,
        canUpdateProduct,
        handleChangeQuantity,
        price,
        qty,
      }}
    />
  );

  return (
    <div className={productLoading ? 'my-bag__product--loading' : ''}>
      {productLoading && loadingSpinner}
      {productLoading && <div className="product__overlay"></div>}
      {isMyBagDesign ? (
        myBagMode
      ) : (
        <>
          {isCompact ? null : oneRowMode}
          {compactMode}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    orderRestrictions: state.auth.user.magento?.orderRestrictions,
    cart: state.app.redeemPoints.cart,
    bagProductsList: state.app.redeemPoints.cart.items,
    productsLimit: state.app.productsLimit,
    maxSaleRestrictions:
      state.auth.user.magento?.orderRestrictions.salesRestrictions
        .maxSaleRestrictions,
    redeemedProductsByTime:
      state.auth.user.magento?.orderRestrictions.redeemedProductsByTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateBagProduct,
      fetchProductsLimit,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyBagProduct);
