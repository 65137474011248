import { Col, Drawer, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import {
  fetchProductsLimit,
  removeBagProduct,
  showBagMenu,
} from 'src/actions/appActions';
import { IRootReducers } from 'src/reducers';
import { IRedeemPointsProduct } from 'src/shared/models';
import { config } from 'config/config';

import {
  MyBagMenu__Content,
  MyBagMenu__Footer,
  MyBagMenu__Header,
} from '../../../utils';
import routePaths from 'config/routes';
import {
  YuBanner,
  YuButton,
  YuFlex,
  YuParagraph,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import redirectTo from '../../../services/redirectTo';
import { EmptyBasket } from './checkout/EmptyBasket';
import { addToast } from '../../actions/toastActions';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const MyBagMenu: FC<Props> = ({
  redeemPoints,
  fetchProductsLimit,
  removeBagProduct,
  showBagMenu,
  addToast,
  maxSaleRestrictions,
  redeemedProductsByTime,
}) => {
  useEffect(() => {
    fetchProductsLimit();
  }, []);
  const [outStockWarning, setOutStockWarning] = useState(null);
  const userTotalProducts = redeemedProductsByTime?.annual;
  const maxTotalProductsByPeriod =
    maxSaleRestrictions?.annual?.maxTotalProductsByPeriod;
  const isAnnualLimitExceded =
    Number(maxTotalProductsByPeriod) <= Number(userTotalProducts);
  const {
    showSiderMenu,
    cart,
    cart: { items: bagProducts },
  } = redeemPoints;
  const { formatMessage } = useIntl();
  const handleRemoveProduct = async (
    product: IRedeemPointsProduct,
    setProductLoading?: (boolean: boolean) => void
  ) => {
    setProductLoading(true);
    try {
      await removeBagProduct(product, cart);
    } catch (err) {
      addToast({
        message: formatMessage({ id: 'my-bag.remove.error' }),
        status: 'error',
      });
    }
    setProductLoading(false);
  };

  const onRemoveProductsByOutStockModal = () => {
    setOutStockWarning(Date.now());
  };

  React.useEffect(() => {
    setOutStockWarning(null);
  }, [showSiderMenu]);

  return (
    <Drawer
      className="my-bag-preview"
      placement="right"
      closable={false}
      onClose={() => showBagMenu(false)}
      visible={showSiderMenu}
      width={480}
    >
      <Row justify="space-between" className="my-bag-preview__row">
        <Col span={24}>
          <MyBagMenu__Header
            bagProducts={bagProducts}
            showBagMenu={showBagMenu}
          />
          {bagProducts.length === 0 && (
            <>
              {outStockWarning ? (
                <YuFlex flexDirection="column">
                  <YuSpacer size="S" />
                  <YuBanner
                    key={outStockWarning}
                    icon
                    removable
                    status="warning"
                  >
                    <FormattedMessage id="my-bag.banner.cartUpdatedByNoStock" />
                  </YuBanner>
                </YuFlex>
              ) : null}
              {isAnnualLimitExceded ? (
                <div className="my-bag-no-items">
                  <div className="my-bag-no-items__content">
                    <img
                      className="my-bag__icon"
                      src={`${config.APP.ASSET_PREFIX}/bagIcon.png`}
                    ></img>
                    <YuTitle
                      // TODO Yuma error uncommment when fixed tag="h3"
                      className="my-bag__no-items-title"
                      size="M"
                      center
                    >
                      <FormattedMessage
                        id={`page.redeem-points.my-bag.no-products-title${
                          isAnnualLimitExceded ? '.max-annual-products' : ''
                        }`}
                      />
                    </YuTitle>
                    <YuSpacer size="M" />
                    <YuParagraph
                      className={`my-bag__no-items${
                        isAnnualLimitExceded ? '__max-products' : ''
                      }`}
                      center
                      size="M"
                    >
                      {isAnnualLimitExceded ? (
                        formatMessage(
                          {
                            id: 'page.redeem-points.modal.max-product.title-{maxProducts}',
                          },
                          {
                            maxProducts: maxTotalProductsByPeriod,
                            units: formatMessage({
                              id:
                                Number(maxTotalProductsByPeriod) > 1
                                  ? 'page.redeem-points.errors.units'
                                  : 'page.redeem-points.errors.unit',
                            }),
                          }
                        )
                      ) : (
                        <FormattedMessage id="page.redeem-points.my-bag.no-products-text" />
                      )}
                    </YuParagraph>
                  </div>
                  <div>
                    <YuSpacer size="M" />
                    <YuButton
                      onClick={() => {
                        showBagMenu(false);
                        redirectTo(
                          isAnnualLimitExceded
                            ? routePaths.INDEX
                            : routePaths.PAGES.REDEEM_POINTS.MAIN
                        );
                      }}
                    >
                      <FormattedMessage
                        id={`page.redeem-points.my-bag.no-products-button${
                          isAnnualLimitExceded ? '.max-annual-products' : ''
                        }`}
                      />
                    </YuButton>
                  </div>
                </div>
              ) : (
                <div className="my-bag-no-items">
                  <div className="my-bag-no-items__content">
                    <EmptyBasket hideBottomSpacer={true} hideTopSpacer={true} />
                  </div>
                </div>
              )}
            </>
          )}
          {bagProducts.length > 0 && (
            <>
              {outStockWarning ? (
                <YuFlex flexDirection="column">
                  <YuSpacer size="S" />
                  <YuBanner
                    key={outStockWarning}
                    icon
                    removable
                    status="warning"
                  >
                    <FormattedMessage id="my-bag.banner.cartUpdatedByNoStock" />
                  </YuBanner>
                </YuFlex>
              ) : null}
              <MyBagMenu__Content
                {...{ handleRemoveProduct, bagProducts }}
                isCompact
                canUpdateProduct
                modalVisible={showSiderMenu || false}
              />
            </>
          )}
        </Col>
        {bagProducts.length > 0 && (
          <MyBagMenu__Footer
            isDrawer={true}
            buttonText="page.redeem-points.my-bag.view-bag"
            cart={cart}
            onClickButton={() => showBagMenu(false)}
            canRedeem={bagProducts.length > 0}
            onRemoveProductsByOutStockModal={onRemoveProductsByOutStockModal}
          />
        )}
      </Row>
    </Drawer>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    redeemPoints: state.app.redeemPoints,
    maxSaleRestrictions:
      state.auth.user.magento?.orderRestrictions.salesRestrictions
        .maxSaleRestrictions,
    redeemedProductsByTime:
      state.auth.user.magento?.orderRestrictions.redeemedProductsByTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      showBagMenu,
      addToast,
      removeBagProduct,
      fetchProductsLimit,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyBagMenu);
