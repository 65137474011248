import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import ConditionalRenderer from '../../ConditionalRenderer';

const SkeletonCheckout: NextPage = (): JSX.Element => {
  const { upM, upXS } = useSize();

  const SkeletonCheckoutDesktop = () => {
    return (
      <YuSkeletonWrapper width="100%">
        <YuSkeletonWrapper
          minWidth="400px"
          width="100%"
          height="112px"
          backgroundColor="bg.grey"
        >
          <YuFlex justify={'center'} style={{ margin: 'auto' }}>
            <YuSkeleton height={57} variant="text" width={'280px'} />
          </YuFlex>
        </YuSkeletonWrapper>
        <YuSpacer size="L" />
        <YuSkeletonWrapper
          flexDirection="row"
          width="100%"
          maxWidth={'1150px'}
          style={{ margin: '0 auto' }}
        >
          <YuFlex
            flexDirection="column"
            width={'465px'}
            justifyContent="start"
            marginLeft={'XS'}
          >
            <YuSkeleton height={29} variant="text" width={'200px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="L" />
            <YuFlex flexDirection="row">
              <YuSkeleton height={24} variant="text" width={'140px'} />
              <YuSkeleton
                height={24}
                variant="text"
                width={'51px'}
                marginLeft={'L'}
              />
            </YuFlex>
            <YuSpacer size="M" />
            <YuFlex flexDirection="row" width={'465px'}>
              <YuSkeleton
                height={24}
                variant="circular"
                width={'24px'}
                marginRight={'S'}
              />
              <YuFlex flexDirection="column">
                <YuSkeleton height={24} variant="text" width={'140px'} />
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} variant="text" width={'140px'} />
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} variant="text" width={'140px'} />
              </YuFlex>
              <YuFlex flexDirection="row" width={'50%'} justify={'end'}>
                <YuSkeleton
                  height={25}
                  variant="text"
                  width={'70px'}
                  marginTop={'XS'}
                />
              </YuFlex>
            </YuFlex>
            <YuSpacer size="L" />
            <YuSkeleton height={24} variant="text" width={'200px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={22} variant="text" width={'100px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
          </YuFlex>
          <YuFlex
            flexDirection="row"
            justifyContent="end"
            width={'100%'}
            marginRight={'XS'}
          >
            <YuFlex flexDirection="column" width={'465px'}>
              <YuSkeletonWrapper
                minWidth="400px"
                width={'465px'}
                height={'48px'}
                backgroundColor="bg.grey"
              >
                <YuSkeleton
                  height={24}
                  variant="text"
                  width={'100px'}
                  style={{ margin: '12px 20px' }}
                />
              </YuSkeletonWrapper>
              <YuSpacer size="L" />
              <YuFlex flexDirection="row" marginTop={'XS'}>
                <YuSkeleton
                  height={95}
                  variant="text"
                  width={'95px'}
                  marginLeft={'XS'}
                  marginRight={'XXS'}
                />
                <YuFlex
                  flexDirection="column"
                  width={'284px'}
                  marginLeft={'XS'}
                >
                  <YuSpacer size="XXS" />
                  <YuSkeleton height={24} variant="text" width={'220px'} />
                  <YuSpacer size="XXS" />
                  <YuSkeleton height={24} variant="text" width={'100px'} />
                  <YuSpacer size="XS" />
                  <YuFlex flexDirection="row">
                    <YuFlex width={'60%'}>
                      <YuSkeleton height={16} variant="text" width={'16px'} />
                    </YuFlex>
                    <YuFlex width={'40%'}>
                      <YuSkeleton height={16} variant="text" width={'80px'} />
                    </YuFlex>
                  </YuFlex>
                </YuFlex>
                <YuSkeleton
                  height={20}
                  variant="text"
                  width={'20px'}
                  marginTop={'XXS'}
                />
              </YuFlex>
              <YuSpacer size="S" />
              <YuSpacer size="XXXS" />
              <YuSkeleton height={1} variant="text" width={'100%'} />
              <YuSpacer size="M" />
              <YuSkeleton height={24} variant="text" width={'220px'} />
              <YuSpacer size="XS" />
              <YuSkeleton height={54} variant="text" width={'100%'} />
              <YuSpacer size="M" />
              <YuFlex flexDirection="row">
                <YuSkeleton height={22} variant="text" width={'57px'} />
                <YuFlex flexDirection="row" width={'100%'} justify={'end'}>
                  <YuSkeleton height={22} variant="text" width={'107px'} />
                </YuFlex>
              </YuFlex>
              <YuSpacer size="M" />
              <YuSkeleton height={49} variant="text" width={'100%'} />
            </YuFlex>
          </YuFlex>
        </YuSkeletonWrapper>
      </YuSkeletonWrapper>
    );
  };
  const SkeletonCheckoutTablet = () => {
    return (
      <YuSkeletonWrapper width="100%">
        <YuSkeletonWrapper
          minWidth="400px"
          width="100%"
          height="112px"
          backgroundColor="bg.grey"
        >
          <YuFlex justify={'center'} style={{ margin: 'auto' }}>
            <YuSkeleton height={57} variant="text" width={'280px'} />
          </YuFlex>
        </YuSkeletonWrapper>
        <YuSpacer size="L" />
        <YuSkeletonWrapper
          flexDirection="row"
          justify={'center'}
          width="100%"
          maxWidth={'1150px'}
        >
          <YuFlex flexDirection="column" maxWidth={'514'}>
            <YuSkeleton height={29} variant="text" width={'200px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="XL" />
            <YuFlex flexDirection="row">
              <YuSkeleton height={24} variant="text" width={'140px'} />
              <YuSkeleton
                height={24}
                variant="text"
                width={'51px'}
                marginLeft={'L'}
              />
            </YuFlex>
            <YuSpacer size="M" />
            <YuFlex flexDirection="row" width={'514px'}>
              <YuSkeleton
                height={24}
                variant="circular"
                width={'24px'}
                marginRight={'S'}
              />
              <YuFlex flexDirection="column">
                <YuSkeleton height={24} variant="text" width={'140px'} />
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} variant="text" width={'140px'} />
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} variant="text" width={'140px'} />
              </YuFlex>
              <YuFlex flexDirection="row" width={'65%'} justify={'end'}>
                <YuSkeleton
                  height={25}
                  variant="text"
                  width={'70px'}
                  marginTop={'XS'}
                  marginRight={'S'}
                />
              </YuFlex>
            </YuFlex>
            <YuSpacer size="XL" />
            <YuSkeleton height={24} variant="text" width={'200px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={22} variant="text" width={'100px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XL" />
            <YuSkeletonWrapper
              minWidth="400px"
              width={'100%'}
              height={'48px'}
              backgroundColor="bg.grey"
            >
              <YuFlex flexDirection="row" width={'514px'}>
                <YuSkeleton
                  height={24}
                  variant="text"
                  width={'100px'}
                  style={{ margin: '12px 20px' }}
                />
                <YuFlex flexDirection="row" width={'100%'} justify={'end'}>
                  <YuSkeleton
                    height={20}
                    variant="text"
                    width={'38px'}
                    style={{ margin: '14px 20px' }}
                  />
                </YuFlex>
              </YuFlex>
            </YuSkeletonWrapper>
            <YuSpacer size="XL" />
            <YuFlex flexDirection="row" marginTop={'XS'}>
              <YuSkeleton
                height={135}
                variant="text"
                width={'95px'}
                marginLeft={'XS'}
                marginRight={'XXS'}
              />
              <YuFlex flexDirection="column" width={'70%'} marginLeft={'XS'}>
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} variant="text" width={'300px'} />
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} variant="text" width={'200px'} />
                <YuSpacer size="XS" />
                <YuFlex flexDirection="row">
                  <YuFlex width={'60%'}>
                    <YuSkeleton height={16} variant="text" width={'16px'} />
                  </YuFlex>
                  <YuFlex width={'40%'}>
                    <YuSkeleton height={16} variant="text" width={'80px'} />
                  </YuFlex>
                </YuFlex>
              </YuFlex>
              <YuSkeleton
                height={20}
                variant="text"
                width={'20px'}
                marginTop={'XXS'}
              />
            </YuFlex>
            <YuSpacer size="S" />
            <YuSpacer size="XXXS" />
            <YuSkeleton height={1} variant="text" width={'100%'} />
            <YuSpacer size="M" />
            <YuSkeleton
              height={24}
              variant="text"
              marginLeft={'S'}
              width={'220px'}
            />
            <YuSpacer size="XS" />
            <YuSkeleton
              height={54}
              variant="text"
              width={'90%'}
              style={{ margin: 'auto' }}
            />
            <YuSpacer size="L" />
            <YuFlex
              flexDirection="row"
              width={'90%'}
              style={{ margin: 'auto' }}
            >
              <YuSkeleton height={22} variant="text" width={'57px'} />
              <YuFlex flexDirection="row" width={'100%'} justify={'end'}>
                <YuSkeleton height={22} variant="text" width={'107px'} />
              </YuFlex>
            </YuFlex>
            <YuSpacer size="L" />
            <YuSkeleton
              height={49}
              variant="text"
              style={{ margin: 'auto' }}
              width={'90%'}
            />
          </YuFlex>
        </YuSkeletonWrapper>
      </YuSkeletonWrapper>
    );
  };
  const SkeletonCheckoutMobile = () => {
    return (
      <YuSkeletonWrapper width="100%">
        <YuSkeletonWrapper
          width="100%"
          height="112px"
          backgroundColor="bg.grey"
        >
          <YuFlex justify={'center'} style={{ margin: 'auto' }}>
            <YuSkeleton height={57} variant="text" width={'320px'} />
          </YuFlex>
        </YuSkeletonWrapper>
        <YuSpacer size="L" />
        <YuSkeletonWrapper
          flexDirection="row"
          justify={'center'}
          width="100%"
          marginLeft={'XS'}
          marginRight={'XS'}
          maxWidth={'1150px'}
        >
          <YuFlex flexDirection="column" width={'100%'}>
            <YuSkeleton height={29} variant="text" width={'200px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={22} variant="text" width={'160px'} />
            <YuSpacer size="XL" />
            <YuFlex flexDirection="row">
              <YuSkeleton height={24} variant="text" width={'140px'} />
              <YuSkeleton
                height={24}
                variant="text"
                width={'51px'}
                marginLeft={'L'}
              />
            </YuFlex>
            <YuSpacer size="M" />
            <YuFlex flexDirection="row" width={'100%'}>
              <YuSkeleton
                height={24}
                variant="circular"
                width={'24px'}
                marginRight={'S'}
              />
              <YuFlex flexDirection="row" width={'80%'}>
                <YuFlex flexDirection="column">
                  <YuSkeleton height={24} variant="text" width={'140px'} />
                  <YuSpacer size="XXS" />
                  <YuSkeleton height={24} variant="text" width={'140px'} />
                  <YuSpacer size="XXS" />
                  <YuSkeleton height={24} variant="text" width={'140px'} />
                </YuFlex>
                <YuFlex flexDirection="row" width={'60%'} justify={'end'}>
                  <YuSkeleton
                    height={25}
                    variant="text"
                    width={'70px'}
                    marginTop={'XS'}
                    marginRight={'S'}
                  />
                </YuFlex>
              </YuFlex>
            </YuFlex>
            <YuSpacer size="XL" />
            <YuSkeleton height={24} variant="text" width={'200px'} />
            <YuSpacer size="S" />
            <YuSkeleton height={22} variant="text" width={'100px'} />
            <YuSpacer size="XXS" />
            <YuSkeleton height={20} variant="text" width={'120px'} />
            <YuSpacer size="L" />
            <YuSkeletonWrapper
              width={'90%'}
              height={'48px'}
              backgroundColor="bg.grey"
            >
              <YuFlex flexDirection="row">
                <YuSkeleton
                  height={24}
                  variant="text"
                  width={'100px'}
                  style={{ margin: '12px 20px' }}
                />
                <YuFlex flexDirection="row" width={'80%'} justify={'end'}>
                  <YuSkeleton
                    height={20}
                    variant="text"
                    width={'38px'}
                    style={{ margin: '14px 20px' }}
                  />
                </YuFlex>
              </YuFlex>
            </YuSkeletonWrapper>
            <YuSpacer size="XL" />
            <YuFlex flexDirection="row" marginTop={'XS'}>
              <YuSkeleton
                height={135}
                variant="text"
                width={'95px'}
                marginLeft={'XS'}
                marginRight={'XXS'}
              />
              <YuFlex flexDirection="column" width={'50%'} marginLeft={'XS'}>
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} variant="text" width={'90%'} />
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} variant="text" width={'40%'} />
                <YuSpacer size="XS" />
                <YuFlex flexDirection="row">
                  <YuFlex width={'60%'}>
                    <YuSkeleton height={16} variant="text" width={'16px'} />
                  </YuFlex>
                  <YuFlex width={'40%'}>
                    <YuSkeleton height={16} variant="text" width={'80px'} />
                  </YuFlex>
                </YuFlex>
              </YuFlex>
              <YuSkeleton
                height={20}
                variant="text"
                width={'20px'}
                marginTop={'XXS'}
              />
            </YuFlex>
            <YuSpacer size="S" />
            <YuSpacer size="XXXS" />
            <YuSkeleton
              height={1}
              variant="text"
              width={'100%'}
              style={{ marginLeft: '-14px' }}
            />
            <YuSpacer size="M" />
            <YuSkeleton
              height={24}
              variant="text"
              marginLeft={'S'}
              width={'220px'}
            />
            <YuSpacer size="XS" />
            <YuSkeleton
              height={54}
              variant="text"
              width={'85%'}
              marginLeft={'S'}
            />
            <YuSpacer size="L" />
            <YuFlex
              flexDirection="row"
              width={'80%'}
              style={{ margin: 'auto' }}
            >
              <YuSkeleton height={22} variant="text" width={'57px'} />
              <YuFlex
                flexDirection="row"
                width={'100%'}
                justify={'end'}
                marginRight={'S'}
              >
                <YuSkeleton height={22} variant="text" width={'107px'} />
              </YuFlex>
            </YuFlex>
            <YuSpacer size="L" />
            <YuSkeleton
              height={49}
              variant="text"
              marginLeft={'S'}
              width={'85%'}
            />
          </YuFlex>
        </YuSkeletonWrapper>
      </YuSkeletonWrapper>
    );
  };
  return (
    <ConditionalRenderer
      condition={upM}
      trueComponent={<SkeletonCheckoutDesktop />}
      falseComponent={
        <ConditionalRenderer
          condition={upXS}
          trueComponent={<SkeletonCheckoutTablet />}
          falseComponent={<SkeletonCheckoutMobile />}
        />
      }
    />
  );
};

export default SkeletonCheckout;
