import routePaths from 'config/routes';
import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootReducers } from 'src/reducers';
import { IMenu } from 'src/shared/models';
import { getFooter } from '../menu/Menu.utils';
import _ from 'lodash';
import Link from '../Link';
import {
  YuFlex,
  YuFooterLegal,
  YuLogoIsdin,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { PLATFORM_PREFIX } = publicRuntimeConfig;

type Props = ReturnType<typeof mapStateToProps>;

const BasicFooter_Copyright: NextPage<Props, unknown> = ({
  authenticated,
  publicMenu,
  privateMenu,
}) => {
  const [menu, setMenu] = useState<IMenu[]>(
    getFooter(authenticated, publicMenu, privateMenu, true)
  );

  useEffect(() => {
    setMenu(getFooter(authenticated, publicMenu, privateMenu, true));
  }, [authenticated, publicMenu, privateMenu]);

  return (
    <>
      <YuSpacer size="M" />
      <YuFlex align="center" justify="center">
        <Link href={routePaths.INDEX}>
          <YuLogoIsdin variant="logotype" />
        </Link>
      </YuFlex>
      <YuSpacer size="M" />
      <YuFooterLegal
        copyright="Copyright © ISDIN All rights reserved."
        countries={[]}
        links={_.map(menu[0]?.subMenu, (item) => {
          return {
            href: item.href,
            nextAs: `${PLATFORM_PREFIX}${item.href}`,
            name: item.name,
            targetBlank: item.href.startsWith('http'),
          };
        })}
        onChangeCountry={_.noop}
      />
    </>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    authenticated:
      state.auth.accessToken !== null && state.auth.accessToken !== '',
    publicMenu: state.app.publicMenu,
    privateMenu: state.app.userMenu,
    isLoggingIn: state.app.isLoggingIn,
  };
};

export default connect(mapStateToProps, null)(BasicFooter_Copyright);
