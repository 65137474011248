import {
  CampaignModuleStatusEnum,
  PearlStatusEnum,
  SellOutStatusEnum,
  UserResourceEnum,
  UserResourceUploadMediaStatusEnum,
} from 'src/shared/enums';
import { IChallengeResourceConfig } from 'src/shared/models';

export interface IUserUploadMedia extends IChallengeResourceConfig {
  description: string;
  buttonTitle: string | JSX.Element;
  allowedExtensions: string[];
  linkStaticPage?: string;
  idResource: number;
  idChallenge?: number;
  mediaData: IUserUploadMediaData;
  endDate?: Date;
  maxImagesAllowed?: number;
}

export interface IUserUploadMediaData {
  fileName: string;
  idUserResource: number;
  isFirstTime: boolean;
  isUploaded: boolean;
  size: string;
  type: MediaTypeEnum;
  url: string;
  statusResource:
    | UserResourceEnum
    | CampaignModuleStatusEnum
    | UserResourceUploadMediaStatusEnum
    | SellOutStatusEnum
    | PearlStatusEnum;
}

export enum MediaTypeEnum {
  IMAGE = 1,
  VIDEO = 2,
  URL = 3,
}
