import { YuBanner } from '@isdin/yuma-react-web-pin';
import { useIntl } from 'react-intl';
import { IRootReducers } from '../../reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { privateRoute } from '../../../services/privateRoute';
import React from 'react';

interface OwnProps {
  remaining: number;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const _BannerReferenceLimitedInfo = ({
  remaining,
  orderRestrictions,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  const max = parseInt(
    orderRestrictions?.salesRestrictions?.maxSaleRestrictions?.annual
      ?.maxSpecificProductByPeriod || '0'
  );

  if (!max) return null;

  return (
    <YuBanner icon status="info">
      {formatMessage(
        {
          id: `components.banner-reference-limited-info.${
            remaining > 1 ? 'plural' : 'singular'
          }-${max > 1 ? 'plural' : 'singular'}`,
        },
        {
          remaining,
          max,
        }
      )}
    </YuBanner>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    orderRestrictions: state.auth.user.magento?.orderRestrictions,
    bagProducts: state.app.redeemPoints.cart.items,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export const BannerReferenceLimitedInfo = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(
  privateRoute({
    WrappedComponent: _BannerReferenceLimitedInfo,
  })
);
