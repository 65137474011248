export enum ContentPageEnum {
  HOME = 1,
  STATIC_PAGE = 2,
  STATIC_MEDIA = 3,
}

export enum PageComponentEnum {
  TEXT = 1,
  CAROUSEL = 2,
  BANNER = 3,
  LINKED_IMAGE = 4,
  TRAINING = 5,
  WYS = 6,
  REDEEM_POINTS = 7,
  FAQ = 13,
  FORMATION = 91,
  TALK = 92,
  CAMPAIGN = 93,
}

export enum PageDetailEnum {
  IMAGE = 1,
  VIDEO = 2,
  TEXT = 3,
  LINK = 4,
}

export enum PageDetailLinkedImage {
  FULL = 0,
  LEFT = 1,
  RIGHT = 2,
}

export enum ReedemProductsSortBy {
  NAME = 'Name',
  PRICE = 'Price',
}
