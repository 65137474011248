import React from 'react';
import { useSize, YuLink } from '@isdin/yuma-react-web-pin';
import { IRootReducers } from '../../../reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { addToast } from '../../../actions/toastActions';
import { useIntl } from 'react-intl';
import routePaths from '../../../../config/routes';
import { config } from '../../../../config/config';

const { BASE_URL } = config.APP;

interface BasicHeaderBagContextProps extends BasicHeaderBagContextPropsSet {
  setBasicHeaderBagVisible?: (visible: boolean) => void;
  onProductBuy?: () => void;
}

interface BasicHeaderBagContextPropsSet {
  visible: boolean;
}

export const BasicHeaderBagContext =
  React.createContext<BasicHeaderBagContextProps>({
    visible: false,
  });
BasicHeaderBagContext.displayName = 'BasicHeaderBagContext';

export const _BasicHeaderBagContextProvider = ({ children, addToast }) => {
  const { upM } = useSize();
  const { formatMessage } = useIntl();
  const [state, setState] = React.useState<BasicHeaderBagContextProps>();

  const setBasicHeaderBagVisible = (visible: boolean) => {
    setState({
      visible,
    });
  };

  const onProductBuy = () => {
    if (upM) {
      setState({
        visible: true,
      });
    } else {
      addToast({
        message: (
          <div style={{ whiteSpace: 'nowrap' }}>
            {formatMessage({ id: 'header.bag.product-added' })}{' '}
            <YuLink
              size="M"
              onClick={() => setBasicHeaderBagVisible(false)}
              href={routePaths.PAGES.REDEEM_POINTS.MY_BAG}
              nextAs={BASE_URL + routePaths.PAGES.REDEEM_POINTS.MY_BAG}
            >
              {formatMessage({ id: 'header.bag.view-bag' })}
            </YuLink>
          </div>
        ),
        status: 'success',
      });
    }
  };

  return (
    <BasicHeaderBagContext.Provider
      value={{ ...state, setBasicHeaderBagVisible, onProductBuy }}
    >
      {children}
    </BasicHeaderBagContext.Provider>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ addToast }, dispatch);

export const BasicHeaderBagContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(_BasicHeaderBagContextProvider);
