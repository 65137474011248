import { Row } from 'antd';
import React, { FC } from 'react';
import { IChallengeResourceConfig } from '../../shared/models';
import Product from './Product';
import { IShoppingGroup } from './ShoppingCart.utils';

interface IProps {
  config: IChallengeResourceConfig;
  group: IShoppingGroup;
  handleChangeQuantity: (idProduct: number, quantity: number) => void;
  showPrices: boolean;
}

const ShoppingGroup: FC<IProps> = ({
  config,
  group,
  handleChangeQuantity,
  showPrices,
}) => {
  const { name, products } = group;

  const header = (
    <Row justify="center" className="shopping-products__title">
      {name}
    </Row>
  );

  if (!products || products.length === 0) {
    return null;
  }

  return (
    <div className="shopping-products-container">
      <>
        {header}
        {products?.map((product, index) => (
          <Product
            {...{
              config,
              key: index,
              handleChangeQuantity,
              product,
              showPrices,
            }}
          />
        ))}
      </>
    </div>
  );
};

export default ShoppingGroup;
