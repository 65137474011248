import yuma, {
  useSize,
  YuFlex,
  YuIcon,
  YuParagraph,
} from '@isdin/yuma-react-web-pin';
import React, { CSSProperties, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IOrderItem, IRedeemPointsProduct } from '../../../shared/models';
import { config } from '../../../../config/config';

interface OwnProps {
  item?: IOrderItem | IRedeemPointsProduct;
  mode?: 'purchased' | 'out-stock';
}

const productImgContainerStyleUpXS: CSSProperties = {
  width: '74px',
  height: '92px',
  position: 'relative',
};

const productImgContainerStyleUpM: CSSProperties = {
  width: '80px',
  height: '100px',
  position: 'relative',
};

export const OrderItem: FC<OwnProps> = ({
  item,
  mode = 'purchased',
}): JSX.Element => {
  const { formatNumber } = useIntl();
  const { upM } = useSize();

  return (
    <YuFlex width="100%" gap="XS" justifyContent="space-between">
      <YuFlex gap="XS">
        <YuFlex flexDirection="column">
          <YuFlex
            style={
              upM ? productImgContainerStyleUpM : productImgContainerStyleUpXS
            }
          >
            <img
              loading="lazy"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={
                item.image
                  ? item.image
                  : `${config.APP.ASSET_PREFIX}/material_default_bg.png`
              }
              alt="Product image"
            />
          </YuFlex>
        </YuFlex>
        <YuFlex flexDirection="column" width={166} justify="space-between">
          <YuFlex flexDirection="column">
            <YuParagraph size={{ L: 'L', XS: 'M' }} fw="sbold" lineClamp={2}>
              {item.name}
            </YuParagraph>
            {mode === 'purchased' ? (
              <YuParagraph size="M" lineClamp={1}>
                <FormattedMessage
                  id="page.account.order-details.SKU"
                  values={{ number: item.sku }}
                />
              </YuParagraph>
            ) : null}

            <YuParagraph size={{ L: 'M', XS: 'S' }}>
              <FormattedMessage
                id="common.properties.quantity"
                values={{
                  quantity: formatNumber(
                    item.quantity || (item as IRedeemPointsProduct).qty
                  ),
                }}
              />
            </YuParagraph>

            {!upM && mode === 'out-stock' ? (
              <YuParagraph size={upM ? 'XL' : 'L'} fw="bold">
                <FormattedMessage
                  id="page.account.order-details.points"
                  values={{ points: formatNumber(item.points) || 0 }}
                />
              </YuParagraph>
            ) : null}
          </YuFlex>
          {mode === 'out-stock' ? (
            <YuFlex gap="XXS" align="center">
              <YuIcon
                color={yuma.token.color.feedback.error}
                size="S"
                name="CrossCircle"
              />
              <YuParagraph
                color={yuma.token.color.feedback.error}
                size="M"
                fw="sbold"
              >
                <FormattedMessage id="products-out-stock.modal.sold-out.single" />
              </YuParagraph>
            </YuFlex>
          ) : null}
        </YuFlex>
      </YuFlex>
      {upM || mode !== 'out-stock' ? (
        <YuParagraph
          size={upM ? 'XL' : 'L'}
          style={{ textAlign: 'right' }}
          fw="bold"
        >
          <FormattedMessage
            id="page.account.order-details.points"
            values={{ points: formatNumber(item.points) || 0 }}
          />
        </YuParagraph>
      ) : null}
    </YuFlex>
  );
};
