import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { config } from 'config/config';
import { FormattedMessage } from 'react-intl';
import { YuIcon } from '@isdin/yuma-react-web-pin';
import _ from 'lodash';

export type CampaignLabelStatus = 'completed' | 'error' | 'plain';

interface OwnProps {
  isDescriptionDangerouslySetInnerHTML?: boolean;
  bottomLabel?: {
    label: string;
    style: CampaignLabelStatus | string;
  };
  children: JSX.Element;
  description?: string;
  icon?: string;
  id?: string;
  isBlocked?: boolean;
  points?: string; // * TODO: Set icon as prop of every "resource" in "IChallengeResourceConfig"
  styleClass?: string;
  title: string;
  iconWithBackground?: boolean;
}

const ChallengeResourceWrapper: FC<OwnProps> = ({
  bottomLabel,
  children,
  description,
  icon,
  iconWithBackground,
  id,
  isDescriptionDangerouslySetInnerHTML,
  points,
  styleClass,
  title,
}) => {
  const yumaIcon = React.useMemo(() => {
    if (_.isString(icon) && icon?.startsWith('yuma_')) {
      return icon.replace('yuma_', '');
    }
    return null;
  }, [icon]);
  const checkPointsGtLimit = (points: number | string): boolean => {
    if (isNaN(Number(points))) {
      const pointsRegex = /\+(\d+)/;
      const match = (points as string).match(pointsRegex);
      const parsedPoints = match ? parseInt(match[1]) : 0;
      return Number(parsedPoints) >= config.APP.SHOW_POINTS_LIMIT;
    }
    return Number(points) >= config.APP.SHOW_POINTS_LIMIT;
  };

  return (
    <>
      <Row
        align="top"
        className={`challengeResourceWrapper ${styleClass} `}
        id={id}
        justify="center"
      >
        <div className="challengeResourceWrapper__blocked-overlay" />

        <Col className="challengeResourceWrapper__container">
          {icon && (
            <Row justify="center" className="challengeResourceWrapper__icon">
              {yumaIcon ? (
                <YuIcon size="XL" name={yumaIcon as never} />
              ) : (
                <>
                  {iconWithBackground ? (
                    <div className="challengeResourceWrapper__icon-container">
                      <div className={`icon icon--${icon}`} />
                    </div>
                  ) : (
                    <div className={`icon icon--${icon}`} />
                  )}
                </>
              )}
            </Row>
          )}
          {points && checkPointsGtLimit(points) && (
            <Row
              justify="center"
              align="middle"
              className="challengeResourceWrapper__points"
            >
              <div className="icon icon--puntos" />
              {points}
            </Row>
          )}
          {title && (
            <Row
              justify="center"
              className={`challengeResourceWrapper__title ${
                icon === 'survey'
                  ? 'challengeResourceWrapper__title-survey'
                  : ''
              }`}
            >
              {title}
            </Row>
          )}
          {description && (
            <Row
              className={`challengeResourceWrapper__description ${
                icon === 'survey'
                  ? 'challengeResourceWrapper__description-survey'
                  : ''
              }`}
              justify="center"
            >
              {isDescriptionDangerouslySetInnerHTML ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              ) : (
                description
              )}
            </Row>
          )}
          {children}
          {bottomLabel && (
            <Row justify="center" align="middle">
              <div
                className={`challengeResourceWrapper__label challengeResourceWrapper__label--${bottomLabel.style}`}
              >
                <FormattedMessage id={bottomLabel.label} />
              </div>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ChallengeResourceWrapper;

export const ChallengeResourceWrapper_Blocked: FC<unknown> = () => {
  return (
    <Row
      align="middle"
      justify="center"
      className="challengeResourceWrapper__bloqued-icon"
      children={
        <img className="icon icon--padlock--blocked-module icon__size-120" />
      }
    />
  );
};
