import { Row } from 'antd';
import { config } from 'config/config';
import { FormattedMessage } from 'react-intl';
import {
  LEGACY_PlatformID_Enum,
  PIN_INFILTRATED_COUNTRIES_NAMES_Enum,
  PIN_PlatformID_Enum,
} from 'src/shared/enums';

const { PLATFORM } = config.APP;

const NIF = 'NIF';
const NIE = 'NIE';

export interface InputValidationConfig {
  hasCorrectUppercaseLetters: boolean;
  hasCorrectLowercaseLetters: boolean;
  correctPasswordLength: boolean;
  hasCorrectNumber: boolean;
  password: string;
}

export const isNieOrNif = (docNumber: string) => {
  const firstChar: string = docNumber?.charAt(0);
  if (firstChar == 'X' || firstChar == 'Y' || firstChar == 'Z') {
    return 'NIE';
  } else {
    return 'NIF';
  }
};

const documentType = (type: string): string => {
  const NIF_REGEX = /^(\d{8})([A-Z])$/;
  const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

  if (type && type.match(NIF_REGEX)) {
    return NIF;
  }

  if (type && type.match(NIE_REGEX)) {
    return NIE;
  }

  return 'unknown';
};

const validNIF = (nif: string): boolean => {
  const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const letter = dni_letters.charAt(parseInt(nif, 10) % 23);

  return letter == nif.charAt(8);
};

const validNIE = (nie: string): boolean => {
  // Change the initial letter for the corresponding number and validate as NIF
  let nie_prefix: number;

  switch (nie.charAt(0)) {
    case 'X':
      nie_prefix = 0;
      break;
    case 'Y':
      nie_prefix = 1;
      break;
    case 'Z':
      nie_prefix = 2;
      break;
  }

  return validNIF(nie_prefix + nie.substring(1));
};

// ! DEPRECATED: Remove it and dependecies.
export const validateDocument = (
  document: string
): { type: string; valid: boolean } => {
  let valid = false;
  const type = documentType(document);

  if (type === NIF) {
    valid = validNIF(document);
  }

  if (type === NIE) {
    valid = validNIE(document);
  }

  return {
    type: type,
    valid: valid,
  };
};

export const MIN_PASSWORD_LENGTH = 8;

// Never used
// export const PASSWORD_REGEX = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{1,20})/g;

export const PASSWORD_UPPERCASE = /((?=.*[A-Z]).{1,20})/g;

export const PASSWORD_LOWERCASE = /((?=.*[a-z]).{1,20})/g;

export const PASSWORD_NUMBER = /((?=.*\d).{1,20})/g;

export const MAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/g;

export const checkPasswordValidations = (
  password: string,
  currentPassword?: string
) => {
  const hasUpperLetter = !!password?.match(PASSWORD_UPPERCASE);
  const hasLowerLetter = !!password?.match(PASSWORD_LOWERCASE);
  const hasNumber = !!password?.match(PASSWORD_NUMBER);
  const isLengthOK = password?.length >= MIN_PASSWORD_LENGTH;
  const isDone = hasUpperLetter && hasLowerLetter && hasNumber && isLengthOK;
  // Never used
  //  const isCorrectFormat = !!password?.match(PASSWORD_REGEX);

  return {
    hasUpperLetter,
    hasLowerLetter,
    hasNumber,
    isLengthOK,
    isDone,
    hasError: !isDone,
    passwordEquals: password && password === currentPassword,
    // Never used
    // isCorrectFormat
  };
};

export const validateUserDocumentIDSpain = (
  documentId: string,
  country: string
): Promise<boolean> => {
  if (!documentId) return Promise.resolve(false);

  const documentIdUpperCase = documentId.toString().toUpperCase();
  const validDocumentIdLastLetter = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const documentIdAndorraRegex = /^[a-zA-Z]\d{6}[a-zA-Z]$/;
  const dniAndNieRegex = /^[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/;

  if (country === PIN_INFILTRATED_COUNTRIES_NAMES_Enum.ANDORRA) {
    if (!documentIdAndorraRegex.test(documentIdUpperCase))
      return Promise.reject(
        new Error('El NIF/NIE no tiene el formato correcto')
      );
    else return Promise.resolve(true);
  }

  if (!dniAndNieRegex.test(documentIdUpperCase))
    return Promise.reject('El NIF/NIE no tiene el formato correcto');

  const nie = documentIdUpperCase
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2');

  const letter = documentIdUpperCase.slice(-1);
  const charIndex = parseInt(nie.substring(0, 8)) % 23;

  if (validDocumentIdLastLetter.charAt(charIndex) === letter)
    return Promise.resolve(true);

  return Promise.reject(new Error('El NIF/NIE no tiene el formato correcto'));
};

export const telephoneLengthValidation = (stringToTest: string): boolean => {
  return getSpecificPatternTelephoneNumber().test(stringToTest);
};

export const getSpecificPatternTelephoneNumber = (): RegExp => {
  let pattern: RegExp;

  switch (PLATFORM) {
    case PIN_PlatformID_Enum.PIN_SPAIN:
    case PIN_PlatformID_Enum.PIN_PORTUGAL:
    case PIN_PlatformID_Enum.PIN_BRAZIL:
    case PIN_PlatformID_Enum.PIN_ALEMANIA:
      pattern = /^(?:.*\d.*){4,}$/; // 4+ length
      break;
    case PIN_PlatformID_Enum.PIN_ITALY:
      pattern = /^(?:.*\d.*){6,}$/; // 6+ length
      break;
    case LEGACY_PlatformID_Enum.PIN_USA:
      pattern = /^(?:.*\d.*){10,}$/; // 10+ length
      break;
    default:
      pattern = /^(?:.*\d.*){4,}$/;
  }

  return pattern;
};

export const InputValidation = (config: {
  id: string;
  validations: InputValidationConfig;
}) => {
  let inputStatus: string;
  const {
    id,
    validations: {
      hasCorrectUppercaseLetters,
      hasCorrectLowercaseLetters,
      correctPasswordLength,
      hasCorrectNumber,
      password,
    },
  } = config;
  switch (id) {
    case 'page.password-change.validation-uppercase-letters':
      inputStatus = hasCorrectUppercaseLetters ? 'valid' : 'not-valid';
      break;
    case 'page.password-change.validation-lowercase-letters':
      inputStatus = hasCorrectLowercaseLetters ? 'valid' : 'not-valid';
      break;
    case 'page.password-change.validation-number':
      inputStatus = hasCorrectNumber ? 'valid' : 'not-valid';
      break;
    case 'page.password-change.validation-length':
      inputStatus = correctPasswordLength ? 'valid' : 'not-valid';
      break;
  }
  if (!password) inputStatus = 'empty';
  return (
    <Row
      className={`password-validation password-validation__${inputStatus}`}
      align="top"
    >
      <i className={`icon icon--${inputStatus}-circle`} />
      <div className="password-validation__message">
        <FormattedMessage id={id} />
      </div>
    </Row>
  );
};

export const PasswordInputValidations = (
  validations: InputValidationConfig
): JSX.Element => {
  return (
    <div className="change-password-validations">
      <InputValidation
        {...{
          id: 'page.password-change.validation-length',
          validations,
        }}
      />
      <InputValidation
        {...{
          id: 'page.password-change.validation-uppercase-letters',
          validations,
        }}
      />
      <InputValidation
        {...{
          id: 'page.password-change.validation-lowercase-letters',
          validations,
        }}
      />
      <InputValidation
        {...{
          id: 'page.password-change.validation-number',
          validations,
        }}
      />
    </div>
  );
};
