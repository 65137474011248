import { useSize } from '@isdin/yuma-react-web-pin';

export const useScientificMaterialCardsMaxWidth = (): string => {
  const { upXS, upL, upM } = useSize();
  let maxWidth = '100%';
  if (upXS) maxWidth = '50%';
  if (upM) maxWidth = '33.333333%';
  if (upL) maxWidth = '25%';
  return maxWidth;
};
