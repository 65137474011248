import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_CL: IRegion[] = [
  {
    id: 'a0C5800000FiEMYEA3',
    code: '02',
    name: 'Antofagasta',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiENfEAN',
    code: '15',
    name: 'Arica y Parinacota',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERcEAN',
    code: '08',
    name: 'Biobío',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERhEAN',
    code: '03',
    name: 'Atacama',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERxEAN',
    code: '11',
    name: 'Aysén del General Carlos Ibáñez del Campo',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWcEAN',
    code: '01',
    name: 'Tarapacá',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYgEAN',
    code: '04',
    name: 'Coquimbo',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEezEAF',
    code: '10',
    name: 'Los Lagos',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEf4EAF',
    code: '12',
    name: 'Magallanes y la Antártica Chilena',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfOEAV',
    code: '06',
    name: 'Libertador General Bernardo O’Higgins',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEh4EAF',
    code: '13',
    name: 'Metropolitana',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEj1EAF',
    code: '07',
    name: 'Maule',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjLEAV',
    code: '09',
    name: 'La Araucanía',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkWEAV',
    code: '14',
    name: 'Los Ríos',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkaEAF',
    code: '05',
    name: 'Valparaíso',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000g345xUAA',
    code: '16',
    name: 'Ñuble',
    country: 'Chile',
    countryCode: 'CL',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'es-ES');
});
