import { Row, Col } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PearlStatusEnum } from 'src/shared/enums';

export const formatPearlStatus = (statusResource: PearlStatusEnum) => {
  return ['not-sent', 'pending', 'validate', 'deny'][statusResource];
};

const PearlCard = ({ userPearl, setPearlSelected, isLoggedUserPearl }) => {
  const { formatMessage } = useIntl();
  if (!userPearl) return <React.Fragment />;

  const StatusLabel = () => {
    if (!isLoggedUserPearl) return <React.Fragment />;

    const formattedStatus = formatPearlStatus(userPearl.statusResource);

    return (
      <div
        className={`challengeCard__image__label challengeCard__image__label--${formattedStatus}`}>
        <label>
          <FormattedMessage
            id={`page.challenge-card.pearl.status-${formattedStatus}`}
          />
        </label>
      </div>
    );
  };

  return (
    <Col xs={24} md={8} key={`pearl-card-${userPearl.idUser}`}>
      <div className="pearl__user-pearl">
        <StatusLabel />
        <p className="regular-body">{userPearl?.title}</p>
        <p className="pearl__card-description">{userPearl?.description}</p>
        <p className="pearl__links" onClick={() => setPearlSelected(userPearl)}>
          {isLoggedUserPearl
            ? formatMessage({ id: 'pearl.see-my-pearl' })
            : formatMessage({ id: 'pearl.discover-more' })}
        </p>
        <Row justify="space-between" className="pearl__card-details">
          <Col span={18}>
            <div className="icon icon--myaccount"></div>
            {userPearl?.userName}
          </Col>
          <Col span={6}>
            <div className="icon icon--calendar"></div>
            {userPearl.date}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default PearlCard;
