import {
  IResourceDetailList,
  IResourceList,
} from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IChallengeGenericButton } from 'src/shared/models';

abstract class IGenericButtonFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(
    genericButton: IChallengeGenericButton,
  ): IChallengeGenericButton;
}

class ModalTitleFiller extends IGenericButtonFiller {
  fill(genericButton: IChallengeGenericButton): IChallengeGenericButton {
    return {
      ...genericButton,
      modalTitle: this.detail.value,
    };
  }
}

class ModalDescriptionFiller extends IGenericButtonFiller {
  fill(genericButton: IChallengeGenericButton): IChallengeGenericButton {
    return {
      ...genericButton,
      modalDescription: this.detail.value,
    };
  }
}
class DescriptionFiller extends IGenericButtonFiller {
  fill(genericButton: IChallengeGenericButton): IChallengeGenericButton {
    return {
      ...genericButton,
      description: this.detail.value,
    };
  }
}

class ShowModalFiller extends IGenericButtonFiller {
  fill(genericButton: IChallengeGenericButton): IChallengeGenericButton {
    return {
      ...genericButton,
      showModal: this.detail.value === 'true',
    };
  }
}

class ShowSeparatorFiller extends IGenericButtonFiller {
  fill(genericButton: IChallengeGenericButton): IChallengeGenericButton {
    return {
      ...genericButton,
      showSeparator: this.detail.value === 'true',
    };
  }
}

class ButtonLabelCompletedFiller extends IGenericButtonFiller {
  fill(genericButton: IChallengeGenericButton): IChallengeGenericButton {
    return {
      ...genericButton,
      labelCompleted: this.detail.value,
    };
  }
}
class ChallengeGenericButtonFactory {
  private readonly genericButtonFillers: Map<
    ResourceTypeDetailEnum,
    IGenericButtonFiller
  >;

  constructor(detail: IResourceDetailList) {
    this.genericButtonFillers = new Map();

    this.genericButtonFillers.set(
      ResourceTypeDetailEnum.GENERIC_BUTTON_SHOW_MODAL,
      new ShowModalFiller(detail),
    );
    this.genericButtonFillers.set(
      ResourceTypeDetailEnum.GENERIC_BUTTON_MODAL_DESCRIPTION,
      new ModalDescriptionFiller(detail),
    );
    this.genericButtonFillers.set(
      ResourceTypeDetailEnum.GENERIC_BUTTON_MODAL_TITLE,
      new ModalTitleFiller(detail),
    );
    this.genericButtonFillers.set(
      ResourceTypeDetailEnum.GENERIC_BUTTON_LABEL_COMPLETED,
      new ButtonLabelCompletedFiller(detail),
    );
    this.genericButtonFillers.set(
      ResourceTypeDetailEnum.GENERIC_BUTTON_DESCRIPTION,
      new DescriptionFiller(detail),
    );
    this.genericButtonFillers.set(
      ResourceTypeDetailEnum.GENERIC_BUTTON_SHOW_SEPARATOR,
      new ShowSeparatorFiller(detail),
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    genericButton: IChallengeGenericButton,
  ): IChallengeGenericButton {
    const genericButtonFiller = this.genericButtonFillers.get(idResourceTypeD);

    if (genericButtonFiller) {
      return genericButtonFiller.fill(genericButton);
    }

    return genericButton;
  }
}

class ChallengeGenericButtonBuilder {
  public build(resourceList: IResourceList): IChallengeGenericButton {
    let resourceGuide: IChallengeGenericButton;

    const clickPerformance = resourceList.usersM2MList.find(
      ({ status }) => status,
    );

    resourceList.resourceDetailList
      .filter((genericButton) => genericButton.status)
      .forEach((detail) => {
        const Factory = new ChallengeGenericButtonFactory(detail);

        resourceGuide = Factory.fill(detail, resourceGuide);
      });

    return {
      ...resourceGuide,
      title: resourceList.name,
      wasClicked: !!clickPerformance,
    };
  }
}

export const builderGenericButton = (
  resourceList: IResourceList,
): IChallengeGenericButton => {
  const builder = new ChallengeGenericButtonBuilder();

  return builder.build(resourceList);
};
