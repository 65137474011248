import routePaths from 'config/routes';
import React, { Component } from 'react';
import { UserStatusEnum } from 'src/shared/enums/user.enum';
import { CenterRolesEnum } from 'src/shared/enums/usersCenter.enum';
import { ReduxPageContext } from 'src/store';
import redirectTo from './redirectTo';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialData?: any;
}

export const privateRoute = ({
  canBePublic,
  hidden = false,
  initialAction,
  magentoComponent,
  salesforceComponent,
  validUserStatus,
  WrappedComponent,
}: {
  canBePublic?: boolean;
  hidden?: boolean;
  initialAction?: (
    accessToken: string,
    ctx: ReduxPageContext
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<{ data: any }>;
  magentoComponent?: boolean;
  role?: CenterRolesEnum;
  salesforceComponent?: boolean;
  validUserStatus?: UserStatusEnum[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  WrappedComponent: any;
}) => {
  return class extends Component<Props> {
    static async getInitialProps(ctx: ReduxPageContext) {
      const user = ctx.store.getState().auth.user;
      const accessToken = ctx.store.getState().auth.accessToken;
      const userStatus = ctx.store.getState().auth.userStatus;
      const { magento, salesforce } = user || {};
      const isHomePage = [
        routePaths.BASE,
        routePaths.INDEX,
        routePaths.STATIC,
      ].includes(ctx.pathname);

      let response;

      switch (true) {
        case !isHomePage && !hidden && !user && canBePublic:
          return null;
        case hidden:
        case validUserStatus && !validUserStatus.includes(userStatus):
        case magentoComponent && !magento:
        case salesforceComponent && !salesforce:
          redirectTo(routePaths.INDEX, ctx);
          return null;
        case Boolean(initialAction) && (canBePublic || Boolean(accessToken)):
          response = await initialAction(accessToken, ctx);

          if (!response?.data) redirectTo(routePaths.ERROR, ctx);

          return { initialData: response.data };
        default:
          return null;
      }
    }

    render() {
      const { initialData, ...propsWithoutAuth } = this.props;
      return (
        <WrappedComponent initialData={initialData} {...propsWithoutAuth} />
      );
    }
  };
};
