import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, Row, Upload } from 'antd';
import {
  RcCustomRequestOptions,
  UploadChangeParam,
} from 'antd/lib/upload/interface';
import { config } from 'config/config';
import React, { ChangeEvent, FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { postDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { S3BucketPathEnum } from 'src/shared/enums';
import { IUserUploadMedia, MediaTypeEnum } from 'src/shared/models';
import _ from 'lodash';

interface OwnProps {
  labelText?: string;
  btnDisabled?: boolean;
  accessToken: string;
  beforeUpload?: () => void;
  afterError?: () => void;
  updateResource: (url: string, fileName: string, size: string) => void;
  userUploadconfig: IUserUploadMedia;
  s3Bucket: S3BucketPathEnum;
}

export const UploadFiles: FC<OwnProps> = ({
  labelText,
  btnDisabled,
  accessToken,
  beforeUpload = _.noop,
  afterError = _.noop,
  updateResource,
  userUploadconfig,
  s3Bucket,
}): JSX.Element => {
  const {
    buttonTitle,
    allowedExtensions,
    maxImagesAllowed,
    mediaData: { type, url },
  } = userUploadconfig;
  const [inputURL, setInputURL] = useState<string>(url);
  const { formatMessage } = useIntl();
  const asociateURLToUser = async (
    url: string,
    fileName: string,
    size: string
  ) => {
    beforeUpload();
    updateResource(url, fileName, size);
  };

  const getKbytesSize = (size: number): string => {
    const k_size = size / 1024 || 0;

    return `${k_size.toFixed(2)}kb`;
  };

  const handleSuccess = (info: UploadChangeParam) => {
    const { file } = info;

    if (file?.status === 'done') {
      asociateURLToUser(
        file.response?.data?.url,
        file.name,
        getKbytesSize(file.size)
      );
    }
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputURL(event.target.value.trim());
  };

  const formatAllowedExtensions = (): string => {
    const extensions = [];

    allowedExtensions.forEach((element) => {
      extensions.push(element.split('/')[1]);
    });

    return extensions.join(', ').toUpperCase();
  };

  const uploadImage = async (options: RcCustomRequestOptions) => {
    const { onSuccess, onError, file } = options;

    const data = new FormData();
    data.append('upload', file);

    try {
      beforeUpload();
      const response = await postDataCall({
        dataPath: `${config.API.BASE_URL}${apiPaths.CHALLENGES.CHALLENGE_VISIBILITY_FILES_NEW}/${s3Bucket}`,
        data,
        callConfig: {},
      });

      onSuccess({ success: true, data: response.data }, file);
    } catch (error) {
      console.error(error);
      onError(error);
      notification.error({
        message: formatMessage({ id: 'app.error.on_upload_file' }),
      });
      afterError();
    }
  };

  const UploadFileInput = (): JSX.Element => {
    return (
      <>
        <Row justify="center">
          <Upload
            headers={{
              Authorization: `Bearer ${accessToken}`,
            }}
            customRequest={uploadImage}
            name="upload"
            onChange={handleSuccess}
            accept={allowedExtensions.toString()}
          >
            <Button
              className={`btn-ghost btn-ghost--black btn-upload-media ${
                btnDisabled ? 'btn-ghost--black--disabled' : ''
              } btn-ghost--black btn-upload-media`}
              disabled={btnDisabled}
            >
              {buttonTitle} <UploadOutlined />
              {/* //!utilizar los iconos de _icons.scss */}
            </Button>
          </Upload>
        </Row>

        {btnDisabled && labelText && (
          <Row
            className={'container-repeat-quiz expired-challenge'}
            justify="center"
            align="middle"
          >
            <FormattedMessage id={labelText} />
          </Row>
        )}

        <Row justify="center" className="allowed-extensions-message">
          {formatMessage(
            {
              id: maxImagesAllowed
                ? 'page.challenge.challengeUserUploadMedia.allowedExtensions-{allowedExtensions}-maxImagesAllowed-{maxImagesAllowed}'
                : 'page.challenge.challengeUserUploadMedia.allowedExtensions-{allowedExtensions}',
            },
            { allowedExtensions: formatAllowedExtensions(), maxImagesAllowed }
          )}
        </Row>
      </>
    );
  };

  const getLabelName = (key: string) => {
    let className = `floating-${key}`;

    if (inputURL !== '') className += ` floating-${key}--filled`;

    return className;
  };

  const renderUploadURLInput = (
    <>
      <Row justify="center">
        <Row className="input-row">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'string',
                message: formatMessage(
                  { id: 'form.field.required' },
                  { name: formatMessage({ id: 'form.field.url' }) }
                ),
              },
            ]}
          >
            <Input
              required
              onChange={handleChangeInput}
              id="standard-required--user"
              style={{
                width: '100%',
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: 'none',
              }}
            />
          </Form.Item>
          <span className={getLabelName('email')}>
            {formatMessage({
              id: 'page.challenge.challengeUserUploadMedia.upload-url',
            })}
          </span>
        </Row>
        <Row justify="center">
          <Button
            className={`btn-ghost ${
              inputURL == '' ? 'btn-ghost--black--disabled' : ''
            } btn-ghost--black btn-upload-media`}
            disabled={inputURL == ''}
            onClick={() => {
              asociateURLToUser(inputURL, inputURL, '');
            }}
          >
            {buttonTitle}
            <UploadOutlined />
          </Button>
        </Row>
      </Row>
    </>
  );

  return (
    <div className="upload-files">
      {type === MediaTypeEnum.URL ? renderUploadURLInput : <UploadFileInput />}
    </div>
  );
};
