import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IGallery, IGalleryItem, isVideoValidFormat } from 'src/shared/models';

const { GALLERY_VIDEO_URL, GALLERY_IMAGE_URL } = ResourceTypeDetailEnum;

export const buildChallengeGallery = (
  quiz: IResourceList,
  idChallenge: number,
): IGallery => {
  const items: IGalleryItem[] = [];
  const resourceList = quiz.resourceList.filter((r) => r.status);
  let item: IGalleryItem;

  resourceList.forEach((_item) => {
    _item.resourceDetailList.forEach((resourceDetail) => {
      switch (resourceDetail.idResourceTypeD.idResourceTypeD) {
        case GALLERY_VIDEO_URL:
          item = {
            ...item,
            idResource: _item.idResource,
            url: resourceDetail.value,
            isVideo: true,
            isImage: false,
            isVideoFile: isVideoValidFormat(resourceDetail.value),
          };
          items.push(item);
          break;

        case GALLERY_IMAGE_URL:
          item = {
            ...item,
            idResource: _item.idResource,
            url: resourceDetail.value,
            isVideo: false,
            isImage: true,
            isVideoFile: false,
          };
          items.push(item);
          break;
      }
    });
  });

  return {
    idChallenge,
    items,
  };
};
