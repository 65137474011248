import { intervalContainsNow } from '../../../helpers/luxon.helper';
import {
  IResourceList,
  IUsersM2MList,
} from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeEnum, UserResourceEnum } from 'src/shared/enums';
import { IChallenge } from 'src/shared/models';

const { INSCRIPTION, PARTICIPATION } = ResourceTypeEnum;
const { IN_PROGRESS, DONE, SIGNED } = UserResourceEnum;

type _data = Omit<
  IChallenge,
  | 'active'
  | 'optimisticLocking'
  | 'resourceList'
  | 'showList'
  | 'restrictionList'
  | 'likesList'
  | 'idChallengeType'
  | 'createDate'
  | 'endImage'
  | 'endImageMobile'
  | 'updateDate'
  | 'updateProgram'
  | 'updateUser'
  | 'textMachine'
>;

export type IChallengeHeadboard = _data & {
  isUserRegistered: boolean;

  isChallengeOpen: boolean;
  isChallengeInLife: boolean;

  isQuizInprogress: boolean; //TODO: Remove. Unnecesary??
  isQuizDone: boolean;
};

export const buildHeadboard = (data: IChallenge): IChallengeHeadboard => {
  const { resourceList, ...challengeData } = data;

  const { endDate, startDate, startVisDate } = challengeData;

  let resourceData: IUsersM2MList;

  let isQuizInprogress = false;
  let isQuizDone = false;

  let isUserRegistered = false;

  const isChallengeOpen = intervalContainsNow(startVisDate, endDate, true);
  const isChallengeInLife = intervalContainsNow(startDate, endDate, true);

  resourceList
    ?.filter((resource) => resource.status)
    .forEach((resource) => {
      switch (resource.idResourceType.idResourceType) {
        case INSCRIPTION:
          // Recurso utilizado por los challenge del tipo EVENT
          // gestiona si el usuario está inscrito al evento
          resourceData = getResourceDataIfExists(resource);
          if (resourceData) {
            const { statusResource } = resourceData;
            isUserRegistered = isUserRegistered || statusResource == SIGNED;
          }

          break;
        case PARTICIPATION:
          // Recurso utilizado por los del tipo TRAINING
          // Gestiona el estado de la QUIZ respecto al usuario.
          resourceData = getResourceDataIfExists(resource);
          if (resourceData) {
            const { statusResource } = resourceData;
            isQuizInprogress = statusResource == IN_PROGRESS;
            isQuizDone = statusResource == DONE;
          }

          break;
      }
    });

  return {
    ...challengeData,
    isUserRegistered,
    isChallengeOpen,
    isChallengeInLife,
    isQuizDone,
    isQuizInprogress,
  };
};

export const getResourceDataIfExists = (
  data: IResourceList
): IUsersM2MList | null => {
  return data.usersM2MList.slice(-1)[0];
};
