import { Col, Row } from 'antd';
import { FC } from 'react';
import { connect } from 'react-redux';
import { IChallengeExpertsGroup } from '../../shared/models';
import ChallengeSpeakers from './ChallengeSpeakers';

interface OwnProps {
  config: IChallengeExpertsGroup;
}

const ChallengeExpertsGroup: FC<OwnProps> = ({ config }): JSX.Element => {
  const { icon, title, description, idResource, expertsList } = config;

  const iconRender = (icon) => {
    switch (icon) {
      case 'badge':
      case 'timer':
      case 'qr-code':
      case 'tickets':
        return (
          <a
            className={`icon icon--${icon} icon__increase_50 challengeExpertGroup--icon-small`}
          ></a>
        );
      case 'sun':
      case 'transport':
        return (
          <a
            className={`icon icon--${icon} challengeExpertGroup--icon-small`}
          ></a>
        );
      case 'microphone':
      default:
        return (
          <a className={`icon icon--microphone challengeExpertGroup--icon`}></a>
        );
    }
  };

  return (
    <div className="challengeExpertGroup">
      <Row justify="center" className="challengeExpertGroup">
        <Col span={24}>
          <Row justify="center">{iconRender(icon)}</Row>
          <Row className="expertGroup-title" justify="center">
            {title}
          </Row>
          <Row className="expertGroup-description" justify="center">
            {description}
          </Row>
        </Col>
      </Row>
      <ChallengeSpeakers
        challengeSpeakers={expertsList}
        key={`challenge-speakers-${idResource}`}
      />
    </div>
  );
};

export default connect(null)(ChallengeExpertsGroup);
