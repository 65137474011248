import {
  YuButton,
  YuFlex,
  YuIllustration,
  YuParagraph,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import redirectTo from 'services/redirectTo';
import routePaths from '../../../../config/routes';

interface OwnProps {
  hideBottomSpacer?: boolean;
  hideTopSpacer?: boolean;
}

export const EmptyBasket: FC<OwnProps> = ({
  hideBottomSpacer = false,
  hideTopSpacer = false,
}): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <YuFlex pl="XS" pr="XS" width="100%" flexDirection="column" align="center">
      {!hideTopSpacer && <YuSpacer size="L" />}
      <YuIllustration size="L" name="EmptyBag" />
      <YuTitle center size={{ L: 'M', XS: 'S' }}>
        {formatMessage({ id: 'empty-basket.title' })}
      </YuTitle>
      <YuSpacer size="S" />
      <YuParagraph center size={{ L: 'XL', XS: 'L' }}>
        {formatMessage({ id: 'empty-basket.description' })}
      </YuParagraph>
      <YuSpacer size="M" />
      <YuButton
        size="L"
        onClick={() => redirectTo(routePaths.PAGES.REDEEM_POINTS.MAIN)}
      >
        {formatMessage({ id: 'empty-basket.button' })}
      </YuButton>
      {!hideBottomSpacer && <YuSpacer size="XL" />}
    </YuFlex>
  );
};
