import {
  YuButton,
  YuFlex,
  YuIllustration,
  YuParagraph,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRootReducers } from '../../../reducers';
import { useIntl } from 'react-intl';
import redirectTo from '../../../../services/redirectTo';
import routePaths from '../../../../config/routes';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const BasicHeaderBagEmpty: NextPage<Props, unknown> = (): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <YuFlex pl="XXS" pr="XXS">
      <YuFlex pl="S" pr="S" flexDirection="column" align="center">
        <YuSpacer size="XS" />
        <YuIllustration name="EmptyBag" size="S" />
        <YuSpacer size="XXS" />
        <YuTitle center size="S">
          {formatMessage({ id: 'header.bag.empty.title' })}
        </YuTitle>
        <YuSpacer size="XS" />
        <YuParagraph center size="XL">
          {' '}
          {formatMessage({ id: 'header.bag.empty.description' })}{' '}
        </YuParagraph>
        <YuSpacer size="M" />
        <YuButton
          size="L"
          onClick={() => redirectTo(routePaths.PAGES.REDEEM_POINTS.MAIN)}
        >
          {formatMessage({ id: 'header.bag.empty.button' })}
        </YuButton>
        <YuSpacer size="XS" />
      </YuFlex>
    </YuFlex>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicHeaderBagEmpty);
