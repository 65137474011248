import { NextPage } from 'next';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRootReducers } from '../../../reducers';
import { redeemPointStatus } from '../../../../utils';
import { YuBadgeNumeric, YuButton, YuIcon } from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import redirectTo from '../../../../services/redirectTo';
import routePaths from '../../../../config/routes';
import { useRouter } from 'next/router';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const BasicHeaderBagIconBadge: NextPage<Props, unknown> = ({
  userStatus,
  nItemsCart,
}): JSX.Element => {
  const router = useRouter();

  return (
    <YuButton
      disabled={!redeemPointStatus.includes(userStatus)}
      variant="ghost"
      size="XXS"
      onClick={() => {
        if (
          !router.pathname.includes(routePaths.PAGES.REDEEM_POINTS.CHECKOUT) &&
          !router.pathname.includes(routePaths.PAGES.REDEEM_POINTS.MY_BAG)
        )
          redirectTo(routePaths.PAGES.REDEEM_POINTS.MY_BAG);
      }}
    >
      <YuBadgeNumeric
        description=""
        badgeContent={nItemsCart}
        size="S"
        variant="primary"
      >
        <YuIcon name="ShoppingBag" size="M" />
      </YuBadgeNumeric>
    </YuButton>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    userStatus: state.auth.userStatus,
    nItemsCart: _.sum(_.map(state.app.redeemPoints.cart.items, 'qty')),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicHeaderBagIconBadge);
