import { Dispatch } from 'redux';
import { IRootReducers } from 'src/reducers';
import { toastTypes } from '../types/toastTypes';
import { IToast } from '../shared/models/toast.model';
import _ from 'lodash';

interface ISetToasts {
  type: toastTypes.SET_TOASTS;
  payload: { toasts: IToast[]; oneTimeToastIds: string[] };
}

export type ToastActionTypes = ISetToasts;

export const addToast =
  (toast: IToast) =>
  async (dispatch: Dispatch<ISetToasts>, getState: () => IRootReducers) => {
    const { toasts, oneTimeToastIds } = getState().toast;

    if (toast.oneTime) {
      if (!toast.id) throw new Error('id is required is if oneTime');
      if (oneTimeToastIds.indexOf(toast.id) >= 0) {
        return;
      }
      oneTimeToastIds.push(toast.id);
    }

    if (!toast.id) toast.id = Date.now().toString();
    const existsToast = _.find(toasts, (element) => element.id === toast.id);

    if (!existsToast)
      dispatch({
        type: toastTypes.SET_TOASTS,
        payload: { toasts: [...toasts, toast], oneTimeToastIds },
      });
  };

export const removeToastById =
  (id: string) =>
  async (dispatch: Dispatch<ISetToasts>, getState: () => IRootReducers) => {
    const { toasts, oneTimeToastIds } = getState().toast;
    dispatch({
      type: toastTypes.SET_TOASTS,
      payload: {
        toasts: _.filter(toasts, (toast) => toast.id !== id),
        oneTimeToastIds,
      },
    });
  };
