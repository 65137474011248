import {
  base_esES,
  deDE,
  enUS,
  esAR,
  esCL,
  esCO,
  esES,
  esGT,
  esMX,
  esPE,
  itIT,
  ptBR,
  ptPT,
} from './messages';

const keys = Array.from(
  new Set([
    ...Object.keys(enUS),
    ...Object.keys(itIT),
    ...Object.keys(ptPT),
    ...Object.keys(ptBR),

    ...Object.keys(base_esES),
    ...Object.keys(esAR),
    ...Object.keys(esCL),
    ...Object.keys(esCO),
    ...Object.keys(esES),
    ...Object.keys(esMX),
    ...Object.keys(esPE),
    ...Object.keys(esGT),
  ])
);

export const validate = (
  language: string,
  jsonToCompare: Record<string, unknown>
) => {
  const arrayToCompare = Object.keys(jsonToCompare);

  const difference = keys
    .filter((labelFromSchema) => !arrayToCompare.includes(labelFromSchema))
    .concat(
      arrayToCompare.filter(
        (labelFromCompare) => !arrayToCompare.includes(labelFromCompare)
      )
    );

  if (difference?.length) console.error(language, difference);
};

export const checkTranslationsLabels = async () => {
  validate('en-US', enUS);
  validate('it-IT', itIT);
  validate('pt-PT', ptPT);
  validate('pt-BR', ptBR);
  validate('de-DE', deDE);

  validate('es-ES', getAltLiterals(base_esES, esES));
  validate('es-AR', getAltLiterals(base_esES, esAR));
  validate('es-CL', getAltLiterals(base_esES, esCL));
  validate('es-CO', getAltLiterals(base_esES, esCO));
  validate('es-MX', getAltLiterals(base_esES, esMX));
  validate('es-PE', getAltLiterals(base_esES, esPE));
  validate('es-GT', getAltLiterals(base_esES, esGT));
  return;
};

const getAltLiterals = (mainLanguage, altLanguage) => {
  if (!mainLanguage || !altLanguage) return;

  return { ...mainLanguage, ...altLanguage };
};
