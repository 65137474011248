import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IChallengeSpeaker } from 'src/shared/models';

const { SPEAKER_EMAIL, SPEAKER_IMAGE, SPEAKER_JOB, SPEAKER_PHONE } =
  ResourceTypeDetailEnum;

export const buildChallengeSpeakers = (
  speaker: IResourceList
): IChallengeSpeaker => {
  let challengeSpeaker: IChallengeSpeaker;

  speaker.resourceDetailList
    .filter((speaker) => speaker.status)
    .forEach((_detail) => {
      const { idResourceTypeD } = _detail.idResourceTypeD;
      challengeSpeaker = {
        ...challengeSpeaker,
        name: speaker.name,
        order: speaker.order,
      };
      switch (idResourceTypeD) {
        case SPEAKER_JOB:
          challengeSpeaker = {
            ...challengeSpeaker,
            job: _detail.value,
          };
          break;
        case SPEAKER_IMAGE:
          challengeSpeaker = {
            ...challengeSpeaker,
            imageURL: _detail.value,
          };
          break;
        case SPEAKER_EMAIL:
          challengeSpeaker = {
            ...challengeSpeaker,
            email: _detail.value,
          };
          break;
        case SPEAKER_PHONE:
          challengeSpeaker = {
            ...challengeSpeaker,
            phone: _detail.value,
          };
          break;
      }
    });

  return challengeSpeaker;
};
