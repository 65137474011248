export enum UserCenterRequestEnum {
  DECLINED = 0,
  PENDING = 1,
  ACCEPTED = 2,
}

export enum CenterRolesEnum {
  MANAGER = 'Manager',
  EMPLOYEE = 'Employee',
}

export enum CenterStatusEnum {
  DENIED = 1,
  PENDING = 2,
  GRANT = 3,
}
