import Link from 'src/components/Link';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import routePaths, { deprecatedRoutePaths } from 'config/routes';
import getConfig from 'next/config';
import { YuLink } from '@isdin/yuma-react-web-pin';

const { publicRuntimeConfig } = getConfig();
const { PLATFORM_PREFIX, EMAIL_CUSTOMER_CARE } = publicRuntimeConfig;

export const customFormatMessageValues = {
  lineBreak: () => <br></br>,
  strong: (chunks: string) => <strong>{chunks}</strong>,
  b: (chunks: string) => <b>{chunks}</b>,
  italic: (chunks: string) => <i>{chunks}</i>,
  u: (chunks: string) => <u>{chunks}</u>,
  br: () => <br></br>,
  link_staticPage: (chunks: string) => {
    return (
      <Link href={`${routePaths.STATIC}?page=${chunks}`}>
        <span className="termsPage__externLink">{chunks}</span>
      </Link>
    );
  },
  link_privacyPolicy: (chunks: string) => (
    <Link href="/privacy-policy">
      <span className="termsPage__externLink">{chunks}</span>
    </Link>
  ),
  link_contactUs: (chunks: string) => (
    <Link href="/contact-us">
      <span className="termsPage__externLink">{chunks}</span>
    </Link>
  ),
  link_myProfile: (chunks: string) => (
    <Link href="/account/my-profile">
      <span className="termsPage__externLink">{chunks}</span>
    </Link>
  ),
  link_contactUs_redVariant: (chunks: string) => (
    <a
      target="_blank"
      href={`${PLATFORM_PREFIX}${routePaths.FOOTER.CONTACT_US}`}
      className="no-practices-found_link"
    >
      {chunks}
    </a>
  ),
  link_myOrders: (chunks: string) => (
    <Link href="/account/orders">
      <span className="termsPage__externLink">{chunks}</span>
    </Link>
  ),
  link_US_MED: (chunks: string) => (
    <a className="termsPage__externLink" href={`${chunks}`} target="_blank">
      {chunks}
    </a>
  ),
  email: (chunks: string[]) => (
    <a className="termsPage__mailTo" href={`mailto: ${chunks}`} target="_blank">
      <strong>{chunks}</strong>
    </a>
  ),
  yuEmailL: (chunks: string[]) => (
    <YuLink size="L" targetBlank href={`mailto:${chunks}`}>
      {chunks}
    </YuLink>
  ),
  yuEmailM: (chunks: string[]) => (
    <YuLink size="M" targetBlank href={`mailto:${chunks}`}>
      {chunks}
    </YuLink>
  ),
  yuCustomerCareEmailM: () => (
    <YuLink size="M" targetBlank href={`mailto:${EMAIL_CUSTOMER_CARE}`}>
      {EMAIL_CUSTOMER_CARE}
    </YuLink>
  ),
  yuPhoneL: (chunks: string[]) => (
    <YuLink size="L" targetBlank href={`tel:${chunks}`}>
      {chunks}
    </YuLink>
  ),
  yuFaqsL: (chunks: string[]) => (
    <YuLink size="L" href="/faqs" nextAs={(PLATFORM_PREFIX || '') + '/faqs'}>
      {chunks}
    </YuLink>
  ),
  yuContactUsL: (chunks: string[]) => (
    <YuLink
      size="L"
      href="/contact-us"
      nextAs={(PLATFORM_PREFIX || '') + '/contact-us'}
    >
      {chunks}
    </YuLink>
  ),
  yuLinkMyProfileLLight: (chunks: string) => (
    <YuLink
      size="L"
      light
      href="/account/my-profile"
      nextAs={(PLATFORM_PREFIX || '') + '/account/my-profile'}
    >
      {chunks}
    </YuLink>
  ),
  tag: () => <div>&trade;</div>,
  terms: (chunks: React.ReactNode) => (
    <a
      target="_blank"
      href={`static?page=${deprecatedRoutePaths.FOOTER.TERMS}`}
      className="custom-link"
    >
      {chunks}
    </a>
  ),
  yuTerms: (chunks: React.ReactNode) => (
    <YuLink
      size="S"
      targetBlank
      href={`/static?page=${deprecatedRoutePaths.FOOTER.TERMS}`}
      nextAs={
        (PLATFORM_PREFIX || '') +
        `/static?page=${deprecatedRoutePaths.FOOTER.TERMS}`
      }
    >
      {chunks}
    </YuLink>
  ),
  privacy: (chunks: React.ReactNode) => (
    <a
      target="_blank"
      href={`static?page=${deprecatedRoutePaths.FOOTER.PRIVACY_POLICY}`}
      className="custom-link"
    >
      {chunks}
    </a>
  ),
  my_profile: (chunks: string) => (
    <a className="custom-link" href={`${chunks}`} target="_blank">
      {chunks}
    </a>
  ),
};

export const getTranslatedData = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: { [key: string]: any },
  key: string,
  locale = '',
  i18nKey?: string
) => {
  // In case there is used inside an useEffect we need to provide the locale
  if (!locale) {
    locale = useIntl().locale;
  }

  const translationKey = i18nKey ?? key;

  const current = data.i18nList?.filter((tran) => tran.language === locale);

  if (current?.length) return current[0][translationKey];

  return data[key];
};

export const customFormatMessage = (
  text: string,
  values?: {
    [key: string]: React.ReactNode;
  }
) => {
  return (
    <FormattedMessage
      id={text}
      values={{ ...customFormatMessageValues, ...values }}
    />
  );
};

export const CustomFormatMessage = (props: {
  id: string;
  values?: {
    [key: string]: React.ReactNode;
  };
}) => {
  const { id, values = {} } = props;

  return <>{customFormatMessage(id, values)}</>;
};

export const buildRegularParagraph = (text: string): JSX.Element => {
  return <p className="regular-body">{customFormatMessage(text)}</p>;
};

export const toRUTFormat = (documentId: string) => {
  return (
    documentId.slice(0, 2) +
    '.' +
    documentId.slice(2, 5) +
    '.' +
    documentId.slice(5, 8) +
    '-' +
    documentId.slice(8)
  );
};
