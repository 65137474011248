import React from 'react';
import { isUSAPlatform } from '../../utils';
import { getBagTotalItems } from '../helpers/getBagTotalItems';

export const useTotalItemsToRemoveBag = ({
  orderRestrictions,
  bagProducts,
}) => {
  const totalInBag = React.useMemo(() => {
    return getBagTotalItems(bagProducts);
  }, [bagProducts]);

  return React.useMemo(() => {
    if (orderRestrictions?.redeemedProductsByTime?.annual) {
      const totalProductsToRemove =
        parseInt(orderRestrictions.redeemedProductsByTime.annual) +
        totalInBag -
        parseInt(
          orderRestrictions.salesRestrictions.maxSaleRestrictions.annual
            .maxTotalProductsByPeriod
        );
      return totalProductsToRemove > 0 ? totalProductsToRemove : 0;
    } else {
      return 0;
    }
  }, [
    orderRestrictions?.redeemedProductsByTime?.annual,
    totalInBag,
    orderRestrictions?.salesRestrictions?.maxSaleRestrictions?.annual
      ?.maxTotalProductsByPeriod,
    isUSAPlatform,
  ]);
};
