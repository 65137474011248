import { NextPage } from 'next';
import {
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';

const StaticSkeleton: NextPage = (): JSX.Element => {
  return (
    <YuSkeletonWrapper
      width="100%"
      pl="XS"
      pr="XS"
      style={{ maxWidth: '1400px', margin: 'auto' }}
    >
      <YuSpacer size="M" />
      <YuSkeleton height={40} width={320} variant="text" alignSelf="start" />

      <YuSpacer size="L" />
      <YuSkeleton height="24px" width="180px" />
      <YuSpacer size="S" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="80%" />

      <YuSpacer size="L" />
      <YuSkeleton height="24px" width="150px" />
      <YuSpacer size="S" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="50%" />
      <YuSpacer size="S" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="70%" />
      <YuSpacer size="S" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="15%" />

      <YuSpacer size="L" />
      <YuSkeleton height="24px" width="220px" />
      <YuSpacer size="S" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="100%" />
      <YuSpacer size="XXS" />
      <YuSkeleton height="20px" width="20%" />
    </YuSkeletonWrapper>
  );
};

export default StaticSkeleton;
