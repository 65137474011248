import { IUser } from '../shared/models';
import _ from 'lodash';
import { PlatformUserStatusEnum } from '../shared/enums';
import { userIsDermatologistSpecialty } from '../helpers/userIsDermatologistSpecialty';

export const useShowUserProfileSpecialtyExtraFields = (
  user: IUser
): boolean => {
  if (
    ![
      PlatformUserStatusEnum.GRANT,
      PlatformUserStatusEnum.MIGRATE_GRANT,
      PlatformUserStatusEnum.GRANT_FIRST_LOGIN,
    ].includes(user?.userStatus)
  )
    return false;
  return (
    !!user?.salesforce.birthdate &&
    !!user?.currentPosition &&
    !_.isEmpty(user?.specialtyAreas) &&
    userIsDermatologistSpecialty(user)
  );
};
