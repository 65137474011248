export const SkeletonChallengeCard = (): JSX.Element => {
  return (
    <section className="skeleton-card">
      <div className="skeleton-card--media" />
      <div className="skeleton-card--title" />
      <div className="skeleton-card--details">
        <div className="skeleton-card--details__column">
          <div className="skeleton-card--details__item" />
          <div className="skeleton-card--details__item" />
        </div>
        <div className="skeleton-card--details__column">
          <div className="skeleton-card--details__item" />
          <div className="skeleton-card--details__item" />
        </div>
      </div>
      <div className="skeleton-card--link skeleton-button" />
    </section>
  );
};
