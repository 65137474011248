import { isBRPlatform, isPEPlatform } from '../../utils';

/**
 * get specific regex rule. returns non-white space by default
 *
 * @param value dni given value to validate
 * @returns RegExp
 */
export function validatePlatformDNI(value: string) {
  if (isPEPlatform) return /^\d{8}$/.test(value);
  if (isBRPlatform) return /^\d{11}$/.test(value);

  return /\S+/.test(value);
}
