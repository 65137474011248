import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { IChallengeResourceConfig } from 'src/shared/models/challengeDetail.model';

export interface IChallengeMaterials extends IChallengeResourceConfig {
  description: string;
  i18nList: string[];
  idResource: number;
  materials: IMaterial[];
  points: number;
  resource: IResourceList;
  title: string;
}

export interface IMaterial {
  content: string;
  description: string;
  downloaded: boolean;
  href: string;
  i18nList: string[];
  idResource: number;
  image: string;
  name: string;
  order: number;
  points: number;
  type: MaterialTypeEnum;
}

export enum MaterialTypeEnum {
  // YOUTUBE_URL = 1, //! DEPRECATED:
  VIDEO = 2,
  IMAGE = 3,
  FILE = 4,
}
