import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IChallengeExternalRedeemPointsPoints } from 'src/shared/models';

const {
  EXTERNAL_REDEEM_POINTS_POINTS_VALUE,
  EXTERNAL_REDEEM_POINTS_POINTS_CARD_LABEL,
  EXTERNAL_REDEEM_POINTS_POINTS_ACCEPTANCE_MESSAGE,
  EXTERNAL_REDEEM_POINTS_POINTS_COUPON_CODE,
} = ResourceTypeDetailEnum;

export const buildChallengeExternalRedeemPointsPoints = (params: {
  resource: IResourceList;
  minimPointsToRender: number;
}): IChallengeExternalRedeemPointsPoints => {
  const { resource, minimPointsToRender } = params;

  let _properties: IChallengeExternalRedeemPointsPoints;

  resource.resourceDetailList
    .filter((point) => point.status)
    .forEach((_detail) => {
      const { idResourceTypeD } = _detail.idResourceTypeD;

      switch (idResourceTypeD) {
        case EXTERNAL_REDEEM_POINTS_POINTS_VALUE:
          _properties = {
            ..._properties,
            points: parseInt(_detail.value),
          };
          break;
        case EXTERNAL_REDEEM_POINTS_POINTS_CARD_LABEL:
          _properties = {
            ..._properties,
            pointsMessage: _detail.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_POINTS_ACCEPTANCE_MESSAGE:
          _properties = {
            ..._properties,
            acceptanceMessage: _detail.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_POINTS_COUPON_CODE:
          _properties = {
            ..._properties,
            couponCode: _detail.value,
          };
          break;
      }
    });

  _properties = {
    ..._properties,
    id: resource.idResource,
    isSelected: false,
    isDisabled: minimPointsToRender < _properties.points,
  };

  return _properties;
};
