import getConfig from 'next/config';
import { IUser } from 'src/shared/models';
const {
  SALESFORCE_INSTANCE,
  BASE_LIVE_AGENT_CONTENT_URL,
  BASE_LIVE_AGENT_URL,
  ORDER_ID,
  CUSTOMER_CARE_ISDIN_CHATS,
} = getConfig().publicRuntimeConfig;

export interface IChatCustomerCare {
  active: boolean;
  name: string;
  deploymentID: string;
  buttonID: string;
  eswLiveAgentDevName: string;
  schedule_start: string;
  schedule_end: string;
  type: string;
}

export const injectEmbeddedService = (params: {
  targetURL: string;
  parameters?: IChatCustomerCare;
  user: IUser;
}) => {
  const { parameters, targetURL, user } = params;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const embedded_svc = window?.embedded_svc;

  if (!embedded_svc || embedded_svc?.hasSessionDataLoaded) return false;

  embedded_svc.settings.displayHelpButton = true; //Or false
  embedded_svc.settings.language = 'es-ES'; //For example, enter 'en' or 'en-US'

  // Images
  embedded_svc.settings.prechatBackgroundImgURL =
    'https://love.isdin.com/assets/bundles/app/img/salesforce-Chat/PreFormBanner.jpg';
  embedded_svc.settings.avatarImgURL =
    'https://love.isdin.com/assets/bundles/app/img/salesforce-Chat/ChatAvatar.png';
  embedded_svc.settings.smallCompanyLogoImgURL =
    'https://www-staging.cdn.love.isdin.com/20220324090046/isdin_logo.png';

  embedded_svc.settings.defaultMinimizedText = '¿Necesitas ayuda?'; //(Defaults to Chat with an Expert)
  embedded_svc.settings.disabledMinimizedText = 'Chat offline'; //(Defaults to Agent Offline)
  embedded_svc.settings.loadingText = 'Cargando'; //(Defaults to Loading)

  //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

  embedded_svc.settings.prepopulatedPrechatFields = {
    FirstName: user?.salesforce?.userFirstname,
    LastName: user?.salesforce?.userSurname,
    Email: user?.salesforce?.userMail,
  };

  embedded_svc.settings.extraPrechatFormDetails = [
    { label: 'Origin', value: 'Chat', displayToAgent: true },
    { label: 'Reported By', value: 'Cliente', displayToAgent: true },
    { label: 'Priority', value: '3', displayToAgent: true },
    {
      label: 'Org. Ventas',
      value: 'Org. Ventas ISDIN ES',
      displayToAgent: true,
    },
    { label: 'Canal_Venta', value: 'Online', displayToAgent: true },
    { label: 'Tienda', value: 'M\u00E9dico', displayToAgent: true },
  ];

  embedded_svc.settings.extraPrechatInfo = [
    {
      entityFieldMaps: [
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Origin',
          isExactMatch: true,
          label: 'Origin',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Caso_Reportado_por__c',
          isExactMatch: true,
          label: 'Reported By',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Priority',
          isExactMatch: true,
          label: 'Priority',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Org_Ventas__c',
          isExactMatch: true,
          label: 'Org. Ventas',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Canal_Venta__c',
          isExactMatch: true,
          label: 'Canal_Venta',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'Tienda__c',
          isExactMatch: true,
          label: 'Tienda',
        },
      ],
      entityName: 'Case',
    },
    {
      entityFieldMaps: [
        {
          doCreate: true,
          doFind: false,
          fieldName: 'LastName',
          isExactMatch: false,
          label: 'Last Name',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'FirstName',
          isExactMatch: false,
          label: 'First Name',
        },
        {
          doCreate: true,
          doFind: true,
          fieldName: 'Email',
          isExactMatch: true,
          label: 'Email',
        },
      ],
      entityName: 'Contact',
      saveToTranscript: 'Contact',
      showOnCreate: true,
    },
  ];
  //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
  //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

  embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  embedded_svc.settings.entryFeature = 'LiveAgent';

  embedded_svc.init(
    SALESFORCE_INSTANCE,
    CUSTOMER_CARE_ISDIN_CHATS,
    targetURL,
    ORDER_ID,
    parameters.name,

    {
      baseLiveAgentContentURL: BASE_LIVE_AGENT_CONTENT_URL,
      deploymentId: parameters.deploymentID,
      buttonId: parameters.buttonID,
      baseLiveAgentURL: BASE_LIVE_AGENT_URL,
      eswLiveAgentDevName: parameters.eswLiveAgentDevName,
      isOfflineSupportEnabled: false,
    }
  );

  return embedded_svc;
};
