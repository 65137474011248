import { renderCampaignDetail } from '../src/components/challenge/ChallengeCard';
import { ContainerCardsColumns } from '../src/shared/enums';

export class ChallengeCardDetails {
  constructor(
    private column: ContainerCardsColumns,
    private component: JSX.Element
  ) {}

  public getComponent(): JSX.Element {
    return this.component;
  }

  public isLeftColumn(): boolean {
    return this.column === ContainerCardsColumns.LEFT;
  }

  public isRightColumn(): boolean {
    return this.column === ContainerCardsColumns.RIGHT;
  }
}

export class ChallengeCardDetailsBuilder {
  private details: ChallengeCardDetails[] = [];
  private maxDetailsPerColumn = 2;
  private readonly formatNumber: (value: number) => string;

  constructor(formatNumber: (value: number) => string) {
    this.formatNumber = formatNumber;
  }

  private _checkPosition(details: ChallengeCardDetails[]) {
    return details.length;
  }

  private _addDetail(detail: JSX.Element) {
    const position = this._checkPosition(this.details);
    const column =
      position < this.maxDetailsPerColumn
        ? ContainerCardsColumns.LEFT
        : ContainerCardsColumns.RIGHT;
    const details = new ChallengeCardDetails(column, detail);

    this.details = [...this.details, details];
  }

  public haveTwoColumns(): boolean {
    return this.details.length > this.maxDetailsPerColumn;
  }

  public buildLeftColumn(): JSX.Element[] {
    return this.details
      .filter((detail) => detail.isLeftColumn())
      .map((detail) => detail.getComponent());
  }

  public buildRightColumn(): JSX.Element[] {
    return this.details
      .filter((detail) => detail.isRightColumn())
      .map((detail) => detail.getComponent());
  }

  addActionDetail(actions: number): ChallengeCardDetailsBuilder {
    const detail = renderCampaignDetail(
      'menu',
      'page.challenge-card.{actions}-actions',
      { actions }
    );

    this._addDetail(detail);

    return this;
  }

  addDateDetail(
    startDate: string,
    endDate: string
  ): ChallengeCardDetailsBuilder {
    const detail = renderCampaignDetail(
      'calendar',
      'page.challenge-card.{startDate}-{endDate}',
      { startDate, endDate }
    );
    this._addDetail(detail);
    return this;
  }

  addPointsDetail(points: number): ChallengeCardDetailsBuilder {
    const detail = renderCampaignDetail(
      'puntos',
      'page.challenge.plus-sign-{points}-points',
      { points: this.formatNumber(points) },
      true
    );
    this._addDetail(detail);
    return this;
  }

  addDurationDetail(duration: number): ChallengeCardDetailsBuilder {
    const detail = renderCampaignDetail(
      'clock',
      'page.challenge.duration-time',
      { duration }
    );
    this._addDetail(detail);
    return this;
  }

  addTalkDetail(): ChallengeCardDetailsBuilder {
    const detail = renderCampaignDetail(
      'event-hybrid',
      'page.challenge.event',
      {}
    );
    this._addDetail(detail);
    return this;
  }

  addCongressDetail(): ChallengeCardDetailsBuilder {
    const detail = renderCampaignDetail('bank', 'page.challenge.congress', {});
    this._addDetail(detail);
    return this;
  }

  build(): ChallengeCardDetailsBuilder {
    return this;
  }
}
