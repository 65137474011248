import { buildExpertsGroup } from 'src/components/challenge/builder';
import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import {
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
  UserResourceEnum,
} from 'src/shared/enums';
import {
  IChallenge,
  IChallengeEvent,
  IChallengeExpertsGroup,
  IChallengeResource,
  IEventStatus,
  IEventWatchAgain,
  IUserStatus,
} from 'src/shared/models';
import {
  isAttendAvailable,
  isEventExpired,
  isEventInProgress,
  isInscriptionAvailable,
} from '../../../../utils';
import { getTimeZone } from 'utils/timeUtils';
import ChallengeExpertsGroup from '../ChallengeExpertsGroup';
import { DateTime } from 'luxon';

const {
  CANCELED,
  CAPACITY,
  CITY,
  CODE,
  ENABLED,
  EVENT_CONTENT,
  EVENT_END,
  EVENT_START,
  FULL,
  IN_PROGRESS,
  LOCATION,
  MAPS_URL,
  ONLINE,
  PRE_INSCRIPTION_TEXT,
  URL,
  WATCH_AGAIN_YT_ID,
  WATCH_AGAIN_ONLY_ATTENDED_USERS,
  TIME_TO_SHOW_LINK,
} = ResourceTypeDetailEnum;

const { ATTEND, INSCRIPTION, EXPERTS_GROUP } = ResourceTypeEnum;
const { DONE, SIGNED, WAITING_LIST, CONFIRM_ATTEND } = UserResourceEnum;

export const buildChallengeEvents = (
  sortedResources: IResourceList[],
  challenge: IChallenge,
  events: IResourceList[],
  challengeResources?: IChallengeResource[]
): IChallengeEvent[] => {
  const performedStatus = [SIGNED, WAITING_LIST, DONE, CONFIRM_ATTEND];
  const challengeEvents: IChallengeEvent[] = [];
  let eventType: undefined | 'online' | 'onSite';
  let startEventDate: DateTime;
  let endEventDate: DateTime;
  let status: IEventStatus;
  let attend: IUserStatus;
  let inscription: IUserStatus;
  let watchAgain: IEventWatchAgain;
  let timeToShowLink: number;

  const { startDate: _startDate, endDate: _endDate, startVisDate } = challenge;

  const endDate = DateTime.fromJSDate(new Date(_endDate), {
    zone: 'utc',
  }).setZone(getTimeZone());
  const startDate = DateTime.fromJSDate(new Date(_startDate), {
    zone: 'utc',
  }).setZone(getTimeZone());

  const _events = events.filter((event) => event.status);

  const challengeExpertsGroup = challenge.resourceList.filter(
    (_resource) =>
      _resource.idResourceType.idResourceType ===
        ResourceTypeEnum.EXPERTS_GROUP && _resource.status
  );

  const challengeSpeakers = challenge.resourceList.filter(
    (_resource) =>
      _resource.idResourceType.idResourceType === ResourceTypeEnum.SPEAKER &&
      _resource.status
  );

  _events.forEach((event) => {
    const {
      resourceDetailList,
      resourceList,
      i18nList,
      name,
      survey,
      idResource,
    } = event;

    let challengeEvent: IChallengeEvent;
    let isMain = _events.length == 1;
    let points = survey?.score.points || 0;

    let expertGroup: IResourceList;
    let eventSpeakers: IResourceList[];
    let expertsGroupSortedResources: IResourceList[];

    resourceList
      .filter((resource) => resource.status)
      .forEach((_detail) => {
        const {
          idResourceType: { idResourceType },
          score,
          idResource,
          usersM2MList: [usersM2MList],
        } = _detail;

        const statusResource = usersM2MList?.statusResource as UserResourceEnum;
        const isPerformed = performedStatus.includes(statusResource);

        points += score?.points ? score.points : 0;
        isMain = isMain || isPerformed;

        const data = {
          points: score?.points,
          idResource,
          usersM2MList,
          isPerformed,
          statusResource,
          inWaitingList: statusResource === UserResourceEnum.WAITING_LIST,
        };

        expertGroup = challengeExpertsGroup.find(
          (_expertGroup) => _expertGroup.indResource === event.idResource
        );

        eventSpeakers = challengeSpeakers.filter(
          (_speaker) => _speaker.indResource === expertGroup?.idResource
        );

        expertsGroupSortedResources = [expertGroup].concat(eventSpeakers);

        let expertsGroupConfig: IChallengeExpertsGroup;

        switch (idResourceType) {
          case ATTEND:
            attend = {
              ...attend,
              ...data,
            };
            break;
          case INSCRIPTION:
            inscription = {
              ...inscription,
              ...data,
            };
            break;
          case EXPERTS_GROUP:
            expertGroup = {
              ...expertGroup,
              resourceList: expertsGroupSortedResources,
            };
            expertsGroupConfig = buildExpertsGroup(
              expertGroup,
              sortedResources
            );

            challengeResources.push({
              key: `challenge--experts-group-${expertGroup.idResource}`,
              idResource: expertGroup.idResource,
              type: EXPERTS_GROUP,
              resource: expertGroup,
              config: expertsGroupConfig,
              render: () => {
                return <ChallengeExpertsGroup config={expertsGroupConfig} />;
              },
            });
            break;
        }
      });

    resourceDetailList
      .filter((resource) => resource.status)
      .forEach((_detail) => {
        const { idResourceTypeD } = _detail.idResourceTypeD;

        switch (idResourceTypeD) {
          case ENABLED:
            status = {
              ...status,
              isEnabled: _detail.value === 'true',
            };
            break;
          case IN_PROGRESS:
            status = {
              ...status,
              isInProgress: _detail.value === 'true',
            };
            break;
          case FULL:
            status = {
              ...status,
              isFull: _detail.value === 'true',
            };
            break;
          case CANCELED:
            status = {
              ...status,
              isCanceled: _detail.value === 'true',
            };
            break;
          case ONLINE:
            eventType = _detail.value === 'true' ? 'online' : 'onSite';
            break;
          case LOCATION:
            challengeEvent = {
              ...challengeEvent,
              location: _detail.value,
            };
            break;
          case EVENT_START:
            startEventDate = _detail.value
              ? DateTime.fromJSDate(new Date(_detail.value), {
                  zone: 'utc',
                }).setZone(getTimeZone())
              : null;
            break;
          case EVENT_END:
            endEventDate = _detail.value
              ? DateTime.fromJSDate(new Date(_detail.value), {
                  zone: 'utc',
                }).setZone(getTimeZone())
              : null;
            break;
          case CITY:
            challengeEvent = {
              ...challengeEvent,
              city: _detail.value,
            };
            break;
          case CAPACITY:
            challengeEvent = {
              ...challengeEvent,
              capacity: _detail.value,
            };
            break;
          case EVENT_CONTENT:
            challengeEvent = {
              ...challengeEvent,
              content: _detail.value,
            };
            break;
          case URL:
            challengeEvent = {
              ...challengeEvent,
              url: _detail.value,
            };
            break;
          case CODE:
            challengeEvent = {
              ...challengeEvent,
              code: _detail.value,
            };
            break;
          case PRE_INSCRIPTION_TEXT:
            challengeEvent = {
              ...challengeEvent,
              preInscriptionText: _detail.value,
            };
            break;
          case MAPS_URL:
            challengeEvent = {
              ...challengeEvent,
              urlMap: _detail.value,
            };
            break;
          case WATCH_AGAIN_YT_ID:
            watchAgain = {
              ...watchAgain,
              videoID: _detail.value?.trim(),
              isInformed: Boolean(_detail.value?.trim()),
            };
            break;
          case WATCH_AGAIN_ONLY_ATTENDED_USERS:
            watchAgain = {
              ...watchAgain,
              isPublic: _detail.value !== 'true',
            };
            break;
          case TIME_TO_SHOW_LINK:
            timeToShowLink = parseInt(_detail.value);
            break;
        }
      });

    watchAgain = {
      ...watchAgain,
      id: `challenge-watch-again-${idResource}`,
      shouldRender:
        isEventExpired(endEventDate) &&
        watchAgain?.isInformed &&
        (attend.isPerformed || watchAgain?.isPublic),
    };

    if (startEventDate) {
      //Get time
      attend = {
        ...attend,
        isAvailable: isAttendAvailable(
          startEventDate,
          endEventDate,
          timeToShowLink ?? 15
        ),
      };
      inscription = {
        ...inscription,
        isAvailable: isInscriptionAvailable(startVisDate, startEventDate),
      };
    }

    status = {
      ...status,
      isExpired: isEventExpired(endEventDate),
      isInProgress: isEventInProgress(startEventDate, endEventDate),
      isOnline: eventType == 'online',
      isOnsite: eventType == 'onSite',
    };

    let eventStatusLabel = null;

    switch (true) {
      case status?.isCanceled:
        eventStatusLabel = 'canceled';
        break;
      case status?.isExpired:
        eventStatusLabel = 'expired';
        break;
      case status?.isFull:
        eventStatusLabel = 'sold-out';
        break;
      case status?.isInProgress:
        eventStatusLabel = 'on-live';
        break;
      case DateTime.now().setZone(getTimeZone()).diff(startEventDate, 'days')
        .days <= 3:
        eventStatusLabel = 'new';
        break;
    }

    if (!startEventDate) return;

    challengeEvent = {
      ...challengeEvent,
      watchAgain,
      idResource,
      status,
      eventType,
      dates: {
        startDate,
        endDate,
        attendDate: startEventDate,
        endEventDate,
        startEventDate,
      },
      attend,
      inscription,
      isMain,
      i18nList,
      name,
      survey,
      points,
      timeToShowLink,
      eventStatusLabel,
    };

    challengeEvents.push(challengeEvent);
  });

  return challengeEvents.sort((a, b) =>
    a.dates.startEventDate > b.dates.startEventDate ? 1 : -1
  );
};
