export enum Countries {
  SPAIN = 'España',
  ANDORRA = 'Andorra',
  PORTUGAL = 'Portugal',
  BRAZIL = 'Brasil',
  ITALY = 'Italia',
  SAN_MARINO = 'San Marino',
  GERMANY = 'Deutschland',
  AUSTRIA = 'Österreich',
  UNITED_STATES = 'United States',
  ARGENTINA = 'Argentina',
  CHILE = 'Chile',
  COLOMBIA = 'Colombia',
  MEXICO = 'México',
  PERU = 'Perú',
  GUATEMALA = 'Guatemala',
  VATICAN_CITY = 'Vaticano',
}

export type CountryInfo = {
  platform: string;
  specialCountry: string | null;
  countryCode: string;
};

export const CountryInfoMap: Record<Countries, CountryInfo> = {
  [Countries.SPAIN]: {
    platform: 'med/es-ES',
    specialCountry: null,
    countryCode: 'ES',
  },
  [Countries.ANDORRA]: {
    platform: 'med/es-ES',
    specialCountry: '?country=AD',
    countryCode: 'AD',
  },
  [Countries.PORTUGAL]: {
    platform: 'med/pt-PT',
    specialCountry: null,
    countryCode: 'PT',
  },
  [Countries.BRAZIL]: {
    platform: 'med/pt-BR',
    specialCountry: null,
    countryCode: 'BR',
  },
  [Countries.ITALY]: {
    platform: 'med/it-IT',
    specialCountry: null,
    countryCode: 'IT',
  },
  [Countries.SAN_MARINO]: {
    platform: 'med/it-IT',
    specialCountry: '?country=SM',
    countryCode: 'SM',
  },
  [Countries.VATICAN_CITY]: {
    platform: 'med/it-IT',
    specialCountry: '?country=VA',
    countryCode: 'VA',
  },
  [Countries.GERMANY]: {
    platform: 'med/de-DE',
    specialCountry: null,
    countryCode: 'DE',
  },
  [Countries.AUSTRIA]: {
    platform: 'med/de-DE',
    specialCountry: '?country=AT',
    countryCode: 'AT',
  },
  [Countries.UNITED_STATES]: {
    platform: '/en-US',
    specialCountry: null,
    countryCode: 'US',
  },
  [Countries.ARGENTINA]: {
    platform: 'med/es-AR',
    specialCountry: null,
    countryCode: 'AR',
  },
  [Countries.CHILE]: {
    platform: 'med/es-CL',
    specialCountry: null,
    countryCode: 'CL',
  },
  [Countries.COLOMBIA]: {
    platform: 'med/es-CO',
    specialCountry: null,
    countryCode: 'CO',
  },
  [Countries.MEXICO]: {
    platform: 'med/es-MX',
    specialCountry: null,
    countryCode: 'MX',
  },
  [Countries.PERU]: {
    platform: 'med/es-PE',
    specialCountry: null,
    countryCode: 'PE',
  },
  [Countries.GUATEMALA]: {
    platform: 'med/es-GT',
    specialCountry: null,
    countryCode: 'GT',
  },
};
