import {
  IModalJourneyCollection,
  IModalJourneyIterator,
  IModalJourneySteps,
} from './modal_journey.interfaces';

export class JourneyModalIterator implements IModalJourneyIterator {
  private collection: JourneyModalsCollection;
  private stepController: React.MutableRefObject<number>;
  private hideModal: () => void;

  constructor(params: { closeJourney: () => void }) {
    this.collection = new JourneyModalsCollection();

    const { closeJourney } = params;

    this.hideModal = closeJourney;
  }

  public setStepController(
    stepController: React.MutableRefObject<number>
  ): void {
    this.stepController = stepController;
  }

  public getCurrentStepContent(): JSX.Element {
    return this.getCurrentStep()?.content;
  }

  public getCurrentStepCustomClass(): string {
    return this.getCurrentStep()?.legacyCustomClass;
  }

  public addStep(contentModal: IModalJourneySteps): void {
    this.collection.addStep(contentModal);
  }

  public closeJourney(): void {
    this.hideModal();
  }

  public nextStep(): void {
    const _postion = this.position + 1;

    if (_postion >= this.collection.stepsNumber) {
      this.closeJourney();
      return;
    }

    this.stepController.current = _postion;
  }

  public resetSteps(): void {
    this.stepController.current = 0;
  }

  private get position() {
    return this.stepController.current;
  }

  private getCurrentStep(): IModalJourneySteps {
    return this.collection.getJourneyItem([this.position]);
  }
}

class JourneyModalsCollection implements IModalJourneyCollection {
  private journeySteps: IModalJourneySteps[] = [];

  public getJourneyItem(position): IModalJourneySteps {
    return this.journeySteps[position];
  }

  public get stepsNumber(): number {
    return this.journeySteps.length;
  }

  public addStep(contentModal: IModalJourneySteps): void {
    this.journeySteps.push(contentModal);
  }
}
