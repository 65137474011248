import { config } from 'config/config';
import {
  IResourceDetailList,
  IResourceList,
} from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum } from 'src/shared/enums';
import { IChallengeImage } from 'src/shared/models';

const PNG = 'image/png';
const JPG = 'image/jpg';
const JPEG = 'image/jpeg';

abstract class IChallengeUserUploadMediaFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(visibility: IChallengeImage): IChallengeImage;
}

class DescriptionFiller extends IChallengeUserUploadMediaFiller {
  fill(visibility: IChallengeImage): IChallengeImage {
    return {
      ...visibility,
      description: this.detail.value,
    };
  }
}

class AllowedExtensionsFiller extends IChallengeUserUploadMediaFiller {
  fill(visibility: IChallengeImage): IChallengeImage {
    const url = this.detail.value;
    let allowedExtensions: string[] = [];
    allowedExtensions = [PNG, JPG, JPEG];

    return {
      ...visibility,
      allowedExtensions,
      url: url?.trim() ? url : config.APP.MARKETING_MATERIALS.DEFAULT_IMAGE_BG,
    };
  }
}

class ChallengeImageFactory {
  private readonly userUploadMedia: Map<
    ResourceTypeDetailEnum,
    IChallengeUserUploadMediaFiller
  >;

  constructor(detail: IResourceDetailList) {
    this.userUploadMedia = new Map();
    this.userUploadMedia.set(
      ResourceTypeDetailEnum.IMAGE_DESCRIPTION,
      new DescriptionFiller(detail)
    );
    this.userUploadMedia.set(
      ResourceTypeDetailEnum.IMAGE_URL,
      new AllowedExtensionsFiller(detail)
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    visibility: IChallengeImage
  ): IChallengeImage {
    const visibilityFiller = this.userUploadMedia.get(idResourceTypeD);

    if (visibilityFiller) {
      return visibilityFiller.fill(visibility);
    }

    return visibility;
  }
}

class ChallengeImageBuilder {
  public build(
    resourceList: IResourceList,
    idChallenge: number
  ): IChallengeImage {
    let visibility: IChallengeImage;

    resourceList.resourceDetailList
      .filter((resourceGuide) => resourceGuide.status)
      .forEach((detail) => {
        const Factory = new ChallengeImageFactory(detail);

        visibility = Factory.fill(detail, visibility);
      });

    return {
      ...visibility,
      idChallenge,
      idResource: resourceList.idResource,
      title: resourceList.name,
    };
  }
}

export const buildChallengeImage = (
  resourceList: IResourceList,
  idChallenge: number
): IChallengeImage => {
  const builder = new ChallengeImageBuilder();

  return builder.build(resourceList, idChallenge);
};
