import { CloseOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { Rule, RuleObject } from 'antd/lib/form';
import { Store, StoreValue } from 'antd/lib/form/interface';
import { config } from 'config/config';
import { FormattedMessage } from 'react-intl';
import {
  PIN_INFILTRATED_COUNTRIES_Enum,
  PIN_INFILTRATED_COUNTRIES_NAMES_Enum,
  PIN_PlatformID_Enum,
  SalesforceMedicalCenter,
} from 'src/shared/enums';
import { ICustomerAddress, ISalesforceAddress, IUser } from 'src/shared/models';
import { isESPlatform } from 'utils/platformUtils';
import React from 'react';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';
import { MessageDescriptor } from '@formatjs/intl/src/types';

export const ZIPCODE_LENGTH_parcial = 3;
export const PLATFORM_EQUAL_CP_LENGTH = [
  PIN_PlatformID_Enum.PIN_CHILE,
  PIN_PlatformID_Enum.PIN_MEXICO,
  PIN_PlatformID_Enum.PIN_PORTUGAL,
  PIN_PlatformID_Enum.PIN_SPAIN,
];

export const SHOW_EXTRA_FIELDS_REGISTER = isESPlatform;

export const isDefaultRequired = (
  updatedAddress: Store,
  address: ICustomerAddress,
  key: 'default_billing' | 'default_shipping'
) => {
  if (updatedAddress[key]) return false;

  return address?.[key];
};

export const getSalesforceEmptyAddress = (user: IUser): ISalesforceAddress => {
  switch (true) {
    case Object.values(PIN_PlatformID_Enum).includes(config.APP.PLATFORM):
      return {
        active: true,
        addressID: null,
        addressBilling: false,
        addressCity: '',
        addressCountry: config.REGISTER.COUNTRY_CODE,
        addressCP: '',
        addressDelivery: false,
        addressName: '',
        addressNumber: '',
        addressState: '',
        addressStateName: '',
        addressStreet: '',
        addressType: 'Consumidor',
        country: config.REGISTER.COUNTRY,
        medicalCenterType: getPlatformDefaultMedicalCenterType(),
        PlatformID: config.APP.PLATFORM,
        primary: false,
        userID: user.uuidUser,
      };

    default:
      throw `Not implemented to platform: ${config.APP.PLATFORM}`;
  }
};

export const patternValidation = {
  pattern: /^[^*/\\;#|€$"'~]*$/,
  message: 'form.field.invalid-characters',
};

export const addressValidation = (stringToTest: string): boolean => {
  return patternValidation.pattern.test(stringToTest);
};

export const getPlatformDefaultMedicalCenterType =
  (): SalesforceMedicalCenter => {
    let medicalCenterType = SalesforceMedicalCenter.OTHER;

    switch (config.APP.PLATFORM) {
      case PIN_PlatformID_Enum.PIN_ITALY:
      case PIN_PlatformID_Enum.PIN_BRAZIL:
        medicalCenterType = SalesforceMedicalCenter.OTHER;
        break;
    }

    return medicalCenterType;
  };

export const renderAddressTitle = (props: {
  closeAddress: () => void;
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions
  ) => string;
  editAddress: boolean;
  address?: ISalesforceAddress;
}) => {
  const { closeAddress, formatMessage, address, editAddress } = props;

  let toggleTitle: string;

  switch (true) {
    case !editAddress:
      toggleTitle = 'form.address.add-new';
      break;
    case editAddress:
      toggleTitle = 'form.address.edit-title';
      break;
    default:
      toggleTitle = address.addressName;
      break;
  }

  return (
    <Row className="accountPage__section">
      <span className="page-title">
        {toggleTitle ? formatMessage({ id: `${toggleTitle}` }) : ''}
      </span>

      <div className="address-close-button">
        <Button
          className="btn-ghost btn-ghost--minimalist"
          icon={<CloseOutlined />}
          onClick={closeAddress}
        />
      </div>
    </Row>
  );
};

export const getAddressPostalCodeRulesByPlatform = ({
  storedCountry = '',
}: {
  storedCountry: string;
}): RuleObject[] => {
  const rules: RuleObject[] = [];
  let validator: Validator;
  const postalLength = Number(
    getPlatformPostalCodeMaxLength({ storedCountry })
  );

  switch (config.APP.PLATFORM) {
    case PIN_PlatformID_Enum.PIN_CHILE:
      validator = (
        rule: Rule,
        value: string,
        callback: (error?: React.ReactNode) => void
      ) => {
        if (!value) return callback();

        const _valueLength = value?.trim()?.match(/(\d+)/)?.pop()?.length || 0;

        if (_valueLength < postalLength) {
          callback(
            <FormattedMessage
              id="form.field.postal-code.length-rule"
              values={{ length: postalLength }}
            />
          );
        } else {
          callback();
        }
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rules.push({ validator });
      break;
    case PIN_PlatformID_Enum.PIN_ITALY:
    case PIN_PlatformID_Enum.PIN_MEXICO:
    case PIN_PlatformID_Enum.PIN_ALEMANIA:
    case PIN_PlatformID_Enum.PIN_SPAIN:
      validator = (
        rule: Rule,
        value: string,
        callback: (error?: React.ReactNode) => void
      ) => {
        if (!value) return callback();

        const _valueLength = value?.trim()?.match(/(\d+)/)?.pop()?.length || 0;

        if (_valueLength != postalLength) {
          callback(
            <FormattedMessage
              id="form.field.postal-code.length-rule"
              values={{ length: postalLength }}
            />
          );
        } else {
          callback();
        }
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rules.push({ validator });
      break;

    case PIN_PlatformID_Enum.PIN_PORTUGAL:
      validator = (
        rule: Rule,
        value: string,
        callback: (error?: React.ReactNode) => void
      ) => {
        if (!value) return callback();
        const numberOfDigits = postalLength - 1; // 7;

        const _valueLength =
          value?.trim()?.match(/\d+/g)?.join('')?.length || 0;

        if (_valueLength < numberOfDigits) {
          callback(
            <FormattedMessage
              id="form.field.postal-code.length-rule"
              values={{ length: numberOfDigits }}
            />
          );
        } else {
          callback();
        }
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rules.push({ validator });

      break;
  }

  return rules;
};

export const getPlatformPostalCodeMaxLength = ({
  storedCountry = '',
}: {
  storedCountry?: string;
}): number => {
  const MAX_LENGTH_ZIP_ANDORRA = 3;
  const MAX_LENGTH_ZIP_ALEMANIA = 5;
  const MAX_LENGTH_ZIP_AUSTRIA = 4;

  switch (config.APP.PLATFORM) {
    case PIN_PlatformID_Enum.PIN_ALEMANIA:
      if (
        storedCountry === PIN_INFILTRATED_COUNTRIES_Enum.ALEMANIA ||
        storedCountry === PIN_INFILTRATED_COUNTRIES_NAMES_Enum.ALEMANIA
      )
        return MAX_LENGTH_ZIP_ALEMANIA;

      if (
        storedCountry === PIN_INFILTRATED_COUNTRIES_Enum.AUSTRIA ||
        storedCountry === PIN_INFILTRATED_COUNTRIES_NAMES_Enum.AUSTRIA
      )
        return MAX_LENGTH_ZIP_AUSTRIA;
      break;

    case PIN_PlatformID_Enum.PIN_SPAIN:
      if (
        storedCountry === PIN_INFILTRATED_COUNTRIES_Enum.ANDORRA ||
        storedCountry === PIN_INFILTRATED_COUNTRIES_NAMES_Enum.ANDORRA
      )
        return MAX_LENGTH_ZIP_ANDORRA;

      if (
        storedCountry === PIN_INFILTRATED_COUNTRIES_Enum.ESPAÑA ||
        storedCountry === PIN_INFILTRATED_COUNTRIES_NAMES_Enum.ESPAÑA
      )
        return Number(config.REGISTER.ZIPCODE_LENGTH);

      break;

    case PIN_PlatformID_Enum.PIN_PORTUGAL:
      return Number(config.REGISTER.ZIPCODE_LENGTH);

    default:
      return Number(config.REGISTER.ZIPCODE_LENGTH);
  }
};

declare type Validator = (
  rule: RuleObject,
  value: StoreValue,
  callback: (error?: React.ReactNode) => void
) => Promise<void> | void;
