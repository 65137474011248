import getConfig from 'next/config';
import { ReduxPageContext } from 'src/store';
import { _reAuthenticate } from '../src/actions/authActions';
const { API_BASE_URL } = getConfig().publicRuntimeConfig;

export const getTimeZone = (): string => {
  return getConfig().publicRuntimeConfig.TIMEZONE_NAME;
};

export const fetchInitialData = async (
  accessToken: string,
  path: string,
  ctx: ReduxPageContext,
  id?: string,
  avoidReAuthenticate?: boolean,
) => {
  const url = id ? `${API_BASE_URL}${path}/${id}` : `${API_BASE_URL}${path}`;

  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (res.status === 200) {
    const data = await res.json();

    return await data;
  } else if (res.status === 401) {
    if (avoidReAuthenticate) return null;

    const { newAccessToken } = (await _reAuthenticate(ctx)) || {};

    if (newAccessToken) {
      const refreshedRes = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${newAccessToken}`,
        },
      });

      if (refreshedRes.status === 200) return await refreshedRes.json();

      return null;
    }
  }

  return null;
};
