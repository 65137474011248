import { Col, Row } from 'antd';
import router, { NextRouter } from 'next/router';
import { DateTime } from 'luxon';
import { ParsedUrlQuery } from 'querystring';
import React, { useEffect, useMemo, useRef } from 'react';
import { Dispatch } from 'redux';
import * as api from 'services/api';
import { getDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { UpdateUserPoints } from 'src/actions/authActions';
import {
  IResourceBehavior,
  IResourceDetailList,
  IResourceList,
} from 'src/components/challenge/ChallengeInterfaces';
import {
  renderChallengePearl,
  renderLinkedPearl,
} from 'src/components/pearl/PearlRenders';
import { renderChallengeCampaign } from 'src/pages/campaign';
import { renderChallengeCertifiedCourse } from 'src/pages/certified-course';
import { renderChallengeTalk } from 'src/pages/talk';
import { renderChallengeTraining } from 'src/pages/training';
import {
  CampaignModuleStatusEnum,
  ChallengeTypeEnum,
  ClauseStatusEnum,
  IResourceBehaviorsEnum,
  MaterialsEnum,
  PearlStatusEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
  SellOutStatusEnum,
  UserResourceEnum,
  UserResourceUploadMediaStatusEnum,
} from 'src/shared/enums';
import {
  ChallengeDetail,
  IChallenge,
  IChallengeQuiz,
  IChallengeResource,
  IChallengeResourceConfig,
  IFilter,
  IMenu,
  ISurvey,
  ISurveyResponse,
} from 'src/shared/models';
import { getTimeZone } from 'utils/timeUtils';
import routePaths, { deprecatedRoutePaths } from '../config/routes';
import { config as configApp } from 'config/config';
import _ from 'lodash';
import redirectTo from '../services/redirectTo';

const {
  ACTIVATION_BUTTON,
  ATTEND,
  CHALLENGE_MATERIALS,
  CHALLENGE_VISIBILITY,
  EVENT,
  GENERIC_BUTTON,
  IMAGE,
  LINKED_CHALLENGES,
  PARTICIPATION,
  QUIZ,
  SELL_OUT,
  SHOPPING_CART,
  SPEAKER,
  SURVEY,
  ARN_SURVEY,
  VIDEO,
  WYSIWYG_CHALLENGE,
  EXTERNAL_REDEEM_POINTS,
  CLAUSE,
} = ResourceTypeEnum;

export const getParentChallengeFromUrl = async (
  idParentChallenge: string | string[]
): Promise<IChallenge> => {
  if (idParentChallenge !== null && idParentChallenge !== undefined)
    try {
      const response = await getDataCall({
        dataPath: `${apiPaths.CHALLENGES.GET_USER_CHALLENGE_BY_SLUG}/${idParentChallenge}`,
        callConfig: {},
      });

      return response.data;
    } catch (err) {
      console.error(err.response.data);
      throw err.response.data;
    }
};

export const getParentChallengeId = async () => {
  const idParentChallenge = router.query.idParentChallenge;

  try {
    const parentChallenge = await getParentChallengeFromUrl(idParentChallenge);
    return parentChallenge.idChallengeType.idChallengeType;
  } catch (error) {
    console.error(error);
  }
};

enum ComponentTypeUrlButton {
  QUIZ = 'quiz',
  TYPEFORM = 'typeform',
}

export class UrlButton {
  component: string;
  userMenu: IMenu;
  query: ParsedUrlQuery;
  parentChallengeType: number;
  completedChallenge?: boolean;
  closeModal?: () => void;

  constructor(
    component: string,
    userMenu: IMenu,
    query: ParsedUrlQuery,
    parentChallengeType: number,
    completedChallenge?: boolean,
    closeModal?: () => void
  ) {
    this.component = component;
    this.userMenu = userMenu;
    this.query = query;
    this.parentChallengeType = parentChallengeType;
    this.completedChallenge = completedChallenge;
    this.closeModal = closeModal;
  }

  private routeMappings = {
    [ChallengeTypeEnum.FORMATION]: `${routePaths.PAGES.TRAINING}`,
    [ChallengeTypeEnum.TALK]: `${routePaths.PAGES.TALK}`,
    [ChallengeTypeEnum.CONGRESS]: `${routePaths.PAGES.TALK}`,
    [ChallengeTypeEnum.CAMPAIGN]: `${routePaths.PAGES.CAMPAIGN}`,
    [ChallengeTypeEnum.ANNUAL_CAMPAIGN]: `${routePaths.PAGES.ANNUAL_CAMPAIGN}`,
    [ChallengeTypeEnum.PEARL]: `${routePaths.PAGES.PEARL}`,
    [ChallengeTypeEnum.CERTIFIED_COURSE]: `${routePaths.PAGES.CERTIFIED_COURSES}`,
  };

  handleUrlButton() {
    this.component === ComponentTypeUrlButton.QUIZ
      ? this.handleUrlButtonQuiz()
      : this.component === ComponentTypeUrlButton.TYPEFORM
      ? this.handleUrlButtonTypeform()
      : console.error(`Unknown component: ${this.component}`);
  }

  handleUrlButtonQuiz() {
    if (!this.completedChallenge) {
      this.closeModal();
      return;
    }

    this.isChallengeALinkedChallenge();
    if (!this.query?.idParentChallenge) {
      redirectTo(this.userMenu.route);
      return;
    }

    this.getRoutePath();
  }

  private isChallengeALinkedChallenge() {
    if (!this.query?.idParentChallenge) {
      const urlPath = router.route;
      const modifiedUrl = urlPath.replace(/\/\[\w+\]$/, '');
      const matchingUrl = Object.entries(this.routeMappings).find(
        ([, value]) => value === modifiedUrl
      );
      const macthingUrlValuePosition = 1;

      if (matchingUrl) redirectTo(`${matchingUrl[macthingUrlValuePosition]}`);

      return;
    }
  }

  private getRoutePath() {
    if (this.parentChallengeType in this.routeMappings) {
      redirectTo(
        `${this.routeMappings[this.parentChallengeType]}/[id]`,
        {},
        { linkedCard: this.query.id, id: this.query.idParentChallenge }
      );
    }
  }

  handleUrlButtonTypeform() {
    this.isChallengeALinkedChallenge();

    this.getRoutePath();
  }
}

export const hasCompletedSurvey = (
  challengeResources: IChallengeResource[]
) => {
  let isCompleted = false;

  challengeResources?.forEach((resource) => {
    if (resource.type === ResourceTypeEnum.SURVEY)
      isCompleted = resource.config.isCompleted;
    else return false;
  });

  return isCompleted;
};

export const hasSurvey = (challengeResources: IChallengeResource[]) => {
  let hasSurvey = false;
  challengeResources?.forEach((resource) => {
    if (resource.type === ResourceTypeEnum.SURVEY) hasSurvey = true;
    else hasSurvey = false;
  });

  return hasSurvey;
};

export const getFirstUncompleteSurveyId = (
  challengeResources: IChallengeResource[]
): string => {
  const resource = _.find(
    challengeResources,
    (resource) =>
      resource.type === ResourceTypeEnum.SURVEY && !resource.config.isCompleted
  );
  return resource?.key || null;
};

export const checkIfMenuIsVisible = (userMenus: IMenu[]): IMenu => {
  const urlPath = router.route;
  const modifiedUrl = urlPath.replace(/^\/|\/\[\w+\]$/g, '');

  enum MenuEnum {
    'training' = '/trainings',
    'talk' = '/talks',
  }

  return userMenus.find(
    (menu) =>
      menu.route === MenuEnum[modifiedUrl] && menu.visible && !menu.disabled
  );
};

export const usePageName = (router: NextRouter) => {
  return useMemo(() => {
    const titleMap = {
      [routePaths.PAGES.ANNUAL_CAMPAIGN]: 'app.campaigns',
      [routePaths.PAGES.CAMPAIGN]: 'app.campaigns',
      [routePaths.PAGES.CAMPAIGN_DETAIL]: 'app.campaigns',
      //
      [routePaths.PAGES.TALK]: 'app.eventCongress',
      [routePaths.PAGES.TALK_DETAIL]: 'app.eventCongress',
      [routePaths.PAGES.CONGRESS_DETAIL]: 'app.eventCongress',
      [deprecatedRoutePaths.PAGES.TALKS]: 'app.eventCongress',
      //
      [routePaths.PAGES.TRAINING]: 'page.challenge.title',
      [routePaths.PAGES.TRAINING_DETAIL]: 'page.challenge.title',
      [deprecatedRoutePaths.PAGES.TRAININGS]: 'page.challenge.title',
      //
      [routePaths.PAGES.PEARL]: 'app.pearls',
      [routePaths.PAGES.PEARL_DETAIL]: 'app.pearls',
      //
      [routePaths.PAGES.REDEEM_POINTS.MAIN]: 'page.redeem-points-title',
      [routePaths.PAGES.REDEEM_POINTS.DETAILS]: 'page.redeem-points-title',
      //
      [routePaths.PAGES.REQUEST_ORDER]: 'page.request-order.title',
      //
      [routePaths.PAGES.MARKETING_MATERIALS]: 'page.marketing-materials.title',
      //
      [routePaths.PAGES.SCIENTIFIC_MATERIALS]:
        'page.scientific-materials.title',
      //
      [routePaths.PAGES.CERTIFIED_COURSES]: 'page.certified_courses.title',
      [routePaths.INDEX]: 'page.empty-list.go-to-main-page',
      [routePaths.BASE]: 'page.empty-list.go-to-main-page',
    };

    return titleMap[router.pathname] ?? null;
  }, [router]);
};

export const constructResources = (resources: IResourceList[]) => {
  const recursiveResource: IResourceList[] = [];
  const sortedResources: IResourceList[] = [];
  resources
    ?.filter((_resource) => _resource.status)
    .sort(function (a: IResourceList, b: IResourceList) {
      return a.idResource - b.idResource;
    })
    .sort(function (a: IResourceList, b: IResourceList) {
      return a.order - b.order;
    })
    .forEach((resource: IResourceList) => {
      if (resource.resourceDetailList)
        resource.resourceDetailList.sort(function (
          a: IResourceDetailList,
          b: IResourceDetailList
        ) {
          return a.idResourceD - b.idResourceD;
        });
      if (
        resource.indResource !== null &&
        resource.idResourceType.idResourceType !== SPEAKER
      ) {
        const newResource = { ...resource, resourceList: [] };
        recursiveResource.push(newResource);
      } else {
        const newResource = { ...resource, resourceList: [] };
        sortedResources.push(newResource);
      }
    });

  recursiveResource.forEach((_resource: IResourceList) => {
    recursiveResource.forEach((_destResource) => {
      if (_resource.indResource === _destResource.idResource)
        _destResource.resourceList.push(_resource);
    });
  });

  recursiveResource.forEach((_resource: IResourceList) => {
    sortedResources.forEach((_destResource: IResourceList) => {
      if (_resource.indResource === _destResource.idResource) {
        _destResource.resourceList.push(_resource);
      }
    });
  });

  return sortedResources;
};

export const constructMaterials = (materials: IResourceList[]) => {
  if (!materials.length) return null;

  const getMaterialDetails = (material: IResourceList) => {
    const { resourceDetailList } = material;
    const name = material.name;
    let description, image, content, brand, order, descriptionDetail;
    resourceDetailList
      .filter((detail) => detail.status)
      .forEach((detail) => {
        switch (detail.idResourceTypeD.idResourceTypeD) {
          case ResourceTypeDetailEnum.MATERIAL_BRAND:
            brand = detail.value;
            break;
          case ResourceTypeDetailEnum.MATERIAL_ORDER:
            order = detail.value;
            break;
          case ResourceTypeDetailEnum.MATERIAL_DESCRIPTION:
            descriptionDetail = detail;
            description = detail.value;
            break;
          case ResourceTypeDetailEnum.MATERIAL_CONTENT:
            content = detail.value;
            break;
          case ResourceTypeDetailEnum.MATERIAL_IMAGE:
            image = detail.value;
            break;
          default:
            break;
        }
      });

    return {
      idMaterial: material.idResource,
      type: MaterialsEnum.PRODUCT_IMAGES,
      name,
      description,
      image,
      content,
      brand,
      order,
      descriptionDetail,
      resource: material,
    };
  };

  return materials.map((material) => getMaterialDetails(material));
};

export const hasUserParticipated = (resource: IResourceList) => {
  return (
    resource?.idResourceType?.idResourceType === PARTICIPATION &&
    resource.usersM2MList?.length
  );
};

export const hasUserAttended = (resource: IResourceList) => {
  return (
    resource?.idResourceType?.idResourceType === ATTEND &&
    resource.usersM2MList?.length
  );
};

export const checkUserStatus = (
  resource: IResourceList,
  expectedStatus: (
    | CampaignModuleStatusEnum
    | UserResourceEnum
    | UserResourceUploadMediaStatusEnum
    | SellOutStatusEnum
    | PearlStatusEnum
    | ClauseStatusEnum
  )[]
): boolean => {
  return Boolean(
    resource.usersM2MList.find(({ status, statusResource }) => {
      return status && expectedStatus.includes(statusResource);
    })
  );
};

export const isAttendAvailable = (
  startEventDate: DateTime,
  endEventDate: DateTime,
  time_to_show: number
) => {
  if (!startEventDate || !endEventDate) return false;

  const now = DateTime.now().setZone(getTimeZone());
  const start = startEventDate
    .minus({ minutes: time_to_show })
    .setZone(getTimeZone());
  const end = endEventDate.setZone(getTimeZone());
  return now > start && now < end;
};

export const isInscriptionAvailable = (
  startVisDate: string,
  startEventDate: DateTime
) => {
  if (!startVisDate || !startEventDate) return false;

  const now = DateTime.now().setZone(getTimeZone());

  const start = DateTime.fromJSDate(new Date(startVisDate)).setZone(
    getTimeZone()
  );
  const end = startEventDate.setZone(getTimeZone());

  return now > start && now < end;
};

export const isEventExpired = (endEventDate: DateTime) => {
  if (!endEventDate) return true;

  const now = DateTime.now().setZone(getTimeZone());

  return now > endEventDate.setZone(getTimeZone());
};

export const isEventInProgress = (
  startEventDate: DateTime,
  endEventDate: DateTime
) => {
  if (!startEventDate || !endEventDate) return false;

  const now = DateTime.now().setZone(getTimeZone());

  const start = startEventDate.setZone(getTimeZone());
  const end = endEventDate.setZone(getTimeZone());

  return now > start && now < end;
};

export const getBrowserTimezone = () => {
  return new Date().getTimezoneOffset() / 60;
};

export const getSurveyResponseIfExists = (
  data: ISurvey
): ISurveyResponse | null => {
  return data?.userResponseList.slice(-1)[0];
};

export const setQuizBehavior = (
  config: IChallengeQuiz,
  resourceBehavior: IResourceBehavior
) => {
  // QUIZ BEHAVIOR
  switch (true) {
    case !resourceBehavior:
    case resourceBehavior.status:
      config.disabled = false;
      config.shouldRender = true;
      break;
    case !resourceBehavior.status &&
      resourceBehavior.behavior === IResourceBehaviorsEnum.DISABLED:
      config.shouldRender = true;
      config.disabled = true;
      break;
    case !resourceBehavior.status &&
      resourceBehavior.behavior === IResourceBehaviorsEnum.HIDDEN:
      config.shouldRender = false;
      break;
  }

  return config;
};

export const completeChallengeUserResource = async ({
  idResource,
  updateChallenge,
  updateUserPoints,
  data,
}: {
  idResource: number;
  updateUserPoints: (userPoints: number) => void;
  updateChallenge: (challengeData: IChallenge) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: { [key: string]: any };
}) => {
  try {
    const response = await api.putDataCallById({
      dataPath: apiPaths.CHALLENGES.COMPLETE_USER_RESOURCE,
      id: idResource,
      data,
    });

    const { score, challenge } = response.data;

    if (score && typeof score === 'number') updateUserPoints(score);

    updateChallenge(challenge);
  } catch (err) {
    console.error(err.response.data);
    throw err.response.data;
  }
};

export const checkCampaignActive = (
  resources: IChallengeResource[]
): boolean => {
  const triggerResources = resources
    .map(
      ({
        config: { isCampaignActivator, isCompleted } = {
          isCampaignActivator: false,
          isCompleted: false,
        },
      }) => {
        return { isCampaignActivator, isCompleted };
      }
    )
    .filter(({ isCampaignActivator }) => isCampaignActivator);

  return (
    triggerResources.length >= 1 &&
    triggerResources.some(({ isCompleted }) => isCompleted)
  );
};

export const isDependentConditionSatisfied = (
  depIdResource: number,
  expectedStatus: CampaignModuleStatusEnum,
  challengeResources: IChallengeResource[]
): boolean => {
  const mainResource = challengeResources.find(
    ({ resource: { idResource } }) => idResource === depIdResource
  );

  switch (mainResource?.type) {
    case QUIZ:
      switch (expectedStatus) {
        case CampaignModuleStatusEnum.QUIZ_DONE_DEPRECATED:
        case CampaignModuleStatusEnum.QUIZ_DONE:
          return mainResource.config.isQuizDone;
        case CampaignModuleStatusEnum.QUIZ_NOT_DONE:
          return !mainResource.config.isQuizDone;
      }
      break;
    case IMAGE:
    case ACTIVATION_BUTTON:
    case GENERIC_BUTTON:
    case SHOPPING_CART:
    case SURVEY:
    case ARN_SURVEY:
    case VIDEO:
    case EXTERNAL_REDEEM_POINTS:
    case WYSIWYG_CHALLENGE:
    case CLAUSE:
      switch (expectedStatus) {
        case CampaignModuleStatusEnum.GENERIC_DONE:
          return mainResource.config.isCompleted;
        case CampaignModuleStatusEnum.GENERIC_NOT_DONE:
          return !mainResource.config.isCompleted;
      }
      break;
    case EVENT:
      switch (expectedStatus) {
        case CampaignModuleStatusEnum.EVENT_NOT_ATTENDED:
          return (
            mainResource.config.inscription.isPerformed &&
            !mainResource.config.attend.isPerformed
          );
        case CampaignModuleStatusEnum.EVENT_ATTENDED:
          return (
            mainResource.config.inscription.isPerformed &&
            mainResource.config.attend.isPerformed
          );
        case CampaignModuleStatusEnum.EVENT_JOINED:
          return (
            mainResource.config.inscription.isPerformed ||
            mainResource.config.inscription.inWaitingList
          );
      }
      break;
    case CHALLENGE_MATERIALS:
    case LINKED_CHALLENGES:
      switch (expectedStatus) {
        case CampaignModuleStatusEnum.PARTIAL_DONE:
          return mainResource.config.isCompleted;
        case CampaignModuleStatusEnum.PARTIAL_NOT_DONE:
          return !mainResource.config.isPartialDone;
        case CampaignModuleStatusEnum.PARTIAL_PARTIALLY_DONE:
          return mainResource.config.isPartialDone;
      }
      break;
    case SELL_OUT:
      return mainResource.config.mediaData.statusResource === expectedStatus;
    case CHALLENGE_VISIBILITY:
      return mainResource.config.mediaData.statusResource === expectedStatus;
  }

  return false;
};

export const getResourceDetail = (
  resource: IResourceList,
  type: ResourceTypeDetailEnum
): null | IResourceDetailList => {
  if (!resource?.resourceDetailList?.length) return null;

  return resource.resourceDetailList?.find(
    ({ status, idResourceTypeD: { idResourceTypeD } }: IResourceDetailList) =>
      status && idResourceTypeD === type
  );
};

const ChallengeCard = ({
  challenge,
  isLinkedChallenge = false,
  isCarousel = false,
}: {
  challenge: ChallengeDetail;
  isLinkedChallenge?: boolean;
  isCarousel?: boolean;
}) => {
  const ref = useRef();
  const { challengeType, challengeId, slug } = challenge;

  let cardComponent: JSX.Element;

  const getSizeConfig = () => {
    if (isCarousel) return { span: 24 };
    switch (challengeType) {
      case ChallengeTypeEnum.CAMPAIGN:
      case ChallengeTypeEnum.CERTIFIED_COURSE:
      case ChallengeTypeEnum.FORMATION:
      case ChallengeTypeEnum.CONGRESS:
      case ChallengeTypeEnum.TALK:
        if (isLinkedChallenge)
          return {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 10 },
          };
        return {
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 12 },
          lg: { span: 12 },
          xl: { span: 8 },
        };
      case ChallengeTypeEnum.PEARL:
        if (isLinkedChallenge)
          return {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          };
        else
          return {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 12 },
            lg: { span: 12 },
            xl: { span: 8 },
          };
    }
  };

  switch (challengeType) {
    case ChallengeTypeEnum.FORMATION:
      cardComponent = renderChallengeTraining(challenge, isLinkedChallenge);
      break;
    case ChallengeTypeEnum.CONGRESS:
    case ChallengeTypeEnum.TALK:
      cardComponent = renderChallengeTalk(challenge, isLinkedChallenge);
      break;
    case ChallengeTypeEnum.CAMPAIGN:
      cardComponent = renderChallengeCampaign(challenge);
      break;
    case ChallengeTypeEnum.CERTIFIED_COURSE:
      cardComponent = renderChallengeCertifiedCourse(challenge);
      break;
    case ChallengeTypeEnum.PEARL:
      cardComponent = renderChallengePearl(challenge);
      if (isLinkedChallenge)
        cardComponent = renderLinkedPearl(challenge, isLinkedChallenge);
      break;
  }

  const redirectToLinkedChallengeCard = (elementId: string) => {
    const parentElement = document.getElementById(elementId);

    parentElement?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(() => {
    const { linkedCard } = router.query;
    const elementId = `challenge-card--${linkedCard}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (ref.current && ref.current.props.id == elementId) {
      redirectToLinkedChallengeCard(elementId);
    }
  }, [ref]);

  return (
    cardComponent && (
      <Col
        id={`challenge-card--${slug}`}
        key={challengeId}
        className="container-challengeCard"
        ref={ref}
        {...getSizeConfig()}
      >
        {cardComponent}
      </Col>
    )
  );
};

export const renderChallengeCard = ({
  challenge,
  isLinkedChallenge = false,
  isCarousel = false,
}: {
  challenge: ChallengeDetail;
  isLinkedChallenge?: boolean;
  isCarousel?: boolean;
}) => {
  return (
    <ChallengeCard
      challenge={challenge}
      isLinkedChallenge={isLinkedChallenge}
      isCarousel={isCarousel}
    />
  );
};

export const HandlerResourceRender = (config: {
  isFirstResource?: boolean;
  module: IChallengeResource;
  updateChallenge: React.Dispatch<React.SetStateAction<IChallenge>>;
  updateUserPoints: (
    userPoints: number
  ) => (dispatch: Dispatch<UpdateUserPoints>) => void;
  challengeResources: IChallengeResource[];
  showHeader?: boolean;
}) => {
  const {
    module,
    challengeResources,
    updateChallenge,
    updateUserPoints,
    isFirstResource,
    showHeader,
  } = config;

  const renderModule = (statusClass: 'active' | 'blocked') => (
    <Row
      className={`challenge-module--${statusClass} ${
        isFirstResource && !showHeader ? 'first-module' : ''
      }`}
      key={module.key}
      id={module.key}
      justify="center"
      align="middle"
      data-id={[module.idResource]}
    >
      {module.render({
        updateChallenge,
        updateUserPoints,
      })}
    </Row>
  );

  const {
    resource: { depResource, depResourceStatus, behavior },
    config: { isCampaignActivator: isTriggerModule },
  } = module;

  const withoutDependencies = depResource === null;
  const conditionSatisfied = isDependentConditionSatisfied(
    depResource,
    depResourceStatus,
    challengeResources
  );

  if (isTriggerModule || withoutDependencies || conditionSatisfied)
    return renderModule('active');

  if (IResourceBehaviorsEnum.HIDDEN === behavior) return <React.Fragment />;

  return renderModule('blocked');
};

export const setResourcesDependentCondictions = (
  challengeResources: IChallengeResource[],
  isCampaignActive: boolean
) => {
  challengeResources.forEach((challengeResource) => {
    const { resource, config, type } = challengeResource;
    const { depResource, depResourceStatus, behavior } = resource;
    const withoutDependecies = depResource === null;
    const conditionSatisfied = isDependentConditionSatisfied(
      depResource,
      depResourceStatus,
      challengeResources
    );

    const { isCampaignActivator } = config as IChallengeResourceConfig;

    if (conditionSatisfied || withoutDependecies)
      config.isActive = true || isCampaignActivator;
    if (!conditionSatisfied && IResourceBehaviorsEnum.HIDDEN === behavior)
      config.isVisible = false || isCampaignActivator;
    if (!conditionSatisfied && IResourceBehaviorsEnum.DISABLED === behavior)
      config.isBlocked = !isCampaignActivator;
    if (type === ACTIVATION_BUTTON)
      config.isCompleted = config.isCompleted || isCampaignActive;
  });
};

export const prepareChallengeFiltersResult = (filters: IFilter[]): string => {
  const filters_list = [];

  filters?.forEach((element) => {
    element.items.forEach((item) => {
      if (item.isChecked) filters_list.push(item.value);
    });
  });

  const _filters = filters_list.sort((a: string, b: string) => {
    if (parseInt(a) < parseInt(b)) return -1;
    if (parseInt(a) > parseInt(b)) return 1;
    return 0;
  });

  return _filters.length ? `&filters=${_filters.toString()}` : '';
};

export const enum VideoType {
  YOUTUBE = 0,
  VIMEO = 1,
  FILE = 3,
}

export const getVideoTypeById = (url: string): VideoType => {
  if (isYoutubeFormat(url)) return VideoType.YOUTUBE;

  if (isVimeoFormat(url)) return VideoType.VIMEO;

  return VideoType.FILE;
};

export const isYoutubeFormat = (videoID: string) => {
  if (!videoID) return false;
  const idSymbols = /^[a-zA-Z0-9-_]{11}$/;
  const isValid = videoID.length === 11 && idSymbols.test(videoID);
  return isValid;
};

export const isVimeoFormat = (videoID: string) => {
  if (!videoID) return false;
  const idSymbols = /^[0-9-_]{9}$/;
  const isValid = videoID.length === 9 && idSymbols.test(videoID);
  return isValid;
};

export const GetEmbedVideoIframe = ({
  url,
  challengeUserLabel,
  _className,
}: {
  url: string;
  challengeUserLabel?: string;
  _className?: string;
}): JSX.Element => {
  const urlVideoType = getVideoTypeById(url);

  const commonProps = {
    height: challengeUserLabel ? 365 : 385,
    width: '604',
    className: _className,
  };

  switch (urlVideoType) {
    case VideoType.YOUTUBE:
      return (
        <iframe
          {...commonProps}
          src={`${configApp.APP.YOUTUBE_EMBEDDED_URL}${url}`}
          allowFullScreen
        />
      );
    case VideoType.VIMEO:
      return (
        <iframe
          {...commonProps}
          src={`${configApp.APP.VIMEO_EMBEDDED_URL}${url}?transparent=0`}
          className={_className}
          allow="fullscreen;"
        />
      );
  }
};
