import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_PT: IRegion[] = [
  {
    id: 'a0C5800000FiEZ6EAN',
    name: 'Leiria',
    code: '91',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZEEA3',
    name: 'Lezíria do Tejo',
    code: '34',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZQEA3',
    name: 'Lisboa',
    code: '92',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEO6EAN',
    name: 'Ave',
    code: '12',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEO7EAN',
    name: 'Aveiro',
    code: '71',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEd3EAF',
    name: 'Oeste',
    code: '30',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOLEA3',
    name: 'Baixo Alentejo',
    code: '43',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOMEA3',
    name: 'Baixo Mondego',
    code: '21',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEONEA3',
    name: 'Baixo Vouga',
    code: '20',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOmEAN',
    name: 'Beira Interior Norte',
    code: '27',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOnEAN',
    name: 'Beira Interior Sul',
    code: '28',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOpEAN',
    name: 'Beja',
    code: '72',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPSEA3',
    name: 'Braga',
    code: '73',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPTEA3',
    name: 'Bragança',
    code: '74',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEj3EAF',
    name: 'Tâmega',
    code: '14',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQYEA3',
    name: 'Castelo Branco',
    code: '75',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQdEAN',
    name: 'Cávado',
    code: '11',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEl6EAF',
    name: 'Viana do Castelo',
    code: '97',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiElGEAV',
    name: 'Vila Real',
    code: '98',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiElNEAV',
    name: 'Viseu',
    code: '99',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERUEA3',
    name: 'Coimbra',
    code: '76',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERvEAN',
    name: 'Cova da Beira',
    code: '29',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEe2EAF',
    name: 'Península de Setúbal',
    code: '32',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfSEAV',
    name: 'Reg.Aut.da Madeira',
    code: '70',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfTEAV',
    name: 'Reg.Aut.dos Açores',
    code: '60',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiESIEA3',
    name: 'Dão-Lafoes',
    code: '25',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiESsEAN',
    name: 'Douro',
    code: '16',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETMEA3',
    name: 'Entre Douro e Vouga',
    code: '15',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETdEAN',
    name: 'Évora',
    code: '77',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiETkEAN',
    name: 'Faro',
    code: '78',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUmEAN',
    name: 'Grande Lisboa',
    code: '31',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUnEAN',
    name: 'Grande Porto',
    code: '13',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEaoEAF',
    name: 'Médio Tejo',
    code: '33',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEbBEAV',
    name: 'Minho-Lima',
    code: '10',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEV3EAN',
    name: 'Guarda',
    code: '79',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiELyEAN',
    name: 'Alentejo Central',
    code: '42',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiELzEAN',
    name: 'Alentejo Litoral',
    code: '40',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEh3EAF',
    name: 'Santarém',
    code: '95',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhQEAV',
    name: 'Serra da Estrela',
    code: '26',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhSEAV',
    name: 'Setúbal',
    code: '96',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEM2EAN',
    name: 'Algarve',
    code: '50',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMBEA3',
    name: 'Alto Alentejo',
    code: '41',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMCEA3',
    name: 'Alto Trás-os-Montes',
    code: '17',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWCEA3',
    name: 'Ilha da Madeira',
    code: '81',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWDEA3',
    name: 'Ilha das Flores',
    code: '82',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWEEA3',
    name: 'Ilha de Porto Santo',
    code: '83',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWFEA3',
    name: 'Ilha de Santa Maria',
    code: '84',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWGEA3',
    name: 'Ilha de São Jorge',
    code: '85',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWBEA3',
    name: 'Ilha da Graciosa',
    code: '80',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWHEA3',
    name: 'Ilha de São Miguel',
    code: '86',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWIEA3',
    name: 'Ilha do Corvo',
    code: '87',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWJEA3',
    name: 'Ilha do Faial',
    code: '88',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWKEA3',
    name: 'Ilha do Pico',
    code: '89',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWLEA3',
    name: 'Ilha Terceira',
    code: '90',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEePEAV',
    name: 'Pinhal Interior N',
    code: '23',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeQEAV',
    name: 'Pinhal Interior Sul',
    code: '24',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEeREAV',
    name: 'Pinhal Litoral',
    code: '22',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEekEAF',
    name: 'Portalegre',
    code: '93',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEelEAF',
    name: 'Porto',
    code: '94',
    country: 'Portugal',
    countryCode: 'PT',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'pt-PT');
});
