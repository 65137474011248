import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form as FormItem, Input, Row } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import { config } from 'config/config';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import {
  addDiscountCoupon,
  deleteDiscountCoupon,
} from 'src/actions/appActions';
import { loadingSpinner__small } from 'src/components/CustomModal';
import { getValidationMessage } from 'src/components/forms/EditPersonalData';
import { IDiscountCoupon } from 'src/shared/models';
import { getPhoneMinLengthRegex } from '../../../validations/getPhoneMinLengthRegex';
import { IRootReducers } from '../../../reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import {
  YuButton,
  YuFlex,
  YuIcon,
  YuParagraph,
  YuPopperBase,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import ConditionalRenderer from '../../ConditionalRenderer';
import { customFormatMessage, isDNIRequired } from '../../../../utils';
import { updateUserDocumentId } from '../../../actions/authActions';
import { EditDNIModal } from './EditDNIModal';

export enum RedeemCheckoutStepsEnum {
  PERSONAL_DATA = 0,
  PLACE_ORDER = 1,
}

export enum CheckoutStepsEnum {
  PERSONAL_DATA = 'PersonalDataStep',
  PLACE_ORDER = 'PlaceOrderStep',
}

export interface ICheckoutStep {
  content: JSX.Element;
  id: RedeemCheckoutStepsEnum;
  title: string;
}

const _PersonalData = ({ user, step }) => {
  const {
    userName,
    userMail,
    userPhone,
    userDocumentID,
    userDocumentIDHasBeenModified,
  } = user.salesforce || {};
  const [dniModalIsOpen, setDniModalIsOpen] = useState<boolean>(false);

  const closeModalCompleteDni = () => {
    setDniModalIsOpen(false);
  };

  const openModalCompleteDni = () => {
    setDniModalIsOpen(true);
  };

  return (
    <YuFlex
      className="redeem-checkout__personal-data"
      flexDirection="column"
      justify="flex-start"
    >
      <YuFlex flexDirection="column">
        <YuTitle size="XS" style={{ display: 'flex' }}>
          <FormattedMessage id="page.redeem-points.checkout.personal-data.title" />
          <span className="tooltip">
            <YuPopperBase
              content={
                <span>
                  {customFormatMessage(
                    'page.redeem-points.checkout.update-personal-data.info'
                  )}
                </span>
              }
              colorVariant="dark"
              placement="bottom"
            >
              <YuIcon name="Info" size="M" />
            </YuPopperBase>
          </span>
        </YuTitle>
        <YuSpacer size="S" />
        <YuParagraph size="M">
          <FormattedMessage id="page.redeem-points.checkout.update-personal-data.middlename" />
        </YuParagraph>
        <YuParagraph className="fs-personal-data fs-mask" size="L" fw="sbold">
          {userName}
        </YuParagraph>
      </YuFlex>

      <YuSpacer size="S" />

      <YuFlex flexDirection="column">
        <YuParagraph size="M">
          <FormattedMessage id="page.redeem-points.checkout.update-personal-data.email" />
        </YuParagraph>
        <YuParagraph className="fs-personal-data fs-mask" size="L" fw="sbold">
          {userMail}
        </YuParagraph>
      </YuFlex>

      <YuSpacer size="S" />

      <YuFlex flexDirection="column">
        <YuParagraph size="M">
          <FormattedMessage id="page.redeem-points.checkout.update-personal-data.telephone" />
        </YuParagraph>
        <YuParagraph className="fs-personal-data fs-mask" size="L" fw="sbold">
          {userPhone}
        </YuParagraph>
      </YuFlex>

      <ConditionalRenderer
        condition={isDNIRequired}
        trueComponent={
          <YuFlex flexDirection="column">
            <YuSpacer size="S" />
            <YuParagraph size="M">
              <FormattedMessage id="page.redeem-points.checkout.personal-data.dni" />
            </YuParagraph>
            <ConditionalRenderer
              condition={
                step === CheckoutStepsEnum.PERSONAL_DATA &&
                !userDocumentID &&
                !userDocumentIDHasBeenModified
              }
              trueComponent={
                <YuParagraph size="L">
                  <YuButton
                    variant="link"
                    size="L"
                    onClick={openModalCompleteDni}
                  >
                    <FormattedMessage id="page.redeem-points.checkout.personal-data.dni-missing" />
                  </YuButton>
                </YuParagraph>
              }
              falseComponent={
                <ConditionalRenderer
                  condition={
                    step === CheckoutStepsEnum.PERSONAL_DATA &&
                    userDocumentID &&
                    userDocumentIDHasBeenModified
                  }
                  trueComponent={
                    <YuParagraph size="L">
                      <YuButton
                        variant="link"
                        size="L"
                        onClick={openModalCompleteDni}
                        appendIcon="Pencil"
                      >
                        <YuParagraph
                          className="fs-personal-data fs-mask"
                          size="L"
                        >
                          {userDocumentID}
                        </YuParagraph>
                      </YuButton>
                    </YuParagraph>
                  }
                  falseComponent={
                    <YuParagraph className="fs-personal-data fs-mask" size="L">
                      {userDocumentID}
                    </YuParagraph>
                  }
                />
              }
            />
            <EditDNIModal
              dniModalIsOpen={dniModalIsOpen}
              closeModalCompleteDni={closeModalCompleteDni}
              handleSubmit={closeModalCompleteDni}
            />
          </YuFlex>
        }
      />
    </YuFlex>
  );
};

export const ShippingMethod = () => {
  return (
    <>
      <YuTitle size="XS">
        <FormattedMessage id="page.redeem-points.checkout.shipping-method" />
      </YuTitle>
      <YuSpacer size="M" />
      <YuParagraph size="L" fw="bold">
        <FormattedMessage id="page.redeem-points.checkout.standart-free-delivery" />
      </YuParagraph>
      {config.APP.SHIPPING_METHOD_HOURS && (
        <YuParagraph size="L">
          <FormattedMessage
            id="page.redeem-points.checkout.delivery-hours"
            values={{
              hours: config.APP.SHIPPING_METHOD_HOURS,
            }}
          />
        </YuParagraph>
      )}
      <YuParagraph size="L">
        <FormattedMessage
          id="page.redeem-points.checkout.delivery-days"
          values={{
            days: config.APP.SHIPPING_METHOD_DAYS,
          }}
        />
      </YuParagraph>
    </>
  );
};

export const DiscountCode: FC<{
  enterDiscountCode: boolean | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setEnterDiscountCode: any;
  cartId: number;
  appliedDiscountCodes: IDiscountCoupon;
  discountIsPercentageType: boolean;
}> = ({ cartId, appliedDiscountCodes, discountIsPercentageType }) => {
  const { formatMessage } = useIntl();
  const [discountCode, setDiscountCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [form] = useForm();
  const dispatch = useDispatch();

  const handleDeleteCoupon = async () => {
    setDeleteLoading(true);
    await dispatch(deleteDiscountCoupon(appliedDiscountCodes.code, cartId));
    setDeleteLoading(false);
  };

  const handleAddCoupon = async () => {
    setLoading(true);
    await dispatch(addDiscountCoupon(discountCode, cartId));
    setLoading(false);
  };

  return (
    <>
      <div className={`redeem-checkout__discount`}>
        <p>
          <FormattedMessage id="page.redeem-points.checkout.got-discount-code" />
        </p>
      </div>
      <div className="redeem-checkout__discount-code">
        <Form form={form} onFinish={handleAddCoupon}>
          <Input
            type="text"
            placeholder={formatMessage({
              id: 'page.redeem-points.checkout.coupon-code',
            })}
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
            defaultValue={discountCode}
          />
          <Button htmlType="submit">
            {loading ? (
              loadingSpinner__small
            ) : (
              <FormattedMessage id="page.redeem-points.checkout.apply" />
            )}
          </Button>
        </Form>
      </div>
      {appliedDiscountCodes && Object.keys(appliedDiscountCodes).length > 0 && (
        <Col>
          <Row className="redeem-checkout__discount-codes">
            <Col span={2}>
              <span className="icon icon--process-check" />
            </Col>
            <Col span={18}>
              <p>{appliedDiscountCodes.code}</p>
            </Col>
            <Col>
              {deleteLoading ? (
                loadingSpinner__small
              ) : (
                <i
                  className="icon icon--delete"
                  onClick={handleDeleteCoupon}
                ></i>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={2}></Col>
            <Col className="redeem-checkout__discount-number">
              -{appliedDiscountCodes.discount}
              {discountIsPercentageType
                ? '%'
                : ' ' + formatMessage({ id: 'product.points' })}
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};

const _CheckoutPhoneInput = ({
  userPhone,
  formatMessage,
  setUserPhone,
  user,
}) => {
  return (
    <Row>
      <FormItem.Item
        className="redeem-checkout__user-phone"
        name="userPhone"
        initialValue={userPhone}
        rules={[
          {
            required: true,
            message: getValidationMessage('form.field.telephone'),
          },
          {
            pattern: getPhoneMinLengthRegex(user.salesforce.userCountry),
            message: formatMessage({ id: 'form.field.telephone-length' }),
          },
          {
            pattern: /^[0-9+-]+$/,
            message: formatMessage({
              id: 'form.field.invalid-characters',
            }),
          },
        ]}
        extra={
          <Row justify="start" className="input-phone-information">
            <Col span={2}>
              <div className="ant-info-circle">
                <InfoCircleOutlined />
              </div>
            </Col>
            <Col span={22}>
              <span>
                {formatMessage({
                  id: 'form.field.telephone-info',
                })}
              </span>
            </Col>
          </Row>
        }
      >
        <Input
          onChange={(event) => setUserPhone(event.target.value)}
          onKeyDown={(e) =>
            ['e', ',', '.'].includes(e.key) && e.preventDefault()
          }
          required
          type="string"
          maxLength={15}
          id="standard-required--telephone"
          placeholder={formatMessage({
            id: 'form.field.telephone',
          })}
        />
      </FormItem.Item>
    </Row>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateUserDocumentId }, dispatch);

export const CheckoutPhoneInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(_CheckoutPhoneInput);

export const PersonalData = connect(
  mapStateToProps,
  mapDispatchToProps
)(_PersonalData);

export const commonModalConfig = {
  hideFooter: true,
  showIcon: false,
  closeIcon: null,
  centered: true,
  customClass:
    'shopping-cart-modal buy-modal modal-fullscreen-on-mobile redeem-checkout-addresses',
};
