export enum UserResourceEnum {
  INACTIVE = 0, // any -> deleted
  WAITING_LIST = 1, // event -> inscription
  SIGNED = 2, // event -> inscription - generic & partial resource -> done
  IN_PROGRESS = 3, // quiz -> deprecated
  DONE = 4, // quiz -> done
  REMAKE = 5, // quiz -> doing again
  CONFIRM_ATTEND = 6, // event -> attend
  SHOPPING_CART_IN_PROGRESS = 7, // shopping cart -> sent to magento (waiting)
  SHOPPING_CART_ERROR = 8, // shopping cart -> magento error
  PROCESSED = 9, // shopping cart -> magento confirm order
}

export enum CampaignModuleStatusEnum {
  EVENT_ATTENDED = 6,
  EVENT_JOINED = 2,
  EVENT_NOT_ATTENDED = 1,
  GENERIC_DONE = 2,
  GENERIC_NOT_DONE = 1,
  PARTIAL_DONE = 2,
  PARTIAL_NOT_DONE = 1,
  PARTIAL_PARTIALLY_DONE = 999,
  QUIZ_DONE = 2,
  QUIZ_DONE_DEPRECATED = 4, //! DEPRECATED
  QUIZ_NOT_DONE = 1,
  SHOPPING_CART_IN_PROGRESS = 7, // this status is set by the api
  SHOPPING_CART_ERROR = 8, // this status is set by the api
  SELL_OUT_NOT_REGISTERED = 0,
  SELL_OUT_PENDING = 1,
  SELL_OUT_VALIDATED = 2,
  SELL_OUT_DENIED = 3,
  SELL_OUT_REGISTERED = 4,
  SELL_IN_REGISTERED = 4,
  PROCESSED = 9,
}

export enum UserResourceUploadMediaStatusEnum {
  NOT_SENT = 0,
  PENDING = 1,
  VALIDATE = 2,
  DENY = 3,
}

export enum SellOutStatusEnum {
  NOT_REGISTERED = 0,
  PENDING = 1,
  VALIDATED = 2,
  DENIED = 3,
  REGISTERED = 4,
}

export enum PearlStatusEnum {
  NOT_SENT = 0,
  PENDING = 1,
  VALIDATED = 2,
  DENIED = 3,
}

export enum ClauseStatusEnum {
  UNSIGNED = 0,
  SIGNED_EMBRACE = 1,
  SIGNED_PIN = 2,
  SIGNED_CASCADE = 3,
}
