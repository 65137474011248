import { Col, Row } from 'antd';
import { config } from 'config/config';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { updateChallenges } from 'src/actions/appActions';
import { updateUserPoints } from 'src/actions/authActions';
import Link from 'src/components/Link';
import { IRootReducers } from 'src/reducers';
import {
  CHALLENGE_TYPE_VALUES,
  ChallengeTypeEnum,
  ResourceTypeEnum,
} from 'src/shared/enums';
import {
  ChallengeDetail,
  IChallengesList,
  TypeChallengeStatusLabel,
  TypeChallengeUserLabel,
} from 'src/shared/models';
import { ChallengeCardDetailsBuilder } from '../../../utils/ChallengeCardDetailsBuilder';
import ChallengeCertifiedCourse from './ChallengeCertifiedCourse';
import { ChallengeLike } from './ChallengeLike';

export const renderCampaignDetail = (
  icon: string,
  labelID: string,
  value: Record<string, ReactNode>,
  isBold?: boolean
): JSX.Element => (
  <Row
    className={`details ${isBold && 'details--bold'}`}
    justify="start"
    align="middle"
    key={labelID}
  >
    <div className={`icon icon--${icon}`} />

    <FormattedMessage id={labelID} values={value} />
  </Row>
);

type ChallengeCardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ChallengeDetail;

const ChallengeCard: FC<ChallengeCardProps> = ({
  campaigns,
  cardImage,
  cardImageMobile,
  certifiedCourses,
  challengeCardButton,
  challengeId,
  challengeLikes,
  challengeModality,
  challengeName,
  challengePoints,
  challengeResources,
  challengeStatusLabel,
  challengeType,
  challengeUserLabel,
  congresses,
  dateValues,
  events,
  idParentChallenge,
  isChallengeExpired,
  isFeatured,
  isLinkedChallenge,
  mainCampaignSlug,
  slug,
  showPoints,
  trainings,
  updateChallenges,
  updateUserPoints,
  showDuration,
  duration,
  challengeDescription,
  updateChallenge,
}): JSX.Element => {
  const { formatNumber } = useIntl();

  const isCampaign = [
    ChallengeTypeEnum.CAMPAIGN,
    ChallengeTypeEnum.CERTIFIED_COURSE,
  ].includes(challengeType);

  const isCongress = challengeType === ChallengeTypeEnum.CONGRESS;
  const isTalk = challengeType === ChallengeTypeEnum.TALK;

  const isPearl = challengeType === ChallengeTypeEnum.PEARL;
  const isCertifiedCourse =
    challengeType === ChallengeTypeEnum.CERTIFIED_COURSE;
  const isNewDesign = [
    ChallengeTypeEnum.PEARL,
    ChallengeTypeEnum.CAMPAIGN,
    ChallengeTypeEnum.TALK,
    ChallengeTypeEnum.CONGRESS,
    ChallengeTypeEnum.FORMATION,
  ].includes(challengeType);

  const renderChallengeImage = (): JSX.Element => {
    const blockToDetails: TypeChallengeUserLabel[] = ['campaign-inactive'];

    if (blockToDetails.includes(challengeUserLabel))
      return (
        <>
          <img
            loading="lazy"
            alt={`event-${challengeId}`}
            src={cardImage ?? config.APP.DEFAULT_IMAGE}
            className="challengeCard__image blocked show_only_desktop_excluded"
          />
          <img
            loading="lazy"
            alt={`event-${challengeId}`}
            src={cardImageMobile ?? config.APP.DEFAULT_IMAGE}
            className="challengeCard__image blocked not_show_on_desktop"
          />
        </>
      );

    return (
      <>
        {isCertifiedCourse ? (
          <>
            <img
              loading="lazy"
              alt={`event-${challengeId}`}
              src={cardImage ?? config.APP.DEFAULT_IMAGE}
              className="challengeCard__image show_only_desktop_excluded"
            />
            <img
              loading="lazy"
              alt={`event-${challengeId}`}
              src={cardImageMobile ?? config.APP.DEFAULT_IMAGE}
              className="challengeCard__image not_show_on_desktop"
            />
          </>
        ) : (
          <>
            <Link
              href={{
                pathname: challengeCardButton.path,
                query: isLinkedChallenge
                  ? {
                      id: slug,
                      mainCampaignSlug,
                      idParentChallenge,
                    }
                  : { id: slug },
              }}
            >
              <img
                loading="lazy"
                alt={`event-${challengeId}`}
                src={cardImage ?? config.APP.DEFAULT_IMAGE}
                className="challengeCard__image show_only_desktop_excluded"
              />
            </Link>

            <Link
              href={{
                pathname: challengeCardButton.path,
                query: isLinkedChallenge
                  ? {
                      id: slug,
                      mainCampaignSlug,
                      idParentChallenge,
                    }
                  : { id: slug },
              }}
            >
              <img
                loading="lazy"
                alt={`event-${challengeId}`}
                src={cardImageMobile ?? config.APP.DEFAULT_IMAGE}
                className="challengeCard__image not_show_on_desktop"
              />
            </Link>
          </>
        )}
      </>
    );
  };

  const renderChallengeDates = (): JSX.Element => (
    <Col className="challengeCard__date">
      <Row justify="start" align="middle">
        <a className="challengeCard__icon--small challengeCard__icon--calendar" />
        <FormattedMessage
          id="page.challenge-card.{startDate}-{endDate}"
          values={dateValues}
        />
      </Row>
    </Col>
  );

  const detailsBuilder = (): ChallengeCardDetailsBuilder => {
    const builder = new ChallengeCardDetailsBuilder(formatNumber);

    if (isTalk) builder.addTalkDetail();
    if (isCongress) builder.addCongressDetail();

    builder.addDateDetail(dateValues.startDate, dateValues.endDate);

    if (isCampaign) builder.addActionDetail(challengeResources.length);

    if (showDuration) builder.addDurationDetail(duration);

    if (showPoints && challengePoints) builder.addPointsDetail(challengePoints);

    return builder.build();
  };

  const renderDetails = (): JSX.Element => {
    const details = detailsBuilder();

    return (
      <Row className="challengeCard__card-details-container">
        <Col span={details.haveTwoColumns() ? 11 : 22}>
          {details.buildLeftColumn()}
        </Col>
        {details.haveTwoColumns() && <Col span={2} />}
        {details.haveTwoColumns() && (
          <Col span={11}>{details.buildRightColumn()}</Col>
        )}
      </Row>
    );
  };

  const renderChallengeName = (): JSX.Element =>
    challengeName && (
      <Row justify="start" align="middle" className="challengeCard__title">
        <span className="challengeCard__title__text">{challengeName}</span>
      </Row>
    );

  const renderChallengeModality = (): JSX.Element => {
    return (
      <Row justify="space-between" className="challengeCard__type">
        <Col span={12}>
          <Row justify="start" className="challengeCard__type--text">
            {challengeModality && (
              <FormattedMessage
                id={`page.challenge-card.modality-${challengeModality}`}
              />
            )}
          </Row>
        </Col>
        <Col span={12}>
          {showPoints && (
            <Row justify="end" className="challengeCard__points">
              <FormattedMessage
                id="menu.section.personal.{points}-point"
                values={{ points: formatNumber(challengePoints) }}
              />
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  const renderChallengeButton = (): JSX.Element => {
    const hiddenStatus: TypeChallengeUserLabel[] = ['campaign-inactive'];
    const shouldRender =
      challengeCardButton && !hiddenStatus.includes(challengeUserLabel);

    if (!shouldRender) return <React.Fragment />;

    return (
      <Row className="challengeCard__button" justify="start" align="middle">
        <Link
          href={{
            pathname: challengeCardButton.path,
            query: isLinkedChallenge
              ? {
                  id: slug,
                  mainCampaignSlug,
                  idParentChallenge,
                }
              : { id: slug },
          }}
        >
          <a className={challengeCardButton.className ?? ''}>
            {challengeCardButton.title}
          </a>
        </Link>
        <div className="icon icon--arrow-dropdown-right challengeCard__button--icon"></div>
      </Row>
    );
  };

  const handleUpdateChallenge = (liked: boolean, totalLikes: number) => {
    let propertyKey: CHALLENGE_TYPE_VALUES;
    const challengeList: Pick<IChallengesList, 'challenges' | 'total'> = {
      total: 0,
      challenges: [],
    };
    switch (challengeType) {
      case ChallengeTypeEnum.FORMATION:
        challengeList.challenges = trainings.challenges;
        challengeList.total = trainings.total;
        propertyKey = 'trainings';
        break;
      case ChallengeTypeEnum.CAMPAIGN:
        challengeList.challenges = campaigns.challenges;
        challengeList.total = campaigns.total;
        propertyKey = 'campaigns';
        break;
      case ChallengeTypeEnum.TALK:
        challengeList.challenges = events.challenges;
        challengeList.total = events.total;
        propertyKey = 'events';
        break;
      case ChallengeTypeEnum.CONGRESS:
        challengeList.challenges = congresses.challenges;
        challengeList.total = congresses.total;
        propertyKey = 'congresses';
        break;
      case ChallengeTypeEnum.CERTIFIED_COURSE:
        challengeList.challenges = certifiedCourses.challenges;
        challengeList.total = certifiedCourses.total;
        propertyKey = 'certifiedCourses';
        break;
    }

    const challenge = challengeList.challenges.find(
      (training) => training.challengeId == challengeId
    );

    if (challenge) {
      challenge.challengeLikes.liked = liked;
      challenge.challengeLikes.totalLikes = totalLikes;
    }

    updateChallenges({
      challengeType: propertyKey,
      challengeList,
    });
  };

  const getChallengeTypeClass = (): string => {
    return ChallengeTypeEnum[challengeType].toLocaleLowerCase();
  };

  const buildChallengeCardDetails_CertifiedCourse = (): JSX.Element => {
    const certifiedCourseResource = challengeResources.find(
      ({ type }) => type === ResourceTypeEnum.CERTIFIED_COURSE
    );

    if (
      !certifiedCourseResource ||
      !certifiedCourseResource?.config?.isCorrectlyConfigured
    ) {
      console.error(
        `Challenge ${challengeName} ( ${challengeId} ) is not correctly configured. Check the "Certified Course" resource`
      );

      return (
        <Col className="pearl-denied">
          This challenge is not correctly configured at the BackOffice.
        </Col>
      );
    }

    return (
      <Col className="challenge-card--certified-course-details">
        <Row className="challenge-card--certified-course-details__title">
          {renderChallengeName()}
        </Row>

        <Row className="challenge-card--certified-course-details__description small-body">
          {challengeDescription}
        </Row>

        <ChallengeCertifiedCourse
          {...{
            idResource: certifiedCourseResource.idResource,
            config: certifiedCourseResource.config,
            updateChallenge,
            updateUserPoints,
          }}
        />
      </Col>
    );
  };

  const buildChallengeCardDetails = (): JSX.Element => {
    if (challengeType === ChallengeTypeEnum.CERTIFIED_COURSE) {
      return buildChallengeCardDetails_CertifiedCourse();
    }

    return (
      <>
        {!isNewDesign && renderChallengeDates()}

        {renderChallengeName()}

        {isNewDesign && renderDetails()}

        {!isNewDesign && renderChallengeModality()}

        {renderChallengeButton()}
      </>
    );
  };

  return (
    <Row className="challenge-container" justify="center">
      <Row
        align="top"
        className={`challengeCard challengeCard__${getChallengeTypeClass()}`}
      >
        <Row className="challengeCard__image">
          {isFeatured && !isChallengeExpired
            ? renderCardFeaturedIcon()
            : renderCardStatusLabel(challengeStatusLabel, challengeType)}

          {challengeType === ChallengeTypeEnum.CERTIFIED_COURSE &&
            renderCardStatusLabel(challengeStatusLabel, challengeType)}

          {renderChallengeImage()}
          {!isPearl && !isLinkedChallenge && (
            <div className="challengeCard__likes">
              <Col>
                <ChallengeLike
                  {...{
                    challengeLikes,
                    challengeId,
                    updateChallenges: handleUpdateChallenge,
                    updateUserPoints,
                    likedClassName:
                      'challengeCard__likes__heart challengeCard__likes__heart--filled',
                    dislikedClassName:
                      'challengeCard__likes__heart challengeCard__likes__heart--outlined',
                  }}
                />
              </Col>
            </div>
          )}
        </Row>

        <Row justify="start" align="top" className="challengeCard__container">
          {buildChallengeCardDetails()}
        </Row>

        {renderUserStatus({
          challengeUserLabel,
          startDate: dateValues.startDate,
        })}
      </Row>
    </Row>
    // </Col>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateUserPoints, updateChallenges }, dispatch);

const mapStateToProps = (state: IRootReducers) => {
  return {
    ...state.app.challenges,
    shortDateFormat: state.platform.shortDateFormat,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeCard);

export const renderCardStatusLabel = (
  challengeStatusLabel: TypeChallengeStatusLabel,
  challengeType
) => {
  if (challengeType === ChallengeTypeEnum.FORMATION) return <React.Fragment />;

  return (
    challengeStatusLabel && (
      <label
        className={`challengeCard__image__label challengeCard__image__label--${challengeStatusLabel}`}
      >
        <FormattedMessage
          id={`page.challenge-card.status-${challengeStatusLabel}`}
        />
      </label>
    )
  );
};

export const renderCardFeaturedIcon = () => {
  return (
    <div className="challengeCard__icon-featured-container ">
      <div className={`icon icon--feature-favourite `} />
    </div>
  );
};

export const renderUserStatus = (props: {
  challengeUserLabel: TypeChallengeUserLabel;
  startDate?: string;
}): JSX.Element => {
  const { challengeUserLabel, startDate } = props;

  return (
    challengeUserLabel && (
      <Row
        justify="center"
        align="middle"
        className={`challengeCard__userStatus challengeCard__userStatus__text ${challengeUserLabel}`}
      >
        <FormattedMessage
          id={`page.challenge-card.user-status.${challengeUserLabel}`}
          values={{ startDate }}
        />
      </Row>
    )
  );
};
