import { default as React, FC } from 'react';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import { IChallengeWyswyg } from 'src/shared/models';
import Helmet from 'react-helmet';

interface OwnProps {
  children?: React.ReactNode;
  config: IChallengeWyswyg;
}

const ChallengeWyswyg: FC<OwnProps> = ({ config }) => {
  if (!config) return <React.Fragment />;

  const { content } = config;

  const createHtmlContent = (valueHtml: string): Element[] => {
    const template = document.createElement('template');
    template.innerHTML = valueHtml.trim();
    return Array.from(template.content.children);
  };

  const scriptElements = createHtmlContent(content)
    .filter((element) => element.tagName === 'SCRIPT')
    .map((element) => element.innerHTML);

  const component = (
    <>
      <ChallengeResourceWrapper_Blocked />
      {scriptElements.map((element) => (
        <Helmet>
          <script>{element}</script>
        </Helmet>
      ))}
      <div
        className="dangerous-container"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </>
  );

  return (
    <ChallengeResourceWrapper
      children={component}
      description={null}
      icon={config.icon}
      styleClass="challengeDetail--WYS-WYG"
      title={null}
    />
  );
};

export default ChallengeWyswyg;
