import SkeletonAnnualCampaignCard from '../AnnualCampaignCard.skeleton';
import { SkeletonChallengeCard } from '../ChallengeCard.skeleton';
import SkeletonSubHeaderTitle from '../SubHeaderTitle.skeleton';

const SkeletonAnnualCampaign = (): JSX.Element => {
  return (
    <>
      <SkeletonSubHeaderTitle />
      <div className="annual-campaign-skeleton">
        <div className="annual-campaign-skeleton--first">
          <SkeletonAnnualCampaignCard />
        </div>
        <div className="annual-campaign-skeleton--second">
          <div className="annual-campaign-skeleton--second--separators">
            <div className="annual-campaign-skeleton--second--separators-separator annual-campaign-skeleton--second--separators-separator__1" />
            <div className="annual-campaign-skeleton--second--separators-separator annual-campaign-skeleton--second--separators-separator__2" />
            <div className="annual-campaign-skeleton--second--separators-separator annual-campaign-skeleton--second--separators-separator__3" />
          </div>
          <div className="annual-campaign-skeleton--second--grid">
            <SkeletonChallengeCard />
            <SkeletonChallengeCard />
          </div>
        </div>
      </div>
    </>
  );
};

export default SkeletonAnnualCampaign;
