import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_ES: IRegion[] = [
  {
    id: 'a0C5800000FiEehEAF',
    name: 'Pontevedra',
    code: '36',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiENsEAN',
    name: 'Asturias',
    code: '33',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYWEA3',
    name: 'La Coruña',
    code: '15',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYeEAN',
    name: 'La Rioja',
    code: '26',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYyEAN',
    name: 'Las Palmas',
    code: '35',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.CANARIAS,
  },
  {
    id: 'a0C5800000FiEbkEAF',
    name: 'Murcia',
    code: '30',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdMEAV',
    name: 'Ourense',
    code: '32',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEdeEAF',
    name: 'Palencia',
    code: '34',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOAEA3',
    name: 'Ávila',
    code: '05',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOIEA3',
    name: 'Badajoz',
    code: '06',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOSEA3',
    name: 'Baleares',
    code: '07',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEOXEA3',
    name: 'Barcelona',
    code: '08',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEgbEAF',
    name: 'Salamanca',
    code: '37',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPmEAN',
    name: 'Burgos',
    code: '09',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPtEAN',
    name: 'Cáceres',
    code: '10',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEPuEAN',
    name: 'Cádiz',
    code: '11',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEiJEAV',
    name: 'Soria',
    code: '42',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEiWEAV',
    name: 'Sta.Cruz Tenerife',
    code: '38',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.CANARIAS,
  },
  {
    id: 'a0C5800000FiEjBEAV',
    name: 'Tarragona',
    code: '43',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQGEA3',
    name: 'Cantabria',
    code: '39',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQXEA3',
    name: 'Castellón',
    code: '12',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEQmEAN',
    name: 'Ceuta',
    code: '51',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiElPEAV',
    name: 'Vizcaya',
    code: '48',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERLEA3',
    name: 'Ciudad Real',
    code: '13',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERkEAN',
    name: 'Córdoba',
    code: '14',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiES4EAN',
    name: 'Cuenca',
    code: '16',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZAEA3',
    name: 'León',
    code: '24',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjaEAF',
    name: 'Teruel',
    code: '44',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjpEAF',
    name: 'Toledo',
    code: '45',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkXEAV',
    name: 'Valencia',
    code: '46',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkYEAV',
    name: 'Valladolid',
    code: '47',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEmSEAV',
    name: 'Zamora',
    code: '49',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEmWEAV',
    name: 'Zaragoza',
    code: '50',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZyEAN',
    name: 'Lugo',
    code: '27',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEa7EAF',
    name: 'Madrid',
    code: '28',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEaGEAV',
    name: 'Málaga',
    code: '29',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUREA3',
    name: 'Girona',
    code: '17',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUlEAN',
    name: 'Granada',
    code: '18',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEarEAF',
    name: 'Melilla',
    code: '52',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEV8EAN',
    name: 'Guipúzcoa',
    code: '20',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEUuEAN',
    name: 'Guadalajara',
    code: '19',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEc5EAF',
    name: 'Navarra',
    code: '31',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiELuEAN',
    name: 'Álava',
    code: '01',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiELwEAN',
    name: 'Albacete',
    code: '02',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEVwEAN',
    name: 'Huelva',
    code: '21',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEVxEAN',
    name: 'Huesca',
    code: '22',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhJEAV',
    name: 'Segovia',
    code: '40',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEhUEAV',
    name: 'Sevilla',
    code: '41',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEM6EAN',
    name: 'Almería',
    code: '04',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEM3EAN',
    name: 'Alicante',
    code: '03',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEMPEA3',
    name: 'Andorra La Vella',
    code: '500',
    country: 'Andorra',
    countryCode: 'AD',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEYZEA3',
    name: 'La Massana',
    code: '400',
    country: 'Andorra',
    countryCode: 'AD',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEdIEAV',
    name: 'Ordino',
    code: '300',
    country: 'Andorra',
    countryCode: 'AD',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEQDEA3',
    name: 'Canillo',
    code: '100',
    country: 'Andorra',
    countryCode: 'AD',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiETKEA3',
    name: 'Encamp',
    code: '200',
    country: 'Andorra',
    countryCode: 'AD',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiETQEA3',
    name: 'Escaldes-Buy',
    code: '700',
    country: 'Andorra',
    countryCode: 'AD',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEgxEAF',
    name: 'Sant Julià de Lòria',
    code: '600',
    country: 'Andorra',
    countryCode: 'AD',
    channel: ChannelType.INTERNATIONAL,
  },
  {
    id: 'a0C5800000FiEWtEAN',
    name: 'Jaén',
    code: '23',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEZBEA3',
    name: 'Lleida',
    code: '25',
    country: 'España',
    countryCode: 'ES',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'es-ES');
});
