export interface IGallery {
  idChallenge: number;
  items: IGalleryItem[];
}

export interface IGalleryItem {
  idResource: number;
  url: string;
  isVideo: boolean;
  isVideoFile: boolean;
  isImage: boolean;
}

export const isVideoValidFormat = (videoURL = '') => {
  const VIDEO_VALID_FORMATS = ['ogg', 'mpeg', 'mp4', 'webm', 'x-msvideo'];
  const format = videoURL.split('.');

  const isValid = VIDEO_VALID_FORMATS.includes(format[format.length - 1]);

  return isValid;
};
