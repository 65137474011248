import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import { SkeletonAccountLayout } from './AccountLayout.skeleton';
import ConditionalRenderer from '../../ConditionalRenderer';

const SkeletonCenterProfile: NextPage = (): JSX.Element => {
  const { downM, upM } = useSize();

  return (
    <YuSkeletonWrapper
      width="100%"
      style={{ maxWidth: '1370px', margin: 'auto' }}
    >
      <ConditionalRenderer
        condition={downM}
        trueComponent={
          <>
            <YuSpacer size="XS" />
            <YuSkeleton
              height={24}
              width={150}
              variant="text"
              alignSelf="start"
              ml="XS"
            />

            <YuSpacer size="M" />
          </>
        }
        falseComponent={<YuSpacer size="L" />}
      />
      <SkeletonAccountLayout>
        <YuSkeletonWrapper
          paddingLeft={upM ? 'L' : 'XS'}
          justifyContent="center"
          width="100%"
          maxWidth={640}
          alignItems={upM ? 'left' : 'center'}
          style={{ margin: upM ? '' : 'auto' }}
        >
          <YuFlex
            flexDirection="column"
            mt={{ initial: 0, upM: 'M' }}
            ml={{ initial: 0, upM: 'L' }}
            width="100%"
          >
            <YuFlex justify={downM ? 'center' : ''}>
              <YuSkeleton height="35px" width="200px" maxWidth="100%" />
            </YuFlex>
            <YuFlex
              flexDirection="column"
              justifyContent="center"
              width="100%"
              maxWidth={640}
            >
              <YuSpacer size="L" />
              <YuSkeleton height="24px" width="180px" />
              <YuSpacer size="S" />
              <YuSkeleton height="20px" width="60px" maxWidth="100%" />
              <YuSpacer size="XXS" />
              <YuSkeleton height="20px" width="50px" />
              <YuSpacer size="S" />
              <YuSkeleton height="20px" width="140px" maxWidth="100%" />
              <YuSpacer size="XXS" />
              <YuSkeleton height="20px" width="200px" />
              <YuSpacer size="S" />
              <YuSkeleton height="20px" width="55px" maxWidth="100%" />
              <YuSpacer size="XXS" />
              <YuSkeleton height="20px" width="200px" />
              <YuSpacer size="XXS" />
              <YuSkeleton height="20px" width="150px" />
              <YuSpacer size="S" />
              <YuSkeleton height="24px" width="260px" />
              <YuSpacer size="S" />
              <YuSkeleton height="20px" width="150px" />
              <YuSpacer size="XXS" />
              <YuSkeleton height="20px" width="250px" />
            </YuFlex>
          </YuFlex>
          <YuSpacer size="XL" />
        </YuSkeletonWrapper>
      </SkeletonAccountLayout>
    </YuSkeletonWrapper>
  );
};

export default SkeletonCenterProfile;
