import { Col, Row } from 'antd';
import {
  YuIcon,
  YuParagraph,
  YuQuantitySelector,
  YuSpacer,
  YuTitle,
  YuTooltip,
} from '@isdin/yuma-react-web-pin';
import { FormattedMessage, useIntl } from 'react-intl';
import { BannerReferenceLimitedInfo } from '../../orderLimits/bannerReferenceLimitedInfo';
import React from 'react';

export const MyBagProductCompact = ({
  isCompact,
  product,
  maxProductsOrdered,
  handleRemoveProduct,
  setProductLoading,
  annualMaxQuantityProduct,
  name,
  maxQuantity,
  isCheckoutProduct,
  canUpdateProduct,
  handleChangeQuantity,
  price,
  qty,
}) => {
  const { formatNumber, formatMessage } = useIntl();
  return (
    <Row className={`my-bag__product ${isCompact ? '' : 'show_on_tablet'}`}>
      <Col xs={11} sm={6}>
        <img className="product__image" src={product.image} loading="lazy" />
      </Col>
      <Col xs={13} sm={18} className="product__details">
        <Row justify="space-between" align="middle">
          <Row justify="space-between" align="top">
            <div
              style={handleRemoveProduct ? { width: 'calc(100% - 28px)' } : {}}
            >
              <YuTitle size="XS">{name}</YuTitle>
            </div>
            {handleRemoveProduct && (
              <div style={{ width: '20px' }}>
                <YuIcon
                  size="M"
                  name="Trash"
                  onClick={() =>
                    handleRemoveProduct(product, setProductLoading)
                  }
                ></YuIcon>
              </div>
            )}
          </Row>
          <p
            className={` ${
              isCheckoutProduct ? 'no-display' : 'show_on_tablet'
            }`}
          >
            <YuSpacer size="XXS" />
            <YuParagraph size="L" fw="bold">
              <FormattedMessage
                id="menu.section.personal.{points}-point"
                values={{ points: formatNumber(price * qty) }}
              />
            </YuParagraph>
          </p>
        </Row>
        {!canUpdateProduct && (
          <Row
            align="middle"
            className={
              isCheckoutProduct
                ? 'product__checkout-style'
                : 'product__lateral-bag-quantity '
            }
          >
            <YuParagraph size="L" fw="bold">
              <FormattedMessage
                id="page.redeem-points.checkout.x-quantity"
                values={{ quantity: qty }}
              />
            </YuParagraph>
          </Row>
        )}
        <Row
          align="middle"
          className={
            isCheckoutProduct
              ? 'product__checkout-style'
              : 'product__lateral-bag'
          }
        >
          {canUpdateProduct && !isCheckoutProduct && (
            <Col
              xs={isCheckoutProduct ? 0 : 24}
              sm={14}
              className={isCheckoutProduct ? 'not_show_on_tablet' : ''}
            >
              <Row>
                <YuQuantitySelector
                  disabled={false}
                  minValue={0}
                  onSetQuantity={handleChangeQuantity}
                  size="XXS"
                  value={qty}
                  variant="nonEditable"
                  maxValue={maxQuantity}
                />
                {annualMaxQuantityProduct <= qty && (
                  <div className="tooltip-my-bag">
                    <YuTooltip
                      colorVariant="dark"
                      text={formatMessage(
                        {
                          id: 'page.redeem-points.my-bag.limit-message-{maxProducts}',
                        },
                        {
                          maxProducts: annualMaxQuantityProduct,
                          units: formatMessage({
                            id:
                              annualMaxQuantityProduct > 1
                                ? 'page.redeem-points.errors.units'
                                : 'page.redeem-points.errors.unit',
                          }),
                        }
                      )}
                    >
                      <YuIcon name="Info" size="M" />
                    </YuTooltip>
                  </div>
                )}
              </Row>
            </Col>
          )}
          {isCheckoutProduct && (
            <>
              <Col
                xs={isCheckoutProduct ? 0 : 18}
                sm={12}
                className={isCheckoutProduct ? ' ' : ''}
              >
                <p className="product__quantity">
                  <FormattedMessage
                    id="page.redeem-points.checkout.x-quantity"
                    values={{ quantity: qty }}
                  />
                </p>
              </Col>
              <Col
                xs={isCheckoutProduct ? 12 : 0}
                className={isCheckoutProduct ? 'no-display' : ''}
              >
                <p className="product__quantity">
                  <FormattedMessage
                    id="page.redeem-points.checkout.x-quantity"
                    values={{ quantity: qty }}
                  />
                </p>
              </Col>
            </>
          )}
          <Col
            xs={isCheckoutProduct ? 12 : 0}
            sm={10}
            className={isCheckoutProduct ? '' : 'not_show_on_tablet'}
          >
            <p>
              <YuParagraph size="L" fw="bold">
                <FormattedMessage
                  id="menu.section.personal.{points}-point"
                  values={{ points: formatNumber(price * qty) }}
                />
              </YuParagraph>
            </p>
          </Col>
        </Row>
      </Col>
      {maxQuantity <= qty && !maxProductsOrdered && (
        <>
          <YuSpacer size="S" />
          <BannerReferenceLimitedInfo remaining={maxQuantity} />
        </>
      )}
    </Row>
  );
};
