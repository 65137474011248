import {
  IResource,
  IResourceDetailList,
} from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum, UserResourceEnum } from 'src/shared/enums';
import { IChallengeLegalCheck } from 'src/shared/models';

abstract class ILegalCheckFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(legalCheck: IChallengeLegalCheck): IChallengeLegalCheck;
}

class UrlLegalFiller extends ILegalCheckFiller {
  fill(legalCheck: IChallengeLegalCheck): IChallengeLegalCheck {
    return {
      ...legalCheck,
      legalUrl: this.detail.value,
    };
  }
}

class ChallengeLegalCheckFactory {
  private readonly legalCheckFillers: Map<
    ResourceTypeDetailEnum,
    ILegalCheckFiller
  >;

  constructor(detail: IResourceDetailList) {
    this.legalCheckFillers = new Map();

    this.legalCheckFillers.set(
      ResourceTypeDetailEnum.LEGAL_CHECK_URL,
      new UrlLegalFiller(detail)
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    legalCheck: IChallengeLegalCheck
  ): IChallengeLegalCheck {
    const legalCheckFiller = this.legalCheckFillers.get(idResourceTypeD);

    if (legalCheckFiller) {
      return legalCheckFiller.fill(legalCheck);
    }

    return legalCheck;
  }
}

class ChallengeLegalCheckBuilder {
  public build(resource: IResource): IChallengeLegalCheck {
    let legalCheck: IChallengeLegalCheck;

    resource.resourceDetailList
      .filter((legalCheck) => legalCheck.status)
      .forEach((detail) => {
        const Factory = new ChallengeLegalCheckFactory(detail);

        legalCheck = Factory.fill(detail, legalCheck);
      });

    legalCheck.wasPerformed = false;
    legalCheck.wasAccepted = false;

    resource?.usersM2MList
      ?.filter(({ status }) => status)
      ?.forEach((_userResource) => {
        legalCheck.wasPerformed = true;
        legalCheck.wasAccepted =
          _userResource?.statusResource == UserResourceEnum.DONE;
      });

    return {
      ...legalCheck,
      title: resource.name,
      idResource: resource.idResource,
      uuidResource: resource.uuidResource,
    };
  }
}

export const builderLegalCheck = (
  resource: IResource
): IChallengeLegalCheck => {
  const builder = new ChallengeLegalCheckBuilder();

  return builder.build(resource);
};
