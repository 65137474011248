import { Row } from 'antd';
import { config as configApp } from 'config/config';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import ChalllengeResourceWrapper from 'src/components/challenge/ChallengeResourceWrapper';
import { ChallengeTypeEnum } from 'src/shared/enums';
import { IChallengeResourceRender, ILinkedChallenges } from 'src/shared/models';
import { renderChallengeCard } from '../../../utils';

interface OwnProps extends IChallengeResourceRender {
  config: ILinkedChallenges;
}

const ChallengeLinkedChallenges: FC<OwnProps> = ({ config }): JSX.Element => {
  if (!config || !config?.challenges?.length) return <React.Fragment />;

  const { formatMessage, formatNumber } = useIntl();

  const {
    challenges,
    description,
    points,
    title,
    type,
    icon,
    mainCampaignSlug,
    idParentChallenge,
  } = config;

  const shouldRenderIconBG = [ChallengeTypeEnum.PEARL].includes(type);

  const shouldRenderPoints =
    points >= configApp.APP.SHOW_POINTS_LIMIT &&
    [
      ChallengeTypeEnum.FORMATION,
      ChallengeTypeEnum.TALK,
      ChallengeTypeEnum.CONGRESS,
      ChallengeTypeEnum.PEARL,
    ].includes(type);

  const getClassNameByType = (type: ChallengeTypeEnum) => {
    switch (type) {
      case ChallengeTypeEnum.CAMPAIGN:
        return 'campaignsPage';
      case ChallengeTypeEnum.FORMATION:
        return 'trainingPage';
      case ChallengeTypeEnum.CONGRESS:
      case ChallengeTypeEnum.TALK:
        return 'talksPage';
      case ChallengeTypeEnum.PEARL:
        return 'pearlsPage';
    }
  };

  const component = (
    <Row
      justify="center"
      className={`challengePage ${getClassNameByType(type)}`}
    >
      {challenges.map((challenge) =>
        renderChallengeCard({
          challenge: { ...challenge, mainCampaignSlug, idParentChallenge },
          isLinkedChallenge: true,
        })
      )}
    </Row>
  );

  return (
    <ChalllengeResourceWrapper
      styleClass="challengeDetail--LinkedChallenge"
      description={description}
      icon={icon}
      points={
        shouldRenderPoints &&
        formatMessage(
          { id: 'page.challenge.plus-sign-{points}-points' },
          { points: formatNumber(points) }
        )
      }
      title={title}
      children={component}
      iconWithBackground={shouldRenderIconBG}
    />
  );
};

export default ChallengeLinkedChallenges;
