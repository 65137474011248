import * as typeformEmbed from '@typeform/embed';
import { config } from 'config/config';
import { useRouter } from 'next/router';
import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { PIN_PlatformID_Enum } from 'src/shared/enums';
import {
  checkIfMenuIsVisible,
  customFormatMessage,
  getParentChallengeId,
  UrlButton,
} from '../../utils';
import { IRootReducers } from '../reducers';
import { ISurvey } from '../shared/models';
import CustomModal from './CustomModal';
import ConditionalRenderer from './ConditionalRenderer';
import { YuButton, YuFlex, YuIcon, YuSpacer } from '@isdin/yuma-react-web-pin';

interface IUser {
  idUser: number;
  email: string;
  customerId: number;
}

interface ITypeform {
  user: IUser;
  resource?: IResourceList;
  showSurvey: boolean;
  surveyData: ISurvey;
  completeSurvey: () => Promise<void>;
}

const TypeformComponent: FC<ITypeform & ReturnType<typeof mapStateToProps>> = ({
  user,
  resource,
  showSurvey,
  surveyData,
  completeSurvey,
  userMenus,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { formatNumber } = useIntl();
  const [parentChallengeType, setParentChallengeId] = useState<number>();
  const {
    idSurvey,
    idTypeform,
    score: { points },
  } = surveyData;
  const { query } = useRouter();
  const resourcePoints = resource?.score?.points ?? points;

  const typeformRef = useRef(null);

  const handleSubmit = async () => {
    try {
      setModalVisible(true);
      setLoading(true);
      await completeSurvey();
    } catch (err) {
      setError(err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    typeformEmbed.createWidget(
      `${config.APP.TYPEFORM_URL}/${idTypeform}#user_id=${user.idUser}&survey_id=${idSurvey}&email=${user.email}&id_magento=${user.customerId}`,
      {
        container: typeformRef.current,
        hideFooter: true,
        hideHeaders: true,
        onSubmit: handleSubmit,
        opacity: 0,
      }
    );
  }, [typeformRef]);

  const individualSurveyCopy = () => {
    const isNotPinEsPlatform = () => {
      return config.APP.PLATFORM !== PIN_PlatformID_Enum.PIN_SPAIN;
    };
    if (isNotPinEsPlatform) return 'page.challenge.survey.modal-success';

    const isChallengeRelated = Boolean(resource);
    if (isChallengeRelated) {
      return 'page.challenge.congratulations-training-completed';
    }

    return 'page.challenge.survey.modal-success';
  };

  const goToUrlButton = () => {
    const urlButtonHandler = new UrlButton(
      'typeform',
      checkIfMenuIsVisible(userMenus),
      query,
      parentChallengeType
    );
    urlButtonHandler.handleUrlButton();
  };

  const getMessageButton = (): string => {
    if (query?.idParentChallenge)
      return 'page.challenge.quiz.continue-with-campaign';
    else return 'page.challenge.quiz.find-more-experiences';
  };

  const modalContent = (
    <YuFlex flexDirection="column" alignItems="center">
      <YuFlex justify="center" gap={'M'}>
        <YuIcon name="CheckmarkCircle" size="XL" color="#218500" />
      </YuFlex>
      <YuSpacer size="S" />

      <span className="finalize-title">
        <FormattedMessage id={individualSurveyCopy()} />
      </span>

      {resourcePoints >= config.APP.SHOW_POINTS_LIMIT && (
        <FormattedMessage
          id="page.challenge.survey.you-win-{points}-points"
          values={{
            points: formatNumber(resourcePoints || 0),
          }}
        />
      )}

      <ConditionalRenderer
        condition={
          (!error && !!checkIfMenuIsVisible(userMenus)) ||
          Boolean(query?.idParentChallenge)
        }
        trueComponent={
          <>
            <YuSpacer size="S" />
            <YuButton
              onClick={() => goToUrlButton()}
              children={customFormatMessage(getMessageButton())}
            />
          </>
        }
      />
    </YuFlex>
  );

  const fetchData = async () => {
    setParentChallengeId(await getParentChallengeId());
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div
        ref={typeformRef}
        style={{ display: showSurvey ? 'unset' : 'none' }}
      />
      <CustomModal
        {...{
          visible: modalVisible,
          loading,
          error,
          content: modalContent,
          onCancel: closeModal,
          footer: null,
          showIcon: false,
          centered: true,
          customClass: 'quiz-modal-finalize',
        }}
      />
    </>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    challengeResources: state.app.challenge?.challengeResources,
    userMenus: state.app.userMenu,
  };
};
export default connect(mapStateToProps)(TypeformComponent);
