import React from 'react';

const ConditionalRenderer = ({
  condition,
  trueComponent,
  falseComponent = <></>,
}: {
  condition: boolean;
  trueComponent: JSX.Element;
  falseComponent?: JSX.Element;
}) => {
  return condition ? trueComponent : falseComponent;
};

export default ConditionalRenderer;
