import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum, ResourceTypeEnum } from 'src/shared/enums';
import {
  IChallengeResourceConfig,
  ISellIn,
  ISellInProduct,
  ISellInRange,
} from 'src/shared/models';

const {
  SELL_IN_DESCRIPTION,
  SELL_IN_CONDITIONS,
  SELL_IN_PRODUCT_ID,
  SELL_IN_PRODUCT_MAX_ITEMS,
  SELL_IN_PRODUCT_MIN_ITEMS,
  SELL_IN_PRODUCT_DEFAULT_ITEMS,
  SELL_IN_IMAGE_RANGES,
  SELL_IN_PRODUCT_MIN_QUANTITY,
} = ResourceTypeDetailEnum;

export const buildSellIn = (
  resource: IResourceList,
  resourceMainConfig: IChallengeResourceConfig
): ISellIn => {
  const {
    resourceDetailList,
    idResource,
    name: title,
    resourceList,
  } = resource;

  let item: ISellIn;
  const products: ISellInProduct[] = [];
  const ranges: ISellInRange[] = [];
  let points = 0;

  resourceDetailList
    .filter(({ status }) => status)
    .forEach((_item) => {
      switch (_item.idResourceTypeD.idResourceTypeD) {
        case SELL_IN_DESCRIPTION:
          item = {
            ...item,
            description: _item.value,
          };
          break;

        case SELL_IN_CONDITIONS:
          item = {
            ...item,
            conditions: _item.value,
          };
          break;
        case SELL_IN_PRODUCT_MIN_QUANTITY:
          item = {
            ...item,
            minItemsSellIn: parseInt(_item.value),
          };
      }
    });

  resourceList
    .filter(({ status }) => status)
    .forEach((_itemResource) => {
      let product: ISellInProduct;
      let range: ISellInRange;
      switch (_itemResource.idResourceType.idResourceType) {
        case ResourceTypeEnum.SELL_IN_POINTS_RANGE:
          range = {
            ...range,
            title: _itemResource.name,
            points: _itemResource.score?.points || 0,
          };

          if (range.points > points) points = range.points;

          _itemResource.resourceDetailList
            .filter(({ status }) => status)
            .forEach((_item) => {
              switch (_item.idResourceTypeD.idResourceTypeD) {
                case SELL_IN_IMAGE_RANGES:
                  if (!_item.value) break;
                  range = {
                    ...range,
                    imageRanges: _item.value,
                  };
                  ranges?.push(range);
                  break;
              }
            });
          break;

        case ResourceTypeEnum.SELL_IN_PRODUCTS:
          if (!_itemResource.productData) break;

          product = {
            ...product,
            ..._itemResource.productData,
            title: _itemResource.name,
            idResource: _itemResource.idResource,
            price: _itemResource.productData.pvl,
            pvpr: _itemResource.productData.pvl, //?TODO: the file src/components/shoppingCart/Product.tsx should use the property "price".
          };

          _itemResource.resourceDetailList
            .filter(({ status }) => status)
            .forEach((_item) => {
              switch (_item.idResourceTypeD.idResourceTypeD) {
                case SELL_IN_PRODUCT_ID:
                  product = {
                    ...product,
                    productId: _item.value,
                  };
                  break;

                case SELL_IN_PRODUCT_DEFAULT_ITEMS:
                  product = {
                    ...product,
                    defaultItems: product?.stock
                      ? parseInt(_item.value) || 0
                      : 0,
                    quantity: product?.stock ? parseInt(_item.value) || 0 : 0,
                  };
                  break;

                case SELL_IN_PRODUCT_MAX_ITEMS:
                  product = {
                    ...product,
                    maxItems: parseInt(_item.value) || 0,
                  };
                  break;

                case SELL_IN_PRODUCT_MIN_ITEMS:
                  product = {
                    ...product,
                    minItems: product?.stock ? parseInt(_item.value) || 0 : 0,
                  };
                  break;
              }
            });

          products?.push(product);
          break;
      }
    });

  return {
    ...item,
    products,
    ranges,
    icon: 'campaign-material-request',
    idResource,
    title,
    points,
    isOrderPerformed: resourceMainConfig.isCompleted,
  };
};
