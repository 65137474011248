import { LoadingOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Modal, Row } from 'antd';
import { config } from 'config/config';
import routePaths from 'config/routes';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChallengeResourceWrapper_Blocked } from 'src/components/challenge/ChallengeResourceWrapper';
import Link from 'src/components/Link';
import { CustomCloseIcon } from 'src/components/shoppingCart/ShoppingCart';
import { IChallengeDetailsBox } from 'src/shared/models';

export const ViewMoreButton = ({ setShowModal }) => (
  <span
    className="challengeDetail__view-more"
    onClick={() => setShowModal(true)}
  >
    <FormattedMessage id="page.challenge.description.view-more" />
  </span>
);

export const ShowDetailsModal = ({
  showModal,
  setShowModal,
  title,
  description,
}) => (
  <Modal
    visible={showModal}
    footer={null}
    onCancel={() => setShowModal(false)}
    className="challengeDetail__collapse-modal"
    closeIcon={<CustomCloseIcon />}
  >
    <Row
      justify="start"
      align="middle"
      className="challengeDetail__title__text"
      data-id="challengeDetails"
    >
      {title}
    </Row>
    <div>{description}</div>
  </Modal>
);

export const ChallengeDetailsBox: FC<IChallengeDetailsBox> = ({
  allowRetry,
  detailTitle = '',
  completed,
  date,
  description,
  disabled = false,
  expired,
  handleShowSurvey,
  image,
  isSurvey = false,
  isCampaignComponent = false,
  isPearl = false,
  loading = false,
  points,
  setShowComponent,
  showQuiz,
  title,
  end_message,
  sharePearl = false,
  showButton = true,
  challengeSlug,
  idParentChallenge,
}) => {
  let buttonTitle = '';
  let resourceText: string;
  const { formatNumber } = useIntl();
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!description) return;

    if (description.length >= 70) {
      setShowSeeMore(true);
    }
  }, [description]);

  switch (true) {
    case isSurvey && completed:
      break;
    case isSurvey && !completed && detailTitle !== '':
      resourceText = detailTitle;
      break;
    case isSurvey && !completed && detailTitle === '':
      buttonTitle = 'page.challenge.survey.start';
      break;
    case completed:
      buttonTitle = 'page.challenge.quiz.completed';
      break;
    case isPearl:
      buttonTitle = 'page.challenge.pearl';
      break;
    default:
      buttonTitle = 'page.challenge.quiz.start';
      break;
  }

  let buttonDisabled = disabled || (completed && !allowRetry) || showQuiz;
  if (isSurvey && showQuiz && !disabled) buttonDisabled = false;

  const renderButton = () => {
    if ((!buttonTitle && !resourceText) || !showButton) return;

    return (
      <Button
        className={`btn-ghost btn-ghost--black ${
          buttonDisabled ? 'btn-ghost--black--disabled' : ''
        }`}
        disabled={buttonDisabled}
        onClick={() => {
          if (sharePearl) return;
          if (!buttonDisabled) setShowComponent && setShowComponent(true);
          if (showQuiz && handleShowSurvey) handleShowSurvey();
        }}
        block
      >
        {sharePearl ? (
          <Link
            href={{
              pathname: `${routePaths.PAGES.PEARL_DETAIL}`,
              query: {
                id: challengeSlug,
                createPearl: true,
                idParentChallenge,
              },
            }}
          >
            <Row justify="space-between" align="middle">
              <span>
                {resourceText ?? <FormattedMessage id={buttonTitle} />}
              </span>
              <a className={`icon icon--arrow-dropdown-down`} />
            </Row>
          </Link>
        ) : (
          <Row justify="space-between" align="middle">
            <span>{resourceText ?? <FormattedMessage id={buttonTitle} />}</span>
            <a className={`icon icon--arrow-dropdown-down`} />
          </Row>
        )}
      </Button>
    );
  };

  const renderDetailStatus = () => {
    if (isCampaignComponent || !completed) return;

    return (
      <div className="quiz-image-label">
        {end_message ? (
          end_message
        ) : (
          <FormattedMessage
            id={`page.challenge-card.user-status.${
              isSurvey ? 'survey' : 'quiz'
            }-done`}
          />
        )}
      </div>
    );
  };

  const renderSpecialWarning =
    completed && !isSurvey && allowRetry ? (
      <Row
        className="container-repeat-quiz container-repeat-quiz--small"
        justify="center"
        align="middle"
      >
        <FormattedMessage id="page.challenge.taking-this-quiz-again-will-not-earn-you-more-points" />
      </Row>
    ) : (
      <React.Fragment />
    );

  const renderExpiredWarning =
    expired && isSurvey ? (
      <Row
        className="container-expired-quiz container-repeat-quiz--small"
        justify="center"
        align="middle"
      >
        <FormattedMessage id="page.challenge.survey.expired" />
      </Row>
    ) : (
      <React.Fragment />
    );

  const renderMainButton = (
    <Row className="quiz-button-start block--small">
      <Col xs={24} className="container-button">
        {expired ? renderExpiredWarning : renderButton()}
      </Col>
    </Row>
  );

  const buildQuizCard = (
    <Col xs={24} md={24} lg={24}>
      <Row className="quiz-card-container" justify="center" align="top">
        <div className="quiz-card-container__image">
          <ChallengeResourceWrapper_Blocked />
          <div className="quiz-image-container">
            <Avatar
              className="quiz-image"
              shape="square"
              src={image ? image : config.APP.DEFAULT_IMAGE}
            />
            {renderDetailStatus()}
          </div>
        </div>
        <Row className="quiz-data">
          <Row className="quiz-name">{title}</Row>
          <Row
            className={`quiz-description block--small ${
              showSeeMore ? 'small-margin' : ''
            }`}
          >
            <p>{description}</p>
          </Row>
          {showSeeMore && <ViewMoreButton setShowModal={setShowModal} />}
          <ShowDetailsModal
            description={description}
            title={title}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          {date && (
            <Row className=" quiz-date">
              <div className="icon icon--calendar challenge-detail-box-icon" />
              {date}
            </Row>
          )}
          <Row className="quiz-points block--small">
            {points >= config.APP.SHOW_POINTS_LIMIT && (
              <>
                <div className="icon icon--puntos challenge-detail-box-icon" />
                <FormattedMessage
                  id="page.challenge-resource.earn-up-to-{points}-points"
                  values={{
                    points: formatNumber(points),
                  }}
                />
              </>
            )}
          </Row>
          {renderSpecialWarning}
          {renderMainButton}
        </Row>
      </Row>
    </Col>
  );

  return (
    <div className="quiz-cover">
      <Row justify="center">
        {loading ? (
          <Avatar icon={<LoadingOutlined />} size={64} />
        ) : (
          buildQuizCard
        )}
      </Row>
    </div>
  );
};
