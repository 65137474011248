const SkeletonCampaignDetailsCard = (): JSX.Element => {
  return (
    <div className="skeleton-campaign-details-card">
      <div className="skeleton-campaign-details-card--media" />
      <div>
        <div className="skeleton-campaign-details-card--title">
          <div className="skeleton-campaign-details-card--title-left" />
          <div className="skeleton-campaign-details-card--title-right" />
        </div>
        <div className="skeleton-campaign-details-card--subtitle" />
        <div className="skeleton-campaign-details-card--description skeleton-campaign-details-card--description__1" />
        <div className="skeleton-campaign-details-card--description skeleton-campaign-details-card--description__2" />
        <div className="skeleton-campaign-details-card--description skeleton-campaign-details-card--description__3" />
        <div className="skeleton-campaign-details-card--label skeleton-campaign-details-card--label__1">
          <div className="skeleton-campaign-details-card--label--left" />
          <div className="skeleton-campaign-details-card--label--right" />
        </div>
        <div className="skeleton-campaign-details-card--label skeleton-campaign-details-card--label__2">
          <div className="skeleton-campaign-details-card--label--left" />
          <div className="skeleton-campaign-details-card--label--right" />
        </div>
        <div className="skeleton-campaign-details-card--label skeleton-campaign-details-card--label__3">
          <div className="skeleton-campaign-details-card--label--left" />
          <div className="skeleton-campaign-details-card--label--right" />
        </div>
        <div className="skeleton-campaign-details-card--label skeleton-campaign-details-card--label__4">
          <div className="skeleton-campaign-details-card--label--left" />
          <div className="skeleton-campaign-details-card--label--right" />
        </div>
        <div className="skeleton-campaign-details-card--link" />
      </div>
    </div>
  );
};

export default SkeletonCampaignDetailsCard;
