import {
  IChallengeExternalValidator,
  IChallengeLegalCheck,
  IChallengeResourceConfig,
} from 'src/shared/models';

export interface IChallengeCertifiedCourse extends IChallengeResourceConfig {
  creditsDescriptions: string;
  courseURL: string;
  speaker: string;
  idCourse: string;
  platformType: CertifiedCourse_PlatformsEnum;
  legalCheck: IChallengeLegalCheck;
  externalValidator: IChallengeExternalValidator;
  isCorrectlyConfigured: boolean;
  idChallenge: number;
}

export enum CertifiedCourse_PlatformsEnum {
  CERTIFIED_COURSE_NOT_FOUND = '0',
}
