import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum, ResourceTypeEnum } from 'src/shared/enums';
import { IChallengeExpertsGroup } from 'src/shared/models/challengeExpertsGroup.model';
import { buildChallengeSpeakers } from './BuilderSpeakers';

const { EXPERTS_GROUP_ICON, EXPERTS_GROUP_DESCRIPTION } =
  ResourceTypeDetailEnum;

export const buildExpertsGroup = (
  resource: IResourceList,
  sortedResources: IResourceList[]
): IChallengeExpertsGroup => {
  const {
    resourceDetailList,
    idResource,
    name: title,
    resourceList,
  } = resource;

  const MAX_SPEAKERS_PER_CHALLENGE = 6;

  let expertsGroup: IChallengeExpertsGroup;
  const expertsList = [];

  sortedResources.forEach((resourceInList: IResourceList) => {
    const challengeSpeakers = resourceList?.filter(
      (_resource) =>
        _resource.idResourceType.idResourceType === ResourceTypeEnum.SPEAKER
    );
    if (
      resourceInList.indResource === idResource &&
      resourceInList.idResourceType.idResourceType === ResourceTypeEnum.SPEAKER
    ) {
      const speaker = buildChallengeSpeakers(resourceInList);
      if (
        (!speaker.email ||
          !sortedResources.find((_speaker) => {
            // FIXME: ¿Qué significa el núnero 2?
            _speaker.resourceDetailList[2]?.value === speaker.email;
          })) &&
        challengeSpeakers.length < MAX_SPEAKERS_PER_CHALLENGE
      ) {
        expertsList.push(speaker);
      }
    }
  });
  resourceDetailList
    .filter(({ status }) => status)
    .forEach((_item) => {
      switch (_item.idResourceTypeD.idResourceTypeD) {
        case EXPERTS_GROUP_ICON:
          expertsGroup = {
            ...expertsGroup,
            icon: _item.value,
          };
          break;

        case EXPERTS_GROUP_DESCRIPTION:
          expertsGroup = {
            ...expertsGroup,
            description: _item.value,
          };
          break;
      }
    });

  expertsGroup = {
    ...expertsGroup,
    title: title,
    idResource: idResource,
    expertsList,
  };

  return expertsGroup;
};
