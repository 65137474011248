import { Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import { IRootReducers } from 'src/reducers';
import { IChallengeImage, IChallengeResourceRender } from 'src/shared/models';

interface OwnProps extends IChallengeResourceRender {
  config: IChallengeImage;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const ChallengeImage: FC<Props> = ({ config }) => {
  if (!config) return <React.Fragment />;

  const [showImageModal, setShowImageModal] = useState(false);

  const { idResource, title, url } = config;

  const imageFullView = (
    <Modal
      {...{
        className: 'container',
        centered: true,
        footer: false,
        width: '100%',
        closeIcon: undefined,
        children: (
          <img
            height="100%"
            width="100%"
            id={`challengeImage-${idResource}`}
            src={url}
          />
        ),
        hideFooter: true,
        visible: showImageModal,
        onCancel: () => setShowImageModal(false),
        showIcon: false,
      }}
    />
  );

  const component = config && (
    <Row justify="center" className="challengeDetail--Image__container">
      <ChallengeResourceWrapper_Blocked />
      <img
        height="100%"
        width="100%"
        id={`challengeImage-${idResource}`}
        className={`challengeDetail--Image__container--image`}
        src={url}
        onClick={() => setShowImageModal(true)}
      />
      {imageFullView}
    </Row>
  );

  return (
    <ChallengeResourceWrapper
      children={component}
      description={config.description}
      styleClass="challengeDetail--Image"
      title={title}
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ChallengeImage);
