import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { ResourceTypeDetailEnum, ResourceTypeEnum } from 'src/shared/enums';
import {
  IChallengeActivationButton,
  IChallengeActivationButtonCallback,
  IChallengeLegalCheck,
  IUser,
} from 'src/shared/models';

const { LEGAL_CHECK } = ResourceTypeEnum;

const {
  ACTIVATION_BUTTON_CALLBACK_COMPLETED_MESSAGE,
  ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE,
  ACTIVATION_BUTTON_CALLBACK_REQUEST_URL,
  ACTIVATION_BUTTON_ANCHOR_IN_HEADER,
  LEGAL_CHECK_URL,
} = ResourceTypeDetailEnum;

export const buildActivationButton = (
  resource: IResourceList,
  user: IUser
): IChallengeActivationButton => {
  const { idResource, activate } = resource;

  let challengeActivationButton: IChallengeActivationButton;
  let callBack: IChallengeActivationButtonCallback;
  let shouldRenderInChallengeHeader: boolean;
  let legalCheck: IChallengeLegalCheck;

  resource.resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case ACTIVATION_BUTTON_CALLBACK_COMPLETED_MESSAGE:
          callBack = { ...callBack, onCompleteMessage: value };
          break;
        case ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE:
          switch (value) {
            case 'post':
              callBack = { ...callBack, requestType: 'post' };
              break;
            case 'put':
              callBack = { ...callBack, requestType: 'put' };
              break;
            default:
              callBack = { ...callBack, requestType: null };
              break;
          }
          break;

        case ACTIVATION_BUTTON_CALLBACK_REQUEST_URL:
          callBack = { ...callBack, requestUrl: value };
          break;

        case ACTIVATION_BUTTON_ANCHOR_IN_HEADER:
          shouldRenderInChallengeHeader = value === 'true';
          break;
      }
    });

  if (!callBack) {
    /* Because of the requirement to hide the Callback Request fields from Activation Button resource in the BO, we
      need to check if the button does not have "ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE" assigned and put null request
      type by default - LIWW-3393
     */
    callBack = { ...callBack, requestType: null };
  }

  resource.resourceList
    .filter(({ status }) => status)
    .forEach((resource: IResourceList) => {
      switch (resource.idResourceType.idResourceType) {
        case LEGAL_CHECK:
          legalCheck = buildLegalCheck(
            resource,
            user,
            shouldRenderInChallengeHeader
          );
          break;
      }
    });

  return {
    ...challengeActivationButton,
    isCampaignActivator: activate,
    shouldRenderInChallengeHeader,
    callBack,
    legalCheck,
    idResource,
  };
};

const buildLegalCheck = (
  legalCheckData: IResourceList,
  user: IUser,
  shouldRenderInChallengeHeader: boolean
): IChallengeLegalCheck => {
  let legalCheck: IChallengeLegalCheck;
  legalCheckData.resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case LEGAL_CHECK_URL:
          legalCheck = {
            ...legalCheck,
            legalUrl: value,
            shouldRenderInChallengeHeader: shouldRenderInChallengeHeader,
            isActivationButton: true,
          };
          break;
      }
    });

  return {
    ...legalCheck,
    wasPerformed: false,
    wasAccepted: false,
  };
};
