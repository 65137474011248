export const getHeaderHeight = () => {
  let height = 0;
  if (typeof window !== 'undefined') {
    const header = window.document.querySelector('.header-fixed');
    if (header) {
      height += header.getBoundingClientRect().height;
      const disclaimer = header.querySelector('.container .disclaimer');
      if (disclaimer) {
        height += disclaimer.getBoundingClientRect().height;
      }
    }
  }
  return height;
};
