import { ICenter, ICenterRequest } from 'src/shared/models';
import { ClauseTypeEnum, UserStatusEnum } from 'src/shared/enums/user.enum';
import {
  CenterRolesEnum,
  CenterStatusEnum,
} from 'src/shared/enums/usersCenter.enum';
import { isPlatformPIN } from 'utils/platformUtils';

const { EMPLOYEE, MANAGER } = CenterRolesEnum;
const { CLAUSE_REFERRAL } = ClauseTypeEnum;
const {
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  HCP_NOT_VALIDATED,
  MANAGER_NOT_VALIDATED,
  STAFF_NOT_VALIDATED,
  NO_CENTER,
  ACCOUNT_NOT_VALIDATED,
} = UserStatusEnum;

export const myPointsStatus = [
  ACCOUNT_NOT_VALIDATED,
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  MANAGER_NOT_VALIDATED,
  HCP_NOT_VALIDATED,
  STAFF_NOT_VALIDATED,
  NO_CENTER,
];

export const ordersStatus = [
  ACCOUNT_NOT_VALIDATED,
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  NO_CENTER,
];

export const referralClause = [CLAUSE_REFERRAL];

export const centerProfileStatus = [
  ACCOUNT_NOT_VALIDATED,
  ACCEPTED_MANAGER,
  ACCEPTED_EMPLOYEE,
];

export const centerMembersStatus = [ACCEPTED_MANAGER];

export const myInvoicesStatus = [
  ACCEPTED_MANAGER,
  ACCEPTED_EMPLOYEE_PIN,
  ACCOUNT_NOT_VALIDATED,
];

export const redeemPointStatus = [
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  NO_CENTER,
];

export const myProfileStatus = [
  ACCOUNT_NOT_VALIDATED,
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  STAFF_NOT_VALIDATED,
  NO_CENTER,
];

export const myProfileStatusForEdit = [
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  STAFF_NOT_VALIDATED,
  NO_CENTER,
];

export const myOrdersValidStatus = [
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  STAFF_NOT_VALIDATED,
  NO_CENTER,
];

export const centerProfileValidStatus = [
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  STAFF_NOT_VALIDATED,
  NO_CENTER,
];

export const addressesProfileValidStatus = [
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
  STAFF_NOT_VALIDATED,
  NO_CENTER,
];

export const eventsStatus = [
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
];

export const requestOrderStatus = [
  ACCOUNT_NOT_VALIDATED,
  ACCEPTED_EMPLOYEE,
  ACCEPTED_EMPLOYEE_PIN,
  ACCEPTED_MANAGER,
];

export const getValidCenter = (
  requests: ICenterRequest[]
): { idCenter?: ICenter; role?: CenterRolesEnum } => {
  if (!requests?.length) return;

  const { idCenter, idRole } = requests[0];

  if (!idCenter || !idRole) return;

  const { centerStatus, idSalesforce, status } = idCenter;

  if (!idSalesforce || !status || centerStatus !== CenterStatusEnum.GRANT)
    return;

  const role = idRole === MANAGER || idRole === EMPLOYEE ? idRole : null;

  return { idCenter, role };
};

export const isManager = (userStatus: UserStatusEnum) => {
  if (isPlatformPIN) return userStatus === ACCEPTED_EMPLOYEE_PIN;

  return userStatus === ACCEPTED_MANAGER;
};
